import React from "react";
import classNames from "classnames";
import { Form } from "antd";

export const Label = ({ className, label, required, extra, validateStatus, ...props }) => (
  <Form.Item
    className={classNames("form-label", validateStatus, className)}
    colon={false}
    label={
      <span className="form-label-text">
        {label}
        {/* {required === false ? '' : ' *'} */}
      </span>
    }
    {...props}
  />
);
