import { Space } from 'antd'
import React, { FC } from 'react'

interface FlagProps {
  countryCode: string
  label?: string
  [key: string]: any
}

export const Flag: FC<FlagProps> = ({ countryCode, label, ...props }) => (
  <Space>
    <img src={`/flags/${countryCode.toLowerCase()}.png`} width="16" height="12" alt={label || countryCode} {...props} />
    {label}
  </Space>
)