import { convertToFloat } from '../helpers/utils'

type MoneyValue = {
  value?: number;
  isWrittingDecimal?: boolean;
  decimal?: string;
  type?: string;
  currency?: string;
  locale?: string;
  language?: string; // Add this line
}

type NumberFormatOptions = {
  minDigits?: number;
  maxDigits?: number;
}

// Change local to display FR as DE which mean 123.456,789 format
const getLocale = (language: string) => (language === 'fr' ? 'de' : language)

export const money = {
  serialize: ({ type, currency = 'EUR', language }: { type: string; currency?: string; language?: string }) =>
    (value: string = '0') => {
      const locale = getLocale(language || '')
      const { num, isWrittingDecimal, decimal } = convertToFloat(value.toString(), locale)
      return { value: num, isWrittingDecimal, decimal, type, currency, locale }
    },
  deserialize: ({ value, isWrittingDecimal, decimal, language = 'fr' }: MoneyValue = { value: null }) => {
    const locale = getLocale(language)
    const val = new Intl.NumberFormat(locale).format(value || 0)
    const finalValue = isWrittingDecimal ? `${val}${decimal}` : val
    return /^[\ ,.0-9]*$/.test(finalValue) ? finalValue : ''
  },
  toStr: ({ value = 0, currency = 'EUR', language = 'fr' }: MoneyValue = {}, { minDigits = 0, maxDigits = 2 }: NumberFormatOptions = {}) => {
    const locale = getLocale(language)
    return isNaN(value)
      ? value.toString()
      : new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: minDigits,
          maximumFractionDigits: maxDigits,
        }).format(value)
  },
}