import React, { useState, useCallback, useMemo } from 'react'
import { Button, Input, Space, Table, Tag, Tooltip, Typography } from 'antd'
import moment from 'moment'
import DownloadIcon from '../../components/Icon/DownloadIcon'
import BookIcon from '../../components/Icon/BookIcon'

import { useModal } from '../hooks/useModal'

const { Paragraph, Text } = Typography

interface BodaccTagProps {
  type: string;
}

const BodaccTag: React.FC<BodaccTagProps> = ({ type }) => {
  switch (type) {
    case 'Vente':
      return <Tag color="orange">{type}</Tag>
    case 'Création':
      return <Tag color="blue">{type}</Tag>
    case 'Radiation':
      return <Tag color="red">{type}</Tag>
    case 'Procédure collective':
      return <Tag color="volcano">{type}</Tag>
    case 'Modification':
      return <Tag color="gold">{type}</Tag>
    case 'Dépôt des comptes':
      return <Tag color="green">{type}</Tag>
    case 'Immatriculation':
      return <Tag color="cyan">{type}</Tag>
    default:
      return <Tag>{type}</Tag>
  }
}

interface BodaccItemViewProps {
  label: string;
  value: string | number | undefined;
  valueCopyable?: boolean;
}

const BodaccItemView: React.FC<BodaccItemViewProps> = ({ label, value, valueCopyable = true }) =>
  Boolean(value) && (
    <Paragraph>
      <Text strong>{`${label}: `}</Text>
      <Text ellipsis={true} copyable={valueCopyable}>
        {value}
      </Text>
    </Paragraph>
  )

interface BodaccData {
  type: any
  numero_parution: string;
  numero_annonce: string;
  description?: string;
  administration?: string;
  capital?: string;
  adresse?: string;
  commentaires?: string;
  oppositions?: string;
  publication_legale?: string;
}

const bodaccModificationView = ({ numero_parution, numero_annonce, description, administration }: BodaccData) => {
  const partialKey = `${numero_parution}-${numero_annonce}-`

  return [
    <BodaccItemView key={partialKey + description} label={'Description'} value={description} />,
    <BodaccItemView key={partialKey + administration} label="Administration" value={administration} />,
  ]
}

const bodaccCreationView = ({ numero_parution, numero_annonce, description, administration, capital, adresse }: BodaccData) => {
  const partialKey = `${numero_parution}-${numero_annonce}-`

  return [
    <BodaccItemView key={partialKey + description} label={'Description'} value={description} />,
    <BodaccItemView key={partialKey + administration} label="Administration" value={administration} />,
    <BodaccItemView key={partialKey + capital} label="Capital" value={capital} />,
    <BodaccItemView key={partialKey + adresse} label="Adresse" value={adresse} />,
  ]
}

const bodaccSaleView = ({
  numero_parution,
  numero_annonce,
  description,
  administration,
  commentaires,
  adresse,
  oppositions,
  publication_legale,
}: BodaccData) => {
  const partialKey = `${numero_parution}-${numero_annonce}-`

  return [
    <BodaccItemView key={partialKey + description} label={'Description'} value={description} />,
    <BodaccItemView key={partialKey + administration} label="Administration" value={administration} />,
    <BodaccItemView key={partialKey + adresse} label="Adresse" value={adresse} />,
    <BodaccItemView key={partialKey + commentaires} label="Commentaires" value={commentaires} />,
    <BodaccItemView key={partialKey + oppositions} label="Oppositions" value={oppositions} />,
    <BodaccItemView key={partialKey + publication_legale} label="Publication légale" value={publication_legale} />,
  ]
}

const getBodaccView = (props: BodaccData) => {
  switch (props.type) {
    case 'Modification':
      return bodaccModificationView(props)
    case 'Dépôt des comptes':
    case 'Radiation':
      return null
    case 'Création':
      return bodaccCreationView(props)
    case 'Vente':
      return bodaccSaleView(props)
    default:
      console.warn(`Bodacc publication type not handle ${props.type}`)
      return null
  }
}

interface BodaccReferenceProps {
  bodacc: string;
  numero_parution: string;
  dateLocalised: string;
  numero_annonce: string;
}

const BodaccReference: React.FC<BodaccReferenceProps> = ({ bodacc, numero_parution, dateLocalised, numero_annonce }) => (
  <BodaccItemView
    label={'Références de publication'}
    value={`BODACC ${bodacc} n°${numero_parution} du ${dateLocalised}, annonce n°${numero_annonce}`}
  />
)

interface SiretViewProps {
  id: string;
  type: string;
  validateStatus: string;
  stepId: string;
  processInstance: string;
  loading: boolean;
  meta: any;
  initialOptions: any;
  preview: any;
  handleImportClick: (value: any) => void;
  buttonMsg?: string;
  errorMsg?: string;
  getBodaccEntries: (value: any) => Promise<any>;
  addons?: string[];
  value: string;
}

export const SiretView: React.FC<SiretViewProps> = ({
  id,
  // type,
  // validateStatus,
  // stepId,
  // processInstance,
  // loading,
  meta,
  // initialOptions,
  // preview,
  handleImportClick: handleImportClickProps,
  // buttonMsg = 'Import',
  errorMsg,
  getBodaccEntries,
  addons = [],
  ...props
}) => {
  const { displayModal } = useModal()
  const [loadingQueryName, setLoadingQueryName] = useState<string | false>('')

  const handleImportClick = useCallback(async () => {
    setLoadingQueryName('import')
    await handleImportClickProps(props.value)
    setLoadingQueryName('')
  }, [handleImportClickProps, props.value])

  const handleBodaccClick = useCallback(async () => {
    setLoadingQueryName('bodacc')
    const entries = await getBodaccEntries(props.value)
    const listSorted = (entries || [])
      .map(el => {
        const momentDate = moment(el.date, 'YYYY-MM-DD')
        return {
          ...el,
          momentDate,
          dateLocalisedLong: momentDate.format('LL'),
          dateLocalised: momentDate.format('L'),
        }
      })
      .sort(({ momentDate: a }, { momentDate: b }) => a.isBefore(b))

    const columns = [
      {
        className: 'form-bodacc-view-item-date',
        render: (item: any) => (
          <Space direction="vertical">
            <Typography.Title level={5}>{item.dateLocalisedLong}</Typography.Title>
            <BodaccTag type={item.type} />
          </Space>
        ),
      },
      {
        render: (item: any) => {
          return [
            ...(getBodaccView(item) || []),
            <BodaccItemView
              key={`${item.numero_parution}-${item.numero_annonce}-denomination`}
              label={'Dénomination sociale'}
              value={item.denomination}
            />,
            <BodaccItemView
              key={`${item.numero_parution}-${item.numero_annonce}-rcs`}
              label={'RCS'}
              value={item.rcs}
            />,
            <BodaccReference {...item} />,
          ]
        },
      },
    ]

    const table = (
      <div style={{ height: '80vh', overflowY: 'scroll' }}>
        <Table
          showHeader={false}
          columns={columns}
          dataSource={listSorted}
          rowKey={({ numero_annonce, numero_parution }: any) => `${numero_parution}-${numero_annonce}`}
          pagination={{ defaultPageSize: 100, hideOnSinglePage: true, showLessItems: true, showSizeChanger: false }}
        />
      </div>
    )

    displayModal({
      title: `Announcements published in Bodacc for ${props.value}`,
      width: 1000,
      centered: true,
      cancelButtonProps: { style: { visibility: 'hidden' } },
      type: 'info',
      content: table,
      maskClosable: true,
      closable: true,
    })
    setLoadingQueryName(false)
  }, [displayModal, getBodaccEntries, props.value])

  const addonsButton = useMemo(() => {
    const importButton = (
      <Tooltip key="import" title="Import">
        <Button
          loading={loadingQueryName === 'import'}
          disabled={meta.error}
          onClick={handleImportClick}
          type="text"
          size="small"
          icon={<DownloadIcon style={{ strokeWidth: '1px' }} />}
        />
      </Tooltip>
    )

    const bodaccEntriesButton = (
      <Tooltip key="bodacc" title="Bodacc">
        <Button
          loading={loadingQueryName === 'bodacc'}
          disabled={meta.error}
          onClick={handleBodaccClick}
          type="text"
          size="small"
          icon={<BookIcon style={{ strokeWidth: '1px' }} />}
        />
      </Tooltip>
    )

    return [
      ...(addons.includes('import') ? [importButton] : []),
      ...(addons.includes('bodacc') ? [bodaccEntriesButton] : []),
    ]
  }, [addons, handleBodaccClick, handleImportClick, loadingQueryName, meta.error])

  return (
    <>
      <Input id={id} {...props} addonAfter={addonsButton} maxLength={17} />
      {Boolean(errorMsg) && errorMsg}
    </>
  )
}