import React from "react";
import { useForm } from "react-final-form";
import { getModel } from "../../../../data-model/getModel";
import { getView } from "../../getView";
import jmespath from 'jmespath'
import { handleOptions } from "../../../../data-model/shared/options";
import { TypeField, TypeFieldEntity, TypeFieldEnumeration, TypeFieldIdCheck, TypeFieldCheckBox, TypeFieldSelector, TypeFieldSiret, TypeFieldUpload } from "../../TypeField";
import { get, isEqual, isNil } from "lodash";
import i18n from 'i18next'
import { TABS } from "../../../../types";
import { Divider } from "../../../../data-view";

type FormFieldContentPropsType = {
    user: any,
    name: string
    type: string,
    primaryType?: string,
    frame: any,
    language?: string,
    label: string,
    filename?: string,
    options?: any[],
    hidden?: boolean,
    initialValue?: any,
    tabs: TABS,
    processInstance: any
}
interface FieldDefinition {
  serialize: (options: SerializeOptions) => any; // Define the return type of serialize appropriately
  dataOptions?: Record<string, any>;
}

interface SerializeOptions {
  language: string;
  options?: any;
  type: string;
  // Add other properties that might be part of the serialization options
}

interface SetValueParams {
  name: string;
  type: string;
  options?: any; // Define options type if possible
  definition?: FieldDefinition;
}

interface SetValueChangeFunction {
  (name: string, value: any): void; // Define the value type if possible
}

interface SetValueStateFunction {
  (): { values: Record<string, any> }; // Define the state shape appropriately
}

const setValue = (
  { name, type, options, definition }: SetValueParams,
  value: any, // Define the value type if possible
  { getState, change }: { getState: SetValueStateFunction; change: SetValueChangeFunction }
) => {
  const values = getState().values;
  const valueFromData = get(values, name);

  if (
    (!valueFromData ||
      valueFromData.value === null ||
      valueFromData.value === undefined ||
      !isEqual(valueFromData.value, value)) &&
    ((type === 'uuid' && !valueFromData?.value) || type !== 'uuid')
  ) {
    const { serialize, dataOptions } = definition || {};

    if (!serialize) {
      throw new Error(`[setValue] No serialize function for type ${type} (name: ${name})`);
    }

    const valueSerialized = serialize({ ...dataOptions, language: i18n.language, options, type })(value);
    change(name, valueSerialized);
  }
};

const FormFieldContent: React.FC<FormFieldContentPropsType> = ({ 
    initialValue: initialValueProps,
    name,
    user,
    type,
    primaryType,
    language,
    tabs,
    ...props
  }) => {
    // const initialValue = ''
    const initialValue = isNil(initialValueProps) ? '' : initialValueProps
    const frame = props.frame
    language = (language && language.toLowerCase()) || 'en';
    
    const form = useForm();
    const { getState } = React.useMemo(() => form, [form])
    
    const defaultValue = React.useMemo(
      () =>
      typeof initialValue === 'string' && initialValue.startsWith('@') //TODO Check if jmespath work
      ? jmespath.search(getState().values, initialValue.slice(1))
      : initialValue,
      [getState, initialValue]
      )
      const model = React.useMemo(() => getModel({ type: primaryType || type, ...props}), [])
      
      const { View, viewOptions } = React.useMemo(() => getView(primaryType || type, type), [primaryType, type])    
      
      const params = React.useMemo(() => {
        const propsWithViewOptions = { ...(viewOptions || {}), ...props }
        const {processInstance} = propsWithViewOptions
        const companyName = processInstance?.data?.companyName?.value || ''
        return {
          definition: { ...model, View },
          companyName: companyName,
          // eslint-disable-next-line camelcase
          filename_en: propsWithViewOptions.filename ? propsWithViewOptions.filename : undefined,
          locale: language,
          user,
          ...Object.keys(propsWithViewOptions).reduce(
            (acc, propName) =>
            propName.endsWith('_' + language)
            ? { ...acc, [propName.slice(0, -1 - language.length)]: propsWithViewOptions[propName] }
            : acc,
            propsWithViewOptions
            ),
            defaultValue,
            id: `label_${name}`,
            initialOptions: props.options,
            name,
          options: handleOptions(props.options, model, language),
          primaryType,
          type,
        }
    }, [View, defaultValue, language, model, name, props, type, primaryType, user, viewOptions])
    
    if (tabs === TABS.DOCUMENT && (type !== 'file' && type !== 'idCheck')) {
      return null
    }
    
    if (params?.hidden === true || type === 'uuid') {
      setValue(params, defaultValue, form)
      return null
    }

    if (type === 'info') {
        return null
    } else if (type === 'checkbox') {
      return <TypeFieldCheckBox icon="" {...params} />
    }

    if(type === 'divider') {
      return <Divider label={params['label_' + language.toLowerCase()] || params.label} />
    }

    if (primaryType === 'enumeration') {
        return <TypeFieldEnumeration {...params} />
    }
    console.log('dataOptions in FormFieldContent:', model?.dataOptions)
    console.log('asyncData in FormFieldContent:', model?.dataOptions?.asyncData)
    switch (model?.dataOptions?.asyncData) {
        case 'registrar':
        case 'countries':
        case 'cities':
        case 'nationality':
        case 'legalFormFr':
        case 'companyMemberRoles':
          return <TypeFieldSelector {...params} />
        case 'bodacc':
        case 'insee':
        case 'siret':
          return <TypeFieldSiret frame={frame} {...params} />
        case 'upload':
          return tabs === TABS.INFORMATION ? null :  <TypeFieldUpload {...params} />
        case 'idCheck':
            return tabs === TABS.INFORMATION ? null : <TypeFieldIdCheck {...params} />
        case 'entity':
          return <TypeFieldEntity frame={frame} {...params} />
    
        default:
          return <TypeField prefix={""} initialValue={undefined} {...params} />
            // return <>'TypeField'</>
      }
}

export default FormFieldContent