import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="5 5 25 25" {...props}>
    <path
      d="M4.783,15.473l9.828,4.428l4.428,9.829l7.129-21.386L4.783,15.473z M7.527,15.611l15.998-5.332l-8.646,8.646L7.527,15.611z
	 M18.9,26.985l-3.314-7.353l8.646-8.646L18.9,26.985z"
    />
  </svg>
)

export default SVG
