/* eslint-disable camelcase */
import * as dataView from "../../data-view";
import { Icon } from "../../data-view";

type ViewOptions = {
  label?: string;
  label_fr?: string;
  mode?: string;
  showTime?: boolean;
  addons?: string[];
  buttonMsg?: string;
  maxRows?: number;
  desciption?: string;
  desciption_fr?: string;
  icon?: typeof Icon;
};

type ViewType = {
  View: any;
  viewOptions: ViewOptions;
};

export const getView = (type?: string, realType?: string): ViewType => {
  let View: React.ReactNode;
  let viewOptions: ViewOptions = {};

  switch (type) {
    /*****************************************************************************
     *                                  Base Types                                *
     *****************************************************************************/

    /******************************** Numbers ************************************/

    case "info":
      View = dataView.NoView;
      break;

    /******************************** Numbers ************************************/
    case "number":
      View = dataView.NumberView;
      viewOptions = { label: "Number", label_fr: "Nombre" };
      break;
    case "integer":
      View = dataView.NumberView;
      viewOptions = { label: "Integer", label_fr: "Nombre entier" };
      break;
    case "unsignedInteger":
      View = dataView.NumberView;
      viewOptions = { label: "Unsigned integer", label_fr: "Nombre entier positif" };
      break;
    case "money":
      View = dataView.MoneyView;
      viewOptions = { label: "Amount of money / Price", label_fr: "Montant / Prix" };
      break;
    case "entity":
      View = dataView.EntityView;
      viewOptions = { icon: Icon, label: "Entity", label_fr: "Entité" };
      break;
    case "enumeration":
      const typed = getView(realType);
      View = dataView.SelectView;
      viewOptions = typed.viewOptions;
      break;
    /********************************* Text *************************************/
    case "text":
    case "siret":
      View = dataView.TextView;
      break;
    case "textarea":
      View = dataView.TextAreaView;
      break;
    case "address":
      View = dataView.TextView;
      viewOptions = { label: "Adresse", label_fr: "Address" };
      break;
    case "zipCode":
      View = dataView.TextView;
      viewOptions = { label: "Zip code", label_fr: "Code postal" };
      break;
    case "email":
      View = dataView.TextView;
      viewOptions = { label: "Email", label_fr: "Email" };
      break;
    case "iban":
      View = dataView.TextView;
      viewOptions = { label: "IBAN", label_fr: "IBAN" };
      break;
    case "bic":
      View = dataView.TextView;
      viewOptions = { label: "BIC", label_fr: "BIC" };
      break;
    /******************************* Controls ***********************************/
    case "checkbox":
      View = dataView.CheckBoxView;
      break;
    case "selector":
      View = dataView.SelectView;
      break;
    case "multiple-selector":
      viewOptions = { mode: "multiple" };
      View = dataView.SelectView;
      break;
    case "radio":
      View = dataView.RadioView;
      break;
    case "phoneNumber":
      View = dataView.PhoneView;
      viewOptions = { label: "Phone", label_fr: "Téléphone" };
      break;
    case "datePicker":
      viewOptions = { mode: "date", showTime: false };
      View = dataView.DateTimeView;
      break;
    case "timePicker":
      viewOptions = { mode: "time" };
      View = dataView.DateTimeView;
      break;
    case "dateTimePicker":
      View = dataView.DateTimeView;
      break;
    case "divider":
      View = dataView.Divider;
      break;
    /******************************** AsyncData ************************************/
    case "selectorAsyncData":
    case "registrar":
      View = dataView.SelectView;
      break;
    case "countries": // deprecated
    case "country":
      View = dataView.SelectView;
      viewOptions = { label: "Country", label_fr: "Pays" };
      break;
    case "cities": // deprecated
    case "city":
      View = dataView.SelectView;
      viewOptions = { label: "City", label_fr: "Ville" };
      break;
    case "nationality":
      View = dataView.SelectView;
      viewOptions = { label: "Nationality", label_fr: "Nationalité" };
      break;
    case "companyLegalFormFr":
    case "companyLegalFormUk":
      View = dataView.SelectView;
      viewOptions = { label: "Legal form", label_fr: "Forme légale" };
      break;
    case "companyRegistrationNumberFr":
      View = dataView.SiretView;
      viewOptions = {
        addons: ["import"],
        label: "French company registration number (SIRET)",
        label_fr: "Numéro SIRET de la société",
      };
      break;
    case "companyBodacc":
      View = dataView.SiretView;
      viewOptions = {
        addons: ["bodacc"],
        buttonMsg: "BODACC",
        label: "French company registration number (SIRET)",
        label_fr: "Numéro SIRET de la société",
      };
      break;
    case "companyRegistrationNumberFrSiren":
      View = dataView.TextView;
      viewOptions = {
        label: "French company registration additional number (SIREN)",
        label_fr: "Numéro SIREN de la société",
      };
      break;
    case "companyRegistrationNumberFrApeCode":
      View = dataView.TextView;
      viewOptions = {
        label: "Company APE number",
        label_fr: "Code APE de la société",
      };
      break;
    /********************************** Files **************************************/
    case "file":
      View = dataView.FileView;
      break;
    case "idCheck":
      View = dataView.IdCheckView;
      break;

    /*****************************************************************************
     *                                   Person                                  *
     *****************************************************************************/

    case "firstName":
      View = dataView.TextView;
      viewOptions = { label: "First name", label_fr: "Prénom" };
      break;
    case "lastName":
      View = dataView.TextView;
      viewOptions = { label: "Last name", label_fr: "Nom" };
      break;
    case "gender":
      View = dataView.RadioView;
      viewOptions = { label: "Gender", label_fr: "Sexe" };
      break;
    case "birthDate":
      View = dataView.DateTimeView;
      viewOptions = { label: "Date of birth", label_fr: "Date de naissance", mode: "date", showTime: false };
      break;

    /*****************************************************************************
     *                                   Company                                 *
     *****************************************************************************/

    case "companyName":
      View = dataView.TextView;
      viewOptions = { label: "What's the name of the company?", label_fr: "Quel est le nom de la société ?" };
      break;
    case "companyCapital":
      View = dataView.MoneyView;
      viewOptions = {
        label: "Specify the share capital for the new company",
        label_fr: "Indiquez le montant du capital social de la nouvelle société",
      };
      break;
    case "companyActivity1":
      View = dataView.TextAreaView;
      viewOptions = {
        label: "Describe your project",
        label_fr: "Décrivez l'activité principale de la société",
      };
      break;
    case "companyActivity2":
      View = dataView.TextAreaView;
      viewOptions = {
        label: "Additional activities",
        label_fr: "Activités complémentaires",
        maxRows: 5,
      };
      break;
    case "companyShareAmount":
      View = dataView.MoneyView;
      viewOptions = {
        desciption:
          "Upon standard bylaws, each share provides for one voting right and each share much of a value equal or higher than € 0.01.",
        desciption_fr:
          "Notez que chaque action donne droit à un droit de vote et aucune action ne peut avoir une valeur nominale inférieure à 0,01 €",
        label: "Specify the number of shares",
        label_fr: "Indiquez le nombre d'actions",
      };
      break;
    case "companyActivityFrequency":
      View = dataView.SelectView;
      viewOptions = {
        label: "What's the frequency of your activity?",
        label_fr: "Précisez la fréquence de votre activité",
      };
      break;
    case "companyActivityCategory":
      View = dataView.SelectView;
      viewOptions = {
        label: "What's the closest category to your principal activity?",
        label_fr: "Quelle est la catégorie principale de votre activité",
      };
      break;
    case "companyActivityCategorySCI":
      View = dataView.SelectView;
      viewOptions = {
        label: "What's the closest category to your principal activity?",
        label_fr: "Quelle est la catégorie principale de votre activité",
      };
      break;
    case "companyShareholderRole":
    case "companyMemberRoles":
    case "companyShareholderRoles":
    case "companyShareholderRoleSARL":
    case "companyShareholderRoleSCI":
      View = dataView.SelectView;
      viewOptions = {
        label: "Role within the company",
        label_fr: "Rôle au sein de l'entreprise",
      };
      break;
    case "companyShareholderType":
      View = dataView.SelectView;
      viewOptions = {
        label: "The founder is a person or a company?",
        label_fr: "S'agit-il d'une personne physique ou morale ?",
      };
      break;
    case "companyHeadquarterType":
      View = dataView.SelectView;
      viewOptions = {
        label:
          "Among the following categories, indicate the one corresponding to the address of the company's registered office",
        label_fr:
          "Parmi les catégories suivantes, indiquez celle correspondant à l'adresse du siège social de la société",
      };
      break;
    case "companyCreationType":
      View = dataView.SelectView;
      viewOptions = {
        label: "How did you start the company?",
        label_fr: "Quelle est l'origine de la société ?",
      };
      break;
    case "companyCreationTypeSCI":
      View = dataView.SelectView;
      viewOptions = {
        label: "How did you start the company?",
        label_fr: "Quelle est l'origine de la société ?",
      };
      break;
    case "companyIncomeTaxSystem":
      View = dataView.SelectView;
      viewOptions = {
        label: "Which tax regime would you like to choose for the taxation of your company's profits?",
        label_fr: "Quel régime fiscal souhaitez-vous choisir pour l'imposition des bénéfices de votre société ?",
      };
      break;
    case "companyIncomeTaxSystemSCI":
      View = dataView.SelectView;
      viewOptions = {
        label: "Which tax regime would you like to choose for the taxation of your company's profits?",
        label_fr: "Quel régime fiscal souhaitez-vous choisir pour l'imposition des bénéfices de votre société ?",
      };
      break;
    case "companyVatSystem":
      View = dataView.SelectView;
      viewOptions = {
        label: "Which VAT taxation regime would you like to choose for your company?",
        label_fr: "Quel régime souhaitez-vous choisir pour la TVA ?",
      };
      break;

    default:
      View = null;
  }

  return { View, viewOptions };
};
