import React, { useMemo } from 'react';
import FormFieldContent from './FormField/FormFieldContent';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next'
import { extendedLocaleToLang } from '../../data-model/shared/formatters';
import { Button } from 'antd';
import { FormFieldCheckConditional } from './FormFieldCheckConditional';
import { TABS } from '../../types';


type FormRunnerPropsType = {
    frame: any;
    user: any;
    processInstance: any;
    title: string;
    initialValues: any;
    onSubmit: (values: any, index: number) => Promise<boolean>;
    index: number
    tabs: TABS
    // saveData: (values: any) => Promise<boolean>;
}

const FormRunner: React.FC<FormRunnerPropsType> = ({ 
    frame, 
    user,
    processInstance,
    // title,
    initialValues,
    onSubmit,
    index,
    tabs,
    // saveData
}) => {

    const {
        t,
        i18n: { language: locale },
      } = useTranslation()

    console.log('user ===> ', user, frame)
    const handleSubmit = (values: any) => {
        onSubmit(values, index)
    }

    const language = useMemo(() => extendedLocaleToLang(locale), [locale])



    return (
        <div className='process-instance-form'>
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                subscription={{}}
            >
                {({ handleSubmit }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            {frame.fields.map((field: any, index: number) => (
                                <FormFieldCheckConditional
                                    tabs={tabs}
                                    user={user}
                                    key={`${field.name}_${index}`}
                                    processInstance={processInstance}
                                    {...field}
                                    frame={frame}
                                    language={language}
                                />
                            ))}  
                            <Button 
                                onClick={handleSubmit}
                                className="form-runner-submit-btn"
                                // style={{ backgroundColor: index % 2 === 0 ? '#ED850C' : '#05C5AE'}}
                                type='primary'
                            >
                                {t('common.text.save')}
                            </Button>
                        </form>
                    </>
                )}
            </Form>
        </div>
    )
}

export default FormRunner