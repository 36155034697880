import * as React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { StorePage } from "./pages/StorePage";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import ProcessInstancePage from "./pages/ProcessInstancePage";
import { useMemo } from "react";
import { ConfigProvider } from "antd";
import { AttachPage } from "./pages/AttachPage";

const applicationFragment = gql`
  fragment applicationFragment on Application {
    docuSignBcc
    emailCc
    emailCcEnabled
    embedded
    freemium
    id
    invoiceNumber
    languages
    logo {
      id
      url
    }
    logoLinkUrl
    logoLinkUrlEnabled
    name
    type
    public
    slug
    theme
    termsConditionsUrl
    termsConditionsUrlEnabled
    welcomeContent
    welcomeEnabled
    welcomeLocation
    informations {
      address
      capital
      companyName
      country
      city
      legalForm
      rcsCity
      rcsNumber
      siret
      vatNumber
      zipCode
    }
    config
    legalRepresent {
      type
      firstName
      lastName
      dateOfBirth
      cityOfBirth
      zipCodeOfBirth
      address
      zipCode
      city
      cityResidence
      zipCodeResidence
      nationality
      companyName
      legalForm
      capital
      cityLegal
      zipCodeLegal
      rcsNumber
      rcsCity
      adressHeadquarter
      dateOpeningFiscal
      dateClosingFiscal
    }
  }
`;

const query = gql`
  query AppQuery {
    getCurrentApplication {
      ...applicationFragment
    }

    getCurrentUser {
      id
      createdAt
      type
      firstname
      lastname
      email
      welcomedAt
      facebookId
      incomingMail
      phone
      groups {
        id
        color
        name
        userCount
      }
    }
  }
  ${applicationFragment}
`;

export const App = ({ updateClient }) => {
  const { data: { getCurrentApplication: application, getCurrentUser: user } = {} } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      if (user === null) window.location.href = "/#/login";
    },
  });

  const customTheme = useMemo(() => {
    const colorShade = (col, amt) => {
      col = col.replace(/^#/, "");
      if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

      let [r, g, b] = col.match(/.{2}/g);
      [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

      r = Math.max(Math.min(255, r), 0).toString(16);
      g = Math.max(Math.min(255, g), 0).toString(16);
      b = Math.max(Math.min(255, b), 0).toString(16);

      const rr = (r.length < 2 ? "0" : "") + r;
      const gg = (g.length < 2 ? "0" : "") + g;
      const bb = (b.length < 2 ? "0" : "") + b;

      return `#${rr}${gg}${bb}`;
    };
    const extra = {
      borderRadius: 2,
      fontSize: 12,
      fontFamily: "Helvetica Regular",
      colorPrimary: "#ae56f3",
    };

    if (application?.theme) {
      const regex = /([\w-]*)\s*:\s*([^;]*)/g;
      let match = {};
      const properties: any = {};
      while ((match = regex.exec(application.theme))) {
        properties[match[1]] = match[2].trim();
      }
      if (properties["--primary-color"]) {
        const dark = colorShade(properties["--primary-color"], -110);
        if (!properties["--primary-color-dark"])
          document.documentElement.style.setProperty("--primary-color-dark", dark);

        if (!properties["--primary-color-fade"])
          document.documentElement.style.setProperty("--primary-color-fade", properties["--primary-color"] + "20");

        if (!properties["--primary-color-darkFade"])
          document.documentElement.style.setProperty("--primary-color-darkFade", dark + "15");
        document.documentElement.style.setProperty("--primary-color", properties["--primary-color"]);
        return {
          primaryColor: properties["--primary-color"],
          token: {
            ...extra,
            colorPrimary: properties["--primary-color"],
          },
        };
      }
    }
    return {
      token: {
        ...extra,
      },
    };
  }, [application]);

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        ...customTheme,
      }}
    >
      <HashRouter basename="/">
        <Routes>
          <Route path="login" element={<LoginPage updateClient={updateClient} />} />
          <Route index element={<HomePage />} />
          <Route path="store" element={<StorePage {...{ application, user }} />} />
          <Route path="attach" element={<AttachPage />} />
          <Route path="process-instances/:id" element={<ProcessInstancePage {...{ user }} />} />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  );
};
