import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
// import { createProcessInstanceMutation } from "./graphql/mutations";
import { multilang } from "../../helpers/multiLang";
import { ColorSelection, FilterSelection } from "../../constants/colors";
import WorkflowIcon from "../WorkflowIcon";
import { useTranslation } from "react-i18next";
import { SettingOutlined } from '@ant-design/icons'

type DossierCardPropType = {
    processInstance: any,
    // setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
}

const DossierCard: React.FC<DossierCardPropType> = ({
    processInstance,
    // setSubmitting
}) => {
    const navigate = useNavigate();
    const {
        i18n: { language },
        t,
      } = useTranslation()

    const { product } = processInstance;

    const isColor = (color: string): color is keyof typeof ColorSelection => {
        return Object.keys(ColorSelection).indexOf(color) !== -1
      }
    
      const hexColor = useMemo(
        () => isColor(product?.category.color) ? ColorSelection[product?.category.color] : FilterSelection.color1, [product?.category.color, ColorSelection]
      )
    
      const filterColor = useMemo(
        () => (isColor(product?.category.color) ? FilterSelection[product?.category.color] : FilterSelection.color1),
        [product?.category.color, FilterSelection]
      )

    // const [createProcessInstance] = useMutation(createProcessInstanceMutation)

    const onProductClick = async () => {
        // setSubmitting(true)
        // const {
        //     data: { createProcessInstance: processInstance },
        // } = await createProcessInstance({ variables: { input: { emailId: null, productId: product.id}}})

        // setSubmitting(false)
        const processInstanceLabel = multilang(processInstance.label || t('dashboard.label.undefined'))
        navigate(`/process-instances/${processInstance.id}?from=attach&dossier=${processInstanceLabel}`)
    }

    return (
      // style={{ ::after { borderLeft: `5px solid ${hexColor}` }}}
      <div className="dossier-card-wrap" onClick={onProductClick}>
      <div className="dossier-card-border-left" style={{backgroundColor:`${hexColor}`}}></div>
            <div className="dossier-card-header">
                <WorkflowIcon
                  tagged={product?.status === 'draft' && t('common.status.draft').toLocaleUpperCase()}
                  defaultIcon={<SettingOutlined style={{ fontSize: 26 }} />}
                  remoteIconUrl={product?.icon?.url}
                  color={hexColor}
                  filter={filterColor}
              />
              <div className="dossier-card-description">
                <p className="dossier-card-title">{multilang(processInstance.label || t('dashboard.label.undefined'))}</p>
                <p className="dossier-card-service">{multilang(product?.title)}</p>
              </div>
            </div>
        </div>
    )
}

export default DossierCard