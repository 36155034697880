import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" {...props}>
    <path d="M57.3,48.61a25.19,25.19,0,1,0-21.2,0A34.93,34.93,0,0,0,11.81,81.84V100h4V81.84a30.89,30.89,0,0,1,61.78,0V100h4V81.84A34.93,34.93,0,0,0,57.3,48.61ZM25.51,25.76A21.19,21.19,0,1,1,46.7,47,21.21,21.21,0,0,1,25.51,25.76Z" />
    <path d="M46.7,93.86a4.66,4.66,0,0,0,4.65-4.66h-9.3A4.66,4.66,0,0,0,46.7,93.86Z" />
    <path d="M55.69,74.05v-5.9a9,9,0,0,0-6.34-8.59,2.58,2.58,0,0,0,0-.4,2.7,2.7,0,0,0-5.39,0c0,.14,0,.27,0,.4a9,9,0,0,0-6.33,8.59v5.9c0,3.58-2.58,7.32-6,7.32v5.27h30V81.37C58.27,81.37,55.69,77.63,55.69,74.05Z" />
  </svg>
)

export default SVG
