import React from "react";
import {ReactSVG} from 'react-svg';
import './SVGIcon.less';

type SVGIconPropType = {
    src: string,
    width?: string
}

const SVGIcon: React.FC<SVGIconPropType> = ({
    src, width = '10px'
}) => {
    
    return (
        <div className="svg-icon">
        {src && <ReactSVG src={src} beforeInjection = {(svg) => {
            svg.setAttribute('width', width)
            svg.setAttribute('height', 'auto')
            svg.querySelectorAll('[fill]').forEach((element) => {
                element.setAttribute('fill', 'currentColor')
            })
            svg.querySelectorAll('[stroke]').forEach((element) => {
                element.setAttribute('stroke', 'currentColor')
            })
        }}/>}
        </div>
    );
};

export default SVGIcon;