import React, { MouseEvent, CSSProperties } from "react";
import classnames from "classnames";

export enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  link = "link",
  dashed = "dashed",
  redirect = "redirect",
  filter = "filter",
  dashedFilter = "dashedFilter",
  success = "success",
  warning = "warning",
}

export enum ButtonSize {
  medium = "medium",
  large = "large",
}

export interface ButtonProps {
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  type: ButtonType;
  loading?: boolean;
  block?: boolean;
  text?: string | React.ReactNode;
  size?: string;
  htmlType?: "submit" | "reset" | "button";
  disabled?: boolean;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  className?: string;
}

const LoadingIcon = () => {
  return (
    <span className="button-loading-icon">
      <span role="img" aria-label="loading" className="anticon anticon-loading anticon-spin">
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          data-icon="loading"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
        </svg>
      </span>
    </span>
  );
};

export const InnerButton: React.FC<ButtonProps> = ({
  rightIcon,
  leftIcon,
  text,
  loading,
  className,
  block = false,
  htmlType = "button",
  type,
  size = "medium",
  style,
  labelStyle,
  onClick = () => {},
  ...rest
}) => {
  const classes = classnames(className, "Button", type, size, block && "button--block");

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(event);
  };

  return (
    <button onClick={handleOnClick} style={style} className={classes} {...rest} type={htmlType}>
      {loading && <LoadingIcon />}
      {leftIcon}
      <span style={labelStyle}>{text}</span>
      {rightIcon}
    </button>
  );
};

export default InnerButton;
