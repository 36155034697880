import gql from 'graphql-tag'
export * from './getProducts'

export const createProductMutation = gql`
  mutation CreateProduct(
    $description: String!
    $iconUrl: String!
    $options: [ProductOptionInput]
    $price: Int!
    $processId: String!
    $title: String!
  ) {
    createProduct(
      description: $description
      iconUrl: $iconUrl
      options: $options
      price: $price
      processId: $processId
      title: $title
    ) {
      description
      iconUrl
      id
      options {
        description
        price
        title
      }
      price
      process {
        id
      }
      status
      title
    }
  }
`

export const updateProductMutation = gql`
  mutation UpdateProduct(
    $description: String
    $iconUrl: String!
    $id: ID!
    $options: [ProductOptionInput]
    $price: Int
    $processId: String
    $title: String
  ) {
    updateProduct(
      description: $description
      iconUrl: $iconUrl
      id: $id
      options: $options
      price: $price
      processId: $processId
      title: $title
    ) {
      description
      iconUrl
      id
      options {
        description
        price
        title
      }
      price
      process {
        id
      }
      status
      title
    }
  }
`

export const deleteProductMutation = gql`
  mutation deleteProductMutation($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`

export const getProductQuery = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      description
      icon {
        id
        url
      }
      id
      price
      options {
        description
        price
        title
      }
      process {
        id
      }
      status
      title
    }
  }
`
