const en = {
  translation: {
    EOFsubTitle: "Informations saved",
    EOFtitle: "Form successfully saved",
    accre: "ACRE",
    actions: "Actions",
    addCompany: "Add company",
    address: "Address",
    amount: "Amount",
    ape: "Code APE/NAF",
    app: {
      serviceWorker: {
        button: "Refresh",
        content: "Please, close the notification or reload the browser to apply updates.",
        title: "Updates are pending",
      },
    },
    applicationCreate: {
      create: "Create a platform",
      form: {
        error: "An error has occured during application creation",
        success: "The application has been created",
      },
      subTitle: "Create a new ALF white label",
      title: "Create application",
    },
    applicationForm: {
      categories: {
        audit: { label: "Audit" },
        billing: { label: "Billing" },
        brokerLegalRepresentative: { label: "Legal representative" },
        legal: { label: "Legal" },
        logo: { label: "Logo" },
        platformSettings: { label: "Platform settings" },
        templates: { label: "Email templates" },
        theme: { label: "Theme" },
        roles: { label: "Roles" },
        welcomeLetter: { label: "Welcome message" },
      },
      connect: {
        all: "All applications",
        button: {
          connect: "Connect",
          connected: "Connected",
          disconnect: "Disconnect",
        },
        title: "Connect all your favourite applications",
        request: {
          title: "Request an integration",
          subTitle: "Describe here the application you would like to be integrated:",
          placeholderFeature: "Your message",
        },
        payment: {
          addYourPaymentKeys: {
            label: "Add your payment keys",
          },
        },
        stripe: {
          documentationAt: {
            label: "documentation at",
          },
          getYourStripeKeys: {
            label: "get your stripe keys from",
          },
          keychainName: {
            placeholder: "Give this keyset an explicit name",
          },
          privateKey: {
            label: "Secret Key",
          },
          productionKeys: {
            label: "Production Keys",
          },
          publicKey: {
            label: "Publishable Key",
          },
          stripeKeysConfiguration: {
            label: "Please set your stripe keys before selecting stripe products",
          },
          testKeys: {
            label: "Test keys",
          },
        },
      },
      legalRepresent: {
        firstName: "First name of representative",
        lastName: "Last name of representative",
      },
      docuSignBcc: {
        label: "Set this address as a carbon copy (CC) of all signature emails:",
        placeholder: "dosusignarchives@yourdomain.com",
      },
      domainName: {
        error: "Please type a valid domain name",
        placeholder: "example.com",
      },
      emailCc: {
        clipboard: "Email has been copied",
        enable: { label: "Enable email CC" },
        label: "Set this address as a carbon copy (CC) of all outgoing emails:",
        placeholder: "archives@yourdomain.com",
      },
      embedded: {
        label: "Embedded platform (iframe)",
      },
      invoiceNumber: {
        label: "Next sequential invoice number",
      },
      logo: {
        label: "Logo",
        placeholder: "Upload the application logo",
      },
      logoLinkUrl: {
        label: "Logo link url",
        placeholder: "http://somesite.com",
      },
      multiForm: {
        add: "Add",
        selectLanguage: "Select a language",
      },
      name: {
        error: "Name is required",
        label: "Name",
        placeholder: "Enter the platform name",
      },
      sendGrid: {
        forgotPassword: "Forgot password",
        platformInvitation: "Platform invitation",
        shareEntityOrProcessInstance: "Share contact or process instance",
        signup: "Signup",
        title: "Choose your SendGrid templates",
      },
      public: {
        label: "Public platform",
      },
      slug: {
        error: "Slug is required (only [a-z0-9-])",
        label: "Slug",
        placeholder: "Enter the application slug",
      },
      tabs: {
        appearance: "Customisation",
        connect: "Integrations",
        general: "General",
        sendGrid: "Mail",
        information: "My information",
        invoice: "My invoices",
      },
      termsConditionsUrl: {
        label: "Link to the terms and conditions:",
        placeholder: "https://example.com/terms",
      },
      theme: {
        label: "Theme settings",
      },
      welcome: {
        content: { label: "Welcome message content" },
        enable: { label: "Display a welcome message" },
        location: {
          label: "When should we display the welcome message ?",
          store: "On the store page",
          signin: "When the user signs in",
          topMenu: "As a persistent item at the top of the navigation menu",
        },
      },
    },
    applicationList: {
      column: {
        actions: "Actions",
        id: "ID",
        name: "Name",
        slug: "Slug",
      },
      subTitle: "",
      title: "Platforms",
      create: "Create a platform",
    },
    applicationUpdate: {
      form: {
        error: "An error has occured during application update",
        success: "The application has been updated",
      },
      subTitle: "Update an ALF white label",
      title: "Update application",
    },
    assetUpload: {
      sizeError: "File size is too big",
      typeError: "Unsupported asset type",
    },
    attestation: "Certificates",
    author: "Author",
    backToAnnonce: "Back",
    beneficialOwner: "Person of significant control",
    cancelAnnonce: "Cancel Annonce",
    carbonFootprint: {
      title: "Limit your carbon footprint by selecting only essential documents.",
      info: "11g of CO2 = 1 plastic bag",
      alert: "Your footprint = {{co2}} of CO2 ({{size}})",
    },
    category: {
      invalid: "Please input a category!",
    },
    categoryCreate: {
      subTitle: "Create a new category of service in the store",
      title: "Create a category",
      form: {
        error: "An error has occured during creating the category",
        success: "The category has been created",
      },
    },
    categoryForm: {
      index: {
        error: "Store position is required",
        label: "Store position",
        placeholder: "Position of the category in the Store tab",
      },
      title: {
        error: "Title is required",
        label: "Title",
        placeholder: "Category title",
      },
    },
    categoryList: {
      column: { storePosition: "Store position", title: "Title" },
      confirm: { title: "Do you want to delete this category?" },
      delete: { error: "An error has occured during category deletion" },
      subTitle: "Organize your services in the store",
      title: "Categories",
    },
    categoryUpdate: {
      form: {
        error: "An error has occured during category edition",
        success: "The category has been updated",
      },
      subTitle: "Category",
      title: "update category",
    },
    chooseCompany: "Choose a company",
    chooseCompanyPlease: "Please choose a company to start the workflow",
    city: "City",
    collectiveAgreement: "Collective agreement",
    common: {
      addrecipient: "Add a recipient",
      backTop: "Back to top",
      by: "by",
      cancel: "Cancel",
      confirm: {
        no: "No",
        yes: "Yes",
      },
      condition: {
        if: "If",
        or: "Or",
        and: "And",
        then: "Then",
        else: "Else",
        variable: "Variable",
        value: "Value",
      },
      disactivated: "disactivated",
      requiredError: "Please type a {{name}}",
      company: {
        role: {
          CEO: "President",
          Director: "Director",
          Shareholder: "Shareholder",
          Manager: "Manager",
        },
      },
      collaborator: "Collaborator",
      collaborators: "Collaborators",
      document: {
        create: "Document successfully created",
        delete: "Document successfully deleted",
        edit: "Document successfully edited",
        flags: "Flags",
        new: "New document",
        replace: {
          title: "Are you sure you want to replace this document?",
          description:
            "Replacing this document might remove the automatic signature tags and hence the envelope will be set to draft mode for the Administrator to verify manually",
        },
        select: "Please select a document",
        side: {
          back: "Verso",
          front: "Recto",
        },
        uploadError: "Error: please upload a document",
        uploadingError: "Error while uploading",
      },
      email: {
        invalid: "Please enter a valid email",
      },
      error: {
        fatal: {
          content:
            "An unexpected error has occured. Our team is doing its best to fix the issue. Please, come back later.",
          title: "Mysterious page identified, let us get you out of here.",
        },
        funny: "Whoops, something went wrong.",
        funny404: "Ooops! Looks like you got lost...",
        funny404Subtitle: "Where should you go now?",
        funnyDashboard: "Access my dossiers",
        funnyDashboardIn: "Going to Dashboard in",
        funnyHelp: "Help me",
        funnyHome: "Back Home!",
        funnyHomeIn: "Going Home in",
        subtitle: {
          title: "To resolve the problem, please try these quick actions:",
          element: [
            "Verify that you are connected to the internet",
            "Make sure that you are logged in to your account",
            "Verify that you have completed all the previous questionnaires",
            "Check that you have uploaded the right documents",
            "Refresh the page or go back and attempt the action again",
          ],
          lastElement: 'Still not working? Contact us by clicking "Help me"',
        },
        funnySubtitleBis: "Please contact us if this problem persists.",
        generic: "An error has occured",
        genericTryAgain: "An error has occured, please try again later",
        input: {
          url: {
            invalid: "Please enter a valid url",
            required: "Please enter an URL",
          },
          email: {
            invalid: "Please type a valid email",
            required: "Please type an email address",
            existing: "An account already exists with this email",
          },
          length: "Input should be shorter than {{maxLength}} characters",
          number: "Please type a valid number",
          password: {
            invalid:
              "Your password must contain at least <strong>one capital letter, one special character, one number and 8 characters.</strong>",
            required: "Please type a password",
          },
          passwordConfirm: {
            invalid: "The passwords do not match... yet",
            required: "Please type your password again",
          },
          whitespace: "Please type at least one visible character",
        },
        metasteps: {
          invalidConfig: "Invalid config: Metasteps should go from first step to last step and must be continuous",
          enoughMetaSteps: "You cannot add more metasteps",
        },
        noMatches: "No matches found",

        noStackTrace: "No stack trace",
        paymentError: "Please proceed to payment in order to access this link",
        queryFailed: "Query failed",
        required: {
          generic: "A value for this field is required",
          name: "Name is required",
          type: "Type is required",
        },
        signinError: "Please sign in in order to access this link",
      },
      filetype: {
        NTDNotification: "NTD notification",
        acceptanceLetter: "Acceptance letter",
        accountingSummary: "Accounting summary",
        agreement: "Agreement",
        agreementInternship: "Internship agreement",
        auditorCRCCRegistration: "Auditor - CRCC registration",
        auditorRegistrationCertificate: "Auditor - Registration certificate",
        bankAccountDetails: "Bank account details",
        BSPCEMiniPact: "Mini-pact BSPCE",
        BSPCEPlan: "BSPCE plan",
        bylaws: "Bylaws",
        ceaseAndDesist: "Cease and desist",
        cerfaEE0: "Cerfa EE0",
        cerfaM0: "Cerfa M0",
        cerfaM2: "Cerfa M2",
        certificateAccounting: "Certificate - Accounting",
        certificateBankDeposit: "Certificate - Bank deposit",
        certificateBenificialOwnersList: "Certificate - Benificial owners list",
        certificateCFE: "Certificate - CFE",
        certificateDebits: "Certificate - Debits",
        certificateDocumentFiling: "Certificate - Document filing",
        certificateDPAE: "Certificate - DPAE",
        certificateEmployer: "Employer's certificate",
        certificateEmployment: "Certificate - Employment",
        certificateFilingForPersonOfSignificantControl: "Certificate - Filing for person of significant control",
        certificateINSEE: "Certificate - INSEE",
        certificateIncorporationFiling: "Certificate - Incorporation filing",
        certificateLegalAnnouncement: "Certificate - Legal announcement",
        certificateMaritalStatus: "Certificate - Marital status",
        certificateRegistration: "Certificate - Registration",
        certificateTransferOfShares: "Certificate - Transfer of shares",
        certificateTribunalFiling: "Certificate - Tribunal filing",
        certificateVAT: "Certificate - VAT",
        criminalStatement: "Criminal statement",
        criminalRecordExtract: "Criminal record extract",
        diploma: "Diploma",
        directorSalary: "Director salary",
        domiciliationContract: "Domiciliation contract",
        driverLicense: "Driver licence",
        employmentContract: "Employment contract",
        escrowRequestLetter: "Escrow Request Letter",
        finalPaymentReceipt: "Receipt for final payment",
        foundersList: "Founders list",
        generalAssemblyConvocation: "General assembly convocation",
        generalAssemblyDecision: "General assembly decision",
        guarantorStatement: "Guarantor statement",
        headquarterList: "Headquarter list",
        idCard: "Id card",
        invoice: "Invoice",
        legalAnnouncementInvoice: "Legal announcement - Invoice",
        other: "Other",
        passport: "Passport",
        payslip: "Payslip",
        proofOfActivity: "Proof of activity",
        proofOfResidency: "Proof of residency ",
        proxyFormalist: "Power of Attorney",
        proxyMail: "Proxy - Mail",
        registeredMail: "Registered mail",
        regularMail: "Regular mail",
        resignationLetter: "Resignation letter",
        socialSecurityCard: "Social security card",
        statutoryAuditorLetter: "Statutory auditor letter",
        subpoena: "Subpoena",
        tradeLicense: "Licence commerciale",
        supportingDocuments: "Supporting documents",
        workPermit: "Work permit",
      },
      gdpr: "GDPR",
      modify: "Modify",
      modalConfirm: "Do not show this window again",
      searchInputPlaceholder: "What are you looking for?",
      select: {
        category: {
          others: "Others",
          usualCompany: "Usual forms of companies",
        },
      },
      services: {
        authenticate: "Signin / Signup",
        docPreview: "Review documents",
        form: "Compose questionnaire",
        generateDocuments: "Generate documents",
        inviteUsers: "Invite and share",
        notifications: "Notify user",
        openDocument: "Open documents",
        payment: "Make payment",
        publishLegalNotice: "Publish a legal announcement",
        sendDocuments: "Send an email",
        sendLetter: "Send a registered courrier",
        signDocuments: "Sign documents",
        shareId: "Acquisition of IDs",
        syncEntity: "Synchronize entities",
        sendMailWithPreview: "Preview and send an email",
      },
      status: {
        draft: "Beta",
        publish: "Published",
      },
      state: {
        document: "Status",
        status: {
          draft: "Draft",
          version: "Latest version",
          toggleVersion: "Latest official version",
        },
      },
      success: {
        generic: "Operation successful",
      },
      text: {
        add: "Add",
        addCondition: "Add condition",
        addressHome: "Home address",
        addDocument: "Add a document",
        addtionalDoucment: "document",
        addQuestion: "Add a question",
        address: "Address",
        admin: "Admin",
        anonymousUser: "Anonymous User",
        answers: "Answers",
        appearance: "Appearance",
        application: "Application",
        archive: "Secured Archive",
        areYouSure: "Are you sure?",
        askIntegration: "Ask an integration",
        subTitleIntegration: "Describe here the application you would like to be integrated:",
        authorize: "Authorize",
        bills: "Bills",
        back: "Back",
        bank: "Bank",
        beta: "BETA",
        birthday: "Date of birth",
        cancel: "Cancel",
        category: "Category",
        chat: "Chat",
        city: "City",
        collaborator: "Collaborator",
        companyTitle: "Information about my company",
        companySubTitle: "Fill in the legal information to identify your company and its representatives",
        companyName: "Company Name",
        contactExpert: "Contact a LegalTech expert",
        continue: "Continue",
        country: "Country",
        courrier: "Registered letter",
        created: "Created",
        creationDate: "Creation date",
        createdOn: "created on",
        crop: "Crop the preview to add as an icon for your service",
        customer: "Customer",
        date: "Date",
        days: "Days",
        debug: "Debug",
        delay: "Delay",
        delete: "Delete",
        details: "Details",
        dependencies: "Dependencies",
        dependencyOf: "is a dependancy of",
        description: "Description",
        document: "Document",
        documents: "Documents",
        documentType: "Document type",
        domainName: "Domain Name",
        download: "Download",
        dropZone: {
          labelDrag: "Drag & drop",
          labelOr: "or",
          labelBrowse: "Upload",
          accept: "Accepted format: ",
        },
        dropZoneDoc: {
          labelDragOr: "Drag & drop or",
        },
        edit: "Edit",
        editContent: "Edit content",
        editDocument: "Modify or replace a document",
        editQuestion: "Modify a question",
        editor: "Editor",
        editable: "Variation",
        email: "Email",
        emailCc: "Reference email - ",
        errorButton: "Consult my role and permissions",
        errorText:
          "Your role doesn't allow you to access the content of this page. Contact your Administrator to upgrade your access.",
        export: "Export",
        excluded: "Excluded",
        end: "End",
        faq: "Your questions",
        financial: "Fiscal & Accounting",
        firstname: "Firstname",
        form: "Form",
        forms: "Forms",
        freemium: "Freemium",
        freemiumState: "Type",
        government: "Government",
        greyLabel: "Grey Label",
        grossAmount: "Gross amount",
        guest: "invitee",
        groups: "Groups",
        hours: "Hours",
        idCard: "Id card",
        import: "Import",
        in: "in",
        invoices: "Invoices",
        included: "Included",
        includedVAT: "Include VAT",
        invited: "Invite on",
        job: "Job",
        jobs: "Jobs",
        kyc: "KYC",
        language: "Language",
        launchSignatories: "Remind all signatories",
        remindSignatories: "Resend a reminder to all the signataires who haven't signed",
        lastname: "Lastname",
        legalAnnouncement: "Legal announcement",
        legalForm: "Legal form ",
        legalRep: "Legal representative",
        loading: "Loading",
        maximumSize: "The file is too large.\nThe maximum size allowed for this document is",
        member: "member",
        members: "Members",
        metastep: "Principal step",
        metasteps: "Principal steps",
        minutes: "Minutes",
        moreinfo: "More information",
        modify: "Modify",
        modified: "Modified",
        modifiedAt: "Modified at",
        myTeam: "My team",
        name: "Name",
        namePlural: "Name plural",
        nationality: "Nationality",
        newLanguagePlus: "+ Add a language",
        newLanguage: "Add a language",
        new: "New",
        netAmount: "Net amount",
        no: "No",
        noData: "No Data",
        noDependencies: "There are no dependencies",
        noDocuments: "No Documents",
        ok: "Ok",
        operator: "Operator",
        options: "Options",
        passport: "Passport",
        pay: "Pay",
        payment: "Payment",
        paymentCompleted: "Payment Completed",
        paymentCompletedSubtitle: "We successfully processed your payment",
        paymentCompletedTitle: "Thank you!",
        platform: "platform!",
        preview: "Preview",
        price: "Price",
        priceWithOutVat: "TAX EXCL",
        priceWithVat: "ALL INCL",
        processingPayment: "Processing Payment",
        product: "Service",
        profile: "Profile",
        publish: "Publish",
        private: "Private",
        public: "Public",
        questionnaire: "Form",
        questionnaires: "Forms",
        questions: "Questions",
        rate: "Rate",
        recto: "Front",
        redirectButton: "Continue",
        reject: "Reject",
        replace: "Replace",
        rejectSignature: "Cancel the signature",
        revision: "revision",
        role: "Role",
        save: "Save",
        saveChanges: "Save changes",
        send: "Send",
        sendRequest: "Send my request",
        seconds: "Secondes",
        setup: "Setup",
        share: "Share",
        showLess: "Show less",
        showMore: "Show more",
        signature: "Signature",
        signin: "Signin",
        signout: "Signout",
        sizeLimitExceeded: "Size limit exceeded",
        slug: "Slug",
        state: "Status",
        steps: "Steps",
        storage: "Storage",
        subdomain: "Subdomain",
        summary: "Summary",
        switchNewUI: "Switch to NEW interface",
        switchOldUI: "Switch to OLD interface",
        template: "Template",
        test: "Test",
        testMode: "Test Mode",
        title: "Title",
        to: "to your",
        total: "Total",
        totalCapitalized: "TOTAL (Tax included)",
        type: "Type",
        unallowed: "Only the file manager can validate this step",
        unknown: "Unknown",
        unknownUser: "Unknown User",
        update: "Update",
        updatedOn: "updated on",
        upload: "Upload",
        url: "Url",
        user: "User",
        users: "Users",
        userInvite: "Invitation",
        userConnected: "You are connected as",
        variables: "Variables",
        vat: "VAT",
        vatSystem: "VAT system",
        vatRate: "VAT rate",
        verso: "Back",
        viewAll: "View all",
        validated: "Validated",
        welcome: "Welcome",
        welcomeMessage: "Welcome to your {{applicationName}} platform!",
        whiteLabel: "White Label",
        workflow: "Workflow",
        yes: "Yes",
        you: "It's you",
        yourCompany: "Your company",
        zipCode: "Zip code",
        refuse: "Refuse",
        wipAutomate:
          "This service is currently being automated. If you are interested in this service or any other service that is not in our catalog, thanks for letting us know.",
        writeQuestion: "Write the question here",
        writeSubQuestion: "Describe the response",
      },
      download: {
        json: "JSON",
        csv: "CSV",
        pdf: "PDF",
      },
      username: {
        invalid: "Please input your username!",
      },
      pagesOn: "Page {{current}} of {{length}}",
    },
    companies: "Companies",
    companyName: "Company name",
    completeForm: "Start",
    computedVatRecords: "Vat records by country",
    calendarPage: {
      dossier: "Dossier status",
      category: "Deadline category",
    },
    composePage: {
      freemiumAuto: "Automate your recurrents tasks with key legal features",
      freemiumUpdate: "Update and modify your workflows",
      freemiumConf: "Setup collaboration to your workflows",
    },
    country: "Country",
    create: "Create",
    contactList: {
      create: "Create a contact",
    },
    createAnnonce: "Create",
    createEntity: "New Company",
    createForm: "Create a questionnaire",
    createJob: "Create a step",
    createProcess: "Create a procedure",
    createProvider: "New Provider",
    createTemplate: "New Template",
    createWorkflow: "New Workflow",
    currentStep: "Pending step",
    dateLegalEntity: "Due date of the legal entity",
    dateClosingFiscal: "Date of closing of the fiscal year",
    dashboard: {
      withEmail: "Choose the dossier to associate with the email",
      freemiumSteps: "Modification of your workflow steps",
      freemiumStatus: "Real time update of your dossier",
      freemiumDossier: "Management of your archived and completed dossiers",
      freemiumUpdate: "Modification of the status of your dossier",
      empty: "You don’t have any ongoing dossiers!",
      action: {
        archive: "Archive",
        delete: "Delete",
        detail: "Documents",
        duplicate: "Duplicate",
        restore: "Restore",
        retry: "Retry",
        share: "Share",
        update: "Edit",
        upgradeWorkflow: "Update",
      },
      column: {
        DONE: "Done",
        ERROR: "Error",
        actions: "Actions",
        author: "Author",
        collaborators: "Collaborators",
        date: "Date",
        label: "Title",
        progress: "Progress",
        service: "Service",
        status: "Status",
        step: "Step",
        toDo: "To do",
        owner: "Owner",
        archivedAt: "Archived",
      },
      delete: {
        areYouSure: "Are you sure you want to delete this dossier?",
        yesDelete: "Yes, delete this dossier",
      },
      label: {
        undefined: "Untitled",
      },
      modal: {
        share: {
          form: {
            email: {
              error: "Email is required",
              label: "Email",
              placeholder: "Email",
            },
            error: "An error has occured during process instance share",
            success: "The dossier has been shared",
          },
          title: "Share the dossier",
        },
      },
      searchBar: { placeholder: "Search" },
      subTitle: "",
      title: "Dossiers in progress",
      upgradeWorkflow: {
        error: "Failed to upgrade workflow",
        success: "The dossier has been updated",
      },
      since: "Since",
    },
    dashboardarchive: {
      title: "Dossiers archived",
      empty: "You don’t have any archived dossiers!",
    },
    dashboarddeleted: {
      title: "Dossiers deleted",
      empty: "You don’t have any deleted dossiers!",
    },
    dashboarddone: {
      title: "Dossiers completed",
      empty: "You don’t have any completed dossiers!",
    },
    data: "Data",
    date: "Date",
    details: "Details",
    docName: "Document name",
    documentList: {
      archive: "Archive",
      category: "Category of document",
      confirm: {
        title: "Are you sure you want to delete this document?",
        accept: "Yes, delete the document",
        cancel: "Cancel",
      },
      create: "Create a document",
      toValidate: "You have {{count}} document(s) to validate",
      toValidateShort: "{{count}} document(s) to validate",
      noneToValidate: "You don't have a document to validate",
    },
    documentTitle: "Title",
    documentListPage: {
      delete: {
        areYouSure: "Are you sure you want to delete this document(s)?",
        yesDelete: "Yes, delete document(s)",
      },
    },
    docusign: {
      actions: { sendAgain: "Send again", remind: "Resend", thank: "Thank" },
      status: {
        authenticationfailed: {
          name: "Authentication failure",
          subtitle: "The signer has failed all authentication attempts.",
          title: "Authentication failure",
        },
        completed: {
          name: "Completed",
          subtitle: "All documents were signed by all signatories on {{date}} at {{time}}.",
          title: "Documents already signed",
        },
        declined: {
          name: "Refused",
          subtitle:
            "One or more signatories have refused to sign. Consult the table below to identify and contact the signatories blocking the file.",
          title: "The signature was refused",
        },
        delivered: {
          name: "Received",
          subtitle:
            "All signatories have received and opened the Docusign® signature link. Consult the table below to re-sign the signatories who are blocking the file.",
          title: "The signatories have all received the envelope",
        },
        autoresponded: {
          name: "Never Received",
          subtitle:
            "The signatory never received the Docusign® signature link. Consult the table below to re-sign the signatories who are blocking the file.",
          title: "The signatories have all received the envelope",
        },
        deliveryfailed: {
          name: "Never Received",
          subtitle:
            "The signatory never received the Docusign® signature link. Consult the table below to re-sign the signatories who are blocking the file.",
          title: "The signatories have all received the envelope",
        },
        draft: {
          name: "In process",
          subtitle:
            "Our team will verify the final details of your envelope and you will receive the final signature link shortly. You will then be able to consult the table below to keep up to date with the status of each signatory.",
          title: "We are checking your envelope",
        },
        pending: {
          // copy of draft
          name: "In process",
          subtitle:
            "Our team will verify the final details of your envelope and you will receive the final signature link shortly. You will then be able to consult the table below to keep up to date with the status of each signatory.",
          title: "We are checking your envelope",
        },
        sent: {
          name: "Sent",
          subtitle:
            "A signature link has been sent to all recipients via the secure Docusign® platform. Please check your inbox and the list of email addresses below.",
          title: "The signature envelope has been sent",
        },
        signed: {
          name: "Signed",
          subtitle: "The signature envelope was signed by the recipient.",
          title: "The signature envelope has been signed",
        },
        voided: {
          name: "Cancelled",
          subtitle:
            "The Docusign signature envelope has been cancelled or deleted by your administrator. Contact support if you think this is not normal",
          title: "The signature envelope has been cancelled",
        },
      },
      text: { docusignEnvelope: "Docusign® envelope" },
    },
    done: "Completed",
    download: "Download",
    duplicate: "Duplicate",
    editDate: "Modified date",
    editDocument: "Edit document",
    editProduct: "Edit product",
    editProfile: "Edit profile",
    email: "Mail",
    emailDetail: {
      start: "Start the dossier",
      add: "Add to a dossier",
      to: "To",
      cc: "CC",
      bcc: "BCC",
      open: "Open the email",
    },
    emailList: {
      title: "Mailbox",
      noselected: "No selected message",
      details: {
        total: "{{ total }} messages",
        unviewed: "{{ total }} unread",
      },
    },
    entity: {
      creator: "Internal contact",
      legalRep: "Legal representative",
      legalRepType: "Type of legal representative",
      primaryContact: "Primary contact",
      primaryContactEmpty: "Information of person to contact",
      empty: "Find all your contacts here",
      title: "My contacts",
      who: "Who is your contact?",
      all: "All the contacts",
      delete: {
        title: "Are you sure you want to delete this contact?",
        confirm: "Yes, delete this contact",
      },
      company: {
        actions: {
          changeCapital: "Change Capital",
          changeDirectors: "Change Directors",
          changeHeadquarters: "Change Headquarters",
          changeName: "Change Name",
        },
        description: "Legal entity",
        listSubTitle: "",
        listTitle: "Your Companies",
        name: "Company",
        newSubTitle: "Create a new company",
        newTitle: "New Company",
        share: "Share a Company",
        shareAccountant: "Share with your accountant",
        typeName: "Companies",
        vat: {
          addEvent: "Add a calendar event",
          addDeadline: "Add a deadline",
          allDeadlines: "All deadlines",
          editDeadline: "Modify a deadline",
          addDeadlineForm: {
            name: "Name of the deadline",
            type: "Type of deadline",
            types: {
              accounting: "Accounting",
              contractual: "Contractual",
              fiscal: "Fiscal",
              social: "Social",
            },
            date: "Date",
            notification: "Notify me before the deadline",
            description: "Description",
            characters: "characters",
            workflowAssociated: "Workflow associated",
          },
          amountHT: "Price (VAT excluded)",
          calendar: "Calendar",
          calendarEmpty: "You are up to date with your agenda",
          claimableVat: "VAT credit",
          collectedOnSales: "Collected on sales",
          contact: "Contact",
          deadlineType: "{{type}} deadline",
          descriptionPlaceholder: "description",
          descriptionRequired: "please enter a description",
          nameRequired: "please enter a name",
          vatRecordForm: {
            title: "Invoice upload",
            category: "Category",
            expenseLabel: "Description",
            invoiceNumber: "Invoice number",
            grossAmount: "Gross amount",
            taxRate: "Tax rate",
          },
          owedVat: "VAT due",
          paidOnPurchases: "Paid on purchases",
          quarter: "Quarter",
          recordType: { expense: "Purchases", income: "Sales" },
          selectAmount: "Select the Amount (VAT excluded)",
          deleteEvent: {
            delete: "Are you sure you want to remove this deadline?",
            confirm: "Yes, remove the deadline",
            cancel: "Cancel",
          },
          send: "Send",
          upload: "Add",
          uploadModalTitle: "Complete information about this document",
          invoiceDetails: "Details of the invoice",
          uploadNoData: "Nothing was uploaded yet",
          vat: "VAT",
        },
      },
      entities: {
        error: "An error has occured during the contact update process",
        fullAccess: "full access",
        invite: "Invite a contributor",
        isPublic: "Share this contact with all the members of the platform",
        listSubTitle: "List of all your contacts",
        listTitle: "Contacts",
        name: "Contact",
        newSubTitle: "Create an abstract contact",
        newTitle: "New Contact",
        rightsDescription: "rights description",
        success: "The contact has been updated",
        typeName: "Contacts",
      },
      houses: {
        listSubTitle: "",
        listTitle: "Your Houses",
        name: "House",
        newSubTitle: "Create a new House",
        newTitle: "New House",
        typeName: "Houses",
      },
      new: "New contact",
      persons: {
        listSubTitle: "",
        listTitle: "Your Clients",
        name: "Person",
        newSubTitle: "Create a new person",
        newTitle: "New Person",
        typeName: "Persons",
        description: "Individual person",
      },
      share: {
        title: "Share contact",
        error: "An error has occured during contact share",
        success: "Your contact has been shared",
      },
    },
    entityAdmin: {
      userId: {
        label: "Created by",
        placeholder: "Owner",
      },
      delete: {
        description: "They will be deleted from all files to which they contribute",
        title: "Are you sure you want to delete this collaborator?",
        yesDelete: "Yes, delete this collaborator",
      },
      invite: "Invite a collaborator",
      inviteNewMembers: "Invite one or more collaborators to join",
    },
    entityCategories: {
      empty: "You don't have contacts yet!",
      add: "Add a contact",
    },
    entityDossiers: {
      all: "All dossiers",
      dossier: "Dossier associated",
      service: "Service associated",
      empty: "You do not have any dossiers for this contact yet!",
    },
    entityForm: {
      entityTypeSelection: "Please select the type of contact",
      categoryUnvailable: {
        text: "This feature is not yet configured for your platform. Your role doesn’t allow you to configure this feature",
        redirect: "Check my role and permissions",
      },
      categoryCreate: {
        text: "This feature is not yet configured for your platform",
        redirect: "Configure my contacts",
      },
    },
    entityPage: {
      actionsTab: "Actions",
      admin: "Collaborators",
      detailsTab: "Summary",
      dossiersTab: "Dossiers",
      documentsTab: "Documents",
      title: "Details",
      accountancyTab: "Accountancy",
      calendarTab: "Deadlines",
      summary: {
        upcomingDeadlines: "Upcoming deadlines",
        lastDossiers: "Latest dossiers in progress",
      },
    },
    entityType: {
      new: "Add a new",
      error: {
        name: "Please give a name to this contact",
      },
    },
    entityTypeCreate: {
      title: "Create a contact",
    },
    entityCategoryForm: {
      placeholder: {
        name: "Name of contact in camelCase to use in the questionnaires",
        slug: "Name of contact to be used in the url",
        category: "Category of the contact in the My contacts tab",
      },
    },
    entityTypeForm: {
      placeholder: {
        name: "Name of contact in camelCase to use in the questionnaires",
        slug: "Name of contact to be used in the url",
        type: "Name of contact to be shown in the table",
      },
      creationForm: {
        label: "Edit creation form",
      },
      description: {
        label: "Description",
        placeholder: "Enter the contact description",
      },
      entityNamePath: {
        label: "Display name",
        placeholder: "Enter the display name (JMES path)",
      },
      icon: {
        label: "Icon",
        placeholder: "Icon name from Ant Design",
      },
      namePlural: {
        placeholder: "Enter the plural name of the contact",
      },
      position: {
        placeholder: "Enter a number for the position in the left menu",
      },
      type: {
        error: "Please select a type",
      },
    },
    entityTypesList: {
      empty: "You don't have a type yet!",
      add: "Add a type",
    },
    entityView: {
      import: {
        title: "Import",
        button: "Select",
      },
      select: {
        button: "Choose from the list",
      },
      create: {
        title: "Create",
      },
    },
    enumerationList: {
      create: "Create a variable",
      delete: {
        areYouSure: "Are you sure you want to delete this variable?",
        yesDelete: "Yes, delete this variable",
        subTitle:
          "Deleting this variable will impact the following questionnaires in which it is used. Make sure there are no dependencies",
      },
    },
    enumerationForm: {
      saved: "Variable saved with success",
      alreadyExists: "A variable with this name already exists",
      placeholder: {
        name: "Name",
        defaultValue: "Default value",
      },
      option: {
        value: "Value",
        label: "English display name",
        labelFr: "French display name",
        delete: {
          areYouSure: "Are you sure you want to delete this option?",
          yesDelete: "Yes, delete this option",
          subTitle:
            "Deleting this option will impact the following questionnaires in which it is used. Make sure there are no dependencies",
        },
      },
    },
    factureName: "Invoice name/number",
    faqPage: {
      displayAll: "All answers",
      delete: {
        yesDelete: "Yes, delete the page",
        areYouSure: "Are you sure you want to remove this question from the F.A.Q.?",
      },
      goFurther: "Go further",
      headerTitle: "How can I help you ?",
    },
    videoPage: {
      displayAll: "All videos",
      delete: {
        yesDelete: "Yes, delete the video",
        areYouSure: "Are you sure you want to delete this video from library?",
      },
      headerTitle: "Video library",
      form: {
        title: "Add a video",
        question: "Title of the video",
      },
    },
    featureFreemium: {
      title: "Your FREEMIUM access does not allow you to access this feature",
      content: "Activate the Starter plan and access:",
      freemiumButton: "Activate this feature",
    },
    file: {
      purpose: {
        headquarters: "Headquarters",
        kyc: "KYC",
        proofOfResidence: "Justificatif de domicile",
      },
      type: {
        companyLeaseAgreement: "Company lease agreement",
        companyRegistrationCertificate: "Company registration certificate",
        domiciliationAgreement: "Domiciliation agreement",
        hostID: "Host ID",
        leaseAgreement: "Lease agreement",
        proofOfResidence: "Proof of residence",
        residenceAttestation: "Attestation of Residence",
        visa: "Visa",
      },
    },
    fillForm: "Complete the form",
    firstQuote: "What's up today",
    fithQuote: "What's up today",
    forgotPassword: {
      form: {
        button: "I forgot my Password",
        email: {
          error: "Email is required",
          placeholder: "Please type your email",
        },
        error: "An error has occured",
        signin: "<1>Return to the login page</1>",
      },
      title: "Forgot Password",
    },
    forgotPasswordResult: {
      content:
        "If a user is registered with this address, an email has been sent to it.\nClick on the link in this email to reset your password",
      description: "A link to reset your password has been sent to ",
      signin: "Sign in!",
      signinPrompt: "Did you reset your password already?",
      receiveEmail: "Didn't receive an email?",
      title: "Your request was accounted for",
      resend: "Resend",
    },
    form: "Form",
    formRunner: {
      common: {
        add: "Add",
        save: "Save and continue",
        import: "Import",
        remove: "Remove",
      },
      fieldView: {
        fake: "Fake user input with random data",
        fakeAll: "Fake user input with random data for the whole form",
        fileView: { maxSize: "Maximum size per file" },
      },
      fields: {
        idCheck: {
          generic: "Unable to retrieve information",
        },
        info: {
          title: "Information",
        },
        insee: {
          companyNotFound: "Could not find a company with this registration number",
        },
        bodaccModalTitle: "Announcements published in Bodacc for {{search}}",
      },
      fileUploadInput: {
        error: "The upload has failed",
        passport: "Double page (photo & signature)",
      },
    },
    bugRequest: {
      title: "Submit a bug",
      subTitle: "Describe your problem and precise any elements that might help solve it:",
      placeholder: "Describe your problem here",
      sendButton: "Submit a bug",
    },
    featureRequest: {
      title: "Request a feature",
      subTitle: "Describe here the feature you want and precise anything else that might be useful:",
      placeholder: "Describe your request here",
      sendButton: "Send my request",
      success: "Your message has been sent successfully!",
    },
    serviceRequest: {
      title: "Request a service",
      subTitle: "Describe here the service you would like to be automated:",
    },
    forms: "Forms",
    fourthQuote: "What's up today",
    fullname: "Full name",
    goToDashboard: "Go to Dashboard",
    goToPersonalSpace: "Go to my personal space",
    headquarter: "Headquarters",
    headOffice: "Address of the head office",
    historyCapital: "Equity history",
    immatriculation: "Registration",
    immatriculationDate: "Registration date",
    import: {
      error: "Undefined error occured",
      product: {
        error: "An error has occured during service import",
        success: "The service has been imported",
      },
      process: {
        error: "An error has occured during workflow import",
        success: "The worfkow has been imported",
      },
    },
    importCompany: "Import company",
    information: "Informations",
    invit: "Invite",
    invoices: {
      freemiumGestion: "The management of your invoicing",
      freemiumCustom: "Customization of your invoice templates and numbering",
      freemiumHistory: "Payment history",
    },
    invitMembers: "Invite members",
    invitMembersTitle: "Invite a member",
    job: {
      error: {
        name: "Please give a name to this job",
        category: "The name of the category must be unique",
      },
      title: { label: "Give it a specific name" },
      type: { label: "What do you want to automate now ?" },
    },
    jobCreate: {
      form: { success: "Job created successfully" },
      subTitle: "Automate a job",
      title: "New Job",
    },
    jobList: {
      column: {
        filter: {
          placeholder: "Name",
          reset: "Reset",
          submit: "Filter",
        },
        label: "Name",
        type: "Type",
      },
      create: { jobs: "Create a step", forms: "Create a questionnaire" },
      subTitle: "Your processes are made of jobs",
      title: "Jobs",
      empty: {
        jobs: "Build the steps for your workflows",
        forms: "Compose your questionnaires here",
      },
    },
    jobUpdate: {
      form: { success: "The questionnaire has been updated" },
      job: { success: "The step has been updated" },
      subTitle: "Customize your job",
      title: "Job",
    },
    jobForm: {
      JobDocumentsFields: {
        addUploadedDocuments: {
          label: "Add uploaded documents",
          placeholder: "members",
        },
        documents: {
          labelLong: "Select the documents to ",
        },
        templates: "My Documents",
        uploadedDocuments: "Uploaded documents",
        uploadedDocumentsPath: {
          label: "Filter documents according to path",
        },
        uploadedDocumentsTypes: {
          label: "Filter documents according to their type",
        },
        verb: {
          sign: "sign",
          attach: "attach",
          send: "send",
        },
      },
    },
    lang: "en",
    layout: {
      navigation: {
        administrativeFormalities: "Administrative formalities - SASU for startups",
        analytics: "Analyse",
        application: "My platform",
        applications: "Alf portfolio",
        categories: "Categories",
        company: "My company",
        compose: "Compose",
        contacts: "My contacts",
        dossiers: {
          title: "My dossiers",
          inProgress: "In progress",
          archive: "Archived",
          done: "Completed",
          deleted: "Deleted",
        },
        entities: "My entities",
        entitiesType: "Entities",
        forms: "My questionnaires",
        job: "Jobs",
        jobs: "My steps",
        clauses: "My clausier",
        platform: "My platform",
        procedures: "Procedures",
        processes: "Process",
        products: "Products",
        reports: "My reports",
        settings: "Setup",
        store: "My services",
        services: "Services",
        storeSettings: "Store Settings",
        templates: "My documents",
        userInvites: "Invites",
        users: "Users",
        welcome: "Welcome",
        workflows: "My workflows",
        workflowJobs: "Workflows",
        formsJobs: "Questionnaire",
      },
      header: {
        faq: "Consult the F.A.Q",
        video: "Access video library",
        submitBug: "Submit a bug",
        requestFeature: "Request a feature",
      },
    },
    leaders: "Directors",
    legalForm: "Company form",
    linkDevis: "Link to the quote",
    linkSpecimen: "Link to the specimen",
    listCompanies: "Companies",
    listPartener: "Shareholders",
    listProcess: "Pending Procedures",
    listProvider: "Experts",
    listTemplates: "Templates",
    listUser: "Users",
    listVatRecords: "Invoices",
    login: "Log in",
    logout: "Log out",
    mainActivity: "Main activity",
    mainInfo: "Main information",
    memberMsg: "The member receive an invite",
    members: "Members",
    model: "Templates",
    myCompanies: "Companies",
    name: "Name",
    nbActions: "Shares #",
    next: "Next",
    nextStep: "Next step",
    no: "No",
    notFound: {
      content: "The requested page was not found",
      title: "Page missing",
    },
    notStarted: "Not Started",
    number: "number",
    office: "Offices",
    owner: "Owner",
    paymentCompleted: { title: "Your payment has already been registered" },
    paymentForm: {
      PaymentSuccessRedirection: {
        subTitle: "Click below or wait 5 seconds to continue",
        title: "Thank you!",
      },
    },
    pending: "Pending",
    percentCapital: "Equity %",
    phone: "Phone",
    platformInformations: {
      freemiumPerso: "Customization of your platform from A to Z",
      freemiumInteg: "Integration of your logo",
      freemiumChoice: "The choice of your colors, fonts and icon librariess",
    },
    previewDocument: "Preview document",
    price: "Price",
    process: "Processes",
    processInstance: {
      details: "Details",
      noDocuments: "You don't have any documents yet!",
      succes: "Success",
      title: "Procedure",
      correspondence: {
        tabTitle: "Communication",
        empty: "Find here all your communication related to the dossier",
      },
      journal: {
        tabTitle: "Journal",
        dateLabels: {
          today: "Today",
          yesterday: "Yesterday",
        },
        filterLabels: {
          all: "All interactions",
          ACTIVITY: "Activity",
          DOCUMENT: "Document",
          EMAIL: "Email",
          SHARE: "Invitation",
          VALIDATION: "Validation",
          TIME: "Timer",
        },
        logText: {
          title: {
            generic: "Latest activity",
            NEXT_STEP_PROCESS_INSTANCE: "Step change",
            START_PROCESS_INSTANCE: "Status of the dossier",
            FINISH_PROCESS_INSTANCE: "Status of the dossier",
            INCOMMING_MAIL: "Email",
            OUTGOING_MAIL: "Email",
            ADDED_MAIL: "Email",
            DOCUMENT_REJECTION: "Validation",
            DOCUMENT_VALIDATION: "Validation",
            DOCUMENT_UPLOAD: "Document",
            DOCUMENT_GENERATE: "Document",
            ACTIVITY_TRACKED: "Time tracker",
            SHARE_PROCESS_INSTANCE: "Invitation",
          },
          message: {
            NEXT_STEP_PROCESS_INSTANCE: "The dossier is moved to step <2>{{nextStepIndex}}. {{nextStepName}}</2>",
            START_PROCESS_INSTANCE: "Dossier <1>{{processInstanceName}}</1> is started",
            FINISH_PROCESS_INSTANCE: "Dossier <1>{{processInstanceName}}</1> is closed",
            INCOMMING_MAIL: "An email with the subject <1>{{subject}}</1> has been received",
            OUTGOING_MAIL: "An email with the subject <1>{{subject}}</1> has been sent",
            ADDED_MAIL: "An email with the subject <1>{{subject}}</1> has been modified",
            OUTGOING_MAIL_SUB: "A reply with subject <1>{{subject}}</1> is received",
            DOCUMENT_REJECTION: "The document <1>{{documentName}}</1> is rejected",
            DOCUMENT_VALIDATION: "The document <1>{{documentName}}</1> is validated",
            DOCUMENT_UPLOAD: "A new document <1>{{documentName}}</1> is uploaded",
            DOCUMENT_GENERATE: "A new document <1>{{documentName}}</1> is generated",
            ACTIVITY_TRACKED: "An activity of <1>{{timeAdded}}</1> has been tracked",
            ACTIVITY_TRACKED_NOTE: " with following note <1>{{note}}</1>",
            SHARE_PROCESS_INSTANCE: "The dossier is shared with <1>{{content}}</1>",
            SHARE_PROCESS_INSTANCE_GROUP: "The dossier is shared with the group <1>{{content}}</1>",
          },
        },
      },
      email: {
        quiz: "Do you want to send the copy of the email to dossier?",
        yes: "Yes",
        no: "No",
      },
    },
    processInstanceUpdate: {
      handling: {
        toRevalidateModal: {
          title: "You can choose :",
          reRunDescription:
            "either relaunch the step <strong>{{processInstanceStepIndex}}. {{processInstanceName}}</strong>",
          skipDescription:
            "either to go directly to the next step <strong>{{nextStepIndex}}. {{nextStepName}}</strong>",
          reRun: "Restart",
          skip: "Advance",
        },
        inviteUsers: {
          success: "An invitation to this dossier has been sent to <strong>{{target}}</strong>",
        },
        reject:
          "Clicking on this button will cancel this step and take you to the first step of the process. Contact a responsable to get further assistance.",
      },
      done: {
        action: "Continue my dossier",
        title: "The process is complete",
      },
      payment: {
        action: "Proceed to payment",
        configurationError: "Payment Configuration Error. Did you forget to set you payment Keys ?",
        error: "Payment Error",
        success: "Your payment has been successfully processed",
      },
      processing: {
        default: {
          description: "Please bear with us",
          title: {
            0: "Planting trees",
            1: "Counting bees",
            2: "Generating rainbows",
            3: "Cleaning up the oceans",
            4: "Watching birds fly",
          },
        },
        generateDocuments: {
          description: "Generating your documents",
          title: {
            0: "Planting trees",
            1: "Counting bees",
            2: "Generating rainbows",
            3: "Cleaning up the oceans",
            4: "Watching birds fly",
          },
        },
        publishing: {
          description: "Publication in progress...",
          title: "Sending for printing",
        },
        publishLegalNotice: {
          description: "Scanning 323 newspapers...",
          title: {
            0: "Looking for the best newspaper for you",
            1: "scanning calendars",
            2: "Compressing words",
          },
        },
        sendDocuments: {
          description: "Sending an update",
          title: {
            0: "Check your inbox",
          },
        },
        sendLetter: {
          description: "Your letter is being prepared",
          title: {
            0: "Planting trees",
            1: "Counting bees",
            2: "Generating rainbows",
            3: "Cleaning up the oceans",
            4: "Watching birds fly",
          },
        },
        signDocuments: {
          description: "Check your inbox !",
          title: {
            0: "Your documents have been sent to your mail for signature through Docusign secured platform. Please check your inbox and spam. All the team members have received a mail from Docusign for this process.",
          },
        },
      },
      timer: {
        title: "Time tracker",
        stop: "Are you sure you want to log your activity of <strong>{{time}}</strong> for this dossier?",
        note: "Note",
        reset: "Delete this session",
        tabs: {
          timer: "Timer",
          manual: "Manual",
          range: "Range",
        },
      },
      publishLegalNotice: {
        alreadyPublished:
          "Your legal announcement is only {{distance}} days away and scheduled for {{date}}. You can see its preview below:",
        description:
          "Great news! We found a spot for your legal announcement in {{distance}} days and scheduled it in {{newspaper}} for {{date}}. Please review the annoucement preview below and contact us if you see any discrepancies. If you think everything is correct, click on Publish.",
        error: "An error has occured during publication operation",
        newspaper: {
          coveredDepartments: { title: "Covered departments" },
          digitalCertificate: { title: "Digital certificate" },
          economicFormat: { economic: "Economic", standard: "Standard", title: "Format" },
          publicationDays: { title: "Publication days" },
          publicationType: {
            online: "Online",
            print: "Print",
            title: "Publication type",
          },
        },
        orderInfo: {
          companyName: { title: "Company name" },
          companyZipCode: { title: "Company zip code" },
          orderNumber: { title: "Order number" },
          publicationDate: { title: "Publication date" },
          title: "Your order details",
        },
        publishing: "Publishing...",
      },
      signDocuments: {
        signersList: { title: "Signatories" },
      },
      reviewDocuments: {
        draft: "Draft",
        description: "For documents in ",
        endDescription: "status, be sure to check that the document information is correct before validating.",
        GDPR: "GDPR",
        descriptionConfidential: "All documents that contain ",
        endDescriptionConfidential: "are confidential and subject to strict sharing and security guidelines.",
        rectify: "Rectify",
        title: "Review and validate your documents",
        validateAll: "Validate all documents",
        information: "My information",
        verify: "Information",
        compare: "Compare",
        archive: "Your archived documents",
        all: "All my documents",
        validateDocument: "Validate this document",
        search: "Search for a document",
        notAllowed:
          "Your access rights doesn’t allow you to validate the documents. However, you can review them. Someone with superior access rights will soon review and validate this step.",
      },
      sendLetter: {
        reviewDocuments: {
          title: "Verify the attached documents",
          description:
            "You will find below all the documents in this courrier. You can edit, download or upload a replacement by clicking on the icons next to each document.",
        },
        sendLetter: {
          success: "The letter has been sent successfully",
          error: "Failed to send the letter",
        },
        cancelLetter: {
          success: "The letter has been canceled successfully",
          error: "Failed to cancel the letter",
        },
        timeLeft: "Time left to cancel the letter",
        update: { success: "Letter configuration was updated" },
      },
    },
    processOverview: {
      step: {
        description: {
          done: "The step has been successfully processed",
          error: "The step has encountered an error",
          waiting: "The step is currently processing",
        },
      },
    },
    documentCard: {
      all: "All documents",
    },
    product: "Products",
    productCard: {
      days: "Days",
      documentsGenerated: "Documents",
      steps: "Steps",
      vatIncluded: "All incl.",
      vatExcluded: "Tax excl.",
      allServices: "All my services",
    },
    productCreate: {
      form: {
        error: "An error has occured during product creation",
        success: "The product has been created",
      },
      subTitle: "Create a service for the store",
      title: "New service",
    },
    productDetails: {
      title: "Service details",
      free: "Free",
      start: "Start",
      steps: "Steps",
      variable: "Variable",
      serviceType: "Type of service",
      value: {
        internal: "Internal",
        external: "External",
      },
    },
    productForm: {
      category: {
        label: "Store category",
        placeholder: "Enter the product category",
      },
      delete: {
        areYouSure: "Are you sure you want to delete this service?",
        yesDelete: "Yes, delete this service",
        subTitle: "Deleting the service will delete the dossiers and documents associated with the service",
      },
      description: {
        error: "Summary is required",
        lengthError: "Summary can be up to 150 characters",
        label: "Summary",
        placeholder: "Enter the product summary",
      },
      detailedDescription: {
        error: "Detailed description is required",
        label: "Detailed description",
        placeholder: "Enter the product detailed description (markdown supported)",
      },
      steps: {
        label: "Steps",
      },
      documents: {
        label: "Documents",
      },
      entity: {
        action: {
          label: "Contact action",
        },
        category: {
          label: "Contact category",
        },
      },
      estimatedTime: {
        label: "Delay",
        estimated: "Estimate in",
        errorEstimatedTime: "Delay is required",
        errorEstimatedTimeType: "Type of delay is required",
      },
      generatedDocsDescription: {
        disclaimer: "We may generate additional documents based on your situation",
        label: "We prepare these documents for you",
        labelForm: "Select the main generated documents",
        placeholder: "Select the documents ",
      },
      icon: {
        label: "Store icon",
        placeholder: "Upload the product icon",
      },
      options: {
        placeholder: "Workflow options",
      },
      display: {
        label: "Display",
        information: "Information to display in the service card (3 maximum)",
      },
      position: {
        error: "Position is required",
        label: "Store position",
        placeholder: "Enter the product's position",
      },
      price: {
        error: "Price is required",
        label: "Service price - in EUR cents, tax included",
        placeholder: "Enter the product's price",
      },
      priceDescription: {
        error: "Price description is required",
        label: "Price description",
        placeholder: "Describe your price (markdown supported)",
      },
      process: {
        error: "Process is required",
        label: "Workflow",
        placeholder: "Pick an existing workflow from this list",
      },
      status: {
        error: "Status is required",
        label: "Status",
        placeholder: "Enter the editing status",
      },
      title: {
        error: "Title is required",
        label: "Title",
        placeholder: "Enter the product title",
      },
      uploadedDocsDescription: {
        disclaimer: "We may require additional documents based on your situation",
        label: "You receive these documents",
        labelForm: "Select the main uploaded documents",
        placeholder: "Select the documents",
      },
      priceIncludesVat: {
        label: "VAT",
        error: "VAT is required",
      },
    },
    clauseList: {
      column: {
        title: "Clause name",
      },
      create: "Create a clausier",
    },
    productCategory: {
      delete: {
        form: {
          error: "An error occurred while deleting the category",
          success: "The category has been deleted",
        },
        confirm: {
          yesDelete: "Yes, delete this category",
          areYouSure: "Are you sure you want to delete this category?",
        },
      },
    },
    productList: {
      info: "To select a service from the catalog, click on the “Star” icon on the to right corner of the service card",
      column: {
        description: "Description",
        status: "Status",
        title: "Title",
      },
      confirm: {
        title: "Do you want to delete this product?",
      },
      subTitle: "Offer your services in the store",
      empty: "Propose your catalog of services here",
      title: "Catalog of services",
      tabTitle: {
        first: "Internal services",
        second: "External services",
      },
      freemiumLimit: "Choose up to 10 services of your choice",
      freemiumAutomize: "Automate your own services and categorise them as per your needs",
      freemiumCustom: "Personalise your services with your colours and icons",
      withEmail: "Choose the service to associate with the email",
    },
    productUpdate: {
      form: {
        error: "An error has occured during product update",
        success: "The service has been updated",
      },
      subTitle: "Update a product for your customers",
      title: "Update product",
    },
    projectType: "Project type",
    provider: "Experts",
    providerProcedure: "Mandates",
    quarter: "Quarter",
    rate: "Taux",
    rcs: "RCS",
    rcsNumber: "RCS Number",
    rcsCity: "RCS city",
    recoverPassword: {
      form: {
        error: "An error has occured",
        password: {
          error: "Your password is required",
          placeholder: "Please choose your password",
          newUIPlaceholder: "Enter your new password",
        },
        passwordConfirm: {
          error: "The passwords do not match... yet",
          placeholder: "Please confirm your password",
        },
        button: "Login",
      },
      title: "Recover password",
      newUITitle: "Create a new password",
      validateStatus: {
        upperCase: "A capital letter",
        specialSymbol: "A special character (?!/$@)",
        number: "A number",
        eightSymbols: "8 characters minimum",
      },
    },
    regulatedActivity: "Regulated activity",
    rejectDocuments: "Reject Documents",
    role: "Role",
    runProcess: "Start a procedure",
    secondQuote: "What's up today",
    selectCountry: "Select a country",
    shareWithUser: {
      placeholder: "Share with a group, a user or an email",
      add: "Share with several interlocutors",
    },
    sendLetter: {
      actions: { sendAgain: "Send again", remind: "Resend" },
      status: {
        draft: {
          name: "Draft",
          title: "Verify the information of your courrier",
          subTitle: "You can directly modify the informations, save and preview of your courrier.",
        },
        created: {
          name: "Created",
          title: "The letter is created",
        },
        accepted: {
          name: "Accepted",
          title: "The letter is accepted by the print and mail system",
        },
        filingProof: {
          name: "Filing proof",
          title: "The letter received proof of deposit",
        },
        sent: {
          name: "Sent",
          title: "The letter is sent",
        },
        inTransit: {
          name: "In transit",
          title: "The letter is in transit",
        },
        waitingToBeWithdrawn: {
          name: "Waiting to be withdrawn",
          title: "The letter is available to be picked up by the recipient",
        },
        deliveryProof: {
          name: "Delivery proof",
          title: "The letter received an acknowledgment",
        },
        distributed: {
          name: "Distributed",
          title: "The letter is received by the recipient",
        },
        wrongAddress: {
          name: "Wrong address",
          title: "The letter could not be delivered to the recipient",
        },
        canceled: {
          name: "Canceled",
          title: "The letter is canceled",
        },
        error: {
          name: "Error",
          title: "An error is occurred",
        },
        returnedToSender: {
          name: "Returned to sender",
          title: "The letter is returned to the sender",
        },
      },
    },
    sendMailWithPreview: {
      title: "Email",
      subTitle: "Check the email before sending it",
      send: "Send",
      header: {
        title: "Review the draft email",
        description: "Verify destinataires → Verify content → Verify attachments → ",
        final: "Send the mail",
      },
      emailDetail: {
        title: "Details of the email",
        to: "To",
        cc: "CC",
        bcc: "BCC",
        subject: "Subject",
        emailTitle: "Title of the email",
        message: "Message",
        modify: "Modify email",
        preview: "Preview email",
      },
      error: {
        to: "Please enter at least one main recipient",
        subject: "Please enter the subject of the email",
        title: "Please enter the title of the email",
        message: "Please enter the email message",
      },
      reviewDocuments: {
        title: "Your attachments",
        description:
          "You will find below all the documents in this email. You can edit, download or upload a replacement by clicking on the icons next to each document.",
      },
    },
    service: "Service",
    serviceDescription: {
      info: "You can personalise the content of this page as per your needs. Try it by clicking on the edit icon on the top right corner beside the “Start” button",
      mainTitle: "SASU for startups",
      mySteps: "Service details",
      myDocuments: "Documents",
      priceDetails: "Details of the price",
      addDocument: "+ Add a document",
      infoDocuments:
        "Personalise the “Documents generated” with your own templates by clicking on the edit icon beside the document",
      documentsBlock: {
        titles: {
          provided: "Documents to be provided",
          generated: "Documents generated",
          received: "Documents received",
          displayed: "Documents to be displayed in the service page :",
        },
        managersID: "ID of the managers",
        proofRCS: "Proof of RCS registration",
        companyLaws: "By-laws of the company",
        proofOccupancy: "Proof of occupancy of the premises",
        subscribersList: "List of subscribers of shares",
        newByLaws: "New by-laws of the company",
        depositOfFunds: "Certificat - Deposit of funds",
        documentsFiling: "Certificat - Filing of documents",
        beneficialFiling: "Certificate of filing of beneficial owners",
        bankDetails: "Bank details",
      },
      detailsBlock: {
        administrativeCosts: "Administrative costs and expenses",
        legalAnnouncement: "Legal announcement",
        serviceFees: "Service fees",
        totalPackage: "Total package excluding VAT",
        additional: "Additional fees will apply if you choose a regulated profession",
      },
      stepsBlock: {
        readySASU: "Your SASU is ready!",
        byLaws: "By-laws",
        fillQuestionnaire: "Fill in the questionnaire with your company information",
        checkAndValidate: "Check and validate the automatically generated bylaws",
        downloadDocuments: "Download the necessary documents to finalize your formality ",
        step: "Step",
        steps: "Steps",
      },
    },
    signedDocs: "Signed documents",
    signin: {
      form: {
        email: {
          placeholder: " Email",
        },
        error: {
          invalidCredentials:
            "Ooops... Your username or password is invalid.\nIf you forgot your password, you can always click on 'I forgot my password'",
        },
        forgotPassword: "Forgot your password?",
        login: "Log in",
        password: {
          error: "Please type your password",
          newUIPlaceholder: "Create a password",
          placeholder: "Password",
        },
        signup: "Sign up now!",
        signupPrompt: "Don't have an account yet?",
      },
      title: "Join {{name}}!",
      newUITitleRemembered: "We missed you!",
      newUITitle: "Nice to see you again!",
      loginViaEmail: "Login with my email",
      noaccount: "You don't have an account yet?",
      signInConnect: "Or login via",
      popoverText: {
        emailUnknown: "There is no account associated with this email. Would you like to",
        signup: "create an account?",
        loginError: "I don't recognise this email and/or password. <strong>Try another combination!</strong>",
        validateError:
          "<strong>This email address does not seem to be valid.</strong> Please try again or enter a different email address!",
      },
    },
    signup: {
      popoverText: {
        usedEmail: "This email address is already associated with an account.",
        connect: "Log in",
      },
      firstLabel: "Welcome to {{name}}!",
      secondLabel: "Access the platform via",
      form: {
        button: {
          text: "Signup",
          newUIText: "Create an account ",
        },
        email: {
          error: "Your email is required",
          placeholder: "Email",
          // label: 'Or create an account with your email address',
          label: "Create an account with your email address",
        },
        error: {
          generic: "Your username or password is invalid",
        },
        password: {
          error: "Your password is required",
          placeholder: "Create a password (8 characters minimum)",
        },
        termsOfService: {
          first: "I accept the",
          second: " Terms of Use",
        },
        passwordConfirm: {
          error: "The passwords do not match... yet",
          placeholder: "Confirm your password",
        },
        signin: "Already have an Alf account? <1>Log in!</1>",
        terms: {
          error: "You must accept the general terms & conditions to access the service",
          text: "I accept <1>the general terms & conditions</1>",
          url: "https://thisisalf.com/termes",
        },
      },
      invite: {
        expired: "Invite has expired",
        title: "Redeem your invite on {{name}}",
      },
      title: "Signup",
      hadAccount: {
        label: "You already have an account ?",
        link: "Login",
      },
      mainTitle: "The platform",
      subTitle: "for innovative legal teams",
    },
    since: "Since",
    siren: "SIREN",
    siret: "SIRET",
    siretRegistration: "SIRET or foreign registration number",
    sixthQuote: "What's up today",
    slug: "Reference",
    smallScreenDisclaimer: {
      action: "More information from your mobile on <1>https://thisisalf.com</1>",
      content:
        "You will soon be able to access the mobile version of the site. In the meantime, you can still access all of our content from your laptop or desktop computer.",
      title: "Small screens disclaimer",
    },
    socialCapital: "Equity share",
    socialDenomination: "Company name",
    socialLogin: {
      Linkedin: {
        loginError: "Unable to login with linkedin, you are going to be redirected to the home page",
      },
      description: "And make your life easier",
      or: "or",
      signin: "Sign in",
      signout: "Sign out",
      signup: "Sign up",
      title: "Continue with {{name}}",
      newUITitle: "Connect me with {{name}}",
      warning: "This application is accessible only by invitation.",
    },
    socialObject: "Corporate activity",
    startActivityDate: "Starting date",
    startedAt: "Start date",
    step: "Step",
    store: {
      subTitle: "",
      title: "A good time to...",
      start: "Start a dossier",
      dossierEmpty: "No ongoing dossiers at this stage",
    },
    success: "Success",
    summary: "Summary",
    table: {
      noData: {
        button: "Help me!",
        subtitle:
          "Please check if you have started a workflow or ask for our assistance, we would be glad to help you!",
        title: "No results found",
      },
    },
    teamForm: {
      name: {
        required: "The name is required",
        exists: "The name must be unique",
        label: "Name of the team",
      },
    },
    faqCreate: {
      form: {
        error: "An error has occured during faq creation",
        success: "The template has been created",
      },
    },
    templateCreate: {
      form: {
        error: "An error has occured during template creation",
        success: "The template has been created",
      },
      subTitle: "Create a new template",
      title: "Template",
    },
    templateForm: {
      editor: {
        content: {
          error: "Content is required",
          label: "Content",
          placeholder: "Enter template content",
        },
        preview: {
          title: "Content preview",
        },
        tab: {
          data: "Data",
          preview: "Preview",
        },
        title: "Template content editor",
        word: {
          title: "Personalise with your own template",
          drop: "Drag and drop the variables into your document",
          info1:
            "To personalise the version of the generated document (preview on the left), prepare your model in Word  format and upload it below.",
          info2:
            "In the next step, you will be able to customise your model in Word format with the available variables by placing them wherever you wish.",
        },
      },
      filename: {
        label: "Generated filename",
        placeholder: "SAS ByLaws - ${companyName}",
      },
      filetype: {
        label: "Generated file type",
        placeholder: "Other",
      },
      mimetype: {
        error: "Mimetype is required",
        label: "Mimetype",
        placeholder: "Choose template mimetype",
      },
      status: {
        error: "Status is required",
        label: "Status",
        placeholder: "Choose template status",
      },
      tags: {
        label: "Tags",
      },
      title: {
        error: "Title is required",
        label: "Title",
        placeholder: "Enter template title",
      },
      type: {
        document: "Document",
        error: "Type is required",
        label: "Type",
        partial: "Partial",
        placeholder: "Choose template type",
      },
    },
    templateList: {
      column: {
        id: "ID",
        slug: "Slug",
        status: "status",
        title: "Title",
        type: "Type",
        jobs: "Steps",
      },
      confirm: {
        title: "Do you want to delete this template?",
      },
      delete: {
        error: "An error has occured during template deletion",
      },
      subTitle: "Your document templates",
      title: "Templates",
      empty: "Build your library of document templates",
    },
    templateUpdate: {
      form: {
        error: "An error has occured during template update",
        success: "The document has been updated",
      },
      subTitle: "Update a template",
      title: "Template",
    },
    templates: "Templates",
    thirdQuote: "What's up today",
    totalPrice: "Total price",
    troubleLogging: "I can't connect?",
    type: "Type",
    user: "Users",
    userForm: {
      email: {
        error: "Email is required",
        label: "Email",
        placeholder: "Enter the user email",
      },
      firstname: {
        label: "Firstname",
        placeholder: "Enter the user firstname",
      },
      lastname: {
        label: "Lastname",
        placeholder: "Enter the user lastname",
      },
      phone: {
        label: "Phone number",
        placeholder: "Enter the user phone number",
      },
      type: {
        admin: "Super Admin",
        broker: "Administrator",
        customer: "User",
        editor: "Editor",
        label: "Type",
        operator: "Operator",
        placeholder: "Choose the user type",
      },
    },
    reportsPage: {
      share: {
        title: "Share the report",
        success: "Your report has been shared",
        error: "An error occurred during the sharing of the report",
      },
      document: {
        update: "Modification of the report version",
        textPopUp: "Save and share a version of your report",
        buttonPopUp: "Add a version",
      },
      tab: {
        data: "Data",
        versions: "Versions",
      },
      create: "Create a report",
      update: "Modification of the report",
      delete: {
        areYouSure: "Are you sure you want to delete this report?",
        yesDelete: "Yes, delete this report",
      },
      column: {
        add: "Add a column",
        workflow: "Service",
        variable: "Variable",
      },
      textPopUp: "Access and share your personalised reports",
      buttonPopUp: "Create your first report",
      freemiumActivity: "All your activity reports",
      freemiumPers: "Customization of your performance indicators",
      freemiumConfig: "The management of your KPIs",
      extraVariables: {
        documents: "Documents",
        timeSpent: "Time spent",
      },
      ranges: {
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
        lastweek: "Last week",
        last2week: "Last 2 weeks",
        lastmonth: "Last month",
        lastquarter: "Last 4 months",
        lastyear: "Last year",
      },
      version: {
        new: "Save this version",
        name: "{{name}} - Report of {{dateAndTime}}",
      },
    },
    routeLeavingGuard: {
      cancel: "Leave this page",
      confirm: "Save my changes",
      title: "Are you sure you want to leave this page without saving?",
    },
    userInviteTableList: {
      roles: "Roles and Permissions",
      admin: "Administrator",
      editor: "Editor",
      operator: "Operator",
      user: "User",
      features: {
        createProcedure: "Create workflows",
        setUpPlatform: "Configure platform",
        editFiles: "Edit dossiers",
        interveneFile: "Intervene in a case",
      },
    },
    userInviteCreate: {
      form: {
        error: "An error has occured during invite creation",
        success: "User invite has been created and sent",
      },
      subTitle: "Create and send user invite",
      title: "User invite",
    },
    userInviteForm: {
      email: {
        error: "Email is required",
        label: "Email",
        placeholder: "Enter the user email",
      },
      userType: {
        label: "User access rights",
      },
    },
    userInviteResend: {
      form: {
        error: "An error occurred while sending the invitation",
        success: "The invitation has been sent",
      },
    },
    userInviteList: {
      column: {
        email: "Email",
        expired: "Status",
        id: "ID",
      },
      confirm: {
        title: "Are you sure you want to delete this?",
      },
      firstMember: "Invite your first member!",
      subTitle: "Manage user invites",
      title: "User invites",
      handling: {
        resend: "Resend",
      },
      status: {
        expired: "Expired",
        pending: "Pending",
      },
      waiting: "On hold",
    },
    userList: {
      column: {
        email: "Email",
        firstName: "First Name",
        id: "ID",
        lastName: "Last Name",
        type: "Type",
      },
      confirm: {
        areYouSure: "Are you sure you want to delete this {{modalText}} ?",
        yesDelete: "Yes, delete the {{modalText}}",
        title: "Are you sure you want to delete this member?",
        deleteSubtitle: "They will be deleted from all files to which they contribute ",
      },
      collaborator: {
        title: "Invite collaborators",
        placeholder: "Invite a group, a user or an email",
        add: "Invite another collaborator",
      },
      removeTeam: "Are you sure you want to delete this team?",
      confirmRemoveTeam: "Yes, delete this team",
      inviteAnotherMember: "Invite another member",
      inviteNewMembers: "Invite new members",
      select: "Select invitees",
      noMembers: "You don't have any members yet!",
      firstTeam: "Create your first team!",
      createTeam: "Create a team",
      inviteNewMembersTeam: "Invite one or more collaborators to join the team",
      removeNewMembersTeam: "Are you sure you want to remove this collaborator from this team?",
      confirmRemoveNewMembersTeam: "Yes, delete this collaborator",
      subTitle: "List of all users",
      tabs: {
        members: "Members",
        invitations: "Invitations",
        teams: "Teams",
        manageRoles: "Manage roles",
      },
      title: "Users",
    },
    userProfile: {
      name: "My profile",
      GDPR: {
        anonymize: "Anonymize my Data",
        deleteAccount: "Delete Account",
        deleteData: "Delete my Data",
      },
      entity: {
        label: "Bind a contact to your user",
        placeholder: "Choose a contact",
      },
      form: {
        email: {
          error: "Email is required",
          label: "Email",
          placeholder: "Enter your email address",
        },
        error: "Update failed due to an unexpected error",
        firstname: {
          label: "First name",
          placeholder: "Enter your firstname",
        },
        lastname: {
          label: "Last name",
          placeholder: "Enter your lastname",
        },
        phone: {
          label: "Phone number",
          placeholder: "Enter your phone number",
        },
        job: {
          label: "Title",
          placeholder: "Enter your title",
        },
        role: {
          label: "Role",
          placeholder: "Enter your role",
        },
        status: {
          title: "Status",
          acivated: "Active",
          disactivated: "Inactive",
        },
        team: {
          label: "Teams",
          placeholder: "Enter your teams",
        },
        save: "Save changes",
        success: "Your profile has been updated",
      },
      preferedLanguage: {
        label: "Prefered language for communications",
        placeholder: "Select a language",
      },
      subTitle: "Your privacy matters to us",
      title: "User profile",
      roles: {
        title: "My permissions",
        update: "Modify roles",
      },
      teams: {
        title: "My teams",
        update: "Manage my teams",
        empty: {
          title: "You are not yet associated with a team",
          description: "Be patient, your administrator will take care of it for you",
        },
      },
    },
    userUpdate: {
      form: {
        error: "An error has occured during user update",
        success: "The user has been updated",
      },
      subTitle: "La protection de votre vie privée est notre priorité",
      title: "My Account",
    },
    modal: {
      wip: {
        title: "Welcome to the future...",
        subtitle: "This functionality is not yet available",
        description: "Our team is working on its creation. You will be alerted as soon as it is available",
      },
      noRights: {
        subtitle: "Your role does not allow you to perform this action",
        linkRolesPermissions: "See my role and permissions",
      },
    },
    roles: {
      freemiumRoles: "Role management functionnality",
      freemiumAccess: "Assigning access rights to your team",
    },
    shareCapital: "Capital social",
    storeFreemiumModal: {
      title: "Choose from our customisable legal service templates library",
      CTA: "Access the catalog",
    },
    teams: {
      freemiumCreation: "The creation of your work groups",
      freemiumGestion: "Management of your teams",
    },
    validAnnonce: "Valid Annonce",
    validateDocs: "Validate Documents",
    welcomeLetter: { continue: "Continue", preview: "Preview", welcome: "Welcome message" },
    wipFreemiumModal: {
      title:
        "This feature is not available in Freemium version. Get in touch with our sales team to upgrade to a plan that suits your needs.",
      upgrade: "Upgrade my platform",
      redirect: "Meeting",
    },
    wipFeatureModal: {
      header: {
        processInstanceSettingStep: "Step change",
      },
      title: "This feature will soon be available on your platform",
      subtitle: "This functionality is not yet available!",
      description: "Our team is working on its creation. You will be alerted as soon as it is available",
    },
    workflow: "Workflow",
    workflowCreate: {
      form: {
        error: "An error has occured during workflow creation",
        success: "The workflow has been created",
      },
      subTitle: "Create a new workflow",
      title: "Create workflow",
    },
    workflowExport: {
      error: "An error has occured during workflow export",
    },
    workflowPrevious: {
      consult: "You can find: ",
      yes: "Go back",
      title: "Are you sure you want to go back?",
      description: "If you re-generate any of the documents, the current version will be deleted.",
      summary: {
        text: "All responses to all the questionnaires",
        link: "here",
      },
      documents: {
        text: "All the documents of your dossier",
        link: "here",
      },
    },
    workflowv2Form: {
      create: "No-Code workflow",
      title: "Create a <strong>workflow</strong>",
      step1: { continue: "Continue without documents", accepted: "Accepted format: .docx" },
      step2: {
        title: "Define the variables and conditions of the document",
        continue: "Choice of the first step",
      },
      step3: {
        empty: "Choose your first action",
        variables: "Existing questions",
      },
      variables: {
        description: {
          title: "Define the variables of your document: ",
          step1: "Select the text in your document that you wish to add as a variable",
          step2: "Add a new variable or choose from pre-defined list",
          step3: "Define the properties of the variable",
        },
        delete: "Delete the variable",
        empty: "Variable name",
        name: "Enter the name of the variable",
        type: "Type of question",
        popover: {
          replace: "Would you like to replace similar content?",
          replaceOne: "No",
          replaceAll: "Yes",
          title: "Add a variable",
          search: "Search for a variable",
          add: "Add a new variable",
        },
      },
      steps: {
        title: "Steps",
        step: "Step",
        add: "Add a step",
        edit: "Modify the step",
      },
      actions: {
        title: "Actions",
        action: "Action",
        add: "Add an action",
      },
      form: {
        list: "Question",
        preview: "Preview",
        question: "Question",
        add: "Add a question",
        fields: "Configuration",
        conditions: "Conditions",
        faq: "FAQ",
        name: "Name of the question",
        label: "Title of the question",
        type: "Type of question",
        required: "Required",
      },
      conditions: {
        clausier: "Add article from my clausier",
        description: {
          title: "Add conditions to your document:",
          step1: "Select the text in your document that you wish to be conditional",
          step2: "Add a new condition or choose from pre-defined list",
          step3: "Define the properties of the condition",
        },
        delete: "Delete the condition",
        empty: "Condition name",
        name: "Enter the name of the condition",
        popover: {
          title: "Add a condition",
          search: "Search for a condition",
          add: "Add a new condition",
        },
        text: "Add your text",
      },
      tabs: {
        variables: "Variables",
        conditions: "Conditions",
      },
      types: {
        text: "Text",
        textarea: "Long text",
        money: "Currency",
        number: "Number",
        email: "Email",
        phoneNumber: "Phone",
        country: "Country",
        file: "Document",
        enumeration: "Select",
        radio: "Radio",
        "multiple-selector": "Select multiple",
        checkbox: "Checkbox",
        siret: "Siret",
        idCheck: "idCheck",
        entity: "entity",
      },
    },
    workflowForm: {
      add: {
        form: "Form",
        generateDocuments: "Generate documents",
        notifications: "Notify user",
        openDocument: "Open documents",
        payment: "Payment",
        publishLegalNotice: "Publier une annonce légale",
        sendDocuments: "Send an email",
        signDocuments: "Sign documents",
        validateDocuments: "Validate documents",
      },
      description: {
        error: "The description is required",
        label: "Description",
        placeholder: "Enter the workflow description",
      },
      label: {
        label: "Label",
        placeholder: "Variable that will be used to display the title of this workflow on the Dashboard",
      },
      title: {
        error: "The title is required",
        label: "Title",
        placeholder: "Enter the workflow title",
      },
      workflow: {
        form: {
          form: {
            error: "The step form is required",
            label: "Form",
            placeholder: "Choose the step form",
          },
          name: {
            error: "The step name is required",
            label: "Name",
            placeholder: "Enter the form step name",
          },
          title: {
            label: "Edit form",
          },
        },
        generateDocuments: {
          config: {
            alias: {
              label: "Multiple documents entry alias",
              placeholder: "Enter alias entry path (member as default)",
            },
            bindings: {
              label: "Bindings",
              placeholder: "Pdf form Json bindings of variables",
            },
            input: {
              label: "Multiple documents input",
              placeholder: "Enter JMESPath to iterate on",
            },
            renderMode: {
              highlightChanges: "Highlight responses",
              label: "Document generation mode",
              normal: "Normal",
            },
            templatesSelection: {
              label: "Select documents to generate",
              labelSend: "Select documents to send",
              conditions: "Conditions",
              binding: "Bindings",
              batch: "Batch Documents",
            },
          },
        },
        inviteUsers: {
          config: {
            input: {
              label: "User(s) JMespath to invite",
              placeholder: "User(s) JMespath",
            },
          },
        },
        condition: {
          title: "Condition",
          description: "Condition description",
          variable: "Choose the variable for condition",
          nextStep: "Next step associated with the condition",
          defaultStep: "Default step",
        },
        job: {
          dependsOnPreviousSteps: { label: "Depends on the previous steps" },
          description: { label: "Step description", placeholder: "" },
          toDo: { label: "To do" },
          id: {
            error: "Please select a job",
            label: "Job",
            placeholder: "Select a job",
          },
          name: {
            error: "The job's name is required",
            label: "Step name",
            placeholder: "The step name is visible on the dashboard and as a progress step",
          },
          service: {
            label: "Configuration",
          },
          templates: {
            error: "The step templates are required",
            label: "Templates",
            placeholder: "Choose the step templates",
          },
          title: "Click on '+' to add a job",
        },
        notifications: {
          config: {
            input: {
              error: "You must set a message or remove the step",
              label: "Message",
              placeholder: "Your documents are ready",
            },
          },
        },
        openDocuments: {
          config: {
            documentsPage: {
              label: "Open the documents page rather than download the document",
            },
            input: {
              error: "You must set a message or remove the step",
              label: "Message",
              placeholder: "Opening Documents",
            },
            newWindow: {
              label: "Open in new Window",
            },
          },
        },
        payment: {
          config: {
            creditCard: {
              label: "Credit Card",
            },
            email: {
              label: "Email for your receipt",
            },
            fixedPrice: {
              label: "Fixed price value in Euros",
            },
            floatingPrice: {
              label: "Path to a variable set to the desired price in Euros",
            },
            fullName: {
              label: "Name on card",
            },
            invoice: { title: "Invoices" },
            invoiceTemplates: { label: "Select invoice(s) to generate" },
            globalTaxId: {
              label: "Global Tax",
            },
            liveMode: {
              label: "Live Mode",
            },
            paymentKey: {
              hint: "Payment keys are configured in <0>the application settings</0>. Once configured, please select one below. When the live mode is de-activated, the payment will be set to Test mode so you can test the full payment process by using this fake credit card number: 4242 4242 4242 4242, 01/42, CVV: 424",
              label: "Select a payment key",
              title: "Payment key",
            },
            pricingType: {
              fixedPrice: "Fixed Price",
              floatingPrice: "Floating Price",
              label: "Pricing Type",
              servicePrice: "Service Price",
              stripePrice: "Stripe Product",
              stripeSubscription: "Stripe Subscription",
            },
            stripe: {
              hint: "Pricing details are configured directly on <0>stripe.com</0> and then selected here. For each of your services, you need to create a stripe product here: <1>https://dashboard.stripe.com/products</1>. For each stripe product, you can add as many price items as you want to distinguish each price item.",
              priceItems: {
                title: "Price items",
                hint: "Once you created all your prices on stripe, compose them below. These items will appear in this order on the invoice your customers will receive. Click on + to add a new price",
              },
              taxes: { title: "Taxes" },
              title: "Pricing details",
            },
            stripeGlobalTaxId: {
              label: "Global tax rate applied to all items. Click on + to add a new tax rate",
            },
            stripeKeysConfiguration: {
              error: "Fetching price list from Stripe failed",
              hint: "Are you sure you configured your stripe keys correctly?",
              label: "Please configure your stripe API keys",
            },
            stripePriceIds: {
              add: "Detailed pricing visible on the payment page",
              label: "Price",
            },
            stripeProductIds: {
              label: "Stripe product",
              placeholder: "Select a stripe product or click on + to create a new one",
            },
            stripeProductPrice: {
              label: "Stripe product price in Euros",
            },
            stripeTaxIds: {
              label: "Tax",
            },
            subscription: {
              label: "Stripe subscribtion price id",
            },
          },
        },
        publishLegalNotice: {
          config: {
            newZipCode: "Enter company new zip code path",
            certificateFilename: "Certificate filename",
            data: {
              companyName: {
                label: "Company name",
                placeholder: "Enter company name path",
              },
            },
            email: {
              label: "Email",
              placeholder: "Entery delivery email path",
            },
            invoiceFilename: "Invoice filename",
            type: {
              addendum: "Addendum",
              chairman: "Chairman update",
              changeName: "Name update",
              constitution: "Constitution",
              corrigendum: "Corrigendum",
              headquarters: "Headquarters update",
              headquartersArrival: "Headquarters update (department of arrival)",
              label: "Announce type",
              placeholder: "Choose type",
            },
            zipCode: {
              label: "Actual zip code",
              placeholder: "Enter zip code path",
            },
          },
          section: {
            main: "Main",
            notice: "Notice",
          },
        },
        sendDocuments: {
          addDocuments: "Add the documents",
          buttonText: "Button text",
          config: {
            input: {
              error: "The email JMESPATH is required",
              label: "Specify the recipients",
              placeholder: "Enter the JMESPath of one email or multiples emails",
            },
            sendAsAttachment: "as an attachment to the email",
            sendAsButtonLink: "button inside the email with a link to your platform",
          },
          emailConfiguration: "Email configuration",
          emailTitle: "Email title",
          greetings: {
            label: "Greetings",
            placeholder: "Hello Louis !",
          },
          linkAddress: "Link address",
          linkTitle: "Link title",
          message: "Message",
          redirectButton: "Do you want to add a redirection button?",
          redirectUrl: "Redirect url",
          sendDocuments: "Do you want to add documents?",
          subject: "Subject",
        },
        sendLetter: {
          addDocuments: "Add the documents",
          buttonText: "Button text",
          letterConfiguration: "Mailing details",
          name: {
            label: "Full name",
            placeholder: "Enter name path",
          },
          documents: {
            label: "Documents to send",
          },
          zipCode: "Zip code",
          city: "City",
          sendDocuments: "Do you want to add documents?",
          subject: "Subject",
          cancelWindow: "Cancel window, min",
          sender: "Sender",
          recipient: "Recipient",
          isDraft: {
            label: "Send as draft",
          },
        },
        signDocuments: {
          config: {
            addUploadedDocuments: {
              label: "Add uploaded documents",
            },
            documents: {
              label: "Documents to send",
              labelLong: "Select the documents to send",
            },
            emailCcOptional: { label: "Optional email CC sending" },
            emailCcPath: { label: "Select cc'ed email of signed documents", placeholder: "escrowAccountEmail" },
            emailSubject: {
              label: "Subject of the signature email",
              placeholder: "Signature documents for ${companyName} - ${__name}",
            },
            input: {
              error: "Please select signatories",
              label: "Select signatories",
              placeholder: "members[?role === 'CEO']",
            },
            sendAsDraft: {
              label: "Send as draft",
            },
            smsAuthentication: { label: "Enable SMS authentication" },
            templates: "My Documents",
            title: {
              label: "Select signatories",
            },
            uploadedDocuments: "Uploaded documents",
            uploadedDocumentsPath: { label: "Filter documents according to path", placeholder: "members" },
            uploadedDocumentsTypes: {
              label: "Filter documents according to their type",
              placeholder: "Proof of residence",
            },
            variableName: {
              label: "Variable Name",
              placeholder: "Allows you to identify each signature with a different name when editing documents",
            },
          },
        },
        requiresValidation: {
          label: "Email sending mode",
          true: "Manual",
          false: "Automatic",
        },
        restrictedAccess: {
          user: "The user can modify/validate the step",
          invitee: "The invitee can modify/validate the step",
        },
      },
    },
    vatNumber: "Intra-community VAT number",
    workflowList: {
      column: {
        id: "ID",
        title: "Title",
      },
      confirm: {
        title: "Do you want to delete this workflow?",
      },
      create: "Create a workflow",
      delete: {
        error: "An error has occured during workflow deletion",
      },
      subTitle: "Put your jobs together into a workflow",
      title: "Workflows",
      empty: "Automate your workflows here",
    },
    workflowUpdate: {
      form: {
        error: "An error has occured during workflow update",
        success: "The worfkow has been updated",
      },
      subTitle: "Compose your workflow",
      title: "Workflow",
    },
    workflows: "Workflows",
    yes: "Yes",
    zipCode: "Zip code",
    createDossier: "Create a dossier",
    createDossierDescription: "Start a new dossier from the content of the mail",
    attachDossier: "Attach to a dossier",
    attachDossierDescription: "Add your mail to an existing dossier",
    createAnEntity: "Create an entity",
    createAnEntityDescription: "Save information on your platform",
    updateEntity: "Update an entity",
    updateEntityDescription: "Update information on your platform",
    AIAnalysis: "AI analysis",
    AIAnalysisDescription: "Use AI to analyse the content of mail",
    AddStickyNote: "Add a sticky note",
    AddStickyNoteDescription: "Create and assign a task",
    WhatDoYouWantToDo: "What do you want to do?",
    RedirectToPlatform: "Go to your platform →",
    menu: "Menu",
    selectYourService: "Select your service",
    selectYourDossier: "Select your dossier",
    informationSaved: "Information saved",
    save: "Save",
    fileUploadSuccess: "File uploaded successfully",
    upload: "Upload",
    noAttachment: "No attachment found",
    emailAttachment: "Email Attachments",
    noData: "No data",
    uploadFromPC: "Upload from your computer",
    welcomeBack: "Welcome back!",
    loginEmail: "Email",
    password: "Password",
    niceToSeeYouAgain: "Nice to see you again!",
    failedToGetAttachments: "Failed to get attachments.",
    noAttachments: "No attachments found.",
    selectDocuments: "Select the document to add to the dossier",
    selectWorkSpace: "Select the workspace that you want to connect",
    select: "Select",
    uploadFromLocal: "Select from your computer",
    errorOccurred: "An error occurred",
    errorNoSlugs: "Ooops..., there is no slug to sign in.",
    errorLoadingSlugs: "This is error while loading slugs:",
    errorUserPasswordNotValid: `Ooops... Your username or password is invalid.\nIf you forgot your password, you can always click on 'I forgot my password'`,
    errorEmailSubmit: "I don't recognise this email and/or password. <strong>Try another combination!</strong>",
    errorNotValidEmail:
      "<strong>This email address does not seem to be valid.</strong> Please try again or enter a different email address!",
    errorValidEmail: `There is no account associated with this email.`,
    errorNotValidPassword:
      "Your password must contain at least <strong>one capital letter, one special character, one number and 8 characters.</strong>",
    dossier: "Dossier",
    addAddtionalDocuments: {
      title: "Add additional documents",
      question: "Upload a document to this dossier",
    },
  },
};

export { en };
