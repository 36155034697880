import React, { useState, useCallback } from 'react'
import get from 'lodash/get'
import { FormSpy } from 'react-final-form'

import { FormField } from './FormField'
import { conditionIsFullfilled } from './frames'

const FormFieldResetContent = ({ formValues, form, condition, ...props }) => {
  const [alreadyDisplayed, setAlreadyDisplayed] = useState(null)

  //TODO Register to field value and unregister when field is not render
  const checkConditions = useCallback(
    (values, form) => {
      const conditionsAreFulfilled = condition.every(condition => {
        const path = `${props.prefix ? props.prefix : ''}${props.prefix && condition.key ? '.' : ''}${
          condition.key ? condition.key : ''
        }`

        const value = get(values, path)

        return conditionIsFullfilled(condition, condition.key ? { [condition.key]: value } : value || values)
      })

      if (alreadyDisplayed === true && !conditionsAreFulfilled && props.name) {
        form.change(props.name, '')
      }

      if (conditionsAreFulfilled !== alreadyDisplayed) {
        setAlreadyDisplayed(conditionsAreFulfilled)
      }

      return alreadyDisplayed
    },
    [alreadyDisplayed, condition, props.name, props.prefix]
  )

  if (!checkConditions(formValues, form)) {
    return null
  }

  return <FormField {...props} />
}

export const FormFieldConditionalContent = ({ condition, ...props }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => <FormFieldResetContent {...props} condition={condition} formValues={values} form={form} />}
    </FormSpy>
  )
}
