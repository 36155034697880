import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { InMemoryCache, ApolloClient, ApolloProvider } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import "./styles/index.less";
import { App } from "./App";
import { GRAPHQL_ENDPOINT } from "./constants/url";

/* global document, Office, module, require */

initializeIcons();

const initializeApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      credentials: "include",
      headers: {
        "X-version": "head",
      },
      uri: GRAPHQL_ENDPOINT,
    }),
  });
};

const ApolloApp = () => {
  const [client, setClient] = React.useState(initializeApolloClient);
  // Function to update the Apollo Client with a new slug
  const updateClient = async (newSlug = "home") => {
    localStorage.setItem("slug", newSlug);
    const newClient = initializeApolloClient();
    setClient(newClient);
  };

  return (
    <ApolloProvider client={client}>
      <App updateClient={updateClient} />
    </ApolloProvider>
  );
};

const render = () => {
  ReactDOM.render(<ApolloApp />, document.getElementById("container"));
};

/* Render application after Office initializes */
Office.onReady(render);

const { hot } = module as any;
if (hot) hot.accept("./App", render);
