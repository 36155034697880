import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="5 4 25 25" {...props}>
    <g>
      <polygon points="16.304,9.152 15.597,8.445 13.586,10.454 12.551,9.418 11.844,10.125 13.586,11.869 	" />
      <rect x="17.153" y="9.989" width="5.648" height="1" />
      <path d="M8.303,5.343v23.725H26.1V5.343H8.303z M25.1,28.068H9.303V6.343H25.1V28.068z" />
      <polygon points="16.304,13.866 15.597,13.159 13.586,15.169 12.551,14.133 11.844,14.84 13.586,16.583 	" />
      <rect x="17.153" y="14.703" width="5.648" height="1" />
      <polygon points="16.304,18.58 15.597,17.873 13.586,19.883 12.551,18.847 11.844,19.554 13.586,21.297 	" />
      <rect x="17.153" y="19.417" width="5.648" height="1" />
      <polygon points="16.304,23.294 15.597,22.587 13.586,24.598 12.551,23.562 11.844,24.269 13.586,26.012 	" />
      <rect x="17.153" y="24.132" width="5.648" height="1" />
    </g>
  </svg>
)

export default SVG
