import gql from 'graphql-tag'

export const signinUserMutation = gql`
  mutation SigninUser($input: UserSigninInput!) {
    signinUser(input: $input) {
      user {
        id
      }
      redirectURL
    }
  }
`

export const socialLoginMutation = gql`
  mutation SocialLogin($input: SocialLoginInput!) {
    socialLogin(input: $input) {
      user {
        id
      }
      redirectURL
    }
  }
`

export const signinByGoogleMutation = gql`
  mutation signinByGoogle($input: GoogleLoginInput!) {
    signinByGoogle(input: $input) {
      user {
        id
      }
      redirectURL
    }
  }
`
