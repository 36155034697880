export const applicationPdf = "application/pdf";

export const gdprFileTypes = [
  "bankAccountDetails",
  "certificateBenificialOwnersList",
  "certificateDPAE",
  "certificateFilingForPersonOfSignificantControl",
  "certificateMaritalStatus",
  "criminalRecordExtract",
  "diploma",
  "directorSalary",
  "driverLicensedriverLicense",
  "criminalStatement",
  "idCard",
  "payslip",
  "passport",
  "proofOfResidency",
  "socialSecurityCard",
];
