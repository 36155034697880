import { supportedTypes } from "../constants/images";
import { multilang } from "./multiLang";
import { applicationPdf } from "./fileTypes";

export const getType = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();
  return supportedTypes.includes(extension) ? "image" : extension;
};

export const getTypeWithFilename = (document) => {
  const regexpExtension = /(?:\.([^.]+))?$/;
  let type;
  if (document) {
    const extension = regexpExtension.exec(multilang(document.filename));
    if (extension && extension[1]) {
      switch (extension[1]) {
        case "png":
        case "jpg":
        case "gif":
        case "jpeg":
          type = `image/${extension[1]}`;
          break;
        case "pdf":
          type = applicationPdf;
          break;
        default:
          break;
      }
    }
  }
  return type;
};
