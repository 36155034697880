import React, { useRef, useMemo, useCallback, FC } from "react";
import { useQuery } from "@apollo/client";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { Button, Card, Divider, Row, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Loader from "../../components/Loader";
import { FormFieldCheckConditional } from "./FormFieldCheckConditional";
import { resolveJmespath } from "../../helpers/jmespath";
import { isEmptyOrWhitespace, sleep } from "../../helpers/utils/utils";
import { nth } from "../../data-model/shared/formatters";
import scrollIntoView from "scroll-into-view-if-needed";
import { useTranslation } from "react-i18next";
import { getCompanyMemberRolesQuery } from "./graphql/queries";
import { isEmpty } from "lodash";
import { TABS } from "../../types";

interface ProcessInstance {
  data: Record<string, any>;
}

interface Field {
  type: string;
  name: string;
  value?: any;
  initialValue?: any;
  // Add other properties that fields might have
}

interface FormFieldMultipleContentProps {
  fields?: Field[];
  name?: string;
  processInstance?: ProcessInstance;
  frame?: any;
  displayName?: string;
  tabs: TABS;
}

export const FormFieldMultipleContent: FC<FormFieldMultipleContentProps> = React.memo(
  ({ fields, name, processInstance, frame, displayName, tabs }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    const lastElement = useRef<HTMLDivElement>(null);
    const propName = name;
    const value = useMemo(() => processInstance?.data?.[name] || [], [name, processInstance?.data]);
    const { data, loading } = useQuery<{ getCompanyMemberRoles: any[] }>(getCompanyMemberRolesQuery, {
      skip: !fields.some(({ type }) => type === "companyMemberRoles"),
    });
    const handleDisplayNameResolution = useCallback(
      (memberValues: any, index: number) => {
        const extra: Record<string, any> = {};
        if (memberValues?.role?.type === "companyMemberRoles") {
          const role = data?.getCompanyMemberRoles.find(({ value }) => value === memberValues?.role?.value);
          if (role) {
            extra["role"] = {
              ...memberValues.role,
              value: role[language],
            };
          }
        }

        const name = resolveJmespath(
          { ...memberValues, ...extra, __index: nth(index + 1, { locale: language }) },
          displayName || ""
        );
        return !isEmptyOrWhitespace(name)
          ? name
          : `${nth(index + 1, { locale: language })} ${
              propName === "documents" ? t("common.text.addtionalDoucment") : t("common.text.member")
            }`;
      },
      [displayName, language, t, data?.getCompanyMemberRoles]
    );

    const scroll = useCallback(() => {
      const node = lastElement.current;
      if (node) {
        scrollIntoView(node, {
          behavior: "smooth",
          block: "center",
          inline: "center",
          scrollMode: "if-needed",
        });
      }
    }, []);

    if (loading) {
      return <Loader />;
    }

    return (
      <FieldArray
        initialValue={Array.isArray(value) && value.length > 0 && !isEmpty(value[0]) ? value : [{}]}
        name={name}
        render={({ fields: formFields }: FieldArrayRenderProps<any, HTMLDivElement>) => (
          <>
            {formFields.map((fieldName, index) => (
              <div key={index} {...(formFields.length === index + 1 ? { ref: lastElement } : {})}>
                <Card
                  className="form-runner-multiple-field-card"
                  title={
                    <Row justify="space-between">
                      <Typography.Title level={4}>
                        {handleDisplayNameResolution(formFields.value[index], index)}
                      </Typography.Title>
                      <Button
                        htmlType="button"
                        disabled={formFields.length <= 1}
                        icon={<DeleteOutlined />}
                        onClick={() => formFields.remove(index)}
                        size="middle"
                        type="primary"
                      />
                    </Row>
                  }
                  headStyle={{ background: 1, borderBottom: 0, paddingBottom: 0, paddingRight: 0 }}
                  bodyStyle={{ background: 1, borderBottom: 0, paddingBottom: 0, paddingRight: 0 }}
                >
                  <Row gutter={[0, 20]}>
                    {fields.map((field, fieldIndex) => (
                      <FormFieldCheckConditional
                        {...field}
                        initialValue={
                          (Array.isArray(value) &&
                            value[index] &&
                            (value[index][field.name]?.value || value[index][field.name])) ||
                          field.value ||
                          field.initialValue ||
                          undefined
                        }
                        key={fieldIndex}
                        name={`${fieldName}.${field.name}`}
                        processInstance={processInstance}
                        prefix={fieldName}
                        language={language}
                        frame={frame}
                        tabs={tabs}
                      />
                    ))}
                  </Row>
                </Card>
                {formFields.length > 1 && (
                  <>
                    <br />
                    {index < formFields.length - 1 && (
                      <>
                        <Divider /> <br />
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
            <br />
            <Button
              block
              size="large"
              htmlType="button"
              onClick={async () => {
                formFields.push({});
                await sleep(300); // Replaced 3e2 with 300 for clarity
                scroll();
              }}
            >
              <PlusOutlined style={{ fontSize: "24px", fontWeight: "bold" }} />
            </Button>
            <br />
          </>
        )}
      />
    );
  }
);
