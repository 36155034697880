import gql from "graphql-tag"

export const uploadDocumentMutation = gql`
  mutation uploadDocument(
    $file: Upload!
    $documentSource: DocumentSourceInput!
    $metadata: JSONObject
    $flags: [Fileflag]
    $filetype: Filetype
    $tags: JSON
  ) {
    uploadDocuments(
      files: [$file]
      documentSource: $documentSource
      metadata: [$metadata]
      flags: [$flags]
      filetype: [$filetype]
      tags: [$tags]
    ) {
      filename
      id
      mimetype
      processInstanceId
      url
      metadata
      flags
      filetype
      tags
    }
  }
`

export const replaceDocumentMutation = gql`
  mutation ReplaceDocument($file: Upload!, $id: ID!, $documentSource: DocumentSourceInput) {
    replaceDocument(file: $file, id: $id, documentSource: $documentSource) {
      id
      url
      filename
    }
  }
`
