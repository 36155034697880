import React from 'react'
// import { Sad } from 'design-components'
import Sad from '../Icon/Sad'

export const ErrorResultPage = ({ title, subTitle, extra }) => {
  return (
    <div className="error-result">
      <div className="error-result-card">
        <div className="error-result-icon">
          <Sad />
        </div>
        <div className="error-result-body">
          <div className="error-result-content">
            <div className="error-result-title">{title}</div>
            <div className="error-result-subtitle">{subTitle}</div>
          </div>
          <div className="error-result-extra">{extra}</div>
        </div>
      </div>
    </div>
  )
}
