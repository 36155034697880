import React from "react";
import Link from "../Link";
import { APP_URL } from "../../constants/url";

type DocumentLinkPropsType = {
  id: string;
  children: any;
};

const DocumentLink: React.FC<DocumentLinkPropsType> = ({ id, children }) => {
  return <Link href={APP_URL + "/documents/" + id + "/current"}>{children}</Link>;
};

export default DocumentLink;
