import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" {...props}>
    <path d="M75.58,58.92a11.8,11.8,0,1,0,4,16.18A11.78,11.78,0,0,0,75.58,58.92ZM76.18,73a7.77,7.77,0,1,1,.89-5.9A7.74,7.74,0,0,1,76.18,73Z" />
    <path d="M35.5,20.37a11.8,11.8,0,1,0,4,16.19A11.77,11.77,0,0,0,35.5,20.37Zm.6,14.13a7.79,7.79,0,1,1-2.66-10.7A7.81,7.81,0,0,1,36.1,34.5Z" />
    <path d="M46.38,46.29a22.67,22.67,0,0,0,4.95-8.24l7-.63.23-12.85-6.95-.87a23.08,23.08,0,0,0-4.67-8.41l2.95-6.36-11-6.62L34.62,7.9A23.23,23.23,0,0,0,25,7.72L21,2,9.74,8.23l2.72,6.45a22.67,22.67,0,0,0-5,8.24l-7,.63L.31,36.4l6.94.87a23.23,23.23,0,0,0,4.67,8.41L9,52l11,6.62,4.24-5.59a23.23,23.23,0,0,0,9.61.18l4,5.72L49.1,52.74Zm-.51-5.92a19.09,19.09,0,0,1-3.23,4l-1,.95L44,51l-5,2.74-3.53-5-1.34.35A19.2,19.2,0,0,1,24,48.88l-1.33-.39L19,53.38l-4.86-2.92,2.58-5.57-1-1a19.16,19.16,0,0,1-5-8.92l-.33-1.34-6.07-.76.1-5.67,6.11-.55L11,25.33a18.69,18.69,0,0,1,2-4.73,19.09,19.09,0,0,1,3.23-4l1-.95L14.82,10l5-2.74,3.52,5,1.34-.35a19.2,19.2,0,0,1,10.2.19l1.33.39,3.71-4.89,4.85,2.92-2.58,5.57,1,1a19.08,19.08,0,0,1,5,8.92l.33,1.34,6.07.76-.09,5.67-6.11.55-.38,1.33a18.69,18.69,0,0,1-2,4.73Z" />
    <path d="M91.68,62.24A23.19,23.19,0,0,0,87,53.84L90,47.47l-11-6.61L74.7,46.44a23.23,23.23,0,0,0-9.61-.18l-4-5.72L49.82,46.77l2.72,6.46a23.24,23.24,0,0,0-2.92,3.85,22.52,22.52,0,0,0-2,4.39l-7,.62-.22,12.85,6.94.87A23.23,23.23,0,0,0,52,84.22l-3,6.36,11,6.62,4.23-5.59a23.28,23.28,0,0,0,9.62.18l4,5.72,11.24-6.22-2.72-6.46A22.2,22.2,0,0,0,89.38,81a22.52,22.52,0,0,0,2-4.39l7-.63.23-12.85ZM94.46,72.3l-6.11.55L88,74.18a19.51,19.51,0,0,1-2,4.74h0a19.29,19.29,0,0,1-3.23,4l-1,1,2.38,5.65-4.95,2.74-3.53-5-1.34.34a19.1,19.1,0,0,1-10.2-.19L62.76,87l-3.71,4.89L54.19,89l2.58-5.57-1-1a19.19,19.19,0,0,1-5-8.92l-.32-1.35-6.08-.76.1-5.66,6.11-.55L51,63.88a19.51,19.51,0,0,1,2-4.74,19.09,19.09,0,0,1,3.23-4l1-1L54.9,48.53l5-2.75,3.52,5,1.34-.35a19.2,19.2,0,0,1,10.2.2l1.33.39L80,46.13l4.85,2.92-2.58,5.57,1,1a19.18,19.18,0,0,1,5,8.92l.32,1.34,6.08.77Z" />
  </svg>
)

export default SVG
