import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { createProcessInstanceMutation } from "./graphql/mutations";
import { multilang } from "../../helpers/multiLang";
import { ColorSelection, FilterSelection } from "../../constants/colors";
import WorkflowIcon from "../WorkflowIcon";
import { useTranslation } from "react-i18next";
import { SettingOutlined } from "@ant-design/icons";

type ProductCardPropType = {
  product: any;
  setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProductCard: React.FC<ProductCardPropType> = ({ product, setSubmitting }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isColor = (color: string): color is keyof typeof ColorSelection => {
    return Object.keys(ColorSelection).indexOf(color) !== -1;
  };

  const hexColor = useMemo(
    () => (isColor(product.color) ? ColorSelection[product.color] : FilterSelection.color1),
    [product.color, ColorSelection]
  );

  const filterColor = useMemo(
    () => (isColor(product.color) ? FilterSelection[product.color] : FilterSelection.color1),
    [product.color, FilterSelection]
  );

  const [createProcessInstance] = useMutation(createProcessInstanceMutation);

  const onProductClick = async () => {
    setSubmitting(true);
    const {
      data: { createProcessInstance: processInstance },
    } = await createProcessInstance({ variables: { input: { emailId: null, productId: product.id } } });

    setSubmitting(false);
    navigate(`/process-instances/${processInstance.id}?from=create`);
  };

  return (
    <div className="product-card-wrap" onClick={onProductClick} style={{ borderLeft: `5px solid ${hexColor}` }}>
      <WorkflowIcon
        tagged={product.status === "draft" && t("common.status.draft").toLocaleUpperCase()}
        defaultIcon={<SettingOutlined style={{ fontSize: 26 }} />}
        remoteIconUrl={product.icon?.url}
        color={hexColor}
        filter={filterColor}
      />
      <p>{multilang(product.title)}</p>
    </div>
  );
};

export default ProductCard;
