export const LOCATION = window.location.host.split(".").slice(-2).join(".").split(":")[0];

export const SLUG = localStorage.getItem("slug") || "home";

export const BASE_URL = window.location.protocol + "//api." + LOCATION;

export const LOCAL_URL = window.location.protocol + "//" + SLUG + "." + LOCATION;

export const APP_URL = BASE_URL + "/" + SLUG;

export const GRAPHQL_ENDPOINT = APP_URL + "/graphql";
