import React, { useState, useMemo, useEffect } from "react";
import { Form, List, Modal } from "antd";
import { useLazyQuery } from "@apollo/client";
import AuthorizationField from "../../../components/AuthorizationField/AuthorizationField";
import { emailValidation } from "../../../helpers/utils/validateUtils";
import { checkValidPassword } from "../../../helpers/password";
import * as yup from "yup";
import { messageError } from "../../Message";
import { useTranslation } from "react-i18next";
import { getUserSlugsQuery } from "../graphql/queries";

type SignInPageProps = {
  onSubmit: ({ email, password, slug }: { email: string; password: string; slug: string }) => void;
  loginError: boolean;
  setLoginError: (value: boolean) => void;
};

const superAdminEmail = "admin@thisisalf.com";

const SignInPage: React.FC<SignInPageProps> = ({ loginError, setLoginError, onSubmit }) => {
  const { t } = useTranslation();

  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [existError, setExistError] = useState(false);
  const [showSlugModal, setShowSlugModal] = useState(false);
  const [applications, setApplications] = useState([]);
  const [getUserSlugs, { loading: loadingSlugList, data: slugList, error: errorSlugList }] =
    useLazyQuery(getUserSlugsQuery);

  useEffect(() => {
    if (loginError) {
      setSubmitting(false);
    }
  }, [loginError]);

  useEffect(() => {
    setLoginError(false);
  }, [password, loginError]);

  useEffect(() => {
    if (errorSlugList) messageError("This is error while loading applications:", errorSlugList);
    if (!loadingSlugList && !errorSlugList && slugList) {
      if (slugList.getUserApplications.length === 0) {
        const e = new Error(t("errorNoSlugs"));
        messageError("", e);
      }
      setApplications(slugList.getUserApplications);
    }
    setSubmitting(false);
    if (loadingSlugList) {
      setSubmitting(true);
    }
  }, [loadingSlugList, errorSlugList, slugList]);

  useEffect(() => {
    if (applications.length > 1) {
      setShowSlugModal(true);
    } else {
      handleApplicationSelect(applications[0]);
    }
  }, [applications, loginEmail, password]);

  const emailIsAdmin = useMemo(() => loginEmail === superAdminEmail, [loginEmail]);
  const disableButtons = useMemo(() => {
    return submitting || !emailValidation(loginEmail) || password === "";
  }, [submitting, loginEmail, password]);

  const handleSubmit = () => {
    if (loginError) {
      setLoginError(false);
    }
    try {
      setSubmitting(true);
      validationSchema
        .validate({ email: loginEmail, password }, { abortEarly: false })
        .then((_) => {
          setSubmitting(false);
          getUserSlugs({ variables: { email: loginEmail, password } });
        })
        .catch(function (err) {
          setSubmitting(false);
          messageError(t("errorUserPasswordNotValid"), err);
        });
    } catch (e) {
      setSubmitting(false);
      messageError(t("errorUserPasswordNotValid"), e);
    }
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .test("emailExist", "No account for this email", () => !existError)
      .email("Please type a valid email")
      .required("Please type an email address"),
    password: yup
      .string()
      .test("validPassword", "Invalid password", (value) => emailIsAdmin || (value ? checkValidPassword(value) : false))
      .required("Please type a password"),
  });

  const handleApplicationSelect = (application) => {
    if (!application) {
      return;
    }
    localStorage.setItem("slug", application.slug);
    onSubmit({ email: loginEmail, password, slug: application.slug });
    setShowSlugModal(false);
  };

  return (
    <>
      <Form onFinish={handleSubmit}>
        <AuthorizationField
          placeholder="Email"
          stateChange={(val) => {
            setLoginEmail(val);
            setExistError(false);
          }}
          value={loginEmail}
          // onBlur={onEmailBlur}
          error={{
            submitError: loginError,
            textSubmitError: t("errorEmailSubmit"),
            textValidateError: !emailValidation(loginEmail) ? (
              t("errorNotValidEmail")
            ) : (
              <div>{t("errorValidEmail")}</div>
            ),
            validationError: !existError && emailValidation(loginEmail),
          }}
        />
        <AuthorizationField
          placeholder={t("password")}
          passwordEye
          stateChange={setPassword}
          type={"password"}
          value={password}
          error={{
            submitError: loginError,
            textValidateError: t("errorNotValidPassword"),
            validationError: emailIsAdmin || checkValidPassword(password),
            textSubmitError: "",
          }}
        />
        <Form.Item>
          <button className="alf-signin-form-button-long" disabled={disableButtons} type="submit">
            {t("login")}
          </button>
        </Form.Item>
      </Form>
      <Modal title={t("selectWorkSpace")} visible={showSlugModal} footer={null}>
        <List
          locale={{ emptyText: t("noData") }}
          dataSource={applications}
          renderItem={(application) => (
            <List.Item>
              <div className="slug-list-item">
                <p>{application.slug}</p>
                <img
                  src={application.logo}
                  onClick={() => handleApplicationSelect(application)}
                  alt={application.slug}
                />
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default SignInPage;
