import gql from 'graphql-tag'

export const createProcessInstanceMutation = gql`
  mutation CreateProcessInstance($input: CreateProcessInstanceInput!) {
    createProcessInstance(input: $input) {
      id
      workflow {
        id
        type
      }
    }
  }
`