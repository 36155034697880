import React, { FC } from 'react'
import { Collapse, Space, Typography } from 'antd'
import marked from 'marked'

const { Text } = Typography

interface InfoViewProps {
  collapsible?: boolean
  collapsed?: boolean
  justify?: boolean
  name?: string
  noBorder?: boolean
  title?: string
  value?: string
}

interface InfoViewExpandedProps extends InfoViewProps {
  className: string
  html: string
}

interface InfoViewCollapsibleProps extends InfoViewExpandedProps {}

const InfoViewExpanded: FC<InfoViewExpandedProps> = ({ className, html, justify, title }) => {
  return (
    <div style={{ textAlign: justify ? 'justify' : undefined }} className={className}>
      <Space>
        <Text strong>{title}</Text>
      </Space>
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

const InfoViewCollapsible: FC<InfoViewCollapsibleProps> = ({ className, html, justify, title }) => {
  return (
    <Collapse collapsible="header" accordion ghost expandIconPosition="right">
      <Collapse.Panel
        key={title}
        collapsible="header"
        showArrow={true}
        header={
          <Space>
            <Text strong>{title}</Text>
          </Space>
        }
      >
        <div style={{ textAlign: justify ? 'justify' : undefined }} className={className}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

export const InfoView: FC<InfoViewProps> = ({ collapsible, collapsed, justify, name, noBorder, title, value }) => {
  let html

  try {
    html = marked(value || '')
  } catch (error) {
    throw new Error(`[form] info field ${name || 'with-no-name'} failed to render markdown: ${error}`)
  }

  const className = `form-runner-field-info${noBorder ? '-no-border' : ''}`
  return collapsible ? (
    <InfoViewCollapsible className={className} collapsed={collapsed} html={html} justify={justify} title={title} />
  ) : (
    <InfoViewExpanded className={className} html={html} justify={justify} title={title} />
  )
}