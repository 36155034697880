import React, { useCallback, useState } from "react";
import { Collapse, CollapseProps, message } from "antd";
import ProcessInstanceForm from "../../../components/FormRunner";
import { multilang } from "../../../helpers/multiLang";
import QuestionImg from '../../../assets/svg/questionIcon.svg';
import { useMutation } from "@apollo/client";
import { saveProcessInstanceDataMutation } from "../graphql/mutation";
import { TABS } from "../../../types";
import { useTranslation } from "react-i18next";
import SVGIcon from "../../../components/SVGIcon/SVGIcon";

type ProcessInstanceInformationPagePropType = {
    processInstance: any,
    refetch: any,
    user: any,
}

const ProcessInstanceInformationPage: React.FC<ProcessInstanceInformationPagePropType> = ({ 
    processInstance, 
    user,
    refetch
}) => {

    const translation = useTranslation()
    const {
      i18n: { language },
      t
    } = translation

    const [saveProcessInstanceData] = useMutation(saveProcessInstanceDataMutation)
    const [messageApi, contextHolder] = message.useMessage();

    const [openPanelKey, setOpenPanelKey] = useState<string | null>(null);

    const frames = [];

    const questionnaireWorkflows = processInstance?.workflow.filter(workflow => workflow.type === 'form')

    questionnaireWorkflows.forEach((workflow) => frames.push(...workflow.config))

    const saveData = useCallback(
        async values => {
            try {
                await saveProcessInstanceData({ variables: { data: values, id: processInstance.id }})
                refetch();
                messageApi.open({
                    type: 'success',
                    content: t('informationSaved')
                })
                return true
            } catch (e) {
                messageApi.open({
                    type: 'error',
                    content: t('errorOccurred')
                })
                return false
            }
        }, 
        []
    )

    console.log('frames ===> ', frames)

    const hasNonUploadFileType = (fields) => {
        return fields.some(field => {
            if (field.type !== 'file' && field.type !== 'idCheck' && field.type !== 'info') {
                return true;
            } else if (field.type === 'multiple' && field.fields) {
                return hasNonUploadFileType(field.fields);
            }
            return false;
        });
    }
    
    const filteredFrames = frames.filter(frame => hasNonUploadFileType(frame.fields));

    console.log('filteredFrames ===> ', filteredFrames)

    const toggleCollapse = (frameIndex: string) => {
        setOpenPanelKey(openPanelKey === frameIndex ? null : frameIndex);
    };

    return (
        <div className="process-instance-information-page">
            {contextHolder}
            {
                filteredFrames.map((frame, index) => (
                    <Collapse 
                        className={index % 2 === 0 ? `form-card color-red` : `form-card color-blue`}
                        bordered={false} 
                        accordion
                        expandIconPosition="end"
                        activeKey={openPanelKey === index.toString() ? [index]: []}
                        onChange={() => toggleCollapse(index.toString())}
                        items={[
                            {
                                key: index,
                                label: 
                                    <div className="form-card-label">
                                        <SVGIcon src={QuestionImg} width={'20px'} />
                                       {/* <img src={QuestionImg} width={20} alt="red-folder-img" /> */}
                                        <div>{frame[`label_${language.toLowerCase()}`] || frame.label}</div>
                                    </div>,
                                children: 
                                    <ProcessInstanceForm 
                                        processInstance={processInstance} 
                                        frame={frame}
                                        initialValues={processInstance.data || {}}
                                        user={user} 
                                        index={index}
                                        title={multilang(frame.name)}
                                        onSubmit={saveData}
                                        tabs={TABS.INFORMATION}
                                    />
                            }
                        ]}
                    />
                ))
            }
        </div>
    )
}

export default ProcessInstanceInformationPage