import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="5 5 25 25" {...props}>
    <g>
      <polygon points="16.414,19.023 14.27,16.881 13.563,17.588 16.414,20.437 21.014,15.837 20.307,15.13 	" />
      <path
        d="M29.94,17.225c-0.18-0.302-4.493-7.376-12.597-7.376S4.926,16.924,4.747,17.225l-0.152,0.256l0.152,0.256
		c0.18,0.302,4.493,7.376,12.597,7.376s12.417-7.074,12.597-7.376l0.152-0.256L29.94,17.225z M17.397,23.933
		c-1.726,0-3.349-0.673-4.569-1.894c-2.52-2.52-2.52-6.618,0-9.138c1.26-1.26,2.915-1.89,4.569-1.89s3.31,0.63,4.569,1.89
		c2.52,2.52,2.52,6.618,0,9.138C20.746,23.261,19.123,23.933,17.397,23.933z M5.773,17.481c0.616-0.91,2.93-4,6.717-5.62
		c-0.124,0.109-0.25,0.215-0.369,0.333c-2.909,2.909-2.909,7.643,0,10.552c0.131,0.131,0.271,0.252,0.41,0.372
		C8.719,21.504,6.391,18.394,5.773,17.481z M22.365,23.027c0.103-0.093,0.209-0.181,0.308-0.28c2.909-2.909,2.909-7.643,0-10.552
		c-0.086-0.086-0.178-0.16-0.267-0.241c3.662,1.642,5.902,4.634,6.507,5.527C28.307,18.377,26.052,21.389,22.365,23.027z"
      />
    </g>
  </svg>
)

export default SVG
