import React from "react";
import { useMutation } from "@apollo/client";
import Layout from "../../components/Layout";
import Dossier, { DossierPropsType } from "../../components/Dossier/Dossier";
import FolderImg from "../../assets/svg/folders.svg";
import AttachImg from "../../assets/svg/attach.svg";
import AIImg from "../../assets/svg/AI.svg";
import ClientsImg from "../../assets/svg/clients.svg";
import NoteImg from "../../assets/svg/note.svg";
import existingClientsImg from "../../assets/svg/existingClients.svg";
import { useTranslation } from "react-i18next";
import { signOutUserMutation } from "./graphql/mutations";
import { useMixpanel } from "../../components/useMixpanel";
import SVGIcon from "../../components/SVGIcon/SVGIcon";
import { LOCAL_URL } from "../../constants/url";

const HomePage = () => {
  const { reset } = useMixpanel();

  const { t } = useTranslation();
  const [signoutUser] = useMutation(signOutUserMutation);

  const dossiers: DossierPropsType[] = [
    {
      icon: <SVGIcon src={FolderImg} width="31px" />,
      title: t("createDossier"),
      content: t("createDossierDescription"),
      link: "/store",
      beta: false,
    },
    {
      icon: <SVGIcon src={AttachImg} width="31px" />,
      title: t("attachDossier"),
      content: t("attachDossierDescription"),
      link: "/attach",
      beta: false,
    },
    {
      icon: <SVGIcon src={ClientsImg} width="31px" />,
      title: t("createAnEntity"),
      content: t("createAnEntityDescription"),
      link: "/",
      beta: false,
    },
    {
      icon: <SVGIcon src={existingClientsImg} width="31px" />,
      title: t("updateEntity"),
      content: t("updateEntityDescription"),
      link: "/",
      beta: false,
    },
    {
      icon: <SVGIcon src={AIImg} width="31px" />,
      title: t("AIAnalysis"),
      content: t("AIAnalysisDescription"),
      link: "/",
      beta: true,
    },
    {
      icon: <SVGIcon src={NoteImg} width="31px" />,
      title: t("AddStickyNote"),
      content: t("AddStickyNoteDescription"),
      link: "/",
      beta: true,
    },
  ];

  const goToAlf = () => {
    if (Office && Office.context && Office.context.ui) {
      Office.context.ui.openBrowserWindow(LOCAL_URL);
    } else {
      console.error("Office.js is not fully initialized or not supported.");
    }
  };

  const handleSignOut = React.useCallback(async () => {
    await signoutUser();
    reset();
    localStorage.removeItem("slug");
    localStorage.removeItem("loginMethod");
    localStorage.removeItem("uploadedAttachments");
    window.location.href = "/#/login";
  }, [reset, signoutUser]);

  return (
    <Layout>
      {dossiers.map((dossier, index) => (
        <Dossier
          key={index}
          icon={dossier.icon}
          title={dossier.title}
          content={dossier.content}
          link={dossier.link}
          beta={dossier.beta}
        />
      ))}
      <div className="home-btns">
        <div className="go-to-alf" onClick={goToAlf}>
          {t("RedirectToPlatform")}
        </div>
        <div className="log-out" onClick={handleSignOut}>
          {t("logout")}
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
