import mixpanel from 'mixpanel-browser'
import { config } from '../../config'

if (config.mixpanel.token) {
  mixpanel.init(config.mixpanel.token, { debug: false })
}

const actions = {
  alias: id => {
    if (config.mixpanel.token) {
      mixpanel.alias(id)
    }
  },
  identify: id => {
    if (config.mixpanel.token) {
      mixpanel.identify(id)
    }
  },
  people: {
    set: props => {
      if (config.mixpanel.token) {
        mixpanel.people.set(props)
      }
    },
  },
  reset: () => {
    if (config.mixpanel.token) {
      mixpanel.reset()
    }
  },
  track: (name, props) => {
    if (config.mixpanel.token) {
      mixpanel.track(name, props)
    }
  },
}

export const useMixpanel = () => actions
