type Option = {
  value?: any;
  label?: string;
  [key: string]: any;
}

type SelectAsyncDataValue = {
  value?: any;
  options?: Option[];
  asyncData?: any;
}

type LocaleFieldName = {
  locale?: string;
  fieldName?: string;
}

export const selectAsyncData = {
  serialize: ({ type, asyncData }: { type: string; asyncData: any }) =>
    (value: any) => ({
      value: (value && value.value) || value || undefined,
      type,
      asyncData,
    }),
  
  deserialize: ({ value }: { value: any } = { value: null }) => value,
  
  validate: () => 
    ({ value }: { value: any } = { value: null }) =>
      Boolean(value) ? null : 'requiredField',
  
  // fake: faker.random.arrayElement,
  
  toStr: ({ value, options = [] }: SelectAsyncDataValue, { locale = 'en', fieldName = 'label' }: LocaleFieldName = {}) => {
    const getter = (obj: any, field: string) => obj[`${field}_${locale.toLowerCase()}`] || obj[field]

    const res: Option = options.find(option => (option.value !== undefined ? option.value : option) === value) || {}
    return getter(res, fieldName) || getter(res, 'label') || res
  },
}