import React, { useState, useMemo, useCallback } from 'react'
// import { useMutation } from '@apollo/client'

// import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons'
// import { Form } from 'components/Form'

// import { InnerButton, PlusIcon, Modal, DeleteIcon, InputField, ColorSelector, Alert } from 'design-components'

// import { Link } from 'components/Link'
// import { ProductCategoryCreate } from 'components/ProductCategoryCreate'
// import { PageHeader } from 'components/PageHeader'
// import { messageError, messageSuccess } from 'components/Message'
// import { deleteProductCategoryMutation } from '../../../pages/ProductCategoryListPage/graphql/mutations'
// import { ConfirmModal } from 'components/ConfirmModal'
// import { userIsAtLeast } from 'helpers/user'
// import { ProductCard } from './ProductCard'

import { FilterPills } from '../../../components/FilterPills'
// import { USER_TYPES } from 'constants/user'
import { PillType } from '../../../components/FilterPills/types'
import ProductCard from '../../../components/ProductCard';
import ServiceImg from '../../../assets/svg/folders_1.svg';
import { useTranslation } from 'react-i18next';

type ProductCardsPropType = {
  productByCategoriesSorted: any;
  onCategoryEdit: () => void;
  onCategoryCreated?: any;
  onCategoryDeleted?: any;
  onProductClick: (product: any) => void;
  onProductFav?: any;
  notEditable?: boolean;
  application: any
  user: any;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductCards: React.FC<ProductCardsPropType> = ({
  productByCategoriesSorted,
  // onCategoryEdit,
  // onCategoryCreated,
  // onCategoryDeleted,
  // onProductClick,
  // onProductFav,
  notEditable,
  // application,
  setSubmitting,
  user,
}) => {
  // const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [isAddCategoryModalOpened, setIsAddCategoryModalOpened] = useState(false)
  // const [deleteProductCategory] = useMutation(deleteProductCategoryMutation)
  // const [confirmModalData, setConfirmModalData] = useState({})

  // const handleCategoryEdit = useCallback(
  //   async values => {
  //     try {
  //       setSubmitting(true)
  //       await onCategoryEdit(values)
  //     } catch (e) {
  //       setSubmitting(false)
  //     }
  //   },
  //   [onCategoryEdit]
  // )

  // const onDeleteCategory = useCallback(
  //   async id => {
  //     try {
  //       await deleteProductCategory({ variables: { id } })
  //       messageSuccess(t('productCategory.delete.form.success'))
  //       onCategoryDeleted()
  //     } catch (e) {
  //       messageError(t('productCategory.delete.form.error'), e)
  //       throw e
  //     }
  //   },
  //   [t, deleteProductCategory, onCategoryDeleted]
  // )

  const handleCreateCategoryClick = () => {
    setIsAddCategoryModalOpened(true)
  }

  // const handleCloseCategoryModal = () => {
  //   setIsAddCategoryModalOpened(false)
  // }

  // const handleCategoryCreated = () => {
  //   handleCloseCategoryModal()
  //   onCategoryCreated()
  // }

  const categories: Record<string, PillType> = useMemo(
    () =>
      productByCategoriesSorted.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: {
            data: {
              categoryId: category.id,
              count: category.products.length,
              title: category.title,
            },
            productsPublish: category.products.filter(data => data.status === 'publish').length,
          },
        }),
        {}
      ),
    [productByCategoriesSorted]
  )

  const FilterButtons = () => {
    return (
      <FilterPills
        handleCreateCategoryClick={handleCreateCategoryClick}
        displayAdd={!notEditable}
        allPillText={t('productCard.allServices')}
        currentPill={selectedCategory}
        setCurrentPill={setSelectedCategory}
        pills={categories}
        displayCount={false}
        user={user}
      />
    )
  }

  // const CreateCategoryModal = () => {
  //   return (
  //     <Modal
  //       className="create-category-modal"
  //       headerText={<PageHeader title={t('categoryCreate.title')} />}
  //       modalContent={<ProductCategoryCreate onFinish={handleCategoryCreated} />}
  //       isShown={isAddCategoryModalOpened}
  //       hide={handleCloseCategoryModal}
  //       closeIcon={<CloseOutlined />}
  //       style={{
  //         mainStyle: {
  //           maxWidth: 600,
  //           minWidth: 400,
  //           padding: 50,
  //           width: '70%',
  //         },
  //       }}
  //     />
  //   )
  // }

  // const AddServiceButton = () => {
  //   return (
  //     <Link key="new" to={{ pathname: '/admin/products/new', state: { scrollCoordinates: [0, 0] } }}>
  //       <InnerButton
  //         className="add-service-button"
  //         leftIcon={<PlusIcon style={{ height: '80px', strokeWidth: 1, width: '80px' }} />}
  //         type="dashed"
  //       />
  //     </Link>
  //   )
  // }

  // const CategoryHeader = ({ category, noTopMargin }) => {
  //   const [form] = Form.useForm()
  //   const [isEditing, setIsEditing] = useState(false)
  //   const initialValues = {
  //     color: category.color,
  //     name: category.titleProps,
  //   }

  //   const onEditClick = () => {
  //     setIsEditing(true)
  //   }

  //   const onDeleteClick = useCallback(id => {
  //     setConfirmModalData(prevState => ({
  //       ...prevState,
  //       confirmText: t('productCategory.delete.confirm.yesDelete'),
  //       isOpen: true,
  //       onConfirm: () => onDeleteCategory(id),
  //       title: t('productCategory.delete.confirm.areYouSure'),
  //     }))
  //   }, [])

  //   const onCancelClick = () => {
  //     setIsEditing(false)
  //     form.setFieldsValue(initialValues)
  //   }

  //   const onSaveClick = () => {
  //     const value = form.getFieldValue('name')
  //     const title = { ...category.title }
  //     title[language.toUpperCase()] = value
  //     setIsEditing(false)
  //     handleCategoryEdit({
  //       id: category.id,
  //       input: {
  //         color: form.getFieldValue('color'),
  //         index: category.index,
  //         title,
  //       },
  //     })
  //   }
  //   const titleCategory = useMemo(
  //     () =>
  //       category.products.filter(data => data.status === 'publish').length === 0 &&
  //       user?.type === USER_TYPES.operator ? null : (
  //         <h3 className="title-products-filtered">
  //           {category.titleProps} ({(category.products.filter(data => data.status === 'publish') || []).length})
  //         </h3>
  //       ),
  //     [category.products, category.titleProps]
  //   )
  //   return (
  //     <div className="product-category-header" style={{ marginTop: noTopMargin && 0 }}>
  //       {isEditing ? (
  //         <>
  //           <Form form={form} initialValues={initialValues} onFinish={onSaveClick} layout="inline">
  //             <Form.Item
  //               className="product-category-header-input"
  //               name="name"
  //               rules={[
  //                 { message: t('job.error.name'), required: true },
  //                 {
  //                   message: t('job.error.category'),
  //                   required: true,
  //                   validator: (rule, value) => {
  //                     return initialValues.name === value || productByCategoriesSorted.indexOf(value) < 0
  //                       ? Promise.resolve()
  //                       : Promise.reject()
  //                   },
  //                 },
  //               ]}
  //             >
  //               <InputField placeholder={t('categoryForm.title.placeholder')} name="name" type="text" />
  //             </Form.Item>
  //             <Form.Item name="color" className="product-category-header-color">
  //               <ColorSelector />
  //             </Form.Item>
  //           </Form>
  //           <InnerButton type="link" loading={submitting} onClick={form.submit} leftIcon={<CheckOutlined />} />
  //           <InnerButton type="link" onClick={onCancelClick} leftIcon={<CloseOutlined />} />
  //         </>
  //       ) : (
  //         <>
  //           {titleCategory}
  //           {userIsAtLeast(user, USER_TYPES.editor) && !notEditable && (
  //             <>
  //               <InnerButton
  //                 type="link"
  //                 className="product-category-header-edit"
  //                 leftIcon={<EditOutlined />}
  //                 onClick={onEditClick}
  //               />
  //               {!(category.products || []).length && (
  //                 <InnerButton
  //                   className="product-category-header-delete"
  //                   type="link"
  //                   leftIcon={<DeleteIcon />}
  //                   onClick={() => onDeleteClick(category.id)}
  //                 />
  //               )}
  //             </>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   )
  // }
  const filteredCategories = useMemo(
    () =>
      (productByCategoriesSorted || [])
        .filter(({ id }) => !selectedCategory || id === selectedCategory)
        .sort((a, b) => {
          if (a.index !== b.index) {
            return a.index - b.index
          }
          return a.titleProps['EN']?.localeCompare(b.titleProps['EN'])
        }),
    [productByCategoriesSorted, selectedCategory]
  )

  filteredCategories.map(data => data.products.sort((a, b) => b.status.toString().localeCompare(a.status)))
  // const filteredCategoriesProducts = useMemo(
  //   () =>
  //     user?.type === USER_TYPES.operator
  //       ? filteredCategories?.filter(data => data.products.length)
  //       : filteredCategories,
  //   [filteredCategories, user?.type]
  // )

  const ProductCardItems = () => {
    return (
      <div className='product-cards-row'>
        {filteredCategories.map((category) => (
            // <div className={'product-cards-row'} key={category.id}>
              (category.products || []).map(product => {
                return (
                  // <div>{product.title['FR']}</div>
                  <ProductCard 
                    product={product}
                    setSubmitting={setSubmitting}
                  />
                )
              }
              
              )
            // </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {/* <ConfirmModal
        title={confirmModalData.title}
        onConfirm={confirmModalData.onConfirm}
        confirmText={confirmModalData.confirmText}
        isShown={confirmModalData.isOpen}
        setIsShown={value => setConfirmModalData(prevState => ({ ...prevState, isOpen: value }))}
        className="confirm-modal"
      /> */}
      {/* <CreateCategoryModal /> */}
      <FilterButtons />
      {/* {application.freemium && userIsAtLeast(user, USER_TYPES.editor) && (
        <Alert type="primary" showIcon>
          {t('productList.info')}
        </Alert>
      )} */}
      <ProductCardItems />
    </>
  )
}
