import { faker } from '@faker-js/faker'

interface SerializeInput {
  type: string;
}

interface DeserializeInput {
  value: boolean;
}

export const boolean = {
  serialize: ({ type }: SerializeInput) => (value: boolean = true) =>
    value ? { value, type } : undefined,
  deserialize: ({ value }: DeserializeInput = { value: null }) => value,
  validate: () => ({ value }: { value?: any } = { value: undefined }) =>
    value !== undefined ? null : 'requiredField',
  fake: faker.random.boolean,
}