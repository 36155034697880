import React from 'react'
import classNames from 'classnames'
import Happy from '../Icon/ValidIcon'
import Sad from '../Icon/NotValidIcon'
import Eye from '../Icon/EyePasswordIcon'
import EyeOff from '../Icon/EyeOffPasswordIcon'
import TooltipAuthorization from '../TooltipAuthorization/TooltipAuthorization'

type AuthorizationError = {
  submitError?: boolean
  textSubmitError: string | React.ReactNode
  textValidateError: string | React.ReactNode
  validationError?: boolean
}

interface AuthorizationFieldProps {
  placeholder: string
  value: string
  error: AuthorizationError
  stateChange: React.Dispatch<string>
  disableValidateStyle?: boolean
  passwordEye?: boolean
  type?: string
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void
  disableTooltip?: boolean
}

export const AuthorizationField: React.FC<AuthorizationFieldProps> = ({
  placeholder,
  value,
  error,
  stateChange,
  disableValidateStyle,
  passwordEye,
  type,
  onBlur,
  disableTooltip,
}) => {
  const [inputType, setInputType] = React.useState(type)
  const [inputFocus, setInputFocus] = React.useState(false)
  const isInvalid = React.useMemo(() => !error?.validationError || error?.submitError, [error])
  const isValid = React.useMemo(() => error?.validationError, [error])

  const getClassNames = (className: string) => {
    if (disableValidateStyle || !value.length || inputFocus) {
      return className
    }
    return classNames(className, { valid: isValid }, { invalid: isInvalid })
  }

  const renderError = React.useCallback(() => {
    if (disableValidateStyle || !value || inputFocus) return null
    if (error?.submitError) {
      return error?.textSubmitError ? (
        <TooltipAuthorization content={error.textSubmitError} disabled={disableTooltip} isOpen>
          <Sad />
        </TooltipAuthorization>
      ) : null
    }
    if (!error?.validationError) {
      return (
        <TooltipAuthorization content={error?.textValidateError} isOpen>
          <Sad />
        </TooltipAuthorization>
      )
    }

    return <Happy />
  }, [error, disableValidateStyle, inputFocus])

  return (
    <div className={getClassNames('sign-in-form-input-container')}>
      <input
        onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
          typeof onBlur === 'function' && onBlur(evt)
          setInputFocus(false)
        }}
        onFocus={() => setInputFocus(true)}
        className={getClassNames('sign-in-form-input')}
        placeholder=" "
        type={inputType}
        onChange={event => stateChange(event.target.value)}
        value={value}
        data-testid="input-test"
      />
      <label htmlFor="email-input" className="sign-in-form-input-label">
        {placeholder}
      </label>
      {renderError()}
      {passwordEye && (
        <div className="recover-password">
          {inputType === 'password' ? (
            <div onClick={() => setInputType('text')}>
              <EyeOff />
            </div>
          ) : (
            <div onClick={() => setInputType('password')}>
              <Eye />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AuthorizationField
