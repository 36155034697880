import React, { useMemo } from 'react'
import { Button } from 'antd'
import { PreviewFile, PreviewUrl } from '../../components/PreviewUrl'
import { multilang } from '../../helpers/multiLang'
import { getPreviewLink } from '../../helpers/document'
import CloseIcon from '../../components/Icon/CloseIcon'

interface FilePreviewProps {
  value: any
  file: File | null
  document: any
  onRemoveFile: () => void
}

export const FilePreview: React.FC<FilePreviewProps> = ({ value, file, document, onRemoveFile }) => {
  const fileName = useMemo(
    () => value && document && (file ? file.name : multilang(document.filename)),
    [file, document, value]
  )
  
  return (
    <div className="file-preview">
      <div className="file-preview-file">
        {file ? (
          <PreviewFile file={file} />
        ) : (
          <PreviewUrl url={getPreviewLink(document)} filename={multilang(document.filename)} />
        )}
      </div>
      <div className="file-preview-name">
        <p>{fileName}</p>
        <Button
          htmlType="button"
          type="text"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onRemoveFile()
          }}
          icon={<CloseIcon />}
        ></Button>
      </div>
    </div>
  )
}