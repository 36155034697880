import React from 'react'

const SVG = props => (
  <svg viewBox="0 0 512 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <g>
        <path
          d="M479.388,89.13H32.612C14.63,89.13,0,103.76,0,121.742v268.516c0,17.982,14.63,32.612,32.612,32.612h446.776
			c17.982,0,32.612-14.63,32.612-32.612V121.742C512,103.76,497.37,89.13,479.388,89.13z M490.259,390.257
			c0,5.994-4.877,10.871-10.871,10.871H32.612c-5.994,0-10.871-4.877-10.871-10.871V121.741c0-5.994,4.877-10.871,10.871-10.871
			h446.776c5.994,0,10.871,4.877,10.871,10.871V390.257z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M422.107,184.506H305.442c-6.004,0-10.871,4.868-10.871,10.871c0,6.004,4.867,10.871,10.871,10.871h116.665
			c6.004,0,10.871-4.867,10.871-10.871C432.978,189.374,428.111,184.506,422.107,184.506z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M422.107,245.129H305.442c-6.004,0-10.871,4.867-10.871,10.871c0,6.003,4.867,10.871,10.871,10.871h116.665
			c6.004,0,10.871-4.868,10.871-10.871C432.978,249.996,428.111,245.129,422.107,245.129z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M422.107,305.752H305.442c-6.004,0-10.871,4.868-10.871,10.871c0,6.003,4.867,10.871,10.871,10.871h116.665
			c6.004,0,10.871-4.868,10.871-10.871C432.978,310.62,428.111,305.752,422.107,305.752z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M200.747,290.243c-0.22-0.015-20.116,0.814-25.49-20.71c7.261-5.715,13.354-13.656,17.708-23.14
			c0.322,0.032,0.644,0.072,0.964,0.072c2.964-0.001,5.834-1.351,7.72-3.774c9.129-11.725,13.876-26.949,13.368-42.868
			c-1.056-33.004-24.017-59.022-52.312-59.328c-0.774-0.031-1.463-0.011-2.02,0.009c-0.013,0-0.026,0.001-0.04,0.001
			c-6.865-2.975-14.097-4.369-21.55-4.124c-15.932,0.509-30.55,8.401-41.158,22.223c-10.165,13.243-15.446,30.475-14.869,48.527
			c0.098,3.054,0.34,5.87,0.728,8.538c1.053,7.686,3.173,15.054,6.301,21.897c1.503,3.289,4.699,5.482,8.311,5.698
			c0.983,0.063,1.948-0.038,2.876-0.261c4.427,10.973,11.116,20.104,19.206,26.479c-5.267,21.488-25.28,20.749-25.499,20.763
			c-21.389,0.7-38.576,18.305-38.576,39.86v39.614c0,5.404,4.38,9.784,9.784,9.784h163.339c5.404,0,9.784-4.38,9.784-9.784v-39.614
			C239.323,308.548,222.135,290.943,200.747,290.243z M103.303,213.828c-0.045-0.299-0.088-0.6-0.13-0.901
			c-0.292-2.012-0.471-4.115-0.545-6.426c-0.434-13.542,3.413-26.321,10.833-35.985c6.972-9.085,16.301-14.262,26.262-14.581
			c0.329-0.011,8.148-0.63,14.725,3.257c1.377,0.814,3.064,1.057,4.655,1.018c0.201-0.005,3.133-0.153,3.281-0.152
			c17.53,0.115,32.37,18.233,33.078,40.388c0.082,2.538-0.02,5.046-0.296,7.499c-6.475-11.114-16.093-19.72-27.539-24.533
			c-2.46-1.033-5.236-1.018-7.684,0.043c-2.449,1.062-4.357,3.079-5.283,5.582c-0.755,2.042-1.673,4.032-2.726,5.917
			c-2.569,4.594-7.825,7.303-13.716,7.118c-1.172-0.04-2.356-0.042-3.521-0.005c-5.733,0.184-11.324,1.277-16.657,3.264
			C112.713,207.343,107.762,210.199,103.303,213.828z M116.938,228.029c2.479-1.832,5.203-3.187,7.976-4.375
			c5.056-2.168,11.888-2.05,12.627-2.024c12.603,0.428,24.108-5.469,30.51-15.515c4.992,3.825,9.115,8.977,11.991,15.082
			c-3.03,21.957-16.695,38.661-32.201,38.661C134.007,259.858,121.591,246.773,116.938,228.029z M138.437,278.323
			c3.062,0.722,6.205,1.102,9.404,1.102c3.229,0,6.391-0.383,9.463-1.099c3.153,10.068,9.776,18.612,18.454,24.244
			c-4.174,11.486-15.217,19.539-27.888,19.539s-23.714-8.053-27.889-19.54C128.661,296.936,135.284,288.392,138.437,278.323z
			 M75.984,359.934v-29.83c0-11.209,9.119-20.327,20.328-20.327c0.224,0,3.793-0.205,5.322-0.411
			c6.966,19.002,25.257,32.311,46.235,32.311c20.978,0,39.269-13.309,46.235-32.311c1.531,0.206,5.097,0.411,5.321,0.411
			c11.21,0,20.328,9.119,20.328,20.327v29.83H75.984z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)

export default SVG
