import React, { FC } from 'react';
import { Input } from 'antd';

interface TextViewProps {
  id: string;
  type: string;
  stepId: string;
  processInstance: any;
}

export const TextView: FC<TextViewProps> = ({ id, type, stepId, processInstance, ...props }) => <Input id={id} {...props} />