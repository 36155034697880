import React, { FC } from 'react'
import { QuestionCircleFilled } from '@ant-design/icons'
import { Radio, Tooltip } from 'antd'

interface Option {
  label?: string
  value: string
  help?: string
}

interface RadioViewProps {
  id: string
  options: Option[]
  vertical?: boolean
  centered?: boolean
  processInstance?: any
  stepId?: any
  [key: string]: any
}

export const RadioView: FC<RadioViewProps> = ({ id, options, vertical, centered, processInstance, stepId, ...props }) => (
  <Radio.Group
    id={id}
    {...props}
    style={
      vertical ? { width: '100%' } : { display: 'flex', flexWrap: 'wrap', justifyContent: centered ? 'center' : 'left' }
    }
  >
    {(options || []).map(({ label, value, help }) => (
      <Radio key={value} value={value} style={vertical ? { display: 'block' } : null}>
        {label || value}
        {help && (
          <Tooltip title={help}>
            <QuestionCircleFilled style={{ fontSize: '20px', color: '#1890ff', float: 'right' }} />
          </Tooltip>
        )}
      </Radio>
    ))}
  </Radio.Group>
)