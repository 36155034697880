import { faker } from '@faker-js/faker'
import { isString } from 'lodash'

type TextValue = {
  value?: string;
  type?: string;
}

export const text = {
  serialize: ({ type }: { type: string }) =>
    (value: string): TextValue => ({ value: value || '', type }),
  
  deserialize: (value: { value?: string }) => (value && value.value ? value.value : ''),
  
  toStr: (value: { value?: string }) => (value && isString(value.value) ? value.value.trim() : ''),
  
  validate: () => (value: { value?: string }) => 
    value && isString(value.value) && value.value.trim().length > 0 ? null : 'requiredField',
  
  fake: faker.lorem.words,
}