type File = {
  response: any;
}

type SerializedFile = {
  value: any;
  type: string;
  asyncData: any;
}

export const file = {
  serialize: ({ type, asyncData }: { type: string; asyncData: any }) =>
    ({ file: { response: value } = {response: null}}: { file: File } = {file: {response: ""}}): SerializedFile => ({ value, type, asyncData }),
  
  deserialize: ({ value }: { value: any } = {value: null}) => value,
}