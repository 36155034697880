import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="5 5 25 25" {...props}>
    <g>
      <path
        d="M25.839,8.873H8.665c-1.93,0-3.5,1.57-3.5,3.5v9.758c0,1.93,1.57,3.5,3.5,3.5h17.174c1.93,0,3.5-1.57,3.5-3.5v-9.758
		C29.339,10.444,27.768,8.873,25.839,8.873z M8.665,9.873h17.174c1.379,0,2.5,1.121,2.5,2.5v8.55H6.165v-8.55
		C6.165,10.994,7.286,9.873,8.665,9.873z M25.839,24.631H8.665c-1.379,0-2.5-1.121-2.5-2.5v-0.208h22.174v0.208
		C28.339,23.51,27.218,24.631,25.839,24.631z"
      />
      <path
        d="M12.654,16.362h1.854v-4.708h-1.854H9.8H7.946v4.708H9.8H12.654z M12.654,15.362v-0.854h0.854v0.854H12.654z
		 M13.508,12.654v0.854h-0.854v-0.854H13.508z M11.654,12.654v2.708H10.8v-2.708H11.654z M9.8,12.654v0.854H8.946v-0.854H9.8z
		 M8.946,15.362v-0.854H9.8v0.854H8.946z"
      />
    </g>
  </svg>
)

export default SVG
