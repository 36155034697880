import React, { useCallback, useState } from "react";
import { Collapse, CollapseProps, message } from "antd";
import {Button} from 'antd'
import { multilang } from "../../../helpers/multiLang";
import { useTranslation } from "react-i18next";

type ProcessInstanceEmailPagePropType = {
    processInstance: any,
    handleMoveToInformationTab: () => void
}

const ProcessInstanceEmailPage: React.FC<ProcessInstanceEmailPagePropType> = ({ 
    processInstance, 
    handleMoveToInformationTab
}) => {

    const translation = useTranslation()
    const {
      i18n: { language },
      t
    } = translation


    const sendEmail = (toAddress) => {
          const item = Office.context.mailbox.item;
  
          const toRecipients = [{ emailAddress: toAddress }];
          const subject = item.subject;
          let bodyContent = '';
          item.body.getAsync('html', { asyncContext: 'This is passed to the callback' }, (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
              bodyContent = result.value;
              
              var attachments = [];
  
              item.attachments.forEach((attachment) => {
                  attachments.push({
                    type: "item",
                    name: attachment.name,
                    itemId: attachment.id
                  });
                });
              }
              Office.context.mailbox.displayNewMessageForm({
                toRecipients: toRecipients,
                subject: "Fwd: " + subject,
                htmlBody: bodyContent,
                attachments: attachments
            });
          });
      }
    
    const handleSendEmail = () => {
        if(processInstance && processInstance?.bcc) {
            sendEmail(processInstance?.bcc)
          }
    }

    return (
        <div className="process-instance-email-page">
            <span className="process-instance-email-page-quiz">{t('processInstance.email.quiz')}</span>
            <div className="process-instance-email-page-btn-group">
                <Button
                type="primary"
                onClick={() => handleSendEmail()}
                >
                    {t('processInstance.email.yes')}
                </Button>
                <Button type="primary" onClick={() => handleMoveToInformationTab()}>
                    {t('processInstance.email.no')}
                </Button>
            </div>
        </div>
    )
}

export default ProcessInstanceEmailPage