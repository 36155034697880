export const entityTypes = { COMPANY: "company", PERSON: "person" };
export const entityTypesPlural = { COMPANIES: "companies", PERSONS: "persons" };
export const entityTypeName = "type";
export const entityFieldTypeName = "entity";

export const CALENDAR_DEADLINE_TYPES = {
  ACCOUNTING: "accounting",
  CONTRACTUAL: "contractual",
  FISCAL: "fiscal",
  SOCIAL: "social",
};

export const CALENDAR_DEFAULT_DEADLINES_TITLES = {
  accountsClosing: "Cloture comptable",
  cfePaymentRegEx: /^paiement CFE (?<date>.+)$/,
  companyRegistrationRegEx: /^Enregistrement de (?<companyName>.+)$/,
  vatPaymentRegEx: /^paiement TVA (?<date>.+)$/,
};
