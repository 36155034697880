/* eslint-disable camelcase */
// const { faker } = require('@faker-js/faker')
import { faker } from "@faker-js/faker";
import isEmail from "validator/lib/isEmail";

// const isEmail = require('validator/lib/isEmail')
import moment from "moment";
import dayjs from "dayjs";
import isFinite from "lodash/isFinite";
import isString from "lodash/isString";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { randomString } from "./helpers/utils";
import types from "./types";

// faker.locale = 'fr'

//TODO Change validate/serialize/deserialize prototype, maybe  (value, type, option) => ... (?)

/*
 **Options name:
 ** dataOptions
 ** fieldOptions
 */

const definitions = {
  /*****************************************************************************
   *                                  Base Types                               *
   *****************************************************************************/

  /******************************** Numbers ************************************/
  number: {
    toStr: types.number.deserialize,
    fake: types.number.fake,
    serialize: types.number.serialize,
    deserialize: types.number.deserialize,
    validate: types.number.validate,
  },
  integer: {
    inherit: "number",
    validate:
      () =>
      ({ value }) =>
        isFinite(value) && Number.isInteger(value) ? null : "requiredField",
  },
  unsignedInteger: {
    inherit: "integer",
    dataOptions: { min: "0" },
    validate:
      () =>
      ({ value }) =>
        isFinite(value) && Number.isInteger(value) && Math.sign(value) >= 0 ? null : "requiredField",
  },
  money: {
    inherit: "number",
    toStr: types.money.toStr,
    deserialize: types.money.deserialize,
    serialize: types.money.serialize,
  },
  entity: {
    // toStr: types.entity.toStr,
    deserialize: types.entity.deserialize,
    serialize: types.entity.serialize,
    validate: types.entity.validate,
    dataOptions: { asyncData: "entity" },
  },
  enumeration: {
    inherit: "selector",
    toStr: types.enumeration.toStr,
    serialize: types.enumeration.serialize,
    deserialize: types.enumeration.deserialize,
    validate: types.enumeration.validate,
    fake: types.enumeration.fake,
  },
  /********************************* Text *************************************/
  text: {
    toStr: types.text.toStr,
    serialize: types.text.serialize,
    deserialize: types.text.deserialize,
    validate: types.text.validate,
    fake: types.text.fake,
  },
  textarea: {
    inherit: "text",
    fake: faker.lorem.sentences,
  },
  email: {
    inherit: "text",
    fake: faker.internet.exampleEmail,
    validate: () => (value) => {
      if (!value || !value.value || !isString(value.value) || !value.value.length) {
        return "requiredField";
      }
      if (!isEmail(value.value.trim())) {
        return "incorrectEmail";
      }
      return null;
    },
  },
  phoneNumber: {
    inherit: "text",
    serialize:
      ({ type }) =>
      (value) => ({
        value: ((value && String(value)) || "").replace(/[^\d+]/g, ""),
        type,
      }),
    fake: faker.phone.phoneNumber,
    fieldOptions: { defaultValue: "+33" },
    validate: () => (value) => {
      const parse = value && isString(value.value) && parsePhoneNumberFromString(value.value, "FR");
      return parse && parse.isValid() ? undefined : "requiredField";
    },
  },
  address: {
    inherit: "text",
    fake: () => `${Math.floor(Math.random() * 255)}, ${faker.address.streetPrefix()} ${faker.address.streetName()}`,
  },
  zipCode: {
    inherit: "text",
    fake: () => `${faker.address.zipCode().slice(0, -2)}00`,
  },
  uuid: {
    serialize: types.uuid.serialize,
    deserialize: types.uuid.deserialize,
    toStr: types.uuid.deserialize,
    validate: types.uuid.validate,
    fake: types.uuid.fake,
    dataOptions: {
      visible: false,
    },
  },
  siret: {
    inherit: "text",
    dataOptions: { asyncData: "siret" },
    deserialize: (value) =>
      value && value.value
        ? String(value.value)
            .replace(/[^\dA-Z]/g, "")
            .replace(/\d{3,4}?(?=...)/g, "$& ")
        : "",
    fake: () => faker.helpers.replaceSymbolWithNumber("##############").replace(/\d{3,4}?(?=...)/g, "$& "),
    toStr: ({ value = 0 } = {}) =>
      String(value)
        .replace(/\d{3,4}?(?=...)/g, "$& ")
        .trim(),
  },
  siren: {
    inherit: "text",
    fake: () => faker.helpers.replaceSymbols("#########").replace(/(\d{3})/g, "$1 "),
  },
  iban: {
    inherit: "text",
    fake: faker.finance.iban,
  },
  bic: {
    inherit: "text",
    fake: faker.finance.bic,
  },
  bodacc: {
    inherit: "siret",
    dataOptions: {
      asyncData: "siret",
    },
  },
  /******************************* Controls ***********************************/
  checkbox: {
    fieldOptions: { type: "checkbox" },
    toStr: types.boolean.deserialize,
    serialize: types.boolean.serialize,
    deserialize: types.boolean.deserialize,
    validate: types.boolean.validate,
    fake: types.boolean.fake,
  },
  selector: {
    toStr: types.select.toStr,
    serialize: types.select.serialize,
    deserialize: types.select.deserialize,
    validate: types.select.validate,
    fake: types.select.fake,
  },
  "multiple-selector": {
    toStr: types.multipleSelect.deserialize,
    serialize: types.multipleSelect.serialize,
    deserialize: types.multipleSelect.deserialize,
    validate: types.multipleSelect.validate,
    fake: types.multipleSelect.fake,
    fieldOptions: { defaultValue: [] },
  },
  radio: {
    inherit: "selector",
    validate: () => (value) =>
      value && String(value.value !== null && value.value !== undefined ? value : "").trim() ? null : "requiredField",
  },
  dateTimePicker: {
    toStr: types.datetime.toStr,
    fake: types.datetime.fake,
    serialize: types.datetime.serialize,
    deserialize: types.datetime.deserialize,
    validate: types.datetime.validate,
  },
  datePicker: {
    inherit: "dateTimePicker",
    toStr: ({ value }, { locale, format }) =>
      value
        ? dayjs(value)
            .locale(locale || "en")
            .format(format || "LL")
        : "",
  },
  timePicker: {
    inherit: "dateTimePicker",
    toStr: ({ value }, { locale, format }) =>
      value
        ? dayjs(value)
            .locale(locale || "en")
            .format(format || "LT")
        : "",
  },
  /******************************** AsyncData ************************************/
  selectorAsyncData: {
    serialize: types.selectAsyncData.serialize,
    deserialize: types.selectAsyncData.deserialize,
    toStr: types.selectAsyncData.toStr,
    // fake: types.select.fake, //TODO
    validate: types.selectAsyncData.validate,
  },
  registrar: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "registrar" },
  },
  country: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "countries" },
  },
  city: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "cities" },
  },
  nationality: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "nationality" },
    fieldOptions: { defaultValue: "FR" },
  },
  legalFormFr: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "legalFormFr" },
  },
  companyLegalFormFr: {
    inherit: "selectorAsyncData",
    dataOptions: { asyncData: "legalFormFr" },
  },
  /******************************** Files *************************************/
  file: {
    serialize: types.file.serialize,
    deserialize: types.file.deserialize,
    toStr: types.file.deserialize,
    dataOptions: { asyncData: "upload" },
    validate: types.text.validate,
  },
  idCheck: {
    serialize: types.idCheck.serialize,
    deserialize: types.idCheck.deserialize,
    toStr: types.idCheck.deserialize,
    dataOptions: { asyncData: "idCheck" },
    validate: types.idCheck.validate,
    fieldOptions: { defaultValue: { type: "passport" } },
  },
  url: {
    inherit: "text",
  },
  /*****************************************************************************
   *                                   Person                                  *
   *****************************************************************************/

  firstName: {
    inherit: "text",
    fake: faker.name.firstName,
  },
  lastName: {
    inherit: "text",
    fake: faker.name.lastName,
  },
  gender: {
    inherit: "radio",
    dataOptions: {
      options: [
        {
          label: "Male",
          value: "male",
          label_fr: "Homme",
        },
        {
          label: "Female",
          value: "female",
          label_fr: "Femme",
        },
      ],
    },
  },
  birthDate: {
    inherit: "datePicker",
  },

  /*****************************************************************************
   *                                   Company                                 *
   *****************************************************************************/

  companyName: {
    inherit: "text",
    fake: faker.company.companyName,
  },
  companyMemberRoles: {
    inherit: "selectorAsyncData",
    dataOptions: {
      asyncData: "companyMemberRoles",
    },
  },
  companyCapital: {
    inherit: "money",
    validate:
      () =>
      ({ value }) =>
        isFinite(value) && Math.sign(value) >= 0 ? null : "requiredField",
  },
  companyRegistrationNumberFr: {
    inherit: "siret",
  },
  companyRegistrationNumberFrSiren: {
    inherit: "siren",
  },
  companyRegistrationNumberFrApeCode: {
    inherit: "text",
    fake: () => randomString(4, "0123456789") + randomString(1, "ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
  },
  companyActivity1: {
    inherit: "textarea",
  },
  companyActivity2: {
    inherit: "textarea",
  },
  companyShareAmount: {
    inherit: "unsignedInteger",
  },
  companyBodacc: {
    inherit: "bodacc",
  },

  /*****************************************************************************
   *                                   Docusign                                *
   *****************************************************************************/
  docusignEnvelopeId: {
    inherit: "text",
    serialize: types.selectAsyncData.serialize,
    toStr: ({ signedAt }, { locale, format }) =>
      signedAt
        ? dayjs(signedAt)
            .locale(locale || "en")
            .format(format || "LL")
        : "",
    dataOptions: { asyncData: "signature" },
  },
  docusignSignedAt: {
    inherit: "datePicker",
  },
};

export default definitions;
