import { REGEX_PROPS_FILE_NAME, REGEX_PROPS_FILE_NAME_WITH_LANG } from "../constants/regex";
import { isMultilangObject } from "./document";

export const isfileNameProp = (prop) => new RegExp(REGEX_PROPS_FILE_NAME).test(prop);
export const isfileNamePropWithLang = (prop) => new RegExp(REGEX_PROPS_FILE_NAME_WITH_LANG).test(prop);

export const getMultilangFileName = (metadata) => {
  let multilangProps = {} as any;

  Object.keys(metadata)
    .filter((key) => isfileNameProp(key))
    .forEach((key) => {
      if (isfileNamePropWithLang(key)) {
        const splitFileNameLang = key.split("_");
        if (splitFileNameLang[1]) {
          multilangProps[splitFileNameLang[1].toUpperCase()] = metadata[key];
        } else {
          if (isMultilangObject(metadata[key])) {
            multilangProps = Object.assign({}, multilangProps, metadata[key]);
          }
        }
      }
    });

  //If no precision (_LANG) then EN = filename field
  if (!multilangProps.EN && metadata.filename) {
    multilangProps.EN = metadata.filename;
  }

  return multilangProps;
};
