import gql from 'graphql-tag'

export const checkIfUserEmailExistQuery = gql`
  query checkIfUserEmailExist($email: String!) {
    checkIfUserEmailExist(email: $email)
  }
`

export const getCurrentApplicationQuery = gql`
  query GetCurrentApplication {
    getCurrentApplication {
      id
      public
      name
      metadata
      logo {
        id
        url
      }
    }
  }
`    

export const getUserSlugsQuery = gql`
query GetUserApplications($email: String!, $password: String!) {
  getUserApplications(email: $email, password: $password) {
    slug
    logo
  }
}
`