import React from 'react'
import { useLocation } from 'react-router-dom'

interface RouteQuery {
  [key: string]: string
}

export const useRouteQuery = (): RouteQuery => {
  const { search } = useLocation()

  return React.useMemo(
    () => {
      const searchParams = new URLSearchParams(search)
      const queryObject: RouteQuery = {}
      searchParams.forEach((value, key) => {
        queryObject[key] = value
      })
      return queryObject
    },
    [search]
  )
}