import React, { useMemo } from "react";
import NextArrow from '../../assets/svg/nextArrow.svg';
import { useNavigate } from "react-router";
import { messageSuccess } from "../Message";
import { useTranslation } from "react-i18next";
import Clipboard from "../Icon/Clipboard";
import SVGIcon from "../SVGIcon/SVGIcon";

type ServiceLayoutPropType = {
    location: string[];
    currentLocation: string;
    link: string;
    children: React.ReactNode;
    nextArrow?: boolean;
    bcc?: string;
}

const ServiceLayout: React.FC<ServiceLayoutPropType> = ({
    location,
    link,
    children,
    currentLocation,
    nextArrow = true,
    bcc
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const processInstanceBccShort = useMemo(() => {
        const [email, domain] = (bcc || '').split('@') || []
        const shortEmail = email.slice(0, -20)
        return `${shortEmail}...@${domain}`
      }, [bcc])

    return (
        <div className="service-layout-wrap">
            {nextArrow && 
                <div className="service-layout-next-arrow">
                    <SVGIcon src={NextArrow} />
                </div>
            }
            <div className="service-layout-location" onClick={() => navigate(link)}>
                {location.join(' > ')} &gt; <span>{currentLocation}</span>
            </div>
            {bcc && 
                <div 
                    className="service-layout-bcc-container"
                    onClick={() => {
                        navigator.clipboard.writeText(bcc)
                        messageSuccess(t('applicationForm.emailCc.clipboard'))
                    }}
                >
                    {processInstanceBccShort}
                    <Clipboard style={{ cursor: 'pointer', transform: 'scale(1)' }} />
                </div>
            }
            {children}
        </div>
    );
};

export default ServiceLayout;