import React from 'react';
import { FormFieldMultipleContent } from '../../FormFieldMultipleContent';
import { TABS } from '../../../../types';

interface FormFieldMultipleProps  {
  description?: string;
  tabs: TABS;
}

export const FormFieldMultiple: React.FC<FormFieldMultipleProps> = React.memo(({ description, ...props }) => (
  <div className="form-runner-form-field-multiple">
    <FormFieldMultipleContent {...props} />
  </div>
));