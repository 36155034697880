import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { getProcessInstancesQuery } from './graphql/queries'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { PROCESS_INSTANCE_LIST_TITLE, PROCESS_INSTANCE_LIST_TYPE } from '../../constants/processInstance'
import { useQuery } from '@apollo/client'
import { FilterPills } from '../../components/FilterPills'
import { useTranslation } from 'react-i18next'
import { PROCESS_STEP } from '../../constants/workflow'
import { Pagination } from 'antd'
import DossierCard from '../../components/DossierCard'
import ServiceLayout from '../../components/ServiceLayout'
import NextArrow from '../../assets/svg/nextArrow.svg'
import ContainerWrapper from '../../components/ContainerWrapper'
import { ErrorResult } from '../../components/ErrorResult'
import Loader from '../../components/Loader'
import SVGIcon from '../../components/SVGIcon/SVGIcon'

type AttachPagePropType = {
}
export const AttachPage: React.FC<AttachPagePropType> = () => {

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const current = Number(searchParams.get('page')) || 1;
  const selectedCategory = searchParams.get('category') || PROCESS_INSTANCE_LIST_TYPE.IN_PROGRESS;

  const [polling, setPolling] = useState(false)

  const processInstanceQueryVariables = useMemo(
    () => ({
      dossiersListType: selectedCategory || 'all',
      language,
      offset: (current - 1) * 50,
      // search,
      // sortBy,
      // sortDirection,
    }),
    // [page, sortBy, sortDirection, selectedCategory, search, language]
    [selectedCategory, current, language]
  )

  const {
    data: { getProcessInstances: { edges: processInstancesData = [], totalCount = 0, count = {} } = {} } = {},
    error,
    loading,
    stopPolling,



    startPolling,
  } = useQuery(getProcessInstancesQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: processInstanceQueryVariables,
  })

  const categories = useMemo(
    () =>
      ['IN_PROGRESS', 'DONE', 'ARCHIVE', 'DELETED'].reduce((acc, type) => {
        return {
          ...acc,
          [PROCESS_INSTANCE_LIST_TYPE[type]]: {
            data: {
              categoryId: PROCESS_INSTANCE_LIST_TYPE[type],
              count: count[type],
              title: t(PROCESS_INSTANCE_LIST_TITLE[type]),
            },
          },
        }
      }, {}),
    [t, count]
  )

  const processInstances = useMemo(
    () =>
      processInstancesData.map(({ node: processInstance }) => ({
        ...processInstance,
        step: [PROCESS_STEP.NOT_STARTED, PROCESS_STEP.PENDING].includes(processInstance.status)
          ? processInstance.workflow.find(step =>
              [PROCESS_STEP.PENDING, PROCESS_STEP.NOT_STARTED].includes(step.status)
            ) || {}
          : {},
      })),
    [processInstancesData]
  )

  const updateURLCategoryQuery = useCallback((str) => {
    console.log("new Category:", str)
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set('category', str);
  newSearchParams.set('page', '1');
  setSearchParams(newSearchParams);
}, [setSearchParams, searchParams]);

const updateURLPageQuery = useCallback((newPage) => {
  console.log("newPage:", newPage)
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set('page', String(newPage));
  setSearchParams(newSearchParams);
}, [setSearchParams, searchParams]);

  useEffect(() => {
    setPolling(processInstances.some(({ status }) => ![PROCESS_STEP.DONE, PROCESS_STEP.ERROR].includes(status)))
  }, [processInstances])

  useEffect(() => {
    if (polling) {
      // startPolling(3e3)
    }

    return () => {
      stopPolling()
    }
  }, [polling, processInstanceQueryVariables, startPolling, stopPolling])

  if (loading) {
    return (
      <ContainerWrapper>
        <Loader />
      </ContainerWrapper>
    )
  }

  if (error) {
    return (
      <ContainerWrapper>
        <ErrorResult error={error} />
      </ContainerWrapper>
    )
  }

    return (
      <ServiceLayout location={[t('menu')]} currentLocation={t('dossier')} link={"/"} nextArrow={false}>
        <div className="attach-page">
          <div className='attach-page-header'>
            <div>
              {t('selectYourDossier')}
            </div>
            <SVGIcon src={NextArrow} />
            {/* <img src={NextArrow} alt="next arrow" /> */}
          </div>
          <FilterPills
            allPillText={t(PROCESS_INSTANCE_LIST_TITLE.ALL)}
            currentPill={selectedCategory}
            setCurrentPill={updateURLCategoryQuery}
            pills={categories}
            allPillAtEnd
          />
          <div className="dossier-cards">
            {
            (processInstances || []).map((processInstance) =>(
              <>
                <DossierCard 
                  processInstance={processInstance}
                />
              </>
            ))
          }
          </div>
          <Pagination current={current} pageSize = {50} onChange={updateURLPageQuery} total={totalCount}/>
        </div>
      </ServiceLayout>
    )
}


