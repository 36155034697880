import writtenNumber from 'written-number';
import moment from 'moment';
import momentTz from 'moment-timezone';

const isLocale = (locale: string, throwError = false): boolean => {
  try {
    (Intl as any).getCanonicalLocales(locale);
    return true;
  } catch (e) {
    if (throwError) {
      throw new Error(`Not a locale: ${locale}`);
    }
    return false;
  }
};

export const localeToExtendedLocale = (locale: string): string => {
  if (!isLocale(locale)) {
    return 'FR-fr'
  }

  switch (locale.toLowerCase()) {
    case 'fr':
      return 'fr-FR'
    case 'en':
      return 'en-US'
    default:
      return locale
  }
}

export const extendedLocaleToLang = (locale: string): string => {
  if (!isLocale(locale)) {
    return 'FR'
  }

  const intlInstance = new Intl.Locale(locale)

  return intlInstance.language.toUpperCase()
}

interface FormatDateOptions {
  locale?: string;
  horodated?: boolean;
  dispMonthName?: boolean;
}

export const formatDate = (value = Date.now(), { locale = 'fr', horodated = false, dispMonthName = true }: FormatDateOptions = {}): string => {
  const internationalNumericDateFormat = 'DD/MM/YYYY'
  let format =
    locale === 'fr'
      ? dispMonthName
        ? 'DD MMMM YYYY'
        : internationalNumericDateFormat
      : dispMonthName
      ? 'MMMM Do, YYYY'
      : internationalNumericDateFormat
  const at = locale === 'fr' ? ' à' : ' at'
  horodated ? (format = format.concat(`[${at}] HH:mm`)) : {}
  return moment(value).locale(locale).format(format)
}

export const formatDateWithoutYear = (value = Date.now(), { locale = 'fr', horodated = false }: FormatDateOptions = {}): string => {
  let format = locale === 'fr' ? 'DD MMMM' : 'MMMM Do'
  const at = locale === 'en' ? ' at' : ' à'
  horodated ? (format = format.concat(`[${at}] HH:mm`)) : {}
  return moment(value).locale(locale).format(format)
}

interface FormatNumberOptions {
  locale?: string;
  unit?: string;
  subUnit?: string;
}

export const formatNumber = (value: number, { locale = 'fr', unit = '', subUnit = 'centième' }: FormatNumberOptions = {}): string => {
  const format = (value: number): string => {
    const factor = Math.pow(10, 2);
    return (Math.round(value * factor) / factor).toFixed(2);
  };

  const getDecimalValue = (value: number): number => Number(format(value).slice(-2))

  const integer = Math.trunc(value)
  const decimal = getDecimalValue(value)

  return `${writtenNumber(integer, { lang: locale })} ${unit}${
    Boolean(unit) && integer !== 1 && integer !== 0 ? 's' : ''
  }${decimal ? ' et ' : ''}${
    decimal ? `${writtenNumber(decimal, { lang: locale })} ${subUnit}${decimal !== 1 && decimal !== 0 ? 's' : ''}` : ''
  }`
}

interface WriteMoneyOptions {
  currency?: string;
  sub?: string;
  locale?: string;
}

export const writeMoney = (value: number, { currency = 'euro', sub = 'centime', locale = 'fr' }: WriteMoneyOptions = {}): string =>
  formatNumber(value, { locale, unit: currency, subUnit: sub })

interface FormatTimeOptions {
  format?: string;
  locale?: string;
}

export const formatTime = (value: number = Date.now(), { format = 'HH:mm', locale = 'en' }: FormatTimeOptions = {}): string => {
  const timeZones = momentTz.tz.zonesForCountry(locale.toUpperCase())
  let timeZone = 'Europe/Paris'
  if (timeZones && timeZones.length > 0) {
    timeZone = timeZones[0]
  }
  return momentTz.tz(new Date(value).toISOString(), 'YYYY-MM-DD HH:mm:ssZZ', timeZone).format(format)
}

interface FormatMoneyOptions {
  currency?: string;
  minDigits?: number;
  maxDigits?: number;
  locale?: string;
}

export const formatMoney = (value: number, { currency = 'EUR', minDigits = 0, maxDigits = 2, locale = 'fr' }: FormatMoneyOptions = {}): string => {
  return isNaN(value)
    ? value.toString()
    : new Intl.NumberFormat(localeToExtendedLocale(locale), {
        currency,
        maximumFractionDigits: maxDigits,
        minimumFractionDigits: minDigits,
        style: 'currency',
      }).format(value)
}

interface NthOptions {
  locale?: string;
}

export const nth = (n: number, { locale = 'en' }: NthOptions = {}): string => {
  switch (locale.toLowerCase()) {
    case 'fr':
      return n === 1 ? '1er' : `${n}ème`
    case 'en':
    default:
      return n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th')
  }
}