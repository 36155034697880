import React from "react"

export interface ITooltip {
  content: string | React.ReactNode
  children?: JSX.Element
  disabled?: boolean
  isOpen: boolean
}
export const TooltipAuthorization = ({ content, disabled, children, isOpen }: ITooltip) => {
  return (
    <div className="tooltipAuthContainer">
      <div className={`tooltipAuth${isOpen ? ' visible' : ''} ${disabled ? ' disabled' : ''}`}>
        {typeof content === 'string' ? <div dangerouslySetInnerHTML={{ __html: content }}></div> : content}
      </div>
      {children}
    </div>
  )
}

export default TooltipAuthorization
