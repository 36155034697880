import React from 'react'
import { ChevronRight } from 'react-feather'

const ChevronRightIcon = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <ChevronRight />
    </div>
  )
}

export default ChevronRightIcon
