import React, { FC } from 'react'
import { InputNumber } from 'antd'

interface NumberViewProps {
  id: string
  type?: string
  processInstance?: any
  stepId?: any
  [key: string]: any
}

export const NumberView: FC<NumberViewProps> = ({ id, type, processInstance, stepId, ...props }) => (
  <InputNumber id={id} type={type} {...props} keyboard={false} />
)