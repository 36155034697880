import React, { SVGProps, CSSProperties } from 'react'
import { ArrowRight } from 'react-feather'

interface SVGRProps {
  title?: string
  titleId?: string
  style?: CSSProperties
}

const RightArrowIcon = ({ style }: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <ArrowRight style={style} />
}

export default RightArrowIcon
