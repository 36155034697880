import React, { useMemo, useCallback } from 'react';
import { Select } from 'antd';
import { match } from '../../helpers/text';
import isString from 'lodash/isString';

interface Option {
  value?: string | number;
  label: string;
  category?: string;
  children?: string;
  stringLabel?: string;
}

interface SelectViewProps {
  id: string;
  multipleOption: boolean;
  options?: Option[];
  required?: boolean;
  validateStatus?: string;
  stepId?: string;
  processInstance?: any;
}

const handleOptionValue = (option: Option) => (option.value ? option : { label: option.label, value: option.label });

const handleOptionSort = (
  { stringLabel: prevStringLabel, label: prevLabel, children: prevChildren }: Option,
  { stringLabel: curStringLabel, label: currLabel, children: currChildren }: Option
): number =>
  isString(prevStringLabel)
    ? prevStringLabel.localeCompare(curStringLabel)
    : isString(prevLabel)
    ? prevLabel.localeCompare(currLabel)
    : isString(prevChildren) && prevChildren.localeCompare(currChildren);

export const SelectView: React.FC<SelectViewProps> = ({
  id,
  multipleOption,
  options: optionsProps = [],
  required,
  validateStatus,
  stepId,
  processInstance,
  ...props
}) => {
  const options = useMemo(() => {
    if (optionsProps.some(({ category }) => Boolean(category))) {
      const optionsByCategories = optionsProps.reduce(
        (acc, { category, ...option }) => ({
          ...acc,
          [category || 'others']: [...(acc[category || 'others'] || []), handleOptionValue(option)],
        }),
        {}
      );

      Object.keys(optionsByCategories).forEach(category => optionsByCategories[category].sort(handleOptionSort));

      return {
        children: Object.keys(optionsByCategories)
          .map(category => (
            <Select.OptGroup key={category} label={category === 'others' ? 'Others' : 'Usual forms of companies'}>
              {optionsByCategories[category].map(({ value, label }) => (
                <Select.Option key={value.toString()} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))
          .sort(({ key, props: { label: cur = '' } = {} }, { props: { label: next = '' } = {} }) =>
            key === 'others' ? 'zzzzzzzzzz'.localeCompare(cur) : next.localeCompare(cur)
          ),
      };
    }
    return { options: optionsProps.map(handleOptionValue).sort(handleOptionSort) };
  }, [optionsProps]);

  const filterOption = useCallback(
    (input: string, option: any) =>
      match(
        input,
        String(
          (option &&
            option.children &&
            option.children.props &&
            option.children.props.children &&
            option.children.props.children[1]) ||
            option.stringLabel ||
            option.label ||
            option.children
        )
      ),
    []
  );

  return <Select id={id} filterOption={filterOption} showSearch mode={multipleOption ? 'multiple' : undefined} {...props} {...options} />;
};