import pick from "lodash/pick";
import definitions from "./definitions";

//TODO Find alternative to eval or Protect eval call
//TODO Add memo function (from lodash ?)

interface Model {
  primaryType?: string;
  type: string;
  fake?: any;
  [key: string]: any;
}

const createDef = (def: Record<string, any> = {}) =>
  Object.keys(def).reduce((acc, field) => ({ ...acc, [field]: eval(def[field]) }), {});

export const getModel = ({ primaryType, type, ...props }: Model, loop = 0): any => {
  const formDef = pick(props, ["fake"]);
  const definition = definitions[primaryType || type];

  if (!definition) {
    return undefined;
  }

  const { inherit: inheritFrom, ...addProps } = definition;

  if (type === inheritFrom) {
    throw new Error(`Cannot inherit from same type: ${type}`);
  }

  if (loop > 3) {
    throw new Error(`Reach maximum number of inheritance`);
  }

  const inheritedType = (inheritFrom && getModel({ type: inheritFrom }, loop + 1)) || {};
  return { ...inheritedType, ...addProps, ...createDef(formDef) };
};

export const getType = ({ primaryType, type }: Model): string => {
  function getTypeRecurs(type: string): string {
    const { inherit } = definitions[type] || {};
    if (inherit) {
      return getTypeRecurs(inherit);
    } else {
      return type;
    }
  }

  return getTypeRecurs(primaryType || type);
};

interface SerializerOptions {
  throwError?: boolean;
}

export const getSerializer = (type: string, additional: any, { throwError = true }: SerializerOptions = {}): any => {
  const typeObject = { ...additional, type };
  const { serialize, dataOptions } = getModel(typeObject) || {};

  if (!serialize) {
    if (throwError) {
      throw new Error(`Can't find serialize for type : ${type}`);
    }
    return null;
  }

  return serialize({ ...dataOptions, ...typeObject });
};
