import { FC } from 'react'

import TagPrice from '../Icon/TagPrice'
import React from 'react'

export type WorkflowIconProps = {
  defaultIcon: React.ReactNode
  remoteIconUrl?: string
  filter: string
  color: string
  tagged: string
}

export const WorkflowIcon: FC<WorkflowIconProps> = ({ defaultIcon, color, filter, remoteIconUrl, tagged }) => {
  const TagProduct = () => (
    <div className="product-tag-draft">
      <TagPrice
        styleText={{
          color: 'white',
          fontSize: '60%',
          position: 'relative',
          right: 98,
          textAlign: 'center',
          top: 18,
          width: 100,
        }}
        title={tagged}
      />
    </div>
  )
  return (
    <div className="product-card-header" style={{ color }}>
      {remoteIconUrl ? (
        <>
          <img style={{ filter, width: '26px' }} className="card-header-icon" src={remoteIconUrl} />
          {/* {tagged && <TagProduct />} */}
        </>
      ) : (
        <>
          {defaultIcon}
          {/* {tagged && <TagProduct />} */}
        </>
      )}
    </div>
  )
}

export default WorkflowIcon
