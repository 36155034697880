import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="4 4 28 28" {...props}>
    <g>
      <path
        d="M14.272,21.204l-1.103,1.307c-0.657-0.352-1.401-0.492-2.153-0.376c-0.906,0.144-1.703,0.632-2.242,1.375
		c-1.113,1.532-0.772,3.686,0.76,4.801c0.594,0.432,1.293,0.657,2.013,0.657c0.181,0,0.363-0.015,0.546-0.043
		c0.906-0.144,1.703-0.632,2.242-1.375c0.54-0.743,0.759-1.651,0.615-2.558c-0.115-0.728-0.461-1.377-0.974-1.888l1.06-1.256
		L14.272,21.204z M13.526,26.962c-0.383,0.526-0.947,0.873-1.591,0.975c-0.639,0.103-1.286-0.052-1.813-0.435
		c-1.087-0.791-1.328-2.317-0.539-3.404c0.383-0.526,0.947-0.873,1.591-0.975c0.129-0.021,0.258-0.031,0.387-0.031
		c0.51,0,1.006,0.16,1.427,0.466c0.526,0.383,0.873,0.948,0.975,1.591S13.909,26.435,13.526,26.962z"
      />
      <path
        d="M16.843,11.101v2.148h1v-2.148c1.657-0.245,2.937-1.663,2.937-3.386c0-1.896-1.542-3.438-3.437-3.438
		c-1.896,0-3.438,1.542-3.438,3.438C13.906,9.438,15.186,10.856,16.843,11.101z M17.343,5.277c1.344,0,2.437,1.094,2.437,2.438
		s-1.093,2.437-2.437,2.437s-2.438-1.093-2.438-2.437S16,5.277,17.343,5.277z"
      />
      <path
        d="M20.78,17.583c0-1.895-1.542-3.437-3.437-3.437c-1.896,0-3.438,1.542-3.438,3.437c0,1.896,1.542,3.438,3.438,3.438
		C19.238,21.02,20.78,19.478,20.78,17.583z M17.343,20.02c-1.344,0-2.438-1.094-2.438-2.438s1.094-2.437,2.438-2.437
		s2.437,1.093,2.437,2.437S18.687,20.02,17.343,20.02z"
      />
      <path
        d="M11.025,16.086l1.763,0.781l0.404-0.914l-1.85-0.819c0.305-1.665-0.649-3.345-2.304-3.884
		c-1.807-0.587-3.744,0.404-4.331,2.207c-0.585,1.803,0.404,3.745,2.207,4.331c0.35,0.113,0.709,0.17,1.066,0.17
		c0.534,0,1.065-0.126,1.557-0.376C10.189,17.25,10.698,16.725,11.025,16.086z M7.223,16.837c-1.277-0.415-1.979-1.793-1.564-3.071
		c0.335-1.028,1.293-1.684,2.32-1.684c0.249,0,0.502,0.038,0.751,0.119c0.619,0.201,1.123,0.632,1.419,1.212
		c0.295,0.58,0.347,1.24,0.146,1.859s-0.631,1.123-1.211,1.419C8.503,16.987,7.841,17.039,7.223,16.837z"
      />
      <path
        d="M23.67,22.135c-0.753-0.116-1.496,0.024-2.153,0.376l-1.102-1.307l-0.764,0.645l1.059,1.256
		c-0.513,0.51-0.859,1.16-0.974,1.888c-0.144,0.906,0.075,1.814,0.615,2.558c0.539,0.743,1.336,1.231,2.242,1.375
		c0.183,0.028,0.365,0.043,0.546,0.043c0.72,0,1.419-0.226,2.013-0.657c0.743-0.54,1.231-1.337,1.375-2.243
		c0.144-0.907-0.075-1.815-0.615-2.558C25.373,22.766,24.576,22.278,23.67,22.135z M25.539,25.911
		c-0.102,0.643-0.448,1.208-0.975,1.591c-0.527,0.383-1.175,0.537-1.813,0.435c-0.644-0.102-1.208-0.448-1.591-0.975
		s-0.537-1.171-0.436-1.813s0.448-1.208,0.975-1.591c0.421-0.306,0.917-0.466,1.427-0.466c0.129,0,0.258,0.011,0.387,0.031
		c0.644,0.102,1.208,0.448,1.591,0.975S25.64,25.267,25.539,25.911z"
      />
      <path
        d="M29.978,13.457c-0.587-1.803-2.532-2.794-4.331-2.207c-1.653,0.538-2.607,2.218-2.304,3.884l-1.85,0.819l0.404,0.914
		l1.763-0.781c0.327,0.639,0.836,1.163,1.488,1.496c0.491,0.25,1.021,0.376,1.557,0.376c0.357,0,0.717-0.057,1.066-0.17
		c0.873-0.283,1.584-0.891,2.001-1.708C30.188,15.262,30.261,14.33,29.978,13.457z M28.88,15.626
		c-0.295,0.58-0.799,1.01-1.418,1.211c-0.619,0.202-1.279,0.15-1.86-0.146c-0.58-0.296-1.01-0.8-1.211-1.419
		c-0.415-1.278,0.287-2.655,1.564-3.071c0.249-0.081,0.502-0.119,0.751-0.119c1.027,0,1.985,0.655,2.32,1.684
		C29.228,14.385,29.176,15.046,28.88,15.626z"
      />
    </g>
  </svg>
)

export default SVG
