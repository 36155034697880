import gql from 'graphql-tag'

const getProcessInstanceQuery = gql`
  query ProcessInstancePageQuery($id: ID!) {
    getProcessInstance(id: $id) {
      data
      bcc
      dataResolved
      documents {
        id
        user {
          id
          firstname
          lastname
          email
          id
          type
          facebookId
        }
        languages
        filename
        filetype
        templateId
        url
        createdAt
        updatedAt
        flags
        tags
        isLatest
        isDraft
        isHtmlLinked
        documentVersion
        currentVersion
        versions {
          version
          createdAt
          isEdited
        }
        status
        size
      }
      id
      label
      name
      status
      user {
        id
      }
      workflow {
        config
        configResolved
        id
        name
        dependsOnPreviousSteps
        description
        status
        type
        toRevalidate
        restrictedAccess
        access {
          read
          write
        }
      }
      staticData
      timeSpent
      userTimer {
        id
        createdAt
        note
      }
    }
  }
`

const getEnumerationByNamesQuery = gql`
  query getEnumerationByNames($names: [String]!) {
    getEnumerationByNames(names: $names) {
      name
      defaultValue
      options
      createdAt
      updatedAt
    }
  }
`

const getInseeMunicipalitiesByPostalCodeQuery = gql`
  query getInseeMunicipalitiesByPostalCode($postalCode: String) {
    getInseeMunicipalitiesByPostalCode(postalCode: $postalCode) {
      id
      inseeCode
      postalCode
      name
      label
    }
  }
`
const getCountriesEuQuery = gql`
  query getCountriesEu {
    getCountriesEu {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`

const getCountriesQuery = gql`
  query getCountries {
    getCountries {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`
const getRegistrarQuery = gql`
  query GetRegistrar($codeCommune: String) {
    getRegistrar(codeCommune: $codeCommune) {
      id
      registrarCode
      name
    }
    getRegistrars {
      id
      registrarCode
      name
    }
  }
`

const getCompanyMemberRolesQuery = gql`
  query getCompanyMemberRoles($companyType: Int) {
    getCompanyMemberRoles(companyType: $companyType)
  }
`

const getInseeLegalFormsFrQuery = gql`
  query getInseeLegalFormsFr {
    getInseeLegalFormsFr {
      id
      label
      category
    }
  }
`

const getEntityCategoriesQuery = gql`
  query GetEntityCategories {
    getEntityCategories {
      id
      type {
        id
        typeName
        typeNamePlural
        title
        icon
        position
        creationForm
      }
      categoryName
      entityNamePath
      title
      icon
      entityCount
      createdAt
      updatedAt
    }
  }
`

const getDocumentByIdQuery = gql`
  query getDocumentById($documentId: ID!) {
    getDocumentById(documentId: $documentId) {
      id
      url
      filename
    }
  }
`

const getEntitiesByCategoryNameQuery = gql`
  query GetEntitiesByCategoryName($categoryName: [String!]) {
    getEntitiesByCategoryName(categoryName: $categoryName, addPublic: true) {
      id
      name
      data
      category {
        id
        categoryName
      }
      type {
        id
        title
        typeName
        icon
      }
    }
  }
`

const getCompanyInfoBySiretQuery = gql`
  query getCompanyInfoBySiret($siret: String!) {
    getCompanyInfoBySiret(siret: $siret)
  }
`

const getCompanyBodaccEntriesQuery = gql`
  query getCompanyBodaccEntries($siret: String!) {
    getCompanyBodaccEntries(siret: $siret)
  }
`

export { 
  getProcessInstanceQuery, 
  getEnumerationByNamesQuery, 
  getInseeMunicipalitiesByPostalCodeQuery,
  getCountriesEuQuery,
  getCountriesQuery,
  getRegistrarQuery,
  getCompanyMemberRolesQuery,
  getInseeLegalFormsFrQuery,
  getEntityCategoriesQuery,
  getEntitiesByCategoryNameQuery,
  getDocumentByIdQuery,
  getCompanyInfoBySiretQuery,
  getCompanyBodaccEntriesQuery,
}