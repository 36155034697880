import gql from 'graphql-tag'

const getProcessInstanceQuery = gql`
  query ProcessInstancePageQuery($id: ID!) {
    getProcessInstance(id: $id) {
      data
      bcc
      dataResolved
      documents {
        id
        user {
          id
          firstname
          lastname
          email
          id
          type
          facebookId
        }
        languages
        filename
        filetype
        templateId
        url
        createdAt
        updatedAt
        flags
        tags
        isLatest
        isDraft
        isHtmlLinked
        documentVersion
        currentVersion
        versions {
          version
          createdAt
          isEdited
        }
        status
        size
      }
      id
      label
      name
      status
      user {
        id
      }
      workflow {
        config
        configResolved
        id
        name
        dependsOnPreviousSteps
        description
        status
        type
        toRevalidate
        restrictedAccess
        access {
          read
          write
        }
      }
      staticData
      timeSpent
      userTimer {
        id
        createdAt
        note
      }
    }
  }
`

const getEnumerationByNamesQuery = gql`
  query EnumerationByNamesQuery($names: [String]!) {
    getEnumerationByNames(names: $names) {
      name
      defaultValue
      options
      createdAt
      updatedAt
    }
  }
`

export { 
  getProcessInstanceQuery, 
  getEnumerationByNamesQuery,
}