import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useQuery } from "@apollo/client";

import { getProductsQuery, getProductCategoriesQuery } from "../../graphql/product";
import { useMixpanel } from "../../components/useMixpanel";
import ContainerWrapper from "../../components/ContainerWrapper";
import { ProductCards } from "./ProductCards";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
import { ErrorResult } from "../../components/ErrorResult";
import { USER_TYPES } from "../../constants/user";
import ServiceLayout from "../../components/ServiceLayout";
import NextArrow from "../../assets/svg/nextArrow.svg";
import SVGIcon from "../../components/SVGIcon/SVGIcon";
import { useTranslation } from "react-i18next";

type StorePagePropType = {
  application?: any;
  user: any;
};

export const StorePage: React.FC<StorePagePropType> = ({ application, user }) => {
  const { track } = useMixpanel();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: { getProductCategories: productCategories = [] } = {}, refetch: refetchCategories } = useQuery(
    getProductCategoriesQuery,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const {
    loading,
    error,
    data: { getProducts: products = [] } = {},
  } = useQuery(getProductsQuery, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => track("page", { view: "store" }), [track]);

  const onCategoryEdit = () => {};
  const [productsByCategory] = useMemo(
    () =>
      (products || [])
        .map((data) => ({ ...data, color: data.category?.color }))
        .sort(
          ({ title: aTitle = "", position: aPosition }, { title: bTitle = "", position: bPosition }) =>
            aPosition - bPosition || bTitle["EN"]?.localeCompare(aTitle["EN"])
        )
        .reduce(
          ([productsByCategory], { category, ...product }) => {
            const { id } = category || {};
            const existingCategory = productsByCategory.find((category) => category.id === id);
            if (existingCategory) {
              existingCategory.products.push(product);
            } else {
              productsByCategory.push({
                ...category,
                products: [product],
              });
            }
            return [productsByCategory];
          },
          [
            [
              ...productCategories.map((category) => {
                const { title = "" } = category || {};
                const titleProps = title;
                return {
                  ...category,
                  products: [],
                  titleProps,
                };
              }),
            ],
          ]
        ),
    [products, productCategories]
  );

  const productByCategoriesSorted = useMemo(
    () =>
      (productsByCategory || [])
        .filter(({ titleProps, products }) => !!titleProps && (user?.type !== USER_TYPES.customer || products.length))
        .sort((a, b) => {
          if (a.index !== b.index) {
            return a.index - b.index;
          }
          return a.titleProps["EN"]?.localeCompare(b.titleProps["EN"]);
        }),
    [productsByCategory, user]
  );

  const handleProductClick = useCallback(
    (product) => {
      const url = `/product/description/${product.id}`;
      navigate(url);
    },
    [navigate]
  );

  if (loading || submitting) {
    return (
      <ContainerWrapper>
        <Loader />
      </ContainerWrapper>
    );
  }

  if (error) {
    return (
      <ContainerWrapper>
        <ErrorResult error={error} />
      </ContainerWrapper>
    );
  }

  return (
    <ServiceLayout location={[t("menu")]} currentLocation={t("service")} link={"/"} nextArrow={false}>
      <div className="store-page">
        <div className="store-page-header">
          <div>{t("selectYourService")}</div>
          <SVGIcon src={NextArrow} />
        </div>
        <ProductCards
          productByCategoriesSorted={productByCategoriesSorted}
          application={application}
          onCategoryEdit={onCategoryEdit}
          onCategoryCreated={refetchCategories}
          onCategoryDeleted={refetchCategories}
          onProductClick={handleProductClick}
          user={user}
          setSubmitting={setSubmitting}
        />
      </div>
    </ServiceLayout>
  );
};
