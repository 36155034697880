import React from 'react'

const SVG = props => (
  <svg
    version="1.1"
    fill="currentColor"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <g>
      <g>
        <path
          d="M161.95,125.6c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.859,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			c1.86,1.861,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07S163.81,127.46,161.95,125.6z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M509.075,41.85L496.71,29.471l12.361-12.361c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
			L482.574,15.32L470.391,3.122c-1.875-1.876-4.417-2.932-7.069-2.933L294.068,0.095c-0.01,0-0.019,0-0.028,0
			c-13.365,0-25.93,5.204-35.381,14.655l-84.46,84.46c-3.905,3.905-3.905,10.237,0,14.143c3.906,3.905,10.236,3.905,14.143,0
			l84.46-84.46c5.674-5.674,13.215-8.797,21.238-8.797c0.006,0,0.012,0,0.017,0l165.111,0.092l9.266,9.276L431.33,66.568
			c-15.734-9.424-36.477-7.369-50.021,6.173c-7.737,7.738-11.998,18.025-11.998,28.967s4.261,21.229,11.998,28.967
			c7.986,7.986,18.477,11.979,28.968,11.979c10.49,0,20.98-3.993,28.967-11.979c13.522-13.523,15.591-34.225,6.216-49.951
			l37.109-37.109l9.43,9.441l-0.092,164.887c-0.005,8.016-3.129,15.553-8.798,21.222L239.086,483.186
			c-5.673,5.673-13.216,8.797-21.238,8.797c-8.022,0-15.565-3.124-21.238-8.797L28.798,315.373C23.124,309.7,20,302.157,20,294.135
			c0-8.022,3.124-15.565,8.798-21.238l107.794-107.794c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
			L14.655,258.754C5.205,268.204,0,280.77,0,294.135s5.205,25.931,14.655,35.381l167.812,167.813
			c9.755,9.754,22.567,14.632,35.381,14.632c12.814,0,25.626-4.878,35.381-14.632l244.022-244.023
			c9.443-9.443,14.647-21.999,14.655-35.354L512,48.922C512.002,46.27,510.949,43.726,509.075,41.85z M425.101,116.531
			c-3.96,3.96-9.225,6.141-14.824,6.141c-5.601,0-10.865-2.181-14.825-6.141c-8.175-8.174-8.175-21.475,0-29.649
			c4.088-4.088,9.455-6.131,14.825-6.131c2.022,0,4.043,0.291,5.997,0.871l-11.542,11.542c-3.905,3.905-3.905,10.237,0,14.143
			c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929l11.51-11.51C432.463,102.916,430.704,110.928,425.101,116.531z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M427.159,201.363L310.62,84.823c-15.438-15.438-40.559-15.436-55.995,0L70.204,269.245
			c-7.479,7.478-11.598,17.421-11.598,27.997s4.118,20.52,11.597,27.998l116.54,116.538c7.479,7.479,17.421,11.598,27.997,11.598
			s20.52-4.118,27.997-11.597l184.422-184.422c7.479-7.479,11.597-17.421,11.597-27.997S434.638,208.841,427.159,201.363z
			 M413.016,243.214L228.595,427.638c-3.7,3.7-8.621,5.738-13.854,5.738c-5.233,0-10.154-2.038-13.854-5.739L84.346,311.098
			c-3.701-3.701-5.739-8.621-5.739-13.855c-0.001-5.234,2.038-10.155,5.739-13.855L268.768,98.966c3.82-3.82,8.837-5.73,13.855-5.73
			c5.017,0,10.035,1.911,13.854,5.73l116.539,116.539c3.701,3.701,5.739,8.621,5.739,13.855
			C418.755,234.593,416.717,239.514,413.016,243.214z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M326.078,200.082c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0l-5.758,5.758
			c-15.375-9.497-35.103-8.222-47.574,4.247c-7.271,7.271-10.991,17.145-10.475,27.802c0.499,10.292,4.931,20.117,12.478,27.664
			c0.001,0.001,0.002,0.001,0.002,0.002c8.043,8.047,8.941,20.239,2.001,27.179c-6.943,6.941-19.137,6.042-27.183-2.002
			c-8.046-8.047-8.944-20.241-2.003-27.183c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
			c-12.47,12.47-13.744,32.198-4.247,47.574l-6.832,6.831c-3.905,3.905-3.905,10.237,0,14.143c1.958,1.955,4.517,2.931,7.076,2.931
			c2.56,0,5.118-0.977,7.071-2.929l6.836-6.836c6.718,4.149,14.264,6.251,21.676,6.251c9.549-0.001,18.872-3.474,25.893-10.494
			c14.736-14.737,13.84-39.612-1.995-55.457c-0.003-0.003-0.005-0.006-0.008-0.009c-4.026-4.026-6.386-9.171-6.644-14.49
			c-0.239-4.952,1.408-9.459,4.641-12.691c6.94-6.941,19.132-6.043,27.179,2c0.001,0.001,0.001,0.002,0.002,0.003
			c0.001,0.001,0.002,0.001,0.002,0.001c8.044,8.047,8.941,20.239,2,27.18c-3.905,3.905-3.905,10.237,0,14.143
			c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929c12.47-12.471,13.745-32.199,4.248-47.574L326.078,200.082z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M224.867,365.867l-51.361-51.362c-3.905-3.902-10.235-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l51.362,51.362
			c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929C228.772,376.105,228.772,369.773,224.867,365.867z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M146.109,287.11c-1.859-1.859-4.43-2.93-7.069-2.93c-2.63,0-5.2,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.08
			c0,2.63,1.069,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.069-2.93c1.87-1.87,2.94-4.44,2.94-7.07
			C149.049,291.55,147.979,288.98,146.109,287.11z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export default SVG
