export enum ColorSelection {
  color1 = "#AB3CDE",
  color2 = "#4359D8",
  color3 = "#FFBD39",
  color4 = "#3AC9A5",
}

// Generated with https://isotropic.co/tool/hex-color-to-css-filter/
export enum FilterSelection {
  color1 = "invert(79%) sepia(55%) saturate(542%) hue-rotate(349deg) brightness(101%) contrast(90%)",
  color2 = "invert(85%) sepia(27%) saturate(4839%) hue-rotate(325deg) brightness(98%) contrast(93%)",
  color3 = "invert(67%) sepia(30%) saturate(508%) hue-rotate(139deg) brightness(94%) contrast(90%)",
  color4 = "invert(76%) sepia(3%) saturate(4233%) hue-rotate(91deg) brightness(90%) contrast(113%)",
}

export const CALENDAR_DEADLINE_COLORS = {
  accounting: ColorSelection.color1,
  contractual: ColorSelection.color2,
  fiscal: ColorSelection.color3,
  social: ColorSelection.color4,
};
