import React from "react";
import isString from "lodash/isString";

import * as AntIcons from "@ant-design/icons";

import Error from "./icons/errors/generic";

import House from "./icons/entities/house";

import Auth from "./icons/services/auth";
import Condition from "./icons/services/condition";
import Form from "./icons/services/form";
import Invite from "./icons/services/invite";
import Notify from "./icons/services/notify";
import Open from "./icons/services/open";
import Pay from "./icons/services/pay";
import Publish from "./icons/services/publish";
import Review from "./icons/services/review";
import Send from "./icons/services/send";
import Sign from "./icons/services/sign";
import Sync from "./icons/services/sync";
import Write from "./icons/services/write";
import ShareId from "./icons/services/shareId";
import SendLetter from "./icons/services/sendLetter";

import Workflow from "./icons/workflow";

import Archive from "./icons/misc/archive";
import Chat from "./icons/misc/chat";
import Dice from "./icons/misc/dice";
import Fingerprint from "./icons/misc/fingerprint";
import Government from "./icons/misc/government";
import IdCard from "./icons/misc/idcard";
import Key from "./icons/misc/key";
import Passport from "./icons/misc/passport";
import Percent from "./icons/misc/percent";
import Price from "./icons/misc/price";
import Tax from "./icons/misc/tax";
import Docusign from "./icons/third-party/docusign";

const { RocketOutlined, RobotOutlined, UsergroupAddOutlined, PictureOutlined, CheckOutlined, CloseOutlined } = AntIcons;

export const Icon = (props) => {
  if (isString(props.icon) && Boolean(AntIcons[props.icon])) {
    const IconRes = AntIcons[props.icon];
    return <IconRes style={{ ...props.style, fontSize: props.width || 18 }} {...props} />;
  }

  switch (props.name) {
    /* Errors */
    case "error":
      return <Error {...props} />;

    /* Services */
    case "authenticate":
    case "auth":
      return <Auth {...props} />;
    case "condition":
      return <Condition {...props} />;
    case "form":
      return <Form {...props} />;
    case "invite":
    case "inviteUsers":
      return <Invite {...props} />;
    case "noData":
      return <PictureOutlined {...props} />;
    case "notify":
    case "notifications":
      return <Notify {...props} />;
    case "open":
    case "openDocument":
      return <Open {...props} />;
    case "pay":
    case "payment":
      return <Pay {...props} />;
    case "print":
    case "write":
    case "generateDocuments":
      return <Write {...props} />;
    case "publish":
    case "publishLegalNotice":
      return <Publish {...props} />;
    case "review":
    case "docPreview":
      return <Review {...props} />;
    case "send":
    case "mail":
    case "sendMailWithPreview":
    case "sendDocuments":
      return <Send {...props} />;
    case "sendLetter":
      return <SendLetter {...props} />;
    case "sign":
    case "signDocuments":
      return <Sign {...props} />;
    case "sync":
    case "syncEntity":
      return <Sync {...props} />;
    /* Dashboard */
    case "DONE":
      return <CheckOutlined {...props} style={{ ...props.style, color: "green", fontSize: props.width || 18 }} />;
    case "ERROR":
      return <CloseOutlined {...props} style={{ ...props.style, color: "red", fontSize: props.width || 18 }} />;
    /* Entities */
    case "company":
      return <RocketOutlined style={{ fontSize: props.width || 18 }} />;
    case "house":
      return <House {...props} />;
    case "person":
      return <UsergroupAddOutlined style={{ fontSize: props.width || 18 }} />;
    /* Misc */
    case "archive":
      return <Archive {...props} />;
    case "chat":
      return <Chat {...props} />;
    case "dice":
      return <Dice {...props} />;
    case "fingerprint":
      return <Fingerprint {...props} />;
    case "government":
      return <Government {...props} />;
    case "idcard":
      return <IdCard {...props} />;
    case "key":
      return <Key {...props} />;
    case "passport":
      return <Passport {...props} />;
    case "percent":
      return <Percent {...props} />;
    case "price":
      return <Price {...props} />;
    case "tax":
      return <Tax {...props} />;
    case "workflow":
      return <Workflow {...props} />;
    /* 3rd Party */
    case "docusign":
      return <Docusign {...props} />;
    case "shareId":
      return <ShareId {...props} />;

    default:
      return <RobotOutlined {...props} />;
  }
};
