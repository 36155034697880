import { faker } from '@faker-js/faker'

type Option = {
  value?: any;
  [key: string]: any;
}

type MultipleSelectValue = {
  value?: any[];
  options?: Option[];
}

type SerializedMultipleSelect = {
  value: any[];
  type: string;
  options: Option[];
}

export const multipleSelect = {
  serialize: ({ type, options }: { type: string; options: Option[] }) =>
    (value: any): SerializedMultipleSelect => ({
      value: (value && Array.isArray(value.value) && value.value) || (Array.isArray(value) && value) || [],
      type,
      options,
    }),
  
  deserialize: ({ value }: { value: any } = { value: null }) => value,
  
  validate: () => 
    ({ value = [], options = [] }: MultipleSelectValue = {}) =>
      value.every(v => options.findIndex(option => option.value || option === v) >= 0) ? null : 'requiredField',
  
  fake: faker.random.arrayElements,
}