export const PROCESS_INSTANCE_LIST_TYPE = {
  ALL: "all",
  ARCHIVE: "dashboardarchive",
  DELETED: "dashboarddeleted",
  DONE: "dashboarddone",
  IN_PROGRESS: "dashboard",
};

export const PROCESS_INSTANCE_LIST_TITLE = {
  ALL: "entityDossiers.all",
  ARCHIVE: "layout.navigation.dossiers.archive",
  DELETED: "layout.navigation.dossiers.deleted",
  DONE: "layout.navigation.dossiers.done",
  IN_PROGRESS: "layout.navigation.dossiers.inProgress",
};
