import { nth } from '../../data-model/shared/formatters'
 import {search as jmesSearch} from '../../data-model/shared/jmespath'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import { getView } from '../../components/FormRunner/getView'

type ViewOptions = {
  [key: string] : any;
  label?: string;
}

export const groupDataByDivider = (fields = []) => {
  return fields.reduce((acc, field) => {
    if (field.type === 'divider') {
      return [
        ...acc,
        {
          ...field,
          fields: [],
          id: acc.length,
          name: field.label,
        },
      ]
    } else if (acc.length) {
      acc[acc.length - 1].fields.push(field)
    }
    return acc
  }, [])
}

export const getWorkflowInfo = (workflow, language) => {
  const labelName =
    language.toLowerCase() === 'en'
      ? 'label'
      : `label_${language.toLowerCase()}`
  const deadlineName =
    language.toLowerCase() === 'en'
      ? 'deadlineName'
      : `deadlineName_${language.toLowerCase()}`

  const forms = (Array.isArray(workflow) ? workflow : [])
    .filter(({ type }) => type === 'form')
    .flatMap(({ config }) => config)
  const getFieldsInfo = (fields, frameName, path?) =>
    fields.reduce(
      (
        acc,
        {
          fields: subFields,
          name,
          [deadlineName]: deadlineLabelLocalized,
          [labelName]: labelLocalized,
          deadlineName: deadline,
          label,
          type,
          // visible,
          displayName,
        } : {
          [key: string]: any 
        },
        fieldIndex
      ) =>
        acc[(path || '') + name]
          ? acc
          : {
              ...acc,
              ...(type === 'multiple' && Array.isArray(subFields)
                ? getFieldsInfo(subFields, frameName, `${name}.`)
                : {}),
              [(path || '') + name]: {
                deadlineLabel: deadlineLabelLocalized || deadline,
                frameName,
                index: fieldIndex || deadline,
                label: labelLocalized || label,
                type,
                ...(type === 'multiple' &&
                fields[name] &&
                fields[name].displayName
                  ? { displayName: fields[name].displayName }
                  : {}),
                displayName,
                ...(path ? { multiple: path } : {}),
              },
            },
      {}
    )

  const fieldsInfo = forms.reduce(
    (acc, { fields = [], name: frameName } = {}) => ({
      ...acc,
      ...getFieldsInfo(fields, frameName),
    }),
    {}
  )

  const framesInfo = forms.reduce(
    (acc, { name, [labelName]: labelLocalized, label }, index) => ({
      ...acc,
      [name]: { index, label: labelLocalized || label },
    }),
    {}
  )
  return {
    fieldsInfo,
    framesInfo,
  }
}

export const groupDataByFrame = (dataResolved, workflow, language) => {
  const labelName =
    language.toLowerCase() === 'en'
      ? 'label'
      : `label_${language.toLowerCase()}`
  const { framesInfo, fieldsInfo } = getWorkflowInfo(workflow, language)

  const createTable = data =>
    Object.keys(data).reduce(
      (acc, frameName, index) => [
        ...acc,
        {
          children: data[frameName].sort(
            ({ index: prev }, { index: cur }) => prev - cur
          ),
          frameName,
          key: `${frameName}-${index}`,
          label: framesInfo[frameName].label,
        },
      ],
      []
    )

  const groupFieldsByFrame = (data, prefix?) =>
    Object.keys(data).reduce((acc, fieldName) => {
      const path = (prefix || '') + fieldName
      const fieldInfo = fieldsInfo[path]

      //Change this line to handle data not part of the form
      //In case info do not come from a form
      if (
        !fieldInfo ||
        (data[fieldName] && data[fieldName].visible === false)
      ) {
        return acc
      }

      const getFieldLabel = (
        { displayName, label, type, primaryType },
        data
      ) => {
        if (type === 'multiple' && displayName) {
          try {
            return jmesSearch(data, displayName)
          } catch (e) {
            console.log(e, displayName)
          }
        }

        if (isString(label)) {
          return label
        }

        const {
          viewOptions: {
            [labelName]: labelLocalizedFromModel,
            label: labelFromModel = '',
          } = {},
        } : any = getView(primaryType || type, type) || {}
        return labelLocalizedFromModel || labelFromModel
      }

      const getFieldValue = value =>
        value && value.toStr
          ? value.toStr({ locale: language })
          : isPlainObject(value)
          ? JSON.stringify(value)
          : value

      if (fieldInfo.type === 'multiple' && Array.isArray(data[fieldName])) {
        return {
          ...acc,
          [fieldInfo.frameName]: [
            ...(acc[fieldInfo.frameName] || []),
            ...data[fieldName].map((el, index) => ({
              children: groupFieldsByFrame(el, `${fieldName}.`),
              key: `${fieldInfo.frameName}-${fieldName}-${index}`,
              label: getFieldLabel(fieldInfo, {
                ...el,
                __index: nth(index + 1, { locale: language }),
              }),
              name: fieldName,
              type: fieldInfo.type,
            })),
          ],
        }
      }

      const label = getFieldLabel(fieldInfo, data) || fieldName
      const value = getFieldValue(data[fieldName])

      //For fields inside multiple, an array is return
      if (fieldInfo.multiple) {
        return [
          ...acc,
          {
            index: fieldInfo.index,
            key: `${fieldInfo.frameName}-${fieldName}}`,
            label,
            name: fieldName,
            type: fieldInfo.type,
            value,
            valueTranslated:
              typeof value === 'boolean' && value
                ? 'common.text.yes'
                : 'common.text.no',
          },
        ]
      }

      return {
        ...acc,
        [fieldInfo.frameName]: [
          ...(acc[fieldInfo.frameName] || []),
          {
            index: fieldInfo.index,
            key: `${fieldInfo.frameName}-${fieldName}-`,
            label,
            name: fieldName,
            type: fieldInfo.type,
            value,
            valueTranslated:
              typeof value === 'boolean' &&
              (value ? 'common.text.yes' : 'common.text.no'),
          },
        ],
      }
    }, [])

  const sortTable = data => {
    const comp = ({ index: prev }, { index: cur }) => prev - cur

    const sortChildren = data =>
      data.forEach(({ children = {} } = {}) => {
        if (Array.isArray(children)) {
          children.sort(comp)
          sortChildren(children)
        }
      })

    sortChildren(data)
    return data.sort(
      ({ frameName: prevFrameName }, { frameName: curFrameName }) =>
        comp(framesInfo[prevFrameName], framesInfo[curFrameName])
    )
  }

  const fieldsGroupedByFrame = groupFieldsByFrame(dataResolved)
  return sortTable(createTable(fieldsGroupedByFrame))
}
