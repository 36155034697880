import { faker } from '@faker-js/faker'

type Option = {
  value?: any;
  label?: string;
  [key: string]: any;
}

type SelectValue = {
  value?: any;
  options?: Option[];
}

type Locale = {
  locale?: string;
}

export const select = {
  serialize: ({ type, options }: { type: string; options: Option[] }) =>
    (value: any) => ({ value: (value && value.value) || value, type, options }),
  
  deserialize: ({ value }: { value: any } = { value: null }) => value,
  
  validate: () => 
    ({ value, options = [] }: SelectValue) =>
      options.findIndex(option => option.value || option === value) >= 0 ? null : 'requiredField',
  
  fake: faker.random.arrayElement,
  
  toStr: ({ value, options = [] }: SelectValue, { locale = 'en' }: Locale = {}) => {
    const res: Option = options.find(option => (option.value !== undefined ? option.value : option) === value) || {}
    return res[`label_${locale.toLowerCase()}`] || res.label || res
  },
}