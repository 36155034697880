import PasswordValidator from "password-validator";
import { validatePassword } from "./utils";

const passwordValidator = new PasswordValidator()
  .is()
  .min(6)
  .has()
  .digits()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()
  .has()
  .not()
  .spaces();

export const isValid = (password) => passwordValidator.validate(password);

export const checkValidPassword = (password) => {
  const valid = validatePassword(password);
  return !!(valid.hasCapital && valid.hasNumber && valid.hasEightChars && valid.hasSpecial);
};
