import { faker } from '@faker-js/faker'
import isFinite from 'lodash/isFinite'
import isNil from 'lodash/isNil'

type NumberValue = {
  value?: number | string | null;
  type?: string;
}

export const number = {
  serialize: ({ type }: { type: string }) =>
    (value: number | string | null): NumberValue => ({ value: value === '' || isNil(value) ? undefined : Number(value), type }),
  
  deserialize: ({ value }: { value: any } = { value: null }) => (isNil(value) ? '' : String(value)),
  
  validate: () => 
    ({ value }: { value: any }) =>
      isFinite(value) ? null : 'requiredField',
  
  fake: faker.datatype.number,
}