import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="5 5 25 25" {...props}>
    <g>
      <polygon points="18.136,11.026 14.904,19.703 16.753,19.042 16.428,24.422 19.66,15.745 17.811,16.406 	" />
      <path d="M21.536,6.19H9.146v22.109h16.271V10.556L21.536,6.19z M24.417,27.299H10.146V7.19h10.94l3.33,3.747V27.299z" />
    </g>
  </svg>
)

export default SVG
