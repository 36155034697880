export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  return specialChars.test(str)
}

export function containsNumberChars(str) {
  const specialChars = /[0-9]/
  return specialChars.test(str)
}

export function emailValidation(email) {
  const reg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return reg.test(email)
}

export function urlValidation(url) {
  const reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  return reg.test(url)
}

export const validatePassword = password => {
  return {
    hasCapital: password.split('').some(char => {
      if (containsSpecialChars(char)) {
        return false
      }
      if (!isNaN(char * 1)) {
        return false
      }
      return char === char.toUpperCase()
    }),
    hasEightChars: password.length >= 8,
    hasNumber: containsNumberChars(password),
    hasSpecial: containsSpecialChars(password),
  }
}
