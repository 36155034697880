//members[12] -> [12]
export const REGEX_INDEX = /\[[0-9]+\]/g;
//members[12].firstName -> members[12]
export const REGEX_FIELD_NAME_WITH_INDEX = /[\w\[[0-9]+\]/g;

export const REGEX_FILE_NAME_TEMPLATE_LITERAL = /\${[^\}]*}/g;

//fileName_EN => true
export const REGEX_PROPS_FILE_NAME = /^filename*/g;
export const REGEX_PROPS_FILE_NAME_WITH_LANG = /^filename_*/g;

//Urls
export const REGEX_URL =
  /(http:\/\/|ftp:\/\/|https:\/\/|www.)([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gi;
