import { isObject } from "lodash";
import { REGEX_FILE_NAME_TEMPLATE_LITERAL, REGEX_URL } from "../constants/regex";
import { resolveJmespath } from "./jmespath";
import moment from "moment";
import { formatDate, formatTime } from "../data-model/shared/formatters";

const resolveValue = (data, toResolve, language) => {
  let resolved = resolveJmespath(data, toResolve, language);

  if (typeof resolved === "string" || resolved instanceof String) {
    return resolved.toString();
  }
  resolved = isObject(resolved) ? Object.assign({}, resolved) : resolved;
  if (resolved && resolved.type) {
    switch (resolved.type) {
      case "datePicker":
        resolved.value = moment(resolved.value).isValid
          ? formatDate(resolved.value, {
              dispMonthName: false,
              locale: language,
            })
          : resolved.value;
        break;
      case "timePicker":
        resolved.value = moment(resolved.value).isValid
          ? formatTime(resolved.value, { locale: language })
          : resolved.value;
        break;
      case "dateTimePicker":
        //Write code here in case you want to change datetime format
        break;
    }
  }

  return resolved ? resolved[language] || resolved[language.toLowerCase()] || resolved.value || resolved : undefined;
};

export const getLiteralsFromTemplate = (template) => template.matchAll(REGEX_FILE_NAME_TEMPLATE_LITERAL);

export const getCleanedLiteralsFromTemplate = (template) => {
  const literals = getLiteralsFromTemplate(template);
  const result = [];
  for (const literal of literals) {
    result.push(getCleanedLiteral(literal[0]));
  }
  return result;
};

// ${field.subField} => field.subField
export const getCleanedLiteral = (literal) => literal.slice(2, -1);

export const computeStringWithTemplate = (filename, data, language) => {
  let result = filename || "";
  const literals = getLiteralsFromTemplate(filename);
  for (const literal of literals) {
    const toResolve = getCleanedLiteral(literal[0]);
    const resolved = resolveValue(data, toResolve, language);
    result = result.replace(literal, resolved || "");
  }
  return result.startsWith("`") && result.endsWith("`") ? result.slice(1, -1) : result;
};

export const useTemplate = (fileName) => new RegExp(REGEX_FILE_NAME_TEMPLATE_LITERAL).test(fileName);

export const isURL = (fileName) => new RegExp(REGEX_URL).test(fileName);
