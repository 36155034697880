import React from 'react'
import classNames from 'classnames'
import './Loader.less'

type LoaderPropsType = {
    className?: string;
    large?: boolean;
    isRelative?: boolean;
    text?: string;
}

export const Loader:React.FC<LoaderPropsType> = ({ className="", large = false, isRelative, text, ...props }) => {
  return (
    <div className={classNames('loader', 'spin-lg', isRelative && 'loader--relative', className)} {...props}>
      <span className="spin-dot spin-dot-spin">
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
      </span>
    </div>
  )
}
