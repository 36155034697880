import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isJSON } from '../../helpers/text'
import DocumentLink from '../DocumentLink'
// import { Collapse } from 'design-components'
// import { Form } from 'components/Form'
import {Form, Collapse} from 'antd'
import { Tooltip } from 'antd'

// import './index.less'
import './SummaryForm.less'

export const SummaryForm = ({ data }) => {
  const { t } = useTranslation()
  const formItems = useMemo(
    () =>
      data
        .filter(
          ({ value, children }) =>
            ((typeof value === 'string' && !isJSON(value) && value.length) ||
              typeof value === 'boolean') &&
            !children
        )
        .sort((a, b) => a.index - b.index),
    [data]
  )

  const initialValues = formItems.reduce(
    (acc, item) => ({ ...acc, [item.name]: item.value }),
    {}
  )

  const collapseItems = useMemo(
    () =>
      data
        .filter(({ children }) => children)
        .map(categ => {
          console.log("children of categ:", categ)
          return {
            content: <SummaryForm data={categ.children} />,
            title: categ.label,
          }
        }),
    [data]
  )

      console.log("collapseItems:", collapseItems)

  return (
    <Form
      initialValues={initialValues}
      layout="inline"
      className="processInstance-form-row"
    >
      {formItems.map(item => (
        <Form.Item key={item.name} style={{ width: '100%' }}>
          <div className="processInstance-form-display">
            <div className="processInstance-form-title">{item.label}</div>
            {item.type === 'file' ? (
              <div className="processInstance-form-content">
                <DocumentLink id={item.value}>
                  {t('common.text.document')}
                </DocumentLink>
              </div>
            ) : (
              <div className="processInstance-form-content">
                {item.valueTranslated ? (
                  <div>
                    {t(item.valueTranslated)}
                  </div>
                ) : (
                  <div>{item.value}</div>
                )}
              </div>
            )}
          </div>
        </Form.Item>
      ))}
      {collapseItems.length > 0 && (
        collapseItems.map((collapseItem,index) => 
        (<Collapse bordered={false} 
          className="summary-collapse-form"
        accordion
        expandIconPosition="end"
        // activeKey={openPanelKey === index.toString() ? [index]: []}
        // onChange={() => toggleCollapse(index.toString())}
        items={[
            {
                key: index,
                label: 
                    <div className="form-card-label">
                        <div>{collapseItem.title}</div>
                    </div>,
                children:
                collapseItem.content
            }
        ]} />)
      )
      )}
    </Form>
  )
}
