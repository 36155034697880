import React from "react";
import i18n from "../i18n";
import { localeShortCodes } from "./locales";
import { extendedLocaleToLang } from "../data-model/shared/formatters";

export const multilang = (obj = {}, lang = i18n.language) => {
  lang = extendedLocaleToLang(lang);
  const getValue = (obj) => obj[lang.toUpperCase()] ?? (obj["EN"] || "");
  if (typeof obj === "string" || !obj || React.isValidElement(obj)) {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map((element) => getValue(element) || "");
  } else {
    return getValue(obj) || "";
  }
};

// if there is no PI data in English, we're using French version and vice versa
export const getLocalizedText = (prop, lang) => {
  const fallbackLang = lang === localeShortCodes.EN ? localeShortCodes.FR : localeShortCodes.EN;
  return typeof prop === "object" ? (prop[lang] ? prop[lang] : prop[fallbackLang]) : prop;
};
