export const maritalStatus = ["married", "single", "divorced", "widowed"];

export const memberRole = ["leaders", "investor", "owner"];

export const USER_TYPES = {
  admin: "admin",
  broker: "broker",
  customer: "customer",
  editor: "editor",
  operator: "operator",
};
