import gql from "graphql-tag";

const getCurrentUserQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      type
      incomingMail
    }
  }
`;

export { getCurrentUserQuery };
