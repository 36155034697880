import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
    <g>
      <path d="M861.2,691.4V456.9c0-11.8-9.5-21.3-21.3-21.3H520.8v-127c72.7-10.1,128.8-72.5,128.8-148.1C649.5,78,582.6,11.1,500,11.1c-82.6,0-149.5,66.9-149.5,149.5c0,75.2,55.5,137.4,127.7,147.9v127.1H159c-11.8,0-21.3,9.5-21.3,21.3v232.5C65.5,700,10,762.2,10,837.3c0,82.6,66.9,149.5,149.5,149.5c82.6,0,149.5-66.9,149.5-149.5c0-75.5-56-138-128.8-148.1V491c0-5.9,4.8-10.6,10.6-10.6H808c5.9,0,10.6,4.8,10.6,10.6v200.6C746.5,702.1,691,764.3,691,839.4c0,82.6,66.9,149.5,149.5,149.5c82.6,0,149.5-66.9,149.5-149.5C990,763.9,934,701.5,861.2,691.4z M393.1,160.6c0-59,48-106.9,106.9-106.9c59,0,106.9,48,106.9,106.9c0,59-48,106.9-106.9,106.9C441,267.5,393.1,219.5,393.1,160.6z M266.4,837.3c0,59-48,106.9-106.9,106.9c-59,0-106.9-48-106.9-106.9c0-59,48-106.9,106.9-106.9C218.5,730.4,266.4,778.3,266.4,837.3z M840.5,946.4c-59,0-106.9-48-106.9-106.9s48-106.9,106.9-106.9c59,0,106.9,48,106.9,106.9S899.5,946.4,840.5,946.4z" />
    </g>
  </svg>
)

export default SVG
