import React from "react";
import { useNavigate } from "react-router";
import TagPrice from "../Icon/TagPrice";
import NextArrow from '../../assets/svg/nextArrow.svg';
import SVGIcon from '../SVGIcon/SVGIcon'

export type DossierPropsType = {
    icon: React.ReactNode,
    title: string,
    content: string,
    link: string,
    beta: boolean,
}

const Dossier:React.FC<DossierPropsType> = ({
    icon,
    title,
    content,
    link,
    beta,
}) => {

    const navigate = useNavigate();

    const TagProduct = () => (
        <div className="product-tag-draft">
          <TagPrice
            styleText={{
                color: 'white',
                position: 'absolute',
                left: 28,
                textAlign: 'center',
                top: 18,
                fontFamily: 'Gilroy Regular',
                fontSize: '8px'
            }}
            title="BETA"
          />
        </div>
      )

    return (
        <div className="dossier" onClick={() => navigate(link)}>            
            <div className="dossier-main">
                <div className="dossier-main-wrap">
                    <div className="dossier-icon">
                        {icon}
                    </div>
                    <div className="dossier-wrap">
                        <p className="dossier-title">{title}</p>
                        <p className="dossier-content">{content}</p>
                    </div>
                </div>
                <div className="dossier-next-arrow">
                    <SVGIcon src={NextArrow} />
                </div>
            </div>
            {beta && 
                <TagProduct />
            }
        </div>
    )
}

export default Dossier