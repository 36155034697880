import React, { useMemo } from 'react'
import { useBindingField } from '../../helpers/hooks/useBindingField'
import { FieldView } from './FieldView'
import { useTranslation } from 'react-i18next'
import { FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { 
  getCompanyMemberRolesQuery, 
  getCountriesEuQuery, 
  getCountriesQuery, 
  getEnumerationByNamesQuery, 
  getInseeLegalFormsFrQuery, 
  getInseeMunicipalitiesByPostalCodeQuery, 
  getRegistrarQuery,
  getEntityCategoriesQuery,
  getEntitiesByCategoryNameQuery,
  getDocumentByIdQuery,
  getCompanyInfoBySiretQuery,
  getCompanyBodaccEntriesQuery
} from './graphql/queries'
import classNames from 'classnames'
import Loader from '../Loader'
import { useForm } from 'react-final-form'
import { Flag } from '../Flag'
import { debounce, get } from 'lodash'
import jmespath from 'jmespath'
import { entityFieldTypeName } from '../../constants/entities'
import { conditionIsFullfilled } from './frames'
import { capitalizeFirstLetter } from '../../data-model/helpers/utils'
import { getModel, getSerializer } from '../../data-model/getModel'
import { replaceDocumentMutation, uploadDocumentMutation } from './graphql/mutation'
import { messageError } from '../Message'
import { v4 as uuid } from 'uuid'
import { useImperativeQuery } from '../../helpers/hooks/useImperativeQuery'
import { findFieldTypeInFrame, updateFieldForm } from '../../helpers/form'

type TypeFieldProps = {
  name: string,
  user: any,
  prefix: string,
  definition: any,
  defaultValue: any,
  initialValue: any,
  options?: any,
  locale: string,
  initialOptions: any,
  icon?: any,
  [key: string]: any,
}

export const TypeField: React.FC<TypeFieldProps> = ({ name, ...params }) => {
    const { user, prefix, definition, defaultValue, initialValue, options, locale, initialOptions, ...props } =
      useMemo(() => params, [params])
  
    const { input, meta } = useBindingField({ name, ...params })
  
    return <FieldView options={options} definition={definition} user={user} input={input} meta={meta} {...props} />
}

export const TypeFieldEnumeration = params => {
    const translation = useTranslation()
    const {
      i18n: { language },
    } = translation
  
    const { type, processInstance } = params
  
    const { data: { getEnumerationByNames: enumerations = [] } = {}, loading } = useQuery(getEnumerationByNamesQuery, {
      fetchPolicy: 'cache-and-network',
      skip: (processInstance?.staticData || {})[type],
      variables: {
        names: [type],
      },
    })
  
    const enumeration = React.useMemo(
      () => (processInstance?.staticData && processInstance?.staticData[type]) || enumerations[0],
      [processInstance, enumerations, type]
    )
    const formattedOptions = React.useMemo(
      () =>
        (enumeration?.options || []).map(option => ({
          label: option['label_' + language.toLowerCase()] || option.label,
          value: option.value,
        })),
      [enumeration, language]
    )
  
    const { input, meta } = useBindingField({ ...params, options: formattedOptions })
  
    if (loading) {
      return <Loader />
    }
  
    return (
      <FieldView
        {...params}
        defaultValue={enumeration?.defaultValue}
        input={input}
        meta={meta}
        options={formattedOptions}
        translation={translation}
      />
    )
}

export const TypeFieldCheckBox = ({ icon, ...params }) => {
  const { input } = useBindingField(params)
  const finalIcon = React.useMemo(() => {
    switch (icon) {
      case 'information':
        return <div className="form-runner-form-field-checkbox-icon">!</div>
      default:
        return null
    }
  }, [icon])
  return (
    <div className={classNames('form-runner-form-field-checkbox', icon && 'form-runner-form-field-checkbox--custom')}>
      <TypeField name={''} user={undefined} prefix={''} definition={undefined} defaultValue={undefined} initialValue={undefined} locale={''} initialOptions={undefined} {...params} />
      {input.checked && finalIcon}
    </div>
  )
}


export const TypeFieldSelector = params => {
    const {
      i18n: { language: currentLanguage },
    } = useTranslation()
  
    const {
      user,
      prefix,
      definition,
      name,
      defaultValue,
      options: optionsProp,
      locale,
      initialOptions,
      ...props
    } = React.useMemo(() => params, [params])
  
    const [dataOptions] = React.useState(definition.dataOptions || {})
    const [dependency] = React.useState(props.dependency || dataOptions.dependency)
  
    const getQuery = React.useMemo(() => {
      switch (dataOptions.asyncData) {
        case 'cities':
          return getInseeMunicipalitiesByPostalCodeQuery
        case 'countriesEu':
          return getCountriesEuQuery
        case 'countries':
        case 'nationality':
          return getCountriesQuery
        case 'registrar':
          return getRegistrarQuery
        case 'companyMemberRoles':
          return getCompanyMemberRolesQuery
        case 'legalFormFr':
        default:
          return getInseeLegalFormsFrQuery
      }
    }, [dataOptions.asyncData])
  
    const [getValues, { loading, data }] = useLazyQuery(getQuery)
    const { registerField, change, getState } = useForm()
  
    const options = React.useMemo(() => {
      if (!data) {
        return []
      }
      switch (dataOptions.asyncData) {
        case 'cities':
          return (data.getInseeMunicipalitiesByPostalCode || []).map(data => ({
            label: data.label,
            value: data.inseeCode,
          }))
          case 'countriesEu':
            return (data.getCountriesEu || []).map((country) => {
              const localLabel = country[`label_${currentLanguage.toLowerCase()}`] as string; // Cast this dynamically accessed property as a string
              return {
                label: <Flag countryCode={country.isoCode2} label={localLabel || country.label} />,
                value: country.isoCode2,
              };
            });
        case 'countries':
            return (data.getCountries || []).map((country) => {
              const localLabel: string = country[`label_${currentLanguage.toLowerCase()}`]; // Use the index signature to access the dynamic label
                return {
                    label: <Flag countryCode={country.isoCode2} label={localLabel || country.label} />,
                    stringLabel: localLabel || country.label,
                    value: country.isoCode2,
                };
            });
        case 'legalFormFr':
          return (data.getInseeLegalFormsFr || []).map(({ id, label, category }) => ({ category, label, value: id }))
        case 'nationality':
          return (data.getCountries || []).map(({ isoCode2, nationality }) => ({
            label: <Flag countryCode={isoCode2} label={nationality[currentLanguage.toLowerCase()] || nationality['fr'] || isoCode2} />,
            stringLabel: nationality[currentLanguage.toLowerCase()] || nationality['fr'] || isoCode2,
            value: isoCode2,
          }))
        case 'registrar':
          const registrars = Boolean(data.getRegistrar) ? [data.getRegistrar] : data.getRegistrars
          return registrars.map(({ registrarCode, name }) => ({ label: name, value: registrarCode }))
          case 'companyMemberRoles':
            return data.getCompanyMemberRoles.map((role) => {
              // Access the translated role using the current language
              const translatedRole: string = role[currentLanguage.toLowerCase()] || role.en;
              return {
                label: translatedRole,
                value: role.value,
              };
            });
        default:
          return []
      }
    }, [data, dataOptions.asyncData, currentLanguage])
  
    const formatQuery = React.useCallback(
      ({ value = '' } = {}) => {
        switch (dataOptions.asyncData) {
          case 'countriesEu':
          case 'countries':
          case 'legalFormFr':
          case 'nationality':
            getValues()
            break
          case 'registrar':
            Boolean(value) ? getValues({ variables: { codeCommune: value.value || value } }) : getValues()
            break
          case 'cities':
            getValues({ variables: { postalCode: value.value || value } })
            break
          case 'companyMemberRoles':
            Boolean(value) ? getValues({ variables: { companyType: value.value || value } }) : getValues()
            break
          default:
            break
        }
      },
      [dataOptions.asyncData, getValues]
    )
  
    const debouncedFormatQuery = React.useMemo(() => debounce(formatQuery, 3e2), [formatQuery])
  
    React.useEffect(() => {
      if (dependency) {
        if (dependency.startsWith('@')) {
          let value = undefined
          try {
            value = jmespath.search(getState().values, dependency.substring(1))
          } catch (e) {
            console.error(e)
          }
          formatQuery({ value })
          return () => void 0
        } else {
          const unregisterField = registerField(
            prefix ? `${prefix}.${dependency}` : dependency,
            ({ value }) => {
              debouncedFormatQuery(value)
            },
            { value: true }
          )
  
          return () => {
            unregisterField()
            debouncedFormatQuery.cancel()
          }
        }
      } else {
        debouncedFormatQuery()
        return () => {
          debouncedFormatQuery.cancel()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const { input, meta } = useBindingField({ ...params, options })
  
    React.useEffect(() => {
      const valueIsValidOption = Array.isArray(options) && options.some(el => (el.value || el) === input.value)
  
      if (options && (!meta.touched || !valueIsValidOption)) {
        if (Array.isArray(options) && options.length === 1) {
          input.onChange(options[0].value || options[0])
        }
      } else if (meta.touched && input.value !== defaultValue && input.value !== '' && !valueIsValidOption) {
        change(name, '')
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, meta.touched, input.value])
  
    return (
      <FieldView
        meta={meta}
        input={input}
        user={user}
        definition={definition}
        loading={loading}
        {...props}
        options={options}
      />
    )
}

export const TypeFieldEntity = params => {
  const translation = useTranslation()
  const { input, meta } = useBindingField(params)
  const form = useForm()

  const {
    entityCategories: entityCategoriesFilter,
    name,
    excludeFields,
    typeNameFieldName,
    newFields = [],
    frame,
    prefixField,
  } = params
  
  React.useEffect(() => {
    if (!Array.isArray(entityCategoriesFilter) || !entityCategoriesFilter.length) {
      throw new Error('[form] no entity types defined. You need to set the entityCategories array property')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: { getEntityCategories: allEntityCategories = [] } = {} } = useQuery(getEntityCategoriesQuery, {
    fetchPolicy: 'cache-and-network',
  })

  // fetching entities filtered by entityCategories form parameter
  const { data: { getEntitiesByCategoryName: entities = [] } = {} } = useQuery(getEntitiesByCategoryNameQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      categoryName: entityCategoriesFilter,
    },
  })

  // filtering to only relevant entityCategories
  const entityCategories = React.useMemo(
    () =>
      allEntityCategories
        .filter(({ categoryName }) => entityCategoriesFilter.includes(categoryName))
        .map(entity => ({ ...entity, form: entityCategoriesFilter[entity.typeName] })),
    [allEntityCategories, entityCategoriesFilter]
  )

  const multiplePrefix = React.useMemo(() => {
    const lastDotIndex = name.lastIndexOf('.')
    return lastDotIndex > 0 ? name.slice(0, lastDotIndex) : ''
  }, [name])

  const handlePrefixField = field => (prefixField ? `${prefixField}${field}` : field)

  const getFieldValue = () => {
    const { values } = form.getState()
    if (multiplePrefix) {
      return get(values, multiplePrefix)
    } else {
      return Object.keys(values).reduce((acc, value) => {
        if (value.startsWith(realName)) {
          acc[value] = values[value]
        }
        return acc
      }, {})
    }
  }

  const frameFields = (Array.isArray(frame?.fields[0]?.fields) ? frame?.fields[0].fields : frame?.fields) || []

  const realName = input.name.replace(`${multiplePrefix}.`, '')

  const isSubEntity =
    name === `${multiplePrefix}.${entityFieldTypeName}` &&
    frameFields.filter(({ type }) => type === entityFieldTypeName).length === 2

  const updateForm = ({ data } = { data: {}}) => {
    if (Object.keys(data || {}).length > 0) {
      const filterFunction =
        excludeFields === true
          ? // All fields are excluded
            () => false
          : Array.isArray(excludeFields) && excludeFields.length > 0
          ? // Only given fields are excluded and fields starting with '__'
            fieldName => !excludeFields.includes(fieldName) && !fieldName.startsWith('__')
          : // All fields are keep except those starting with '__'
            fieldName => !fieldName.startsWith('__')

      const addFields = newFields.reduce((acc, newField) => {
        try {
          const res = jmespath.search(data, newField.path)
          if (res !== null && res !== undefined) {
            switch (newField.importType) {
              case 'explode':
                const resFiltered = Array.isArray(newField.excludeFields)
                  ? Object.keys(res).reduce(
                      (acc, fieldName) =>
                        newField.excludeFields.includes(fieldName)
                          ? acc
                          : {
                              ...acc,
                              [handlePrefixField(fieldName)]: res[fieldName],
                            },
                      {}
                    )
                  : res

                return { ...acc, ...resFiltered }
              case 'rename':
                if (res.value !== undefined) {
                  return { ...acc, [newField.name]: res }
                }
                return acc
              default:
                return acc
            }
          }
          return acc
        } catch (e) {
          console.error(e)
          return acc
        }
      }, {})

      const fields = Object.keys(data).filter(filterFunction)

      const dataProcessed = {
        ...addFields,
        ...fields.reduce(
          (acc, fieldName) => ({
            ...acc,
            [handlePrefixField(fieldName)]: data[fieldName],
          }),
          {}
        ),
      }

      //We need to add some delay to update the form
      const dataToCheckCondition = {
        ...getFieldValue(),
        ...dataProcessed,
        [realName]: true,
      }

      const excludes = isSubEntity ? ['type'] : []
      const fieldNames = frameFields
        .filter(
          ({ condition = [], name }) =>
            !excludes.includes(name) &&
            (!Boolean(condition) ||
              condition.every(condition => conditionIsFullfilled(condition, dataToCheckCondition)))
        )
        .map(({ name }) => name)
      Object.keys(dataProcessed)
        .filter(
          fieldName =>
            fieldNames.includes(realName + capitalizeFirstLetter([fieldName])) || fieldNames.includes(fieldName)
        )
        .forEach(fieldName => {
          const addPrefix = fieldNames.includes(realName + capitalizeFirstLetter([fieldName]))
          const path = Boolean(multiplePrefix)
            ? `${multiplePrefix}.${fieldName}`
            : addPrefix
            ? realName + capitalizeFirstLetter([fieldName])
            : fieldName
          const value = dataProcessed[fieldName]
          if (value && value.value !== undefined) {
            form.change(path, value)
          }
        })

      // subEntity case
      if (realName !== entityFieldTypeName) {
        if (dataToCheckCondition[entityFieldTypeName] && dataToCheckCondition[entityFieldTypeName].value.entityId) {
          const subEntity = entities.find(({ id }) => id === dataToCheckCondition[entityFieldTypeName].value.entityId)
          const subEntityFields = Object.keys(subEntity.data)
            .filter(fieldName => fieldName !== 'type') // Directly use fieldName here
            .filter(filterFunction);
          subEntityFields.forEach(fieldName => {
            const path = Boolean(multiplePrefix) ? `${multiplePrefix}.${fieldName}` : fieldName
            const value = subEntity.data[fieldName]
            if (value && value.value !== undefined && !dataProcessed[fieldName]) {
              form.change(path, value)
            }
          })
        }
      }
    }
  }

  const clearForm = ({ creationForm }) => {
    const values = {
      ...getFieldValue(),
    }
    const fieldNames = (
      isSubEntity ? creationForm[0].fields.filter(({ name }) => name !== 'type' && Boolean) : frameFields
    ).map(({ name }) => name)
    Object.keys(values)
      .filter(
        fieldName => fieldNames.includes(realName + capitalizeFirstLetter([fieldName])) || fieldNames.includes(fieldName)
      )
      .forEach(fieldName => {
        const addPrefix = fieldNames.includes(realName + capitalizeFirstLetter([fieldName]))
        const path = Boolean(multiplePrefix)
          ? `${multiplePrefix}.${fieldName}`
          : addPrefix
          ? realName + capitalizeFirstLetter([fieldName])
          : fieldName
        form.change(path + '.value', null)
      })
  }

  const handleChange = value => {
    const prefixArray = multiplePrefix.match(/\[[0-9]+]/g)
    const multipleFieldName =
      prefixArray && prefixArray.length > 0
        ? multiplePrefix.slice(0, multiplePrefix.lastIndexOf(prefixArray[prefixArray.length - 1]))
        : ''

    const typeNameField =
      typeNameFieldName &&
      (multipleFieldName.length > 0
        ? frame.fields?.find(({ name }) => multipleFieldName === name)
        : frame
      )?.fields.find(({ name }) => name === typeNameFieldName)

    const type = value.entityId
      ? entities.find(({ id }) => id === value.entityId).type
      : entityCategories.find(({ id }) => id === value.categoryId).type

    if (value?.categoryId && type) {
      clearForm(type)
    }

    if (type && type.typeName && typeNameField && multiplePrefix) {
      const typeNameSerializer = getSerializer(typeNameField.type, typeNameField, { throwError: false })
      if (typeNameSerializer) {
        form.change(`${multiplePrefix}.${typeNameFieldName}`, typeNameSerializer(type.typeName))
      }
    }

    if (value?.entityId) {
      const idNameField = (
        multipleFieldName.length > 0 ? frame.fields?.find(({ name }) => multipleFieldName === name) : frame
      )?.fields.find(({ name }) => name === 'id')
      if (idNameField && multiplePrefix) {
        const idNameSerializer = getSerializer(idNameField.type, idNameField, { throwError: false })
        form.change(`${multiplePrefix}.id`, idNameSerializer(value.entityId))
      }
      const entity = entities.find(({ id }) => id === value.entityId)
      updateForm(entity)
    }

    input.onChange(value)
  }

  return (
    <FieldView
      {...params}
      meta={meta}
      input={{ ...input, onChange: handleChange }}
      translation={translation}
      entityCategories={entityCategories}
      entities={entities}
    />
  )
}

const handleFieldWithoutPrefix = params => {
  const filename = {}
  const regex = new RegExp('^filename_')
  //Exemple (args => result) filename_en => EN
  const handleKeyFileName = key => key.split('_')[1].toUpperCase()

  if (params.prefix) {
    const prefixWithDot = params.prefix.concat('.')

    const prefixWithOutIndex = params.prefix.slice(0, params.prefix.lastIndexOf('[')).concat('.')
    Object.keys(params)
      .filter(key => regex.test(key))
      .forEach(key => {
        const keyHandled = handleKeyFileName(key)
        if (params[key].includes(prefixWithDot)) {
          filename[keyHandled] = params[key]
        } else {
          filename[keyHandled] = params[key].replaceAll(prefixWithOutIndex, prefixWithDot)
        }
      })
  } else {
    Object.keys(params)
      .filter(key => regex.test(key))
      .forEach(key => (filename[handleKeyFileName(key)] = params[key]))
  }
  //If no precision (_LANG) then EN = filename field
  return Object.keys(filename).length === 0 ? { EN: params.filename } : filename
}

export const TypeFieldUpload = (params) => {
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [submitting, setSubmitting] = React.useState(false)
  const { input, meta } = useBindingField(params)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [documentIdInitialValue, setDocumentIdInitialValue] = React.useState(input?.value)

  const {
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    processInstance: { id: processInstanceId } = {id: ""},
    filetype,
    tags,
    signaturePosition,
    signatureType,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorString,
    signatureAnchorOffset,
  } = params

  const filename = handleFieldWithoutPrefix(params)
  const metadata = React.useMemo(() => {
    return {
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signatureAnchorIgnoreIfNotPresent,
      signatureAnchorOffset,
      signatureAnchorString,
      signaturePosition,
      signatureType,
      signer,
      stepId,
    }
  }, [
    filename,
    name,
    fileLanguage,
    processInstanceId,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorOffset,
    signatureAnchorString,
    signaturePosition,
    signatureType,
    signer,
    stepId,
  ])

  const customRequest = React.useCallback(
    async ({ file, onSuccess }) => {
      try {
        setSubmitting(true)
        if (documentIdInitialValue) {
          const {
            data: {
              replaceDocument: { id },
            },
          }: FetchResult<any, Record<string, any>, Record<string, any>>  = await (preview
            ? new Promise(resolve => resolve({ data: { replaceDocument: [{ id: uuid() }] } }))
            : replaceDocument({
                variables: {
                  documentSource: { processInstanceId },
                  file,
                  id: documentIdInitialValue,
                },
              }))
          onSuccess(id)
          setDocumentIdInitialValue(id)
        } else {
          const {
            data: {
              uploadDocuments: [{ id }],
            },
          }: FetchResult<any, Record<string, any>, Record<string, any>> = await (preview
            ? new Promise(resolve => resolve({ data: { uploadDocuments: [{ id: uuid() }] } }))
            : uploadDocument({
                variables: {
                  documentSource: { processInstanceId },
                  file,
                  filetype,
                  flags: ['form'],
                  metadata,
                  tags,
                },
              }))
          onSuccess(id)
          setDocumentIdInitialValue(id)
        }
      } catch (e) {
        messageError('Error while uploading', e)
      } finally {
        setSubmitting(false)
      }
    },
    [filetype, metadata, preview, processInstanceId, tags, uploadDocument, replaceDocument, documentIdInitialValue]
  )

  const { data: { getDocumentById: document } = {} } = useQuery(getDocumentByIdQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !documentIdInitialValue,
    variables: {
      documentId: documentIdInitialValue,
    },
  })

  return (
    <FieldView
      meta={meta}
      input={input}
      submitting={submitting}
      customRequest={customRequest}
      {...params}
      document={document}
    />
  )
}

const __inseeFieldDefaultMapping = [
  {
    field: 'companyName',
    path: 'uniteLegale.denominationUniteLegale',
  },
  {
    field: 'companyHeadquarterAddress',
    path: 'address',
  },
  {
    field: 'companyHeadquarterZipCode',
    path: 'adresseEtablissement.codePostalEtablissement',
  },
  {
    field: 'companyHeadquarterCity',
    path: 'adresseEtablissement.codeCommuneEtablissement',
  },
  {
    field: 'companyHeadquarterCountry',
    path: 'country',
  },
  {
    field: 'companyForm',
    path: 'uniteLegale.categorieJuridiqueUniteLegale',
  },
]

export const TypeFieldSiret = ({ frame, ...params }) => {
  const form = useForm()
  const { input, meta } = useBindingField(params)
  const [noData, setNoData] = React.useState(false)
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const {
    user,
    prefix,
    definition,
    name,
    defaultValue,
    options: optionsProp,
    locale,
    mapping = __inseeFieldDefaultMapping,
    processInstance,
    ...props
  } = params

  const [getCompanyInfo] = useImperativeQuery(getCompanyInfoBySiretQuery)
  const [getCompanyBodaccEntries] = useImperativeQuery(getCompanyBodaccEntriesQuery)

  const getBodaccEntries = React.useCallback(
    async value => {
      const { data: { getCompanyBodaccEntries: bodaccEntries, error } = { getCompanyBodaccEntries: null, error: null} } =
        (await getCompanyBodaccEntries({ siret: value })) || {}

      if (error) {
        messageError(t('common.error.queryFailed'), error)
        return undefined
      }
      if (bodaccEntries) {
        return bodaccEntries
      }
      return []
    },
    [getCompanyBodaccEntries, t]
  )

  const handleImportClick = React.useCallback(
    async value => {
      try {
        const { data: { getCompanyInfoBySiret: companyInfo, error } = { getCompanyInfoBySiret: null, error: null} } =
          (await getCompanyInfo({ siret: value })) || {}
        if (error) {
          messageError(t('common.error.queryFailed'), error)
        }

        if (companyInfo) {
          if (companyInfo.errors && companyInfo.message) {
            messageError(companyInfo.message, companyInfo.error)
            return
          }
          setNoData(false)
          ;(mapping || []).forEach(({ field, path }) => {
            try {
              field = prefix ? `${prefix}.${field}` : field

              const value = jmespath.search(companyInfo, path)
              const typeDef = findFieldTypeInFrame(field, frame) || {}
              const model = getModel(typeDef)

              if (model) {
                updateFieldForm({ field, form, language }, typeDef, model, value)
              }
            } catch (error) {
              console.log('error', error)
              messageError(t('common.error.queryFailed'), error)
            }
          })
        } else {
          setNoData(true)
        }
      } catch (error) {
        console.log('error', error)
        messageError(error.message, t('common.error.queryFailed'))
        setNoData(true)
      }
    },
    [form, frame, getCompanyInfo, language, mapping, prefix, t]
  )

  return (
    <FieldView
      definition={definition}
      user={user}
      input={input}
      meta={meta}
      handleImportClick={handleImportClick}
      errorMsg={noData ? t('formRunner.fields.insee.companyNotFound') : undefined}
      getBodaccEntries={getBodaccEntries}
      {...props}
    />
  )
}

export const TypeFieldIdCheck = params => {
  const translation = useTranslation()
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [submitting, setSubmitting] = React.useState(false)
  const { input, meta } = useBindingField(params)
  const [documentFrontId, setDocumentFrontId] = React.useState(input?.value?.front)
  const [documentBackId, setDocumentBackId] = React.useState(input?.value?.back)
  const [documentType, setDocumentType] = React.useState(input?.value?.type)

  const filename = handleFieldWithoutPrefix(params)

  const {
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    processInstance: { id: processInstanceId } = { id: ''},
    tags,
    signatureType,
    signaturePosition,
  } = params

  const metadata = React.useMemo(
    () => ({
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signaturePosition,
      signatureType,
      signer,
      stepId,
    }),
    [name, fileLanguage, filename, processInstanceId, signaturePosition, signatureType, signer, stepId]
  )

  const customRequest = React.useCallback(
    (type, side) =>
      async ({ file, onSuccess }) => {
        const wrap = {
          back: {
            get: documentBackId,
            set: setDocumentBackId,
          },
          front: {
            get: documentFrontId,
            set: setDocumentFrontId,
          },
        }
        if (!wrap[side]) {
          return
        }
        try {
          setSubmitting(side)
          if (wrap[side].get && documentType === type) {
            const {
              data: {
                replaceDocument: { id },
              },
            }: FetchResult<any, Record<string, any>, Record<string, any>> = await (preview
              ? new Promise(resolve => resolve({ data: { replaceDocument: [{ id: uuid() }] } }))
              : replaceDocument({
                  variables: {
                    documentSource: { processInstanceId },
                    file,
                    id: wrap[side].get,
                  },
                }))
            onSuccess(id)
            wrap[side].set(id)
            input.onChange({
              ...input.value,
              [side]: id,
            })
          } else {
            const {
              data: {
                uploadDocuments: [{ id }],
              },
            }: FetchResult<any, Record<string, any>, Record<string, any>> = await (preview
              ? new Promise(resolve => resolve({ data: { uploadDocuments: [{ id: uuid() }] } }))
              : uploadDocument({
                  variables: {
                    documentSource: { processInstanceId },
                    file,
                    filetype: type,
                    flags: ['form'],
                    metadata: { ...metadata, side },
                    tags,
                  },
                }))
            onSuccess(id)
            wrap[side].set(id)
            input.onChange({
              ...input.value,
              [side]: id,
            })
          }
          setDocumentType(type)
        } finally {
          setSubmitting(false)
        }
      },
    [
      metadata,
      preview,
      documentType,
      processInstanceId,
      tags,
      input,
      uploadDocument,
      replaceDocument,
      documentBackId,
      documentFrontId,
    ]
  )

  const { data: { getDocumentById: documentFront } = {} } = useQuery(getDocumentByIdQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !documentFrontId,
    variables: {
      documentId: documentFrontId,
    },
  })

  const { data: { getDocumentById: documentBack } = {} } = useQuery(getDocumentByIdQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !documentBackId,
    variables: {
      documentId: documentBackId,
    },
  })

  const document = React.useMemo(() => ({ back: documentBack, front: documentFront }), [documentFront, documentBack])

  return (
    <FieldView
      {...params}
      meta={meta}
      input={input}
      submitting={submitting}
      customRequest={customRequest}
      translation={translation}
      document={document}
    />
  )
}