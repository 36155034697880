import React, { FC, ReactNode, useMemo } from 'react'
import { Input } from 'antd'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import isString from 'lodash/isString'

import { Flag } from '../../components/Flag'

interface PhoneViewProps {
  id: string
  addonBefore?: ReactNode
  disabled?: boolean
  value?: string
  defaultCountry?: CountryCode
  processInstance?: any
  stepId?: any
  [key: string]: any
}

export const PhoneView: FC<PhoneViewProps> = ({
  id,
  addonBefore: addonBeforeProps,
  disabled,
  value,
  defaultCountry = 'FR' as CountryCode,
  processInstance,
  stepId,
  ...props
}) => {
  const addonBefore = useMemo(() => {
    if (addonBeforeProps) {
      return addonBeforeProps
    } else {
      const phone = isString(value) && parsePhoneNumberFromString(value, defaultCountry)
      return phone && phone.country ? <Flag countryCode={phone.country as CountryCode} /> : <Flag countryCode={defaultCountry} />
    }
  }, [addonBeforeProps, defaultCountry, value])

  return (
    <Input
      addonBefore={addonBefore}
      className="form-input-phone-number"
      disabled={disabled}
      id={id}
      value={value}
      {...props}
    />
  )
}