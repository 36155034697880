export const randomString = (length: number, chars: string): string => {
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export const capitalizeFirstLetter = ([first, ...rest]: string[], locale: string = undefined): string =>
  first.toLocaleUpperCase(locale) + rest.join('')

export const lowerFirstLetter = ([first, ...rest]: string[], locale: string = undefined): string =>
  first.toLocaleLowerCase(locale) + rest.join('')

export const findDecimalSeparator = (local: string = 'fr'): string => {
  const num = 1.2
  if (typeof Intl === 'object' && Intl && Intl.NumberFormat) {
    const formatter = new Intl.NumberFormat(local)
    const parts = formatter.formatToParts(num)
    const decimal = parts.find(({ type }) => type === 'decimal').value
    return decimal
  }
  const str = num.toLocaleString()
  const parts = /1(\D+)2/.exec(str)
  return parts[1]
}

export const escapeRegex = (string: string): string => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

interface ConvertToFloatResult {
  num: number;
  isWrittingDecimal: boolean;
  decimal: string;
}

export const convertToFloat = (str: string, local: string): ConvertToFloatResult => {
  let body = str
  let sign = ''
  let isWrittingDecimal = false
  const decimal = findDecimalSeparator(local)
  const signMatch = /^\s*(-|\+)/.exec(str)
  if (signMatch) {
    body = str.substring(signMatch.index + 1)
    sign = signMatch[1]
  }
  const rex = new RegExp(`${escapeRegex(decimal)}|-|\\+|\\D`, 'g')
  const updatedBody = body.replace(rex, match => (match === decimal ? '.' : ''))
  if (updatedBody[updatedBody.length - 1] === '.' && updatedBody.split('.').filter(Boolean).length === 1) {
    isWrittingDecimal = true
  }
  const num = parseFloat(sign + updatedBody)
  return { num, isWrittingDecimal, decimal }
}