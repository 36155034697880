import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 25 25" {...props}>
    <g>
      <path d="M8.304,5.343v23.725H26.1V5.343H8.304z M25.1,28.068H9.304V6.343H25.1V28.068z" />
      <path
        d="M21.19,19.882h-8.085c-1.267,0-2.297,1.03-2.297,2.297s1.03,2.297,2.297,2.297h7.775l-0.942,0.942l0.707,0.707l2.149-2.149
		l-2.149-2.15l-0.707,0.707l0.943,0.943h-7.776c-0.715,0-1.297-0.582-1.297-1.297s0.582-1.297,1.297-1.297h8.085
		c1.266,0,2.296-1.03,2.296-2.296c0-1.267-1.03-2.297-2.296-2.297h-8.085c-0.715,0-1.297-0.582-1.297-1.297s0.582-1.296,1.297-1.296
		h8.533v-0.045c1.052-0.209,1.848-1.139,1.848-2.252s-0.796-2.042-1.848-2.252V9.103h-10.33v1h9.882
		c0.715,0,1.296,0.582,1.296,1.297s-0.581,1.297-1.296,1.297h-8.085c-1.267,0-2.297,1.03-2.297,2.296
		c0,1.267,1.03,2.297,2.297,2.297h8.085c0.715,0,1.296,0.582,1.296,1.297S21.905,19.882,21.19,19.882z"
      />
    </g>
  </svg>
)

export default SVG
