interface SerializeInput {
  type: string;
  entityTypes: any;
  prefixField?: string;
}

interface DeserializeInput {
  value: {
    entityId: any;
    typeName: any;
    categoryId: any;
  }
}

interface EntityValue {
  entityId?: any;
  typeName: any;
  categoryId: any;
}

export const entity = {
  serialize: ({ type, entityTypes, prefixField = '' }: SerializeInput = {type: null, entityTypes: null, prefixField: null}) => 
    ({ entityId, typeName, categoryId }: EntityValue = {entityId: '', typeName: '', categoryId: ''}) => ({
      entityTypes,
      prefixField,
      type,
      value: {
        entityId,
        typeName,
        categoryId,
      },
      asyncData: 'entity',
    }),
  deserialize: ({ value }: DeserializeInput) => {
    if (!value) {
      return {}
    }
    return {
      entityId: value.entityId,
      typeName: value.typeName,
      categoryId: value.categoryId,
    }
  },
  validate: () => ({ value }: { value?: EntityValue }) =>
    value && (value.categoryId || value.entityId) ? null : 'requiredField',
}