import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" {...props}>
    <path d="M48.25.94l-33,84.43,3.86,14,12.38-7.69,33-84.43ZM28.21,89l-6.7,4.17-2.09-7.61L50.52,6.12,59.3,9.56Z" />
    <path d="M64.66,73.47c-4.1-1.51-10.85-4-11.95-5.53,1.06-1,5.8-2.08,8.69-2.74,6.6-1.49,12.3-2.79,12.65-6.77.27-3.12-3.1-5.06-6.36-7-1.45-.83-4.45-2.57-4.55-3.37,0,0,.64-2,10.28-3.86A2,2,0,0,0,75,41.9a2,2,0,0,0-2.35-1.57c-9,1.79-13.19,4.08-13.48,7.43S62.34,53,65.69,55c1.43.82,4.06,2.34,4.36,3.13-.7,1.22-6.43,2.52-9.53,3.22-6.39,1.45-11.44,2.6-11.87,6.16-.51,4.18,5.84,6.52,14.63,9.77,4.31,1.59,13.28,4.9,13.49,6.7-.08.51-2,5.13-43.45,10.71a2,2,0,0,0,.27,4,1.23,1.23,0,0,0,.27,0C75,93.06,80.72,88.31,80.77,84,80.83,79.44,73.34,76.68,64.66,73.47Z" />
  </svg>
)

export default SVG
