import gql from 'graphql-tag'

export const getProductsQuery = gql`
  query getProductsQuery {
    getProducts {
      category {
        id
        index
        title
        color
      }
      parentId
      description
      uploadedDocsDescription
      generatedDocsDescription
      providedDocsDescription
      icon {
        id
        url
      }
      id
      status
      estimatedTime
      estimatedTimeType
      price
      priceIncludesVat
      priceDescription
      steps
      statsDisplay
      title
      position
      process {
        description
        id
        title
        workflow {
          name
          description
        }
        products {
          price
          priceDescription
        }
        metasteps {
          start
          end
          name
          description
        }
      }
    }
  }
`

export const getProductCategoriesQuery = gql`
  query getProductCategories {
    getProductCategories {
      id
      title
      color
      index
    }
  }
`
