import React from 'react';
import ProgressiveImage from 'react-progressive-image';

import Loader from '../../Loader';

interface ImageViewerProps {
  filename: string;
  url: string;
  imageClassName?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ filename, url, imageClassName }) => {
  const title = decodeURIComponent(filename);

  return (
    <ProgressiveImage src={url} placeholder="">
      {(src: string, loading: any) => (
        <div className={imageClassName} style={{ height: '100%', overflow: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
          {loading ? (
            <Loader isRelative />
          ) : (
            <img src={src} style={{ opacity: 1 }} width="100%" height="auto" alt={title} />
          )}
        </div>
      )}
    </ProgressiveImage>
  );
};

export default ImageViewer;
