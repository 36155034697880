// import { APP_URL } from 'constants/url'
import i18n from "i18next";
import jsPDF from "jspdf";
import { multilang } from "./multiLang";
import { computeMultilang } from "./compute";
import { getMultilangFileName } from "./metadata";
import { APP_URL } from "../constants/url";

export const getExtension = (str: string): string => (str.includes(".") ? "." + str.split(".").slice(-1)[0] : "");

export const removeExtension = (str: string): string =>
  str.includes(".") ? str.split(".").slice(0, -1).join(".") : str;

interface Document {
  url: string;
}

export const getDownloadLink = (document: Document): string =>
  `${APP_URL}${document.url}/download?lang=${i18n.language.toUpperCase()}`;
export const getPreviewLink = (document: Document): string => `${APP_URL}${document.url}`;

export const html2pdf = (html: HTMLElement, title: string, width?: number, height?: number): Promise<File> =>
  new Promise((resolve) => {
    const orientation = html.offsetWidth > html.offsetHeight ? "l" : "p";
    const doc = new jsPDF(orientation, "px", [width || html.offsetWidth, height || html.offsetHeight]);

    doc.html(html, {
      callback: (doc) => {
        const pageCount = (doc as any).internal.getNumberOfPages();
        pageCount > 1 && doc.deletePage(pageCount);
        return resolve(new File([doc.output("blob")], title + ".pdf", { type: "application/pdf" }));
      },
    });
  });

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const downloadBase64 = (filename: string, b64: string): void => {
  const element = document.createElement("a");
  element.setAttribute("href", decodeURI(b64));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const buildData = (meta, filetype, data, t) => {
  const regexArray = /(\w+)\[(\d)]/g;

  const [, field = meta.fieldName, indexStr] = regexArray.exec(meta.fieldName) || [];

  const index = parseInt(indexStr);

  const getTranslatedSide = (lng) => t(`common.document.side.${meta.side}`, { lng });
  const getTranslatedFiletype = (lng) => t(`common.filetype.${filetype}`, { lng });

  return {
    ...data,
    ...(index >= 0 && data[field] && data[field][index] ? { [field]: data[field][index] } : data),
    ...(filetype
      ? {
          __filetype: {
            en: getTranslatedFiletype("en"),
            fr: getTranslatedFiletype("fr"),
          },
        }
      : {}),
    ...(meta.side ? { __side: { en: getTranslatedSide("en"), fr: getTranslatedSide("fr") } } : {}),
  };
};

export const isMultilangObject = (obj) => obj.hasOwnProperty("EN");

export const generateDocumentName = ({ filename, metadata, filetype }, data, t) => {
  const extensionIndex = multilang(filename, "EN").lastIndexOf(".") + 1;
  const extension = extensionIndex > 0 ? multilang(filename, "EN").slice(extensionIndex) : "";

  const multilangProps = getMultilangFileName(metadata);
  const dataBuilder = {
    ...buildData(metadata, filetype, data, t),
    ...data,
  };

  const multilangFile = multilang(multilangProps);
  if (!multilangFile || typeof multilangFile === "object") {
    return JSON.stringify(filename);
  }
  return multilang(computeMultilang(multilangProps, dataBuilder, extension));
};
