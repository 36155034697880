import panic from 'panic-overlay'
import React from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { Button } from 'antd'

import { extractErrorMessage } from '../../components/Message'
import Loader from '../../components/Loader'
import { getCurrentUserQuery } from './graphql/queries'

import { ErrorResultPage } from './ErrorResultPage'

panic.configure({ handleErrors: false })

type ErrorResultPropsType = {
  error: ApolloError
  errorInfo?: any
}

export const ErrorResult: React.FC<ErrorResultPropsType> = ({ error, errorInfo }) => {
  const { data: { getCurrentUser: user = {} } = {}, loading } = useQuery(getCurrentUserQuery)

  if (loading) {
    return <Loader />
  }

  const errorMessage = error ? extractErrorMessage(error) : 'An error has occured, please try again later'

  const stackTrace =
    process.env.NODE_ENV !== 'production' && errorInfo && errorInfo.componentStack ? (
      <>
        <br />
        {errorInfo.componentStack.split('\n')[1]}
      </>
    ) : (
      'No stack trace'
    )

    const subTitleElement = [
      'Verify that you are connected to the internet',
      'Make sure that you are logged in to your account',
      'Verify that you have completed all the previous questionnaires',
      'Check that you have uploaded the right documents',
      'Refresh the page or go back and attempt the action again',
    ];

  const title = process.env.NODE_ENV === 'production' ? 'Whoops, something went wrong.' : errorMessage
  const subTitle =
    process.env.NODE_ENV === 'production' ? (
      <>
        <h3>{'To resolve the problem, please try these quick actions:'}</h3>
        <ul>
          {(Array.isArray(subTitleElement) ? subTitleElement : []).map(el => (
            <li>{el}</li>
          ))}
        </ul>
        <h3 className="error-result-lastTitle">{'Still not working? Contact us by clicking "Help me"'}</h3>
      </>
    ) : (
      stackTrace
    )

  const url = user ? '/dashboard' : '/'

  const extraGoto = (
    <a href={url} key="goto-button">
      <Button key="goto-button" type="primary" size="large">
        {user ? 'Access my dossiers' : 'Back Home!'}
      </Button>
    </a>
  )

  const extraDebug = process.env.NODE_ENV !== 'production' && error instanceof Error && (
    <Button
      key="debug-button"
      type="primary"
      size="large"
      onClick={() => {
        panic(error)
      }}
    >
      {'Debug'}
    </Button>
  )

  const mailtoSubject = 'Something went wrong... I need help!'
  const newLine = '%0D%0A'
  const userEmail = user ? user.email : 'Anonymous'
  const report = `${userEmail}`
  const mailtoBody = `Hi there!${newLine}${newLine}Something went wrong:${newLine}${newLine}${errorMessage} at ${window.location}${newLine}${newLine}${report}${newLine}`
  const mailto = `mailto:hello@thisisalf.com?subject=${mailtoSubject}&body=${mailtoBody}`
  const extraHelp = (
    <a href={mailto} key="help-button-link">
      <Button key="help-button" type="primary" size="large">
        {'Help me'}
      </Button>
    </a>
  )

  const extra = [extraDebug, extraGoto, extraHelp]

  return <ErrorResultPage title={title} subTitle={subTitle} extra={extra} />
}
