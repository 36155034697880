import jmespath from "jmespath";
import { cleanData } from "../data-model/helpers/cleanData";
import moment from "moment";
import { formatDate, formatMoney, formatNumber, formatTime, writeMoney } from "../data-model/shared/formatters";
import { BASE_URL } from "../constants/url";

export const resolveJmespath = <T>(data: T, jmesPath: string, language?: string): any => {
  // Replace 'any' with the expected return type if known
  try {
    const sanitizedData = cleanData(data, { translateToLocale: language });
    return jmespath.search(sanitizedData, jmesPath);
  } catch {
    return undefined;
  }
};

interface LocaleOptions {
  locale: string;
}

interface AccessorOptions {
  __debug?: boolean;
  __hideUndefined?: boolean;
}

interface Member {
  get: (path: string, options?: any) => any;
  getRaw: (path: string) => any;
}

interface DisplayAddressOptions {
  element: Member;
  ignoreCountry?: boolean;
  prefix?: string;
  locale?: string;
  format?: any;
}

interface PersonInfoOptions {
  member: Member;
  locale?: string;
  format?: string;
}

interface CompanyClosingDateRange {
  endDate: moment.Moment;
  startDate: moment.Moment;
}

interface MinimalInfoOptions {
  member: Member;
  locale?: string;
  ignoreCountry?: boolean;
}

interface MembersCEOOptions {
  paramType?: string;
  paramName?: string;
}

export const accessor = (
  data: any,
  options: AccessorOptions = {},
  context?: any,
  locale: LocaleOptions = { locale: "en" }
) => {
  const { __debug, __hideUndefined } = options;

  const setLocale = (newLocale: string = "en"): void => {
    locale.locale = newLocale;
  };

  const get = (path: string, getOptions: any = {}): any => {
    const value = jmespath.search(data, path);
    if (value) {
      if (Array.isArray(value)) {
        return value;
      }
      if (value.toStr) {
        return value.toStr({ locale: locale.locale, ...getOptions });
      }
      return value.value !== undefined ? value.value : value;
    }
    return __debug
      ? `<span class='alf-tag' data-value='${path}' style="font-weight: bold; background-color: red;" >${
          !__hideUndefined ? "UNDEFINED:" : ""
        }${path}</span>`
      : "";
  };

  const getRaw = (path: string): any => {
    const getPrimitiveString = (value: any): string => (value instanceof String ? String(value) : value);
    const value = jmespath.search(data, path);
    if (value) {
      if (value.value) {
        return getPrimitiveString(value.value);
      }
      return getPrimitiveString(value);
    }
    return undefined;
  };

  const getArray = (path: string): any[] => {
    const value = jmespath.search(data, path);
    if (Array.isArray(value)) {
      return value.map((el) => accessor(el, options, context, locale));
    }
    return (__debug ? [{}] : []).map(() => accessor({}, {}, context, locale));
  };

  const getApiUrl = (): string => BASE_URL;

  const getCss = (name: string): string => `${BASE_URL}/public/templates/${name}.css`;

  const set = (obj: any): void => {
    data = { ...data, ...obj };
  };

  const displayAddress = ({ element, ignoreCountry, prefix, locale = "fr" }: DisplayAddressOptions): string => {
    const display = (get: (path: string, options?: any) => any): string =>
      ["address", "zipCode", "city", "country"]
        .filter((key) => !ignoreCountry || key !== "country")
        .map((key) => get(prefix ? `${prefix}${key.replace(/^./, (x) => x.toUpperCase())}` : key, { locale }))
        .join(", ");
    return element ? display(element.get) : display(get);
  };

  const getPersonInfo = ({ member, locale = "fr", format }: PersonInfoOptions): string => {
    switch (format) {
      case "legalNotice":
        return `${member.get("firstName")} ${member.get("lastName")}${
          member.get("spouseLastName").length && !member.get("spouseLastName").startsWith("<")
            ? `, ${locale === "fr" ? "épouse " : "spouse "} ${member.get("spouseLastName")}`
            : ""
        }`;
      default:
        return `${member.get("firstName")} ${member.get("lastName")}${
          member.get("spouseLastName").length && !member.get("spouseLastName").startsWith("<")
            ? `, ${locale === "fr" ? "épouse " : "spouse "} ${member.get("spouseLastName")}`
            : ""
        }, ${locale === "fr" ? "né(e) le" : "born on"} ${member.get("birthDate", { locale })} ${
          locale === "fr" ? "à" : "in"
        } ${member.get("birthCity")}, ${member.get("birthZipCode")}, ${member.get("birthCountry")}, ${
          locale === "fr" ? "de nationalité" : "of"
        } ${member.get("nationality")}`;
    }
  };

  const getPersonFullInfo = ({ member, locale = "fr", format }: PersonInfoOptions): string =>
    `${getPersonInfo({ format, locale, member })}, ${
      locale === "fr" ? "demeurant" : "whose address is"
    } ${displayAddress({
      element: member,
      format,
      locale,
    })}`;

  const getCompanyFullInfo = ({ member, locale = "fr", format }: PersonInfoOptions): string => {
    switch (format) {
      case "legalNotice":
        return `${member.get("companyName")}, ${
          locale === "fr" ? "sise" : "whose headquarter is located at"
        } ${displayAddress({
          element: member,
          ignoreCountry: true,
          locale,
          prefix: "companyHeadquarter",
        })}, ${member.get("companyRegistrationId").substr(0, 11)} RCS ${member.get("companyRegistrationCity")}, ${
          locale === "fr" ? "représentée par" : "represented by"
        } ${getPersonInfo({ format, locale, member })}`;
      default:
        return `${member.get("companyName")}, ${
          locale === "fr" ? "dont le siège social est situé au" : "whose headquarter is located at"
        } ${displayAddress({
          element: member,
          locale,
          prefix: "companyHeadquarter",
        })}, ${
          locale === "fr" ? "immatriculée au registre des sociétés de" : "registered with the Companies Registry of"
        } ${member.get("companyRegistrationCity")}, ${
          locale === "fr" ? "sous le numéro" : "under the number"
        } ${member.get("companyRegistrationId")}, ${
          locale === "fr" ? "représentée par" : "represented by"
        } ${getPersonInfo({ locale, member })}`;
    }
  };

  const getFullInfo = ({ member, locale = "fr", format }: PersonInfoOptions): string => {
    if ((member.getRaw("type") || "").toLowerCase() === "person" || member.getRaw("entityType") === "Person") {
      return getPersonFullInfo({ format, locale, member });
    } else if ((member.getRaw("type") || "").toLowerCase() === "company" || member.getRaw("entityType") === "Company") {
      return getCompanyFullInfo({ format, locale, member });
    } else {
      const message = `Invalid Person or Company. (type: ${member.getRaw("type")})`;
      return `<span style="font-weight: bold; background-color: red;" >:${message}</span>`;
    }
  };

  const getCompanyClosingDateRange = (date: string): CompanyClosingDateRange => {
    if (!Boolean(date)) {
      return { endDate: moment(), startDate: moment() };
    }
    const startDate = moment(date, "YYYY-MM-DD").add(1, "days");
    if (startDate.month() === 1 && startDate.date() === 29) {
      startDate.add(1, "days");
    }

    const endDate = moment(date, "YYYY-MM-DD").add(1, "years");
    if (endDate.month() === 1 && endDate.date() === 29) {
      endDate.subtract(1, "days");
    }

    return {
      endDate,
      startDate,
    };
  };

  const getMinimalInfo = ({ member, locale = "fr", ignoreCountry }: MinimalInfoOptions): string => {
    if (member.getRaw("type").toLowerCase() === "person") {
      return `${(member.get("lastName") || "").toUpperCase()} ${member.get("firstName")}, ${displayAddress({
        element: member,
        ignoreCountry: ignoreCountry !== undefined ? ignoreCountry : (member.get("country") || {}).fr === "France",
      })}`;
    } else if (member.getRaw("type").toLowerCase() === "company") {
      return `${member.get("companyName")}, ${member.get("companyForm").label} ${
        locale === "fr" ? "sise" : "at"
      } ${displayAddress({
        element: member,
        locale,
        prefix: "companyHeadquarter",
      })}, ${member.get("companyRegistrationId")} RCS ${member.get("companyRegistrationCity")}`;
    } else {
      const message = `Invalid Person or Company. (type: ${member.getRaw("type")})`;
      return `<span style="font-weight: bold; background-color: red;" >:${message}</span>`;
    }
  };

  const getMembersCEO = (members: Member[], fn?: Function, option: MembersCEOOptions = {}): any[] => {
    if (!Array.isArray(members)) {
      console.error("getMembersCEO: First parameter must be an array");
      return [];
    }
    const paramType = option["paramType"] || "param"; // Param can be passed as it is or inside an object to fn
    const paramName = option["paramName"] || "member";

    const ceo = members.filter((member) => ["CEO", "Manager"].includes(member.getRaw("role")));

    if (fn) {
      return ceo.map((member) => (paramType === "param" ? fn(member) : fn({ [paramName]: member })));
    }
    return ceo;
  };

  const formatMultiline = (lines: string, tag: string = "p"): string =>
    (lines || "")
      .split("\n")
      .map((line) => `<${tag}>${line}</${tag}>`)
      .join("\n");

  return {
    displayAddress,
    formatDate,
    formatMoney,
    formatMultiline,
    formatNumber,
    formatTime,
    get,
    getApiUrl,
    getArray,
    getCompanyClosingDateRange,
    getCompanyFullInfo,
    getCss,
    getFullInfo,
    getMembersCEO,
    getMinimalInfo,
    getPersonFullInfo,
    getPersonInfo,
    getRaw,
    moment,
    set,
    setLocale,
    writeMoney,
  };
};
