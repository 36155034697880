import { Download } from 'react-feather'
import React, { SVGProps } from 'react'

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const DownloadIcon = (props: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Download {...props} />
    </div>
  )
}

export default DownloadIcon