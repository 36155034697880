import React from 'react'
import { getValue } from '../../helpers/utils/utils'
import { isEqual } from 'lodash'

const sanitizeCondition = ({ key, op, value, keyPath, condition }) =>
  Array.isArray(key)
    ? { condition, key: key[0], keyPath: keyPath[0], op: op[0], value: value[0] }
    : { condition, key, keyPath, op, value }

//TODO Make condition iso with packages/api/src/helpers/evaluateCondition.js
export const conditionIsFullfilled = (condition, state) => {
  if (!Boolean(condition)) {
    return true
  }

  const { key, op, value, keyPath, condition: subCondition = [] } = sanitizeCondition(condition)

  const getPathValue = (state, keypath) => {
    try {
      const keys = keypath.split('.')
      if (state[keys[0]] === true) {
        return true
      }
      return keys.reduce((previous, current) => previous[current], state)
    } catch {
      return null
    }
  }

  const exceptedValue = getValue(keyPath ? getPathValue(state, keyPath) : state[key])

  switch (op) {
    case 'eq':
    case 'ne': {
      const result = Array.isArray(value)
        ? JSON.stringify(value.sort()) === JSON.stringify(exceptedValue.sort())
        : value === exceptedValue

      return op === 'ne' ? !result : result
    }
    case 'inc':
    case 'ninc': {
      const result = (value || []).includes(exceptedValue)
      return op === 'ninc' ? !result : result
    }
    case 'lt':
      return value < exceptedValue
    case 'le':
      return value <= exceptedValue
    case 'ge':
      return value >= exceptedValue
    case 'gt':
      return value > exceptedValue
    case 'jump':
      return true
    case 'leq':
      return (exceptedValue || []).includes(value)
    case 'lne':
      return !(exceptedValue || []).includes(value)
    case 'or':
      return subCondition.some(condition => conditionIsFullfilled(condition, state))
    case 'not':
      return !subCondition.every(condition => conditionIsFullfilled(condition, state))
    case 'isSet':
    case 'isNotSet':
      const isSet = exceptedValue !== null && exceptedValue !== undefined
      return op === 'isSet' ? isSet : !isSet
    default:
      return true
  }
}

const isFilled = (fields, state) =>
  fields.every(field => {
    if (!Boolean(field.condition)) {
      return true
    }

    if (
      field.condition &&
      field.condition.length &&
      !field.condition.every(condition => conditionIsFullfilled(condition, state))
    ) {
      return true
    }

    if (field.type !== 'multiple') {
      return !field.required || !!state[field.name]
    }

    if (field.required && (!state[field.name] || !state[field.name].length)) {
      return false
    }

    return (state[field.name] || []).every(state => isFilled(field.fields, state || {}))
  })

const collectFrames = (frames, state, result = []) => {
  if (!frames.length) {
    return result
  }

  const [frame, ...otherFrames] = frames
  result.push(frame)
  if (!isFilled(frame.fields, state)) {
    return result
  }

  if (!frame.condition || !frame.condition.length) {
    return collectFrames(otherFrames, state, result)
  }

  for (const condition of frame.condition) {
    if (conditionIsFullfilled(condition, state)) {
      const index = otherFrames.findIndex(frame => frame.id === condition.target)
      if (index > -1) {
        return collectFrames(otherFrames.slice(index), state, result)
      }
    }
  }

  return collectFrames(otherFrames, state, result)
}

export const useFormFrames = ({ config }, frameId, state = {}) => {
  const [frames, setFrames] = React.useState(collectFrames(config, state))
  const currentFrame = React.useMemo(() => frames.find(({ id }) => id === frameId) || frames[0], [frameId, frames])
  const previousFrame = React.useMemo(
    () => (currentFrame ? frames[frames.findIndex(({ id }) => id === currentFrame.id) - 1] : {}),
    [currentFrame, frames]
  )

  React.useEffect(() => {
    const newFrames = collectFrames(config, state)
    if (!isEqual(newFrames, frames)) {
      setFrames(newFrames)
    }
  }, [config, frames, state])

  return { currentFrame, previousFrame }
}

export const getNextFrame = ({ config }, frameId, state = {}) => {
  const index = config.findIndex(({ id }) => frameId === id)
  const [, frame] = index > -1 ? collectFrames(config.slice(index), state) : []
  return frame
}
