import React, { FC, useCallback, useState } from "react";
import { message, Upload, Modal, Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { FilePreview } from "../components/FilePreview";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { generateDocumentName } from "../../helpers/document";
import { multilang } from "../../helpers/multiLang";

interface FileViewProps {
  id: string;
  beforeUpload?: (file: any, fileList: any[]) => boolean | Promise<void>;
  submitting?: boolean;
  customRequest?: any;
  submitFailed?: boolean;
  loading?: boolean;
  value?: any;
  content?: React.ReactNode;
  document?: any;
  processInstance?: any;
  stepId?: any;
  maxSize?: string;
  filename?: string;
  companyName?: string;
  onChange: (value: any) => void;
  acceptedFiletypes?: string;
  [key: string]: any;
}

export interface FileWithId extends File {
  id?: string;
}

export const FileView: FC<FileViewProps> = ({
  id,
  beforeUpload: beforeUploadProps,
  submitting,
  customRequest,
  submitFailed,
  loading: loadingProps = false,
  value,
  content,
  document,
  processInstance,
  stepId,
  maxSize = "",
  onChange,
  acceptedFiletypes = ".doc,.docm,.docx,.dot,.dotm,.dotx,.htm,.html,.msg,.pdf,.rtf,.txt,.wpd,.xps,.bmp,.gif,.jpg,.jpeg,.png,.tif,.tiff,.pot,.potx,.pps,.ppt,.pptm,.pptx,.csv,.xls,.xlsm,.xlsx",
  ...props
}) => {
  console.log("processInstance:", processInstance);
  console.log("updated FileView for FilePreview from local");
  const [file, setFile] = useState<any>();
  const [uploadedAttachments, setUploadedAttachments] = useState<number[]>(
    JSON.parse(localStorage.getItem("uploadedAttachments")) || []
  );
  const { t } = useTranslation();
  const beforeUpload = useCallback(
    (file, fileList) => {
      const maxSizeFloat = parseFloat(maxSize);
      if (maxSizeFloat > 0 && parseFloat((file.size / 1024 / 1024).toFixed(4)) > maxSizeFloat) {
        message.error(`File size exceed the limit: ${maxSizeFloat} mb`);
        return false;
      }
      console.log("results in beforeUpload--------------------------");
      console.log("file before upload:", file);
      console.log("filelist before upload:", fileList);
      const baseFileName = getConventionalBaseFileName();
      const extension = file.name.split(".").pop();
      if (baseFileName !== "") {
        const newFileName = (baseFileName + "." + extension).replace(/`/g, "");
        const newFile = new File([file], newFileName, {
          type: file.type,
        });
        console.log("new file name:", newFileName);
        console.log("new file:", newFile);
        const newFileList = fileList.map((f) =>
          f.uid === file.uid ? { ...f, name: newFileName, originFileObj: newFile } : f
        );
        console.log("newFileList:", newFileList);
        return beforeUploadProps ? beforeUploadProps(newFile, newFileList) : true;
      } else {
        return beforeUploadProps ? beforeUploadProps(file, fileList) : true;
      }
    },
    [beforeUploadProps, maxSize]
  );

  const handleOnChange = (evt) => {
    changeValue(evt);
    console.log("new file from local:", evt.file?.originFileObj);
    const baseFileName = getConventionalBaseFileName();
    const extension = evt.file?.originFileObj.name.split(".").pop();
    console.log("baseFileName:", baseFileName);
    if (baseFileName !== "") {
      const newFileName = (baseFileName + "." + extension).replace(/`/g, "");
      const newFile = new File([evt.file?.originFileObj], newFileName, {
        type: evt.file?.originFileObj.type,
      });
      console.log("new file on handleChange:", newFile);
      setFile(newFile);
    } else {
      setFile(evt.file?.originFileObj);
    }
    setIsModalVisible(false);
  };

  const changeValue = useCallback((value) => onChange(value), [onChange]);

  const handleRemoveFile = () => {
    setFile(null);
    changeValue(undefined);
  };

  const [attachments, setAttachments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = async () => {
    setLoading(true);
    try {
      const item = Office.context.mailbox.item;
      const attachments = item.attachments.filter((attachment) => !attachment.isInline);
      if (attachments.length === 0) {
        message.info(t("noAttachment"));
      } else {
        setAttachments(attachments);
      }
    } catch (error) {
      console.error("Error getting attachments:", error);
      message.error(t("failedToGetAttachments"));
    } finally {
      setLoading(false);
      setIsModalVisible(true);
    }
  };

  const uploadAttachment = async (file) => {
    convertToUploadFileFormat(file, (error, file) => {
      const { id, file: fileWithNoID } = file;
      console.log("converting:", file, fileWithNoID);
      if (error) {
        console.error("Error converting attachment: ", error);
      } else if (file) {
        customRequest({
          file: fileWithNoID,
          onSuccess: (response) => {
            message.success(t("fileUploadSuccess"));
            console.log("results in uploadAttachment************");
            console.log("id in uploadAttachment:", id);
            console.log("fileWithNoID:", fileWithNoID);
            setFile(fileWithNoID);
            changeValue({ file: { response } });
            setUploadedAttachments([...uploadedAttachments, id]);
            console.log(
              "uploadedAttachments in localStorage:",
              uploadedAttachments,
              JSON.stringify([...uploadedAttachments, id])
            );
            localStorage.setItem("uploadedAttachments", JSON.stringify([...uploadedAttachments, id]));
            setIsModalVisible(false);
          },
        });
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function convertToUploadFileFormat(attachment, callback) {
    if (attachment.file) {
      callback(null, attachment.file);
    }
    // Use the Office JavaScript API to get the attachment content
    Office.context.mailbox.item.getAttachmentContentAsync(
      attachment.id,
      { asyncContext: attachment },
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const content = result.value; // The content of the attachment
          let blob;

          // The content could be in different formats such as base64, so we need to handle it accordingly
          if (content.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
            // Convert Base64 string to a byte array
            const byteCharacters = atob(content.content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a blob from the byte array
            blob = new Blob([byteArray], { type: attachment.contentType });
          } else {
            // Handle other formats if necessary
            console.error("Attachment format not supported: ", content.format);
            return;
          }

          // Create a File object from the Blob
          const baseFileName = getConventionalBaseFileName();
          if (baseFileName !== "") {
            const extension = attachment.name.split(".").pop();
            const conventionalFileName = (baseFileName + "." + extension).replace(/`/g, "");
            const fileWithNoID = new File([blob], conventionalFileName);
            console.log("conventionalFileName in normal:", conventionalFileName);
            const file = { id: attachment.id, file: fileWithNoID };
            console.log("file in convertToUPloadFileFormat", file);
            callback(null, file);
          } else {
            const fileWithNoID = new File([blob], attachment.name);
            const file = { id: attachment.id, file: fileWithNoID };
            console.log("file in convertToUPloadFileFormat", file);
            callback(null, file);
          }
        } else {
          console.error("Error getting attachment content: ", result.error);
          callback(result.error, null);
        }
      }
    );
  }

  const getConventionalBaseFileName = () => {
    const { filename, filetype = "" } = { ...props };
    return generateDocumentName({ filename, metadata: { filename }, filetype }, processInstance.data, t);
  };

  const isAttachmentUploaded = (attachment) =>
    uploadedAttachments.filter((uploadedAttachment) => uploadedAttachment === attachment.id).length > 0;
  const handleItemClick = (attachment) => {
    uploadAttachment(attachment);
  };

  return (
    <div tabIndex={10} id={id} className="form-runner-upload-focus">
      <>
        {value && document ? (
          <FilePreview file={file} document={document} value={value} onRemoveFile={handleRemoveFile} />
        ) : (
          <Button
            type="dashed"
            onClick={showModal}
            className="upload-button"
            disabled={submitting}
            icon={<PlusOutlined className="form-upload-icon" />}
          />
        )}
        <Modal title={t("selectDocuments")} visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <List
            locale={{ emptyText: t("noData") }}
            dataSource={attachments}
            renderItem={(attachment) => (
              <List.Item
                style={{ flexWrap: "initial" }}
                actions={[
                  <Button
                    key="add"
                    icon={<UploadOutlined />}
                    loading={submitting}
                    disabled={submitting}
                    onClick={() => handleItemClick(attachment)}
                  ></Button>,
                ]}
              >
                <div
                  className={`ant-list-item-text ${
                    isAttachmentUploaded(attachment) ? "file-uploaded" : "file-not-uploaded"
                  }`}
                >
                  {attachment.name}
                </div>
              </List.Item>
            )}
          />
          <>
            <Upload
              beforeUpload={beforeUpload}
              disabled={submitting}
              customRequest={customRequest}
              listType="picture-card"
              showUploadList={false}
              className={classNames(submitFailed ? "form-runner-upload" : false)}
              accept={acceptedFiletypes}
              {...props}
              onChange={handleOnChange}
            >
              {t("uploadFromLocal")}
            </Upload>
            {parseFloat(maxSize) > 0 && `${"Maximum size per file"}: ${maxSize} MB`}
          </>
        </Modal>
      </>
    </div>
  );
};
