import { Book } from 'react-feather'
import React, { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const BookIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Book {...props} />
    </div>
  )
}

export default BookIcon