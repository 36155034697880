import { isPlainObject, isString } from 'lodash'

export const isEmptyOrWhitespace = str => !isString(str) || !Boolean(str) || str.match(/^ *$/) !== null

export const dateFormat = 'LL'

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const getValue = value => {
  if (isPlainObject(value) && value.type) {
    switch (value.type) {
      case 'entity':
      case 'member':
        if (value.value && !value.value.entityId && !value.value.categoryId) {
          return null
        }
        return value
      default:
        return value.value
    }
  }
  return value
}
