import React from "react";
import classNames from "classnames";

type DividerPropsType = {
  className?: string;
  label: string;
};

export const Divider: React.FC<DividerPropsType> = ({ className, label }) => (
  <>
    <div className={classNames("form-divider", className)}>{label}</div>
    <br />
  </>
);
