import React, { useEffect } from "react";
import { useMixpanel } from "../useMixpanel";
import { useMutation } from "@apollo/client";

// import { Alert, Divider, Typography } from 'antd'
import SignInPage from "./SignInPage";
import { useRouteQuery } from "../useRouteQuery";
// import arrow from '../../assets/arrow.svg'
import logoBlack from "../../assets/svg/logoBackBlack.svg";
import { signinUserMutation } from "./graphql/mutations";
import { useTranslation } from "react-i18next";

const SignIn = ({ onSubmit, loginError, setLoginError }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {isRecoverOpen && children} */}
      <>
        <header className="alf-login-form-title">{t("welcomeBack")}</header>
        <SignInPage
          // processInstanceId={processInstanceId}
          // setLoginState={setLoginState}
          onSubmit={onSubmit}
          // setForgotState={setForgotState}
          // forgotState={forgotState}
          loginError={loginError}
          setLoginError={setLoginError}
          // setEmail={setEmail}
        />
      </>
    </>
  );
};

const Login = ({ updateClient }) => {
  const [loginError, setLoginError] = React.useState(false);
  const { url } = useRouteQuery();

  const { identify } = useMixpanel();
  const [signin] = useMutation(signinUserMutation);
  const signinSucces = React.useCallback(
    ({ user, loginMethod }) => {
      identify(user.id);
      localStorage.setItem("loginMethod", loginMethod);
      window.location.href = `/#/`;
    },
    [identify]
  );

  const handleClientUpdate = (newSlug, email, password) => {
    localStorage.setItem("clientUpdated", "true");
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    updateClient(newSlug, email, password);
  };

  const handleSubmit = React.useCallback(
    async ({ email, password, slug }) => {
      try {
        await handleClientUpdate(slug, email, password);
      } catch (e) {
        setLoginError(true);
      }
    },
    [signin, url, signinSucces]
  );

  useEffect(() => {
    if (localStorage.getItem("clientUpdated") === "true") {
      const func = async () => {
        try {
          const {
            data: { signinUser: { user, redirectURL } = { user: "", redirectURL: "" } },
          } = await signin({
            variables: { input: { email: localStorage.getItem("email"), password: localStorage.getItem("password") } },
          });
          signinSucces({ loginMethod: "email", url: redirectURL || url, user });
          localStorage.removeItem("clientUpdated");
          localStorage.removeItem("password");
          localStorage.removeItem("email");
        } catch (err) {
          console.error("there is an error while sign in:", err);
        }
      };
      func();
    }
  }, [localStorage.getItem("clientUpdated")]);

  const renderLoginComponents = () => {
    return <SignIn onSubmit={handleSubmit} setLoginError={setLoginError} loginError={loginError} />;
  };

  return (
    <div className="login-registration-container">
      <div className="login-registration-menu-container">
        <div className="alf-login-logo">
          <img src={logoBlack} alt="background" />
        </div>
        <div className="alf-login-container">{renderLoginComponents()}</div>
      </div>
    </div>
  );
};

export default Login;
