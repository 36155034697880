import React from 'react';
import { useQuery, DocumentNode, QueryHookOptions, OperationVariables } from '@apollo/client';

export const useImperativeQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
) => {
  const props = useQuery<TData, TVariables>(query, { ...options, skip: true });

  const { refetch } = props;

  const get = React.useCallback(
    (variables: TVariables) => refetch(variables),
    [refetch]
  );

  return [get, props] as const; // Using 'as const' for a readonly tuple type
};

export default useImperativeQuery;