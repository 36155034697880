import { isPlainObject } from "lodash";
import { computeStringWithTemplate, isURL, useTemplate } from "./StringTemplate";
import { isMultilangObject } from "./document";
import { multilang } from "./multiLang";

export const computeFileName = (fileName, data, language, extensionProps) => {
  let extension = extensionProps;
  if (isURL(fileName)) {
    return fileName;
  }
  if (!extension) {
    const extensionIndex = multilang(fileName, "EN").lastIndexOf(".") + 1;
    extension = extensionIndex > 0 ? multilang(fileName, "EN").slice(extensionIndex) : "";
  }
  const stringComputed = resolveVarsIfExist(fileName, data, language);
  return stringComputed.concat(extension ? "." + extension : "");
};

export const computeStringOrMultilang = (multilangJson, data, multilingual) => {
  if (!multilangJson) {
    return "";
  }
  if (multilingual) {
    return computeMultilang(multilangJson, data);
  } else {
    return computeString(multilang(multilangJson), data);
  }
};

export const computeMultilang = (multilangProps, data, extension = "") => {
  const multiLangObj = isMultilangObject(multilangProps) ? multilangProps : { EN: multilangProps };
  return Object.keys(multiLangObj).reduce(
    (acc, key) =>
      multiLangObj[key]
        ? {
            ...acc,
            [key.toLocaleUpperCase()]: extension
              ? computeFileName(multiLangObj[key], data, key.toLocaleLowerCase(), extension)
              : resolveVarsIfExist(multiLangObj[key], data, key.toLocaleLowerCase()),
          }
        : acc,
    {}
  );
};

export const computeString = (str, data, language = null) => {
  const createScopedData = (data) => {
    const scopedObject = Object.keys(data).reduce((acc, prop) => {
      if (data[prop] && data[prop].toStr) {
        return {
          ...acc,
          [prop]: data[prop].toStr(data[prop].value, { locale: language }),
        };
      }
      if (isPlainObject(data[prop])) {
        if (data[prop].value && data[prop].type) {
          return {
            ...acc,
            [prop]: data[prop].value,
          };
        } else {
          return { ...acc, [prop]: createScopedData(data[prop]) };
        }
      } else {
        return {
          ...acc,
          [prop]: data[prop],
        };
      }
    }, {});

    return new Proxy(scopedObject, {
      get: (target, prop) => (prop in target ? (target[prop] && target[prop][language]) || target[prop] : ""),
      has: () => true,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const scopedData = createScopedData(data);

  try {
    const literalString = str.startsWith("`") && str.endsWith("`") ? str : "`" + str + "`";
    return eval(`with (scopedData) {${literalString}}`);
  } catch (e) {
    return undefined;
  }
};

export const resolveVarsIfExist = (str, data, language) =>
  useTemplate(str) ? computeStringWithTemplate(str, data, language) : computeString(str, data, language);
