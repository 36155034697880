import React, { FC } from "react";
import { Checkbox, CheckboxProps } from "antd";

interface CheckBoxViewProps extends Omit<CheckboxProps, "id"> {
  id: string;
  processInstance: any;
  stepId: any;
}

export const CheckBoxView: FC<CheckBoxViewProps> = ({ id, ...props }) => <Checkbox id={id} {...props} />;
