import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 50.344 50.344" {...props}>
    <g>
      <path
        d="M19.6,21.844H3.4c-1.875,0-3.4,1.525-3.4,3.4v16.199c0,1.875,1.525,3.4,3.4,3.4H19.6c1.875,0,3.4-1.525,3.4-3.4V25.245
		C23,23.37,21.475,21.844,19.6,21.844z M21,41.444c0,0.772-0.628,1.4-1.4,1.4H3.4c-0.772,0-1.4-0.628-1.4-1.4V25.245
		c0-0.772,0.628-1.4,1.4-1.4H19.6c0.772,0,1.4,0.628,1.4,1.4V41.444z"
      />
      <circle cx="6" cy="27.844" r="2" />
      <circle cx="17" cy="27.844" r="2" />
      <circle cx="6" cy="38.844" r="2" />
      <circle cx="17" cy="38.844" r="2" />
      <path
        d="M50.155,23.081L44.828,7.782c-0.618-1.77-2.554-2.709-4.329-2.093l-15.3,5.327c-1.771,0.618-2.709,2.561-2.092,4.329
		l5.327,15.3c0.299,0.857,0.913,1.547,1.73,1.942c0.469,0.227,0.972,0.341,1.478,0.341c0.377,0,0.755-0.063,1.121-0.191
		l15.299-5.327C49.832,26.794,50.771,24.852,50.155,23.081z M47.405,25.521l-15.3,5.327c-0.354,0.123-0.733,0.103-1.069-0.062
		c-0.337-0.163-0.59-0.446-0.713-0.8l-5.327-15.3c-0.254-0.729,0.133-1.528,0.861-1.782l15.3-5.327
		C41.309,7.525,41.463,7.5,41.615,7.5c0.58,0,1.123,0.363,1.324,0.94l5.327,15.298C48.521,24.467,48.134,25.267,47.405,25.521z"
      />
      <circle cx="29.628" cy="15.828" r="2" />
      <circle cx="40.016" cy="12.211" r="2" />
      <circle cx="33.246" cy="26.216" r="2" />
      <circle cx="11.5" cy="33.678" r="2" />
      <circle cx="43.634" cy="22.599" r="2" />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export default SVG
