import React, { FC } from 'react';
import { Input } from 'antd';

interface TextAreaViewProps {
  id: string;
  type: string;
  stepId: string;
  processInstance: any;
  autoSize?: boolean | { minRows?: number, maxRows?: number };
  minRows?: number;
  maxRows?: number;
}

export const TextAreaView: FC<TextAreaViewProps> = ({
  id,
  type,
  stepId,
  processInstance,
  autoSize: autoSizeProps,
  minRows,
  maxRows,
  ...props
}) => {
  const autoSize =
    minRows && maxRows ? { minRows, maxRows } : minRows ? { minRows } : maxRows ? { maxRows } : autoSizeProps
  return <Input.TextArea id={id} {...props} autoSize={autoSize} />
}