import { handleOptions } from "../data-model/shared/options";
import i18n from "i18next";

const removeFields = (data, fields) => {
  let index = 0;
  const recursiveRemoval = (data) =>
    Object.keys(data).reduce((acc, val) => {
      if (val === fields[index]) {
        index++;
        if (index === fields.length) {
          return { ...acc, [val]: undefined };
        } else {
          return { ...acc, [val]: recursiveRemoval(acc[val]) };
        }
      } else {
        return { ...acc };
      }
    }, data);
  return recursiveRemoval(data);
};

export const removeFieldsForm = (fields, form) => {
  fields.forEach((field) => form.setFieldsValue(removeFields(form.getFieldsValue(true), field)));
};

export const findFieldTypeInFrame = (name, { fields = [] } = {}) => {
  const [, field = name, subField] = /(\w+)\[\d+]\.(.+)/.exec(name) || [];
  const current = fields.find(({ name } = {}) => name === field);
  return Boolean(subField) ? findFieldTypeInFrame(subField, current) : current;
};

export const updateFieldForm = (
  { field, form, language },
  { type, options: optionsProp },
  { dataOptions, serialize, fake },
  valueProps
) => {
  const options = handleOptions(optionsProp, { dataOptions }, language);

  const value =
    valueProps !== undefined ? valueProps : fake(options.length > 0 ? options.map((a) => a.value) : undefined);

  form.change(field, serialize({ ...dataOptions, language: i18n.language, options: options || [], type })(value));
};
