import React, { FC, useCallback } from 'react';
import { Button, Form, Input } from 'antd';

interface ValidationViewProps {
  id: string;
  type: string;
  validateStatus?: string;
  stepId: string;
  processInstance: any;
  loading: boolean;
  meta: { error: boolean };
  handleClick?: (value: string) => void;
  buttonMsg?: string;
  errorMsg?: string;
  value?: string;
}

export const ValidationView: FC<ValidationViewProps> = ({
  id,
  type,
  validateStatus,
  stepId,
  processInstance,
  loading,
  meta,
  handleClick: handleClickProps,
  buttonMsg = 'Import',
  errorMsg,
  ...props
}) => {
  const handleClick = useCallback(() => {
    if (handleClickProps) {
      handleClickProps(props.value || '');
    }
  }, [handleClickProps, props.value]);

  return (
    <>
      <Form.Item>
        <Input id={id} {...props} />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} disabled={meta.error} onClick={handleClick} type="primary">
          {buttonMsg}
        </Button>
      </Form.Item>
      {Boolean(errorMsg) && errorMsg}
    </>
  )
}