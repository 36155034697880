import { isNil } from 'lodash'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useField, FieldRenderProps } from 'react-final-form'
import pick from 'lodash/pick'
import i18n from 'i18next'

interface SerializeInput {
  type: string;
  entityTypes: any;
  prefixField?: string;
}

interface EntityValue {
  entityId?: any;
  typeName: any;
  categoryId: any;
}

// The type for the serialize function which is a higher-order function
type SerializeFunction = (input: SerializeInput) => (value: EntityValue) => {
  entityTypes: any; // Replace 'any' with the specific type for entityTypes
  prefixField: string;
  type: string;
  value: EntityValue;
  asyncData: string;
};

interface DeserializeInput {
  value?: {
    entityId?: any; // Specify the correct type instead of any if possible
    typeName?: any; // Specify the correct type instead of any if possible
    categoryId?: any; // Specify the correct type instead of any if possible
  };
}

// The type for the deserialize function
type DeserializeFunction = (input: DeserializeInput) => {
  entityId?: any; // Specify the correct type instead of any if possible
  typeName?: any; // Specify the correct type instead of any if possible
  categoryId?: any; // Specify the correct type instead of any if possible
};

type ValidateFunction = (value?: EntityValue) => null | 'requiredField';

type ValidateFunctionCreator = () => ValidateFunction;

export type FieldProps = {
  defaultValue?: any,
  definition?: {
    fieldOptions?: any,
    serialize?: SerializeFunction | null,
    deserialize?: DeserializeFunction | null,
    validate?: ValidateFunctionCreator | null,
    dataOptions?: any,
  },
  name?: string,
  type?: string,
  options?: any,
  value?: any,
  required?: boolean,
  initialOptions?: any,
}

export const useBindingField = ({
  defaultValue: defaultValueProps,
  definition,
  name,
  type,
  options,
  value,
  required,
  initialOptions,
  ...props
}: FieldProps): FieldRenderProps<any, HTMLElement> => {
  const { fieldOptions: fieldOptionsProps, serialize = null, deserialize = null, validate: validateProps = null, dataOptions } = definition;
  const [{ defaultValue: defaultValueOptions, ...fieldOptions }] = useState(fieldOptionsProps || { defaultValue: ""})

  const [dataOptionsFromForm] = useState(pick(props, ['currency', 'min', 'entityTypes', 'prefixField']))

  const parse = useCallback(
    input =>
    serialize({
      ...dataOptions,
      ...dataOptionsFromForm,
      language: i18n.language,
      options: initialOptions || options || [],
      type,
    })(input),
    [dataOptions, dataOptionsFromForm, initialOptions, options, serialize, type]
    )
    
  const defaultValueRaw = useMemo(
    () => (defaultValueProps === '' || isNil(defaultValueProps) ? defaultValueOptions : defaultValueProps),
    [defaultValueOptions, defaultValueProps]
  )

  const defaultValue = useMemo(
    () => (value !== undefined ? value : parse(defaultValueRaw)),
    [defaultValueRaw, parse, value]
  )


  const validate = useCallback(
    (...params) => (required !== false ? validateProps()(...params) : null),
    [options, required, validateProps]
  )

  const fieldRenderProps = useField(name, {
    ...fieldOptions,
    defaultValue,
    format: deserialize,
    parse,
    // validate,
  })

  useEffect(() => {
    if (!fieldRenderProps.meta?.modified && fieldRenderProps.meta?.dirtySinceLastSubmit) {
      fieldRenderProps.input?.onChange(defaultValueRaw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return fieldRenderProps
}