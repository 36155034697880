import React from 'react'
import { FormField } from './FormField'
import { FormFieldConditionalContent } from './FormFieldConditionalContent'
import { TABS } from '../../types'

type FormFieldCheckConditionalPropType = {
    condition?: string[],
    initialValue?: any,
    name?: string,
    processInstance?: any,
    prefix?: string,
    language?: string,
    frame?: any,
    tabs: TABS
}

export const FormFieldCheckConditional:React.FC<FormFieldCheckConditionalPropType> = React.memo(({ condition, ...props }) =>
  Array.isArray(condition) && condition.length > 0 && props.tabs === TABS.INFORMATION ? (
    <FormFieldConditionalContent condition={condition} {...props} />
  ) : (
    <FormField {...props} />
  )
)
