import { faker } from '@faker-js/faker'
import { v4 as uuid } from 'uuid'

type UuidValue = {
  value?: string;
  type?: string;
  visible?: boolean;
}

const _uuid = {
  serialize: ({ type, visible }: { type: string; visible?: boolean }) =>
    (value: string): UuidValue => ({ value: Boolean(value) ? value : uuid(), type, visible }),
  
  deserialize: ({ value }: { value: string } = { value: null }) => value,
  
  validate: () => ({ value }: { value: string } = { value: null }) => Boolean(value) ? null : 'requiredField',
  
  fake: faker.datatype.uuid,
}

export { _uuid as uuid }