import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="8 8 20 20" {...props}>
    <g>
      <path
        className="st1"
        d="M13.356,9.013c1.147-0.527,2.489-0.794,3.988-0.794c1.489,0,2.831,0.267,3.99,0.795
		c0.056,0.025,0.115,0.037,0.172,0.037c0.158,0,0.31-0.09,0.379-0.244c0.095-0.209,0.003-0.456-0.206-0.551
		c-1.267-0.577-2.725-0.87-4.335-0.87c-1.621,0-3.079,0.293-4.336,0.871c-0.208,0.096-0.3,0.343-0.204,0.552
		C12.899,9.018,13.147,9.109,13.356,9.013z"
      />
      <path
        className="st1"
        d="M11.517,12.381c0.107,0,0.215-0.042,0.296-0.123c1.534-1.553,3.445-2.374,5.529-2.374
		c2.091,0,4.005,0.82,5.532,2.372c0.161,0.163,0.425,0.166,0.589,0.005c0.164-0.161,0.166-0.425,0.005-0.589
		c-1.687-1.715-3.806-2.621-6.126-2.621c-2.311,0-4.428,0.907-6.121,2.621c-0.161,0.164-0.16,0.428,0.003,0.589
		C11.306,12.341,11.412,12.381,11.517,12.381z"
      />
      <path
        className="st1"
        d="M17.342,10.716c-0.594,0-1.184,0.073-1.753,0.219c-0.223,0.057-0.357,0.283-0.3,0.505
		c0.057,0.224,0.282,0.358,0.507,0.301c0.501-0.127,1.022-0.192,1.547-0.192c3.443,0,6.243,2.8,6.243,6.243
		c0,0.23,0.186,0.416,0.416,0.416c0.23,0,0.416-0.187,0.416-0.416C24.418,13.89,21.244,10.716,17.342,10.716z"
      />
      <path
        className="st1"
        d="M11.101,17.799c0.027-1.681,0.671-3.244,1.816-4.4c0.389-0.394,0.824-0.733,1.294-1.007
		c0.198-0.117,0.265-0.372,0.149-0.57c-0.117-0.199-0.372-0.265-0.569-0.149c-0.533,0.312-1.026,0.696-1.465,1.141
		c-1.296,1.31-2.026,3.076-2.056,4.973c-0.004,0.231,0.18,0.419,0.41,0.423c0.002,0,0.005,0,0.007,0
		C10.912,18.208,11.097,18.026,11.101,17.799z"
      />
      <path
        className="st1"
        d="M12.765,17.894c0-1.274,0.475-2.455,1.335-3.326c0.864-0.874,2.015-1.355,3.242-1.355
		c1.321,0,2.58,0.573,3.451,1.573c0.152,0.173,0.415,0.19,0.588,0.04c0.173-0.151,0.191-0.414,0.04-0.588
		c-1.03-1.181-2.516-1.858-4.079-1.858c-1.452,0-2.814,0.569-3.834,1.603c-1.016,1.028-1.576,2.417-1.576,3.933
		c0.003,0.895-0.277,1.969-0.778,3.001l-0.234,0.416c-0.112,0.201-0.042,0.454,0.159,0.567c0.065,0.036,0.135,0.053,0.204,0.053
		c0.146,0,0.286-0.077,0.363-0.212l0.246-0.438C12.466,20.12,12.768,18.948,12.765,17.894z"
      />
      <path
        className="st1"
        d="M23.058,21.054c-0.264-1.204-0.286-2.354-0.3-3.041l-0.005-0.221c0-0.617-0.104-1.224-0.309-1.801
		c-0.076-0.216-0.314-0.33-0.531-0.254c-0.216,0.077-0.33,0.315-0.253,0.532c0.173,0.489,0.261,1.001,0.261,1.534l0.005,0.226
		c0.014,0.718,0.038,1.92,0.328,3.236l0.476,1.568c0.055,0.179,0.221,0.295,0.399,0.295c0.04,0,0.081-0.006,0.122-0.018
		c0.22-0.067,0.344-0.299,0.277-0.519L23.058,21.054z"
      />
      <path
        className="st1"
        d="M21.926,23.274c-0.686-2.143-0.814-4.555-0.837-5.482c0-2.065-1.681-3.746-3.746-3.746
		s-3.745,1.706-3.745,3.818c0.001,0.438-0.08,2.752-1.837,5.287c-0.132,0.189-0.084,0.449,0.105,0.579
		c0.187,0.129,0.448,0.083,0.579-0.105c1.852-2.671,1.988-5.088,1.985-5.776c0-1.638,1.306-2.972,2.912-2.972
		c1.607,0,2.914,1.308,2.914,2.924c0.03,1.209,0.184,3.56,0.877,5.721l0.385,1.235c0.056,0.179,0.221,0.293,0.398,0.293
		c0.042,0,0.082-0.006,0.124-0.019c0.22-0.068,0.342-0.301,0.274-0.521L21.926,23.274z"
      />
      <path
        className="st1"
        d="M19.423,17.79c0-1.148-0.933-2.081-2.081-2.081s-2.081,0.934-2.081,2.097c0.005,0.138,0.097,3.408-2.547,6.849
		c-0.14,0.182-0.106,0.444,0.077,0.584c0.183,0.14,0.444,0.106,0.584-0.077c2.825-3.676,2.723-7.237,2.718-7.371
		c0-0.688,0.56-1.249,1.249-1.249c0.688,0,1.25,0.56,1.25,1.259c0.011,0.466,0.014,4.672-3.236,8.894
		c-0.14,0.182-0.107,0.443,0.076,0.584c0.077,0.058,0.166,0.087,0.254,0.087c0.125,0,0.249-0.056,0.33-0.164
		C19.445,22.746,19.436,18.275,19.423,17.79z"
      />
      <path
        className="st1"
        d="M17.139,19.866c0.022,0.003,0.045,0.005,0.067,0.005c0.201,0,0.378-0.146,0.411-0.35
		c0.17-1.057,0.141-1.721,0.141-1.748c-0.01-0.231-0.197-0.39-0.434-0.397c-0.23,0.01-0.408,0.205-0.398,0.434
		c0,0.006,0.023,0.614-0.132,1.579C16.758,19.616,16.913,19.83,17.139,19.866z"
      />
      <path
        className="st1"
        d="M16.94,20.735c-0.22-0.062-0.452,0.061-0.518,0.281c-0.521,1.758-1.386,3.402-2.571,4.885
		c-0.143,0.18-0.114,0.441,0.066,0.585c0.077,0.061,0.169,0.091,0.26,0.091c0.122,0,0.243-0.054,0.325-0.156
		c1.253-1.568,2.168-3.307,2.72-5.169C17.286,21.031,17.161,20.8,16.94,20.735z"
      />
      <path
        className="st1"
        d="M20.472,24.128c-0.2-0.597-0.386-1.229-0.387-1.231c-0.05-0.169-0.2-0.288-0.375-0.298
		c-0.184-0.02-0.339,0.092-0.407,0.253c-0.009,0.022-0.937,2.201-2,3.874c-0.123,0.194-0.066,0.452,0.128,0.574
		c0.069,0.044,0.146,0.065,0.223,0.065c0.137,0,0.272-0.068,0.35-0.191c0.644-1.014,1.228-2.178,1.609-2.989
		c0.023,0.067,0.044,0.134,0.067,0.2l0.499,1.588c0.068,0.219,0.301,0.343,0.522,0.273c0.219-0.069,0.341-0.303,0.272-0.522
		L20.472,24.128z"
      />
    </g>
  </svg>
)

export default SVG
