import React from 'react'

const SVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="50 35 75 100"
    width={100}
    height={100}
    {...props}
  >
    <defs>
      <clipPath id="enrya9d2t1qq2">
        <path id="enrya9d2t1qq3" d="m0,129.603l130.964,0l0,-129.603l-130.964,0l0,129.603z" fill="rgb(0,0,0)" />
      </clipPath>
    </defs>
    <g className="layer">
      <title>Layer 1</title>
      <g id="enrya9d2t1qq5" transform="matrix(1.33333,0,0,-1.33333,53.647999213,114.095213439) ">
        <path id="enrya9d2t1qq6" d="m0,0l17.886,-11.032l0,33.039l-17.886,7.642l0,-29.649z" fill="rgb(33,67,149)" />
      </g>
      <g id="enrya9d2t1qq7" transform="matrix(1.33333,0,0,-1.33333,77.49567292699999,128.80477666500002) ">
        <path
          id="enrya9d2t1qq8"
          d="m0,0l32.51,3.849l0,31.117l-32.51,-1.927l0,-33.039z"
          transform="matrix(1 0 0 1 0 -0.02599131497829)"
          fill="rgb(28,49,108)"
        />
      </g>
      <g id="enrya9d2t1qq9" transform="matrix(1.33333,0,0,-1.33333,77.49567292699999,84.752753462) ">
        <path
          id="enrya9d2t1qq10"
          d="m0,0l32.51,1.927l-17.886,7.643l-32.51,-1.927l17.886,-7.643z"
          fill="rgb(128,214,247)"
        />
      </g>
      <g id="enrya9d2t1qq11" transform="matrix(1.33333,0,0,-1.33333,77.49567292699999,74.86611151200002) ">
        <g id="enrya9d2t1qq12_to" transform="translate(0,0)">
          <path
            id="enrya9d2t1qq12"
            d="m0,0l32.51,1.928l-17.886,7.643l-32.51,-1.927l17.886,-7.644z"
            transform="translate(0,0)"
            fill="rgb(26,181,241)"
          />
        </g>
      </g>
      <g id="enrya9d2t1qq13" transform="matrix(1.33333,0,0,-1.33333,77.49567292699999,63.320540376000025) ">
        <g id="enrya9d2t1qq14_to" transform="translate(0,0)">
          <path
            id="enrya9d2t1qq14"
            d="m0,0l32.51,1.928l-17.886,7.642l-32.51,-1.926l17.886,-7.644z"
            transform="translate(0,0)"
            fill="rgb(14,136,211)"
          />
        </g>
      </g>
      <g id="enrya9d2t1qq15" transform="matrix(1.33333,0,0,-1.33333,77.49567292699999,51.774969240000004) ">
        <g id="enrya9d2t1qq16_to" transform="translate(0,0)">
          <path
            id="enrya9d2t1qq16"
            d="m0,0l32.51,1.928l-17.886,7.642l-32.51,-1.927l17.886,-7.643z"
            transform="translate(0,0)"
            fill="rgb(33,67,149)"
          />
        </g>
      </g>
      <g id="enrya9d2t1qq17" transform="matrix(1.33333,0,0,-1.33333,0,172.804) ">
        <g id="enrya9d2t1qq18" clipPath="url(#enrya9d2t1qq2)">
          <g
            id="enrya9d2t1qq19"
            transform="rotate(180 74.19902038574219,43.44516754150391) matrix(1,0,0,1,83.7576,47.8569) "
          >
            <path
              id="enrya9d2t1qq20"
              d="m-1.10124,-2.40271c-0.198,0.227 -0.474,0.362 -0.773,0.382c-0.315,0.03 -0.589,-0.078 -0.814,-0.277l-0.003,-0.001c-0.007,-0.006 -0.688,-0.559 -2.046,-1.123c-1.445,-0.601 -3.077,-0.973 -4.849,-1.105c-1.518,-0.107 -3.103,0.079 -4.703,0.557c-1.425,0.427 -2.31,0.922 -2.318,0.927c-0.177,0.102 -0.369,0.15 -0.559,0.15c-0.39,0 -0.768,-0.2 -0.976,-0.56c-0.151,-0.26 -0.191,-0.562 -0.114,-0.853c0.077,-0.29 0.262,-0.534 0.522,-0.684c0.128,-0.074 3.158,-1.818 7.27,-1.818c0.353,0 0.705,0.012 1.046,0.038c5.42,0.401 8.1,2.682 8.212,2.78c0.225,0.197 0.361,0.471 0.381,0.771c0.02,0.299 -0.078,0.589 -0.276,0.816"
              fill="rgb(255,255,255)"
            />
          </g>
          <g id="enrya9d2t1qq21" transform="matrix(1,0,0,1,74.3689,55.9575) ">
            <path
              id="enrya9d2t1qq22"
              d="m-2.76276,0.33072c-0.03724,0.63511 -0.46035,1.12357 -0.95708,1.12357c-0.02031,0 -0.04231,-0.00107 -0.06347,-0.00323l-5.24658,-0.49493c-0.52297,-0.0496 -0.91816,-0.63295 -0.87923,-1.29933c0.03639,-0.62972 0.45358,-1.12357 0.94862,-1.12357c0.02285,0 0.04654,0.00108 0.07193,0.00323l5.24658,0.49494c0.52297,0.0496 0.91815,0.63295 0.87923,1.29932"
              transform="matrix(1,0,0,1,0.00487300968253,-0.29289999474998) "
              fill="rgb(255,255,255)"
            />
          </g>
          <g id="enrya9d2t1qq23" transform="matrix(1,0,0,1,82.0457,55.1626) ">
            <path
              id="enrya9d2t1qq24"
              d="m0,0c0.852,0 1.544,0.692 1.544,1.544c0,0.852 -0.692,1.544 -1.544,1.544c-0.852,0 -1.544,-0.692 -1.544,-1.544c0,-0.852 0.692,-1.544 1.544,-1.544"
              fill="rgb(255,255,255)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SVG
