import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import definitions from '../definitions'
import { getModel } from '../getModel'

interface TypedField {
  type: any;
  primaryType: any;
  value: any;
}

interface CleanDataOptions {
  keepToStrMethod?: boolean;
  translateToLocale?: any;
  enumerations?: any[];
}

export const fieldIsTyped = (obj: TypedField, checkValueIsDefined = true): boolean =>
  obj &&
  Boolean(obj.type) &&
  isString(obj.type) &&
  Boolean(definitions[obj.primaryType || obj.type]) &&
  (checkValueIsDefined ? obj.value !== undefined : true)

export const isTyped = (data: { [x: string]: any }): boolean =>
  Object.keys(data || {}).some(key =>
    Array.isArray(data[key]) ? data[key].some(el => isTyped(el)) : fieldIsTyped(data[key])
  )

const removeType =
  (options: CleanDataOptions) =>
  (value: any): any => {
    const { keepToStrMethod = false, translateToLocale = false, enumerations } = options
    if (fieldIsTyped(value, false)) {
      if (value.value === undefined) {
        return undefined
      }

      const model = keepToStrMethod || translateToLocale ? getModel(value) : null

      const valueValue =
        translateToLocale && model.toStr ? model.toStr(value, { locale: translateToLocale }) : value.value

      const res = !keepToStrMethod || isPlainObject(valueValue) ? valueValue : new String(valueValue)

      const enumerationOptions = ((enumerations || []).find(({ name }) => name === value.type) || {}).options
      if (keepToStrMethod) {
        if (model && model.toStr) {
          Object.defineProperty(res, 'toStr', {
            value: options => model.toStr({ ...value, options: enumerationOptions || value.options }, options),
          })
        } else {
          Object.defineProperty(res, 'toStr', { value: () => value.value })
        }

        if (value.visible === false) {
          Object.defineProperty(res, 'visible', { value: false })
        }
      }

      return res
    } else if (isPlainObject(value)) {
      return Object.keys(value).reduce((acc, key) => ({ ...acc, [key]: cleanData(value[key], options) }), {})
    } else if (Array.isArray(value)) {
      return value.map((el: any) => cleanData(el, options))
    }
    return value
  }

export const cleanData = (data: any = {}, options: CleanDataOptions = {}): any => {
  const copy = JSON.parse(JSON.stringify(data))
  return removeType(options)(copy)
}