import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" {...props}>
    <path d="M17.29,0V76.37c0,14.41-6.1,23-17.39,23H82.61c11.29,0,17.39-8.57,17.39-23V0ZM96,76.37c0,8.66-2.32,19-13.39,19H16.25c3.27-4.59,5-11,5-19V4H96Z" />
    <rect x="62.45" y="15.68" width="22.65" height="4" />
    <rect x="62.45" y="28.61" width="22.65" height="4" />
    <rect x="62.45" y="41.55" width="22.65" height="4" />
    <rect x="31.45" y="54.49" width="53.65" height="4" />
    <rect x="31.45" y="67.43" width="53.65" height="4" />
    <rect x="31.45" y="80.36" width="53.65" height="4" />
    <path d="M44,44.68a14.5,14.5,0,1,0-14.5-14.5A14.52,14.52,0,0,0,44,44.68Zm0-25a10.5,10.5,0,1,1-10.5,10.5A10.51,10.51,0,0,1,44,19.68Z" />
  </svg>
)

export default SVG
