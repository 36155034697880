import React, { useState, useEffect, useCallback } from 'react'
import { Modal } from 'antd'

interface ModalOptions {
  content?: React.ReactNode;
  visible?: boolean;
  type?: 'info' | 'success' | 'error' | 'warning' | 'confirm';
  [key: string]: any;
}

export const useModal = ({ content: initialContent = undefined, visible = false, type = 'info', ...props }: ModalOptions = {}) => {
  const [modalParams] = useState({ visible, ...props })

  useEffect(() => checkType(type), [])

  const checkType = (type: string) => {
    if (!['info', 'success', 'error', 'warning', 'confirm'].includes(type)) {
      throw new Error(`Type '${type}' is not handle by ant design modal`)
    }
  }

  const displayModal = useCallback(
    ({ type: newType, ...params }: ModalOptions) => {
      if (Boolean(Object.keys(props))) {
        checkType(newType)
        Modal[newType](params)
      } else {
        Modal[type]({ ...modalParams, visible: true })
      }
    },
    [modalParams, props, type]
  )

  return { displayModal }
}