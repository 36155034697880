import React, { CSSProperties } from 'react'
import classnames from 'classnames'

interface TagProps {
  type?: 'primary' | 'warning' | 'success' | 'draft' | 'error'
  className?: string
  style?: CSSProperties
}

export const Tag: React.FC<TagProps> = ({ type, className, style, children, ...props }) => {
  const classes = classnames('tag', `tag--${type}`, className)

  return (
    <span className={classes} style={style} {...props}>
      {children}
    </span>
  )
}

export default Tag
