import React, { useState, useCallback } from "react";
import { message, Upload, Modal, Button, List, Radio, Row, Space, Typography } from "antd";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Icon } from "../Icon";
import { FilePreview } from "../components/FilePreview";

interface IdCheckViewProps {
  beforeUpload: Function;
  submitting: boolean;
  customRequest?: any;
  onTypeChange: Function;
  submitFailed: boolean;
  loading: boolean;
  value: any;
  onChange: (value: any) => void;
  translation: any;
  id: string;
  processInstance: any;
  stepId: any;
  document: any;
  maxSize: string;
  filename?: string;
  acceptedFiletypes?: string;
  companyName?: string;
}

export const IdCheckView: React.FC<IdCheckViewProps> = ({
  beforeUpload: beforeUploadProps,
  submitting,
  customRequest,
  onTypeChange,
  submitFailed,
  loading: loadingProps = false,
  value = {},
  onChange,
  translation,
  id,
  processInstance,
  stepId,
  document = {},
  maxSize = "",
  acceptedFiletypes,
  ...props
}) => {
  const { t } = translation;
  console.log("value type:", value);
  console.log("step id:", stepId);
  const [oldValue, setOldValue] = useState<any>({});
  const [fileFront, setFileFront] = useState<any>();
  const [fileBack, setFileBack] = useState<any>();

  const [attachments, setAttachments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();
  const [side, setSide] = useState<string>("front");
  console.log("processInstance:", processInstance);
  console.log("id:", id);

  const beforeUpload = useCallback(
    (file: any, fileList: any[]) => {
      const maxSizeFloat = parseFloat(maxSize);
      if (maxSizeFloat > 0 && parseFloat((file.size / 1024 / 1024).toFixed(4)) > maxSizeFloat) {
        message.error(`File size exceed the limit: ${maxSizeFloat} mb`);
        return false;
      }
      return beforeUploadProps ? beforeUploadProps(file, fileList) : true;
    },
    [beforeUploadProps, maxSize]
  );

  const onFrontChange = (evt: any) => {
    const {
      file: { response },
    } = evt;
    changeValue("front")(response);
    setFileFront(evt.file?.originFileObj);
  };

  const onBackChange = (evt: any) => {
    const {
      file: { response },
    } = evt;
    changeValue("back")(response);
    setFileBack(evt.file?.originFileObj);
  };

  const changeValue = useCallback(
    (varName: string) => (newVal: any) => {
      let intermediateValue;
      if (varName === "type") {
        intermediateValue = oldValue;
        setOldValue(value);
      } else {
        intermediateValue = value;
      }
      onChange({ ...intermediateValue, [varName]: newVal });
    },
    [oldValue, onChange, value]
  );

  const handleRemoveFile = (idSide: string) => {
    idSide === "front" ? setFileFront(null) : setFileBack(null);
    changeValue(idSide)(null);
  };

  const handleOnChange = (evt) => {
    changeValue(value.type)(evt);
    if (side === "front") setFileFront(evt.file?.originFileObj);
    else setFileBack(evt.file?.originFileObj);
    setIsModalVisible(false);
  };

  const handleShowModal = (idSide: string) => {
    // if(value.type === 'idcard')
    setSide(idSide);
    // else setSide("");
    showModal();
  };

  const showModal = async () => {
    setLoading(true);
    try {
      const item = Office.context.mailbox.item;
      if (item.attachments.length === 0) {
        message.info(t("noAttachment"));
      } else {
        const attachmentDetails = item.attachments.map((attachment) => ({
          id: attachment.id,
          name: attachment.name,
          size: attachment.size,
          contentType: attachment.contentType,
        }));
        setAttachments(attachmentDetails);
      }
    } catch (error) {
      console.error("Error getting attachments:", error);
      message.error(t("failedToGetAttachments"));
    } finally {
      setLoading(false);
      setIsModalVisible(true);
    }
  };

  function convertToUploadFileFormat(attachment, callback) {
    // Use the Office JavaScript API to get the attachment content
    Office.context.mailbox.item.getAttachmentContentAsync(
      attachment.id,
      { asyncContext: attachment },
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          console.log("attachmenet:", attachment);
          const content = result.value; // The content of the attachment
          let blob;

          // The content could be in different formats such as base64, so we need to handle it accordingly
          if (content.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
            // Convert Base64 string to a byte array
            const byteCharacters = atob(content.content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a blob from the byte array
            blob = new Blob([byteArray], { type: attachment.contentType });
          } else {
            // Handle other formats if necessary
            console.error("Attachment format not supported: ", content.format);
            return;
          }

          // Create a File object from the Blob
          const { filename, companyName } = { ...props };
          console.log("filename:", filename);
          console.log("companyName:", companyName);
          console.log("...props:", { ...props });
          const baseFileName = filename.replace("${companyName}", companyName);
          const extension = attachment.name.split(".").pop();
          const conventionalFileName = `${baseFileName}.${extension}`;
          const file = new File([blob], conventionalFileName, { type: attachment.contentType });
          callback(null, file);
        } else {
          console.error("Error getting attachment content: ", result.error);
          callback(result.error, null);
        }
      }
    );
  }

  const uploadAttachment = async (file) => {
    console.log("file in uploadAttachment:", file);
    console.log("side:", side);
    convertToUploadFileFormat(file, (error, file) => {
      if (error) {
        console.error("Error converting attachment: ", error);
      } else if (file) {
        console.log("file after converToUploadFileFormat:", file);
        customRequest(
          value.type,
          side
        )({
          file: file,
          onSuccess: (response) => {
            message.success(t("fileUploadSuccess"));
            if (side === "front") setFileFront(file);
            else setFileBack(file);
            console.log("file response of customRequest:", response);
            changeValue(value.type)({ file: { response: response } });
            setIsModalVisible(false);
          },
        });
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div id={id} tabIndex={10} className="form-runner-upload-focus">
      <Radio.Group
        style={{ width: "100%" }}
        optionType="button"
        buttonStyle="outline"
        onChange={({ target: { value } = {} }) => changeValue("type")(value)}
        value={value.type}
      >
        <Row justify="space-between">
          <Radio.Button className="credential-check-radio" style={{ width: "50%", display: "flex" }} value={"passport"}>
            <Space>
              <Icon className="anticon" style={{ display: "flex" }} width={16} name="passport" />
              <Typography.Text className="anttext" ellipsis={true}>
                {t("common.text.passport")}
              </Typography.Text>
            </Space>
          </Radio.Button>
          <Radio.Button className="credential-check-radio" style={{ width: "50%", display: "flex" }} value={"idCard"}>
            <Space>
              <Icon className="anticon" style={{ display: "flex" }} width={16} name="idcard" />
              <Typography.Text className="anttext" ellipsis={true}>
                {t("common.text.idCard")}
              </Typography.Text>
            </Space>
          </Radio.Button>
        </Row>
      </Radio.Group>
      <br />
      <br />
      <div className="form-runner-upload-focus-preview">
        <div className="form-label-text">
          {value.type === "passport" ? t("formRunner.fileUploadInput.passport") : t("common.text.recto")}
        </div>
        {value.front && document.front ? (
          <FilePreview
            file={fileFront}
            document={document.front}
            value={value.front}
            onRemoveFile={() => handleRemoveFile("front")}
          />
        ) : (
          <Button
            type="dashed"
            onClick={() => handleShowModal("front")}
            className="upload-button"
            disabled={submitting}
            icon={<PlusOutlined className="form-upload-icon" />}
          />
        )}
        {value.type === "idCard" && (
          <>
            <div className="form-label-text">{t("common.text.verso")}</div>
            {value.back && document.back ? (
              <FilePreview
                file={fileBack}
                document={document.back}
                value={value.back}
                onRemoveFile={() => handleRemoveFile("back")}
              />
            ) : (
              <Button
                type="dashed"
                onClick={() => handleShowModal("back")}
                className="upload-button"
                disabled={submitting}
                icon={<PlusOutlined className="form-upload-icon" />}
              />
            )}
          </>
        )}
      </div>
      <Modal title={t("selectDocuments")} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <List
          locale={{ emptyText: t("noData") }}
          dataSource={attachments}
          renderItem={(attachment) => (
            <List.Item
              actions={[
                <Button
                  key="upload"
                  icon={<UploadOutlined />}
                  loading={submitting}
                  disabled={submitting}
                  onClick={() => uploadAttachment(attachment)}
                ></Button>,
              ]}
            >
              <div className="ant-list-item-text">{attachment.name}</div>
            </List.Item>
          )}
        />
        <>
          <Upload
            beforeUpload={beforeUpload}
            disabled={submitting}
            customRequest={customRequest(value.type, side)}
            listType="picture-card"
            showUploadList={false}
            className={classNames(submitFailed ? "form-runner-upload" : false)}
            accept={acceptedFiletypes}
            {...props}
            onChange={handleOnChange}
          >
            {t("uploadFromLocal")}
          </Upload>
          {parseFloat(maxSize) > 0 && `${"Maximum size per file"}: ${maxSize} MB`}
        </>
      </Modal>
    </div>
  );
};
