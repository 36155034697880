import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Button, Modal, Space, Row } from 'antd'
import { CloseOutlined, ContactsOutlined } from '@ant-design/icons'

import { Icon } from '../Icon'
import { multilang } from '../../helpers/multiLang'
import { SelectInput } from '../../components/SelectInput/SelectInput'
import PlusIcon from '../../components/Icon/PlusIcon'
import { Select } from 'antd'
import { match } from '../../helpers/text'

const { Title } = Typography

// Replace these with your actual type definitions
interface EntityType {
  id: string;
  icon: React.ReactNode;
  typeName: string;
  type: any;
  name: string;
}

interface EntityCategory {
  id: string;
  type: { typeName: string };
  title: string;
}

interface EntityTypeAvatarProps {
  entityType: EntityType;
  round?: boolean;
  className?: string;
}

const EntityTypeAvatar: React.FC<EntityTypeAvatarProps> = ({
  entityType,
  round = false,
  className,
  ...props
}) => (
  <Icon
    key={entityType.id}
    className="anticon"
    width="10px"
    height="10px"
    icon={entityType?.icon}
    name={entityType?.typeName}
    {...props}
  />
);

interface EntityViewValue {
  entityId?: string;
  categoryId?: string;
  typeName?: string;
}

interface EntityViewProps {
  entityCategories: EntityCategory[];
  id: string;
  entities: EntityType[];
  onChange: (value: EntityViewValue) => void;
  value?: EntityViewValue;
  loading?: boolean;
}

export const EntityView: React.FC<EntityViewProps> = React.memo(
  ({ entityCategories, id, entities, onChange, value: { entityId, categoryId } = { entityId: '', categoryId: ''}, loading }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const handleChange = (value: string) => {
      if (value.startsWith('__categ')) {
        const realValue = value.replace('__categ_', '');
        const category = entityCategories.find(({ id }) => id === realValue);
        if (!category) {
          return;
        }
        onChange({ typeName: category.type.typeName, categoryId: realValue });
      } else {
        onChange({ entityId: value });
      }
    };

    const entityOptions = React.useMemo(
      () => [
        ...entities.map(entity => ({
          label: (
            <>
              <EntityTypeAvatar entityType={entity.type} /> {multilang(entity.name, language)}
            </>
          ),
          originalLabel: multilang(entity.name, language),
          value: entity.id,
          icon: () => <EntityTypeAvatar entityType={entity.type} />,
        })),
        ...entityCategories.map(entityCategory => ({
          label: (
            <div className="dropdown-select__list-item--adding">
              <div className="dropdown-select-render-add">
                <PlusIcon />
              </div>
              {t('entityType.new')}&nbsp;{multilang(entityCategory.title, language)}
            </div>
          ),
          originalLabel: multilang(entityCategory.title, language),
          value: `__categ_${entityCategory.id}`,
        })),
      ],
      [entities, entityCategories, language, t]
    );

    const onFilterOption = (inputValue: string, option: any) => {
      if (option.value.startsWith('__categ')) {
        return true;
      }
      return match(inputValue, option.originalLabel);
    };

    return (
      <Select
        id={id}
        style={{ width: '100%' }}
        optionFilterProp="originalLabel"
        loading={loading}
        onChange={handleChange}
        options={entityOptions}
        showSearch
        filterOption={onFilterOption}
        value={entityId || (categoryId ? `__categ_${categoryId}` : undefined)}
      />
    );
  }
);