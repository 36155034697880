export const supportedTypes = [
  "apng",
  "bmp",
  "gif",
  "ico",
  "cur",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "tif",
  "tiff",
  "webp",
];
