import React from "react"
import './ContainerWrapper.less'

const ContainerWrapper = props => {
    return (
      <div className="container-wrapper" style={{ ...props }}>
        {props.children}
      </div>
    )
  }
  
  export { ContainerWrapper }
  