import React from "react";
import LogoAlf from "../../assets/svg/logo-alf.svg";
import { LOCATION, SLUG } from "../../constants/url";
type LayoutPropType = {
  children: React.ReactNode | React.ReactNode[];
};

const Layout: React.FC<LayoutPropType> = ({ children }) => {
  return (
    <div className="layout">
      <div className="layout-top">
        <div className="layout-top-logo">
          <img src={LogoAlf} alt="LogoALF" />
          <p className="layout-top-slug">{`${SLUG}.${LOCATION}`}</p>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
