import gql from 'graphql-tag'

export const getProcessInstancesQuery = gql`
  query GetProcessInstances(
    $offset: Int
    # $sortBy: ProcessInstanceSortBy
    # $sortDirection: SortDirection
    # $search: String
    $dossiersListType: String
  ) {
    getProcessInstances(
      offset: $offset
      # sortBy: $sortBy
      # sortDirection: $sortDirection
      # search: $search
      dossiersListType: $dossiersListType
    ) {
      count
      edges {
        node {
          data
          id
          label
          archivedAt
          createdAt
          product {
            id
            title
            icon {
              id
              url
            }
            category {
              color
            }
          }
          startedAt
          status
          user {
            id
            email
            facebookId
            firstname
            lastname
            type
            disabledAt
          }
          users {
            id
            email
            facebookId
            firstname
            lastname
            type
            disabledAt
          }
          workflow {
            config
            id
            name
            status
            type
            toDo
            toRevalidate
          }
          deletedAt
        }
      }
      totalCount
    }
  }
`
