import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { PdfViewer } from './PdfViewer';
import { ImageViewer } from './ImageViewer';

import { getType } from '../../helpers/getType';

interface PreviewUrlProps {
  url: string;
  type?: string;
  filename: string;
  detailedView?: boolean;
  [key: string]: any; // Allow for additional props
}

export const PreviewUrl: React.FC<PreviewUrlProps> = ({ url, type, filename, detailedView = true, ...props }) => {
  if (!detailedView) {
    return (
      <a className="document-link-icon" href={url}>
        {filename}
      </a>
    );
  }
  const finalType = type || getType(filename || url);

  switch (finalType) {
    case 'pdf':
      return <PdfViewer url={url} {...props} />;
    case 'image':
      return <ImageViewer filename={filename} url={url} {...props} />;
    default:
      return <FileOutlined className="document-link-icon" />;
  }
};
