import React, { useEffect, useState, FC } from 'react';
import { PreviewUrl } from './PreviewUrl';

interface PreviewFileProps {
  file: File;
}

export const PreviewFile: FC<PreviewFileProps> = ({ file, ...props }) => {
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    setUrl(URL.createObjectURL(file));
  }, [file]);

  return <PreviewUrl url={url} filename={file.name} {...props} />;
};