type IdCheckValue = {
  front?: any;
  back?: any;
  type?: string;
};

type SerializedIdCheck = {
  value: IdCheckValue;
  type: string;
  asyncData: string;
};

export const idCheck = {
  serialize: ({ type }: { type: string }) =>
    (value: IdCheckValue = {}): SerializedIdCheck => ({ value, type, asyncData: 'idCheck' }),
  
  deserialize: ({ value }: { value: any } = { value: null }) => value,
  
  validate: () => 
    ({ value }: { value: IdCheckValue }) => {
      if (Boolean(value) && Boolean(value.front) && (value.type === 'passport' || Boolean(value.back))) {
        return null
      }
      return 'requiredField'
    },
}