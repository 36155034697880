import { message } from 'antd'

export const extractErrorMessage = error =>
  (error ? (error instanceof Error ? error.message : error) : error)
    .replace('GraphQL error: INTERNAL_SERVER_ERROR :', 'Error:')
    .replace('GraphQL error: UNAUTHENTICATED :', 'Authentication error:')
    .replace('Network error: Response not successful:', 'Network error:')

export const messageError = (msg, error) => {
  const errorMessage = process.env.NODE_ENV !== 'production' ? (error ? ': ' + extractErrorMessage(error) : '') : ''
  message.error(`${msg} ${errorMessage}`)
  console.log(`error: ${msg}\n${error}`)
}

export const messageSuccess = title => {
  message.success(title)
}
