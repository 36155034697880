import React, { useState, useMemo, createRef, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ChevronLeftIcon from '../../Icon/ChevronLeftIcon';
import ChevronRightIcon from '../../Icon/ChevronRightIcon';
import ZoomInIcon from '../../Icon/ZoomInIcon';
import ZoomOutIcon from '../../Icon/ZoomOutIcon';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';

import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import './PdfViewer.less';
import classNames from 'classnames';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

interface HeaderProps {
  scale: number;
  page: number;
  pages: number | null;
  onPage: (type: number) => void;
  extra: React.ReactNode | null;
  hideZoom: boolean;
  shortPage: boolean;
  onSetScale: (type: number) => void;
}

const Header: React.FC<HeaderProps> = ({ scale, page, pages, onPage, extra, hideZoom, shortPage, onSetScale }) => {
  const { t } = useTranslation();

  const pageText = useMemo(() => {
    return shortPage ? `${page}/${pages}` : t('common.pagesOn', { current: page, length: pages });
  }, [page, pages, t, shortPage]);

  const percentScale = useMemo(() => {
    return `${Math.round(scale * 100)}%`;
  }, [scale]);

  return (
    <div className="pdf-viewer-header">
      {extra && <div className="pdf-viewer-header-extra">{extra}</div>}
      <div className="pdf-viewer-header-pages">
        <div
          className="pdf-viewer-header-icon"
          onClick={evt => {
            evt.stopPropagation();
            onPage(0);
          }}
        >
          <ChevronLeftIcon />
        </div>
        <div className="pdf-viewer-header-page">{pageText}</div>
        <div
          className="pdf-viewer-header-icon"
          onClick={evt => {
            evt.stopPropagation();
            onPage(1);
          }}
        >
          <ChevronRightIcon />
        </div>
      </div>
      {!hideZoom && (
        <div className="pdf-viewer-header-scale">
          <div className="pdf-viewer-header-percentscale">{percentScale}</div>
          <div
            className="pdf-viewer-header-icon"
            onClick={evt => {
              evt.stopPropagation();
              onSetScale(0);
            }}
          >
            <ZoomOutIcon />
          </div>
          <div
            className="pdf-viewer-header-icon"
            onClick={evt => {
              evt.stopPropagation();
              onSetScale(1);
            }}
          >
            <ZoomInIcon />
          </div>
        </div>
      )}
    </div>
  );
};

interface PdfViewerProps {
  url?: string;
  extra?: React.ReactNode | null;
  hideZoom?: boolean;
  shortPage?: boolean;
  ratio?: number;
  maxHeight?: number;
  onLoad?: (data: { margin: number }) => void;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ url, ratio = 0.6, maxHeight = 1000, onLoad }) => {
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState<number | null>(null);
  const [pageHeight, setPageHeight] = useState<number | null>(null);
  const mainRef = createRef<HTMLDivElement>();

  const onDocumentComplete = async (pdf: any) => {
    const pageElm = await pdf.getPage(page);
    const { clientWidth } = mainRef.current!;
    let finalWidth = pageElm.view[2];
    if (pageElm.view[2] > clientWidth) {
      finalWidth = clientWidth * ratio;
      setPageWidth(finalWidth);
    } else if (maxHeight && pageElm.view[3] > maxHeight) {
      setPageHeight(maxHeight * ratio);
    }
    setPages(pdf.numPages);
    typeof onLoad === 'function' && onLoad({ margin: (clientWidth - finalWidth) / 2 });
  };

  const onSetScale = (type: number) => {
    let newScale = type ? scale + 0.1 : scale - 0.1;

    if (newScale > 2) {
      newScale = 2;
    } else if (newScale < 0.1) {
      newScale = 0.1;
    }

    setScale(newScale);
  };

  const onPage = (type: number) => {
    let newPage = type ? page + 1 : page - 1;

    if (newPage > pages!) {
      newPage = 1;
    } else if (newPage < 1) {
      newPage = pages!;
    }

    setPage(newPage);
  };

  return (
    <div ref={mainRef} className={classNames('pdf-viewer')}>
      {/* {pages && (
        <Header
          extra={extra}
          page={page}
          pages={pages}
          scale={scale}
          onSetScale={onSetScale}
          onPage={onPage}
          shortPage={shortPage}
          hideZoom={hideZoom}
        />
      )} */}
      <Document
        className="pdf-viewer-document"
        file={url}
        options={{ withCredentials: true }}
        loading={<Loader isRelative />}
        error={<></>}
        onLoadSuccess={onDocumentComplete}
      >
        <Page width={pageWidth} height={pageHeight} className="pdf-viewer-page" pageNumber={page} scale={scale} />
      </Document>
    </div>
  );
};
