import React, { useMemo, useRef } from "react";
import classNames from "classnames";

import InnerButton, { ButtonType } from "../../components/InnerButton/InnerButton";
import { USER_TYPES } from "../../constants/user";
import { PillType } from "./types";
import { multilang } from "../../helpers/multiLang";
import { Tooltip } from "antd";

type FilterPillsPropType = {
  allPillText: string;
  pills: Record<string, PillType>;
  currentPill: string;
  setCurrentPill: (v: string | null) => void;
  displayCount?: boolean;
  displayAll?: boolean;
  displayAdd?: boolean;
  allPillAtEnd?: boolean;
  handleCreateCategoryClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  user?: any;
};

export const FilterPills: React.FC<FilterPillsPropType> = ({
  allPillText,
  pills,
  currentPill,
  setCurrentPill,
  displayCount = true,
  displayAll = true,
  allPillAtEnd = false,
  user,
}) => {
  const pillsRef = useRef([]);
  const getPillText = (pill) => {
    let text = pill[1] ? multilang(pill[1].data?.title) : pill[1].data.categoryId;
    if (displayCount && pill[1] && pill[1].data?.count >= 0) {
      text += ` (${(pill[1] && pill[1].data?.count) || 0})`;
    }
    return text;
  };

  const addToRefs = (el) => {
    if (el && !pillsRef.current.includes(el)) {
      pillsRef.current.push(el);
    }
  };
  const totalCount = useMemo(() => Object.keys(pills).reduce((acc, b) => acc + pills[b]?.data.count, 0), [pills]);

  const finalAllPillText = useMemo(() => {
    let text = allPillText;
    if (displayCount && totalCount >= 0) {
      text += ` (${totalCount || 0})`;
    }
    return text;
  }, [allPillText, displayCount, totalCount]);

  const productsFiltered = useMemo(
    () =>
      (user?.type === USER_TYPES.operator
        ? Object.entries(pills).filter((data) => data[1].productsPublish > 0)
        : Object.entries(pills)
      ).filter((data) => data[0] !== "undefined" && data[0] !== "null"),
    [pills, user]
  );

  const AllPill = () => (
    <div className="filter-pills-wrap">
      <InnerButton
        type={ButtonType.filter}
        size="large"
        onClick={() => setCurrentPill(null)}
        className={classNames(!currentPill && "selected")}
        text={finalAllPillText}
      />
    </div>
  );


  return (
    <div className="filter-pills">
      {displayAll && !allPillAtEnd && <AllPill />}
      {productsFiltered?.map((pill, index) => (
        <div key={index} ref={addToRefs} className="filter-pills-wrap">
          <InnerButton
            type={ButtonType.filter}
            size="large"
            onClick={() => setCurrentPill(pill[1].data?.categoryId)}
            className={classNames(currentPill === pill[1].data?.categoryId && "selected")}
            text={
              <Tooltip title={getPillText(pill)}>
                <div className="filter-pills-text">{getPillText(pill)}</div>
              </Tooltip>
            }
          />
        </div>
      ))}
      {displayAll && allPillAtEnd && <AllPill />}
    </div>
  );
};
