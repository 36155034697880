import { faker } from '@faker-js/faker'
import dayjs from 'dayjs';
import DATE_TYPES from '../constants/dateTypes'

interface SerializeInput {
  type: string;
}

interface DeserializeInput {
  value: string;
}

interface ToStrOptions {
  locale?: string;
  format?: string;
}

const resolveDate = (initialValue: string): string => {
  if (DATE_TYPES[initialValue]) {
    return DATE_TYPES[initialValue]
  } else {
    let date = dayjs(initialValue)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(initialValue)) {
      date = dayjs(initialValue, 'DD/MM/YYYY')
    } else if (/^\d{2}:\d{2}$/.test(initialValue)) {
      date = dayjs(initialValue, 'hh:mm').subtract(1, 'hour')
    }
    return initialValue && dayjs(initialValue).isValid()
      ? dayjs(initialValue, 'YYYY-MM-DD').toISOString()
      : dayjs().toISOString()
  }
}

export const datetime = {
  serialize: ({ type }: SerializeInput) => (value: string) => ({
    value: resolveDate(value),
    type,
  }),
  deserialize: ({ value }: DeserializeInput = { value: null }) => (value ? dayjs(value) : null),
  validate: () => (value: { value?: any }) => value && value.value ? null : 'requiredField',
  fake: faker.date.past,
  toStr: ({ value }: { value: string }, { locale = 'en', format = 'lll' }: ToStrOptions = {}) =>
    value ? dayjs(value).locale(locale).format(format) : '',
}