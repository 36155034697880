import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useMatch, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { getProcessInstanceQuery } from "./graphql/queries";
import ContainerWrapper from "../../components/ContainerWrapper";
import Loader from "../../components/Loader";
import ServiceLayout from "../../components/ServiceLayout";
import { Tabs } from "antd";
import ProcessInstanceInformationPage from "./ProcessInstanceInformationPage";
import ProcessInstanceSummaryPage from "./ProcessInstanceSummaryPage";
import ProcessInstanceDocumentPage from "./ProcessInstanceDocumentPage";
import ProcessInstanceEmailPage from "./ProcessInstanceEmailPage";
import { multilang } from "../../helpers/multiLang";
import { isEmpty } from "lodash";

export enum TabKey {
    informations = "INFORMATIONS",
    summaries = "SUMMARIES",
    documents = "DOCUMENTS",
    emails = "EMAILS",
}

type ProcessInstancePagePropsType = {
    user: any;
}

const ProcessInstancePage: React.FC<ProcessInstancePagePropsType> = ({ user }) => {

    const match = useMatch('/path/:id')
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const fromValue = queryParams.get('from')
    const { t, i18n: {language} } = useTranslation()

    const [ activeKey, setActiveKey ] = useState<TabKey>(TabKey.emails);

    const {
        data: { getProcessInstance: processInstance } = {},
        error,
        loading,
        startPolling,
        stopPolling,
        refetch,
    } = useQuery(getProcessInstanceQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: { id },
    })

    const handleChange = (key: TabKey) => {
        setActiveKey(key);
    }

    const moveToInformationTab = () => {
      handleChange(TabKey.informations)
    }

    if (loading) {
        return (
          <ContainerWrapper>
            <Loader />
          </ContainerWrapper>
        )
    }

    const currentLocation = fromValue==='create' ? multilang(processInstance?.name) : (`${queryParams.get('dossier')} / ${multilang(processInstance?.name)}`);
    return (
        <div className="process-instance-page">
            <ServiceLayout location={[t('Menu'), fromValue === 'create' ? t('Service') : t('Dossier')]} currentLocation={currentLocation} bcc={processInstance?.bcc} link={fromValue === 'create' ? "/store" : "/attach"} nextArrow={false}>
                <Tabs activeKey={activeKey} onChange={handleChange}>
                    <Tabs.TabPane tab="Informations" key={TabKey.informations}>
                        <ProcessInstanceInformationPage
                            processInstance={processInstance} 
                            refetch={refetch}
                            user={user} 
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('summary')} disabled={isEmpty(processInstance?.data)} key={TabKey.summaries}>
                        <ProcessInstanceSummaryPage
                            processInstance={processInstance}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Documents" key={TabKey.documents}>
                        <ProcessInstanceDocumentPage
                            processInstance={processInstance}
                            refetch={refetch}
                            user={user}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Emails" key={TabKey.emails}>
                        <ProcessInstanceEmailPage
                            processInstance={processInstance}
                            handleMoveToInformationTab = {moveToInformationTab}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </ServiceLayout>
        </div>
    )
}

export default ProcessInstancePage