import { number } from './number';
import { text } from './text';
import { boolean } from './boolean';
import { select } from './select';
import { datetime } from './datetime';
import { selectAsyncData } from './selectAsyncData';
import { file } from './file';
import { idCheck } from './idCheck';
import { multipleSelect } from './multipleSelect';
import { uuid } from './uuid';
import { money } from './money';
import { entity } from './entity';
import { enumeration } from './enumeration';

export default {
  number,
  text,
  boolean,
  select,
  datetime,
  selectAsyncData,
  file,
  idCheck,
  multipleSelect,
  uuid,
  money,
  entity,
  enumeration,
}