import isString from 'lodash/isString'

type Option = {
  label?: string,
  value?: string,
  [key: string]: string
}

type DataOptions = {
  options?: Option[]
}

type Props = {
  dataOptions?: DataOptions
}

type HandleOptionsArgs = {
  onlyLabel?: boolean,
  onlyValue?: boolean
}

export const handleOptions = (
  optionsProp?: Option[] | undefined,
  { dataOptions: { options: optionsDefinition } = { options: [] } }: Props = {},
  locale: string = 'en',
  { onlyLabel, onlyValue }: HandleOptionsArgs = {}
): Option[] | string[] => {
  const options: Option[] =
    (Array.isArray(optionsProp) && optionsProp) || (Array.isArray(optionsDefinition) && optionsDefinition) || []

  if (onlyLabel) {
    return options.every(isString)
      ? options
      : options.map(({ label, [`label_${locale}`]: localeLabel }) => localeLabel || label)
  }

  if (onlyValue) {
    return options.every(isString) ? options : options.map(({ value }) => value)
  }

  return options.every(isString)
    ? options
    : options.map(({ value, label, [`label_${locale}`]: localeLabel }) => ({ label: localeLabel || label, value }))
}