const fr = {
  translation: {
    EOFsubTitle: "Vos informations sont enregistrées",
    EOFtitle: "Le formulaire est validé",
    EditCompany: "Editer une société",
    accre: "ACRE",
    action: "action",
    actions: "Actions",
    activityDate: "Date de début d'activité",
    addCapital: "Ajouter du Capital",
    address: "Adresse",
    addressEmpty: "Aucune addresse",
    amount: "Montant",
    amountSocialCapital: "Montant du Capital Social",
    ape: "Code APE/NAF",
    app: {
      serviceWorker: {
        button: "Rafraîchir",
        content: "Pour les appliquer, fermez cette notification ou rafraîchissez le navigateur.",
        title: "Mises à jour en attente",
      },
    },
    applicationCreate: {
      create: "Créer une plateforme",
      form: {
        error: "Une erreur est survenue durant la création de l'application",
        success: "L'application a été créée",
      },
      subTitle: "Création d'une marque grise ALF",
      title: "Création d'une application",
    },
    applicationForm: {
      categories: {
        audit: { label: "Audit" },
        billing: { label: "Facturation" },
        brokerLegalRepresentative: { label: "Représentant légal" },
        legal: { label: "Légal" },
        logo: { label: "Logo" },
        platformSettings: { label: "Plateforme" },
        templates: { label: "Modèles d'emails" },
        theme: { label: "Thème" },
        roles: { label: "Rôles" },
        welcomeLetter: { label: "Message de bienvenue" },
      },
      connect: {
        all: "Toutes les applications",
        button: {
          connect: "Se connecter",
          connected: "Connecté",
          disconnect: "Se deconnecter",
        },
        title: "Connectez tous vos applications préférées",
        request: {
          title: "Demander une intégration",
          subTitle: "Décrivez ici l’application que vous souhaitez intégrer :",
          placeholderFeature: "Votre message",
        },
        payment: {
          addYourPaymentKeys: {
            label: "Ajoutez vos clés de paiement",
          },
        },
        stripe: {
          documentationAt: {
            label: "Documentation",
          },
          getYourStripeKeys: {
            label: "Obtenez vos clés Stripe ici",
          },
          keychainName: {
            placeholder: "Donnez un nom explicite à cette keychain",
          },
          privateKey: {
            label: "Clé secrète",
          },
          productionKeys: {
            label: "Clés de production",
          },
          publicKey: {
            label: "Clé publique",
          },
          stripeKeysConfiguration: {
            label: "Veuillez configurer vos clé Stripe avant de sélectionner un produit",
          },
          testKeys: {
            label: "Clés de test",
          },
        },
      },
      legalRepresent: {
        firstName: "Prénom du représentant",
        lastName: "Nom du représentant",
      },
      docuSignBcc: {
        label: "Ajouter cette adresse en copie carbone (CC) de toutes les enveloppes de signature:",
        placeholder: "dosusignarchives@yourdomain.com",
      },
      domainName: {
        error: "Veuillez saisir un nom de domaine correct",
        placeholder: "exemple.com",
      },
      emailCc: {
        clipboard: "L'email a été copié",
        enable: { label: "Activer le CC de tous les emails" },
        label: "Ajouter cette adresse en copie carbone (CC) de tous les emails envoyés par la plateforme:",
        placeholder: "archives@yourdomain.com",
      },
      embedded: {
        label: "Plateforme embarquée (iframe)",
      },
      invoiceNumber: {
        label: "Prochain numéro de facture séquentiel",
      },
      logo: {
        label: "Logo",
        placeholder: "Téléchargez le logo de l'application",
      },
      logoLinkUrl: {
        label: "Adresse de redirection du Logo",
        placeholder: "http://somesite.com",
      },
      multiForm: {
        add: "Ajouter",
        selectLanguage: "Sélectionnez une langue",
      },
      name: {
        error: "Le nom est requis",
        label: "Nom",
        placeholder: "Entrez le nom de la plateforme",
      },
      public: {
        label: "Plateforme publique",
      },
      sendGrid: {
        forgotPassword: "Mot de passe oublié",
        platformInvitation: "Invitation à la plateforme",
        shareEntityOrProcessInstance: "Partage d'un contact ou d'un process",
        signup: "Enregistrement sur la plateforme",
        title: "Choisissez vos templates SendGrid",
      },
      slug: {
        error: "Le slug est requis ([a-z0-9-])",
        label: "Slug",
        placeholder: "Entrez le slug de l'application",
      },
      tabs: {
        appearance: "Personnalisation",
        connect: "Intégrations",
        general: "Général",
        mail: "Mail",
        information: "Mes informations",
        invoice: "Mes factures",
      },
      termsConditionsUrl: {
        label: "Lien vers les termes et conditions:",
        placeholder: "https://example.com/terms",
      },
      theme: {
        label: "Réglages du thème",
      },
      welcome: {
        content: { label: "Contenu du message de bienvenue" },
        enable: { label: "Afficher un message de bienvenue" },
        location: {
          label: "A quel moment souhaitez-vous afficher le message de bienvenue ?",
          store: "Sur la page principale",
          signin: "Lorsque l'utilisateur se connecte",
          topMenu: "Toujours au sommet du menu de navigation",
        },
      },
    },
    applicationList: {
      column: {
        actions: "Actions",
        id: "ID",
        name: "Nom",
        slug: "Slug",
      },
      subTitle: "",
      title: "Plateformes",
      create: "Créer une plateforme",
    },
    applicationUpdate: {
      form: {
        error: "Une erreur est survenue durant l'édition de l'application",
        success: "L'application a été mise à jour",
      },
      subTitle: "Édition d'une marque grise ALF",
      title: "Édition d'une application",
    },
    assetUpload: {
      sizeError: "Taille de fichier trop grande",
      typeError: "Type de fichier incorrect",
    },
    attestation: "Attestations",
    author: "Auteur",
    backToAnnonce: "Retour aux annonces",
    beneficialOwner: "Bénéficiaire effectif",
    birthCity: "Ville de naissance",
    birthCountry: "Pays de naissance",
    birthDate: "Date de naissance",
    brands: "Marques",
    cancelAnnonce: "Annule l'annonce",
    carbonFootprint: {
      title: "Limitez votre empreinte carbone en ne sélectionnant que les documents essentiels.",
      info: "11g de CO2 = 1 sac plastique",
      alert: "Votre empreinte = {{co2}} de CO2 ({{size}})",
    },
    category: "Categorie",
    categoryCreate: {
      subTitle: "Créez une nouvelle catégorie de service pour le store",
      title: "Créer une catégorie",
      form: {
        error: "Une erreur s'est produite lors de la création de la catégorie",
        success: "La catégorie a été créée",
      },
    },
    categoryForm: {
      index: {
        error: "La position dans le store est requise",
        label: "Position dans le store",
        placeholder: "Position de la catégorie dans l'onglet Services",
      },
      title: { error: "Le titre est requis", label: "Titre", placeholder: "Titre de la catégorie" },
    },
    categoryList: {
      column: { storePosition: "Position dans le store", title: "Titre" },
      confirm: { title: "Êtes-vous sûr de vouloir supprimer cette catégorie ?" },
      delete: { error: "Une erreur s'est produite durant la suppression de la catégorie" },
      subTitle: "Organisez vos services dans le store",
      title: "Catégories",
    },
    categoryUpdate: {
      form: {
        error: "Une erreur est survenue durant l'édition de la catégorie",
        success: "La catégorie a été mise à jour",
      },
      subTitle: "Catégorie",
      title: "Édition d'une catégorie",
    },
    city: "Ville",
    collectiveAgreement: "Convention collective",
    common: {
      addrecipient: "Ajouter un destinataire",
      backTop: "Retour en haut",
      by: "par",
      cancel: "Annuler",
      confirm: {
        no: "Non",
        yes: "Oui",
      },
      condition: {
        if: "Si",
        or: "Ou",
        and: "Et",
        then: "Alors",
        else: "Sinon",
        variable: "Variable",
        value: "Valeur",
      },
      disactivated: "désactivé",
      requiredError: "Please type a {{name}}",
      company: {
        role: {
          CEO: "Président",
          Director: "Directeur",
          Shareholder: "Actionnaire",
          Manager: "Gérant",
        },
      },
      collaborator: "Collaborateur",
      collaborators: "Collaborateurs",
      document: {
        create: "Document crée avec succès",
        delete: "Document supprimé avec succès",
        edit: "Document edité avec succès",
        flags: "Attributs",
        new: "Nouveau document",
        replace: {
          title: "Êtes-vous sûr(e) de vouloir remplacer ce document ?",
          description:
            "Le remplacement de ce document peut supprimer les étiquettes de signature automatiques et l'enveloppe sera donc placée en mode brouillon pour que l'administrateur puisse la vérifier manuellement",
        },
        select: "Veuillez sélectionner un document",
        side: {
          back: "Verso",
          front: "Recto",
        },
        uploadError: "Erreur: Vous devez choisir un document",
        uploadingError: "Erreur lors du téléchargement",
      },
      email: {
        invalid: "Veuillez saisir un email valide",
      },
      error: {
        fatal: {
          content: "Une erreur inattendue s'est produite. Notre équipe fait de son mieux pour résoudre le problème.",
          title: "Ceci est une page mystérieuse, nous allons vous aider.",
        },
        funny: "Oups, quelque chose a mal tourné.",
        funny404: "Ooops! On dirait que vous êtes perdu ...",
        funny404Subtitle: "Où aller maintenant ?",
        funnyDashboard: "Accéder à mes dossiers",
        funnyDashboardIn: "Retour au Tableau de bord dans",
        funnyHelp: "Aidez-moi",
        funnyHome: "Vers l'Accueil",
        funnyHomeIn: "Retour à l'Accueil dans",
        subtitle: {
          title: "Pour résoudre le problème, vous pouvez :",
          element: [
            "Vérifier que vous êtes connecté à l'Internet",
            "Vérifier que vous êtes bien connecté à votre compte",
            "Vérifier que vous avez bien rempli tous les questionnaires précédents",
            "Vérifier que vous avez téléchargé les bons documents",
            "Actualiser la page ou revenir en arrière et recommencer l'opération",
          ],
          lastElement: 'Cela ne fonctionne toujours pas ? Contactez-nous en cliquant sur le bouton "Aidez moi"',
        },
        funnySubtitleBis: "Veuillez nous contacter si ce problème persiste.",
        generic: "Une erreur est survenue",
        genericTryAgain: "Une erreur est survenue. Veuillez réessayer plus tard",
        input: {
          url: {
            invalid: "Veuillez saisir une url valide",
            required: "Veuillez saisir une url",
          },
          email: {
            invalid: "Veuillez saisir un email valide",
            required: "Veuillez saisir une adresse email",
            existing: "Il existe déjà un compte avec cet email",
          },
          length: "L'entrée doit être inférieure à {{maxLength}} caractères",
          number: "Veuillez taper un nombre valide",
          password: {
            invalid:
              "Votre mot de passe doit contenir au moins <strong>une majuscule, un caractère spécial, un chiffre et 8 caractères.</strong>",
            required: "Veuillez saisir un mot de passe",
          },
          passwordConfirm: {
            invalid: "Les mots de passe ne correspondent pas.. encore",
            required: "Veuillez confirmer le mot de passe",
          },
          whitespace: "Veuillez taper au moins un caractère visible",
        },
        metasteps: {
          invalidConfig: "Les metasteps doivent aller de la 1er étapes jusqu'a la dernière de manière continue",
          enoughMetaSteps: "Vous ne pouvez pas rajouter plus de metasteps",
        },
        noMatches: "Pas de résultat",
        noStackTrace: "Pas de stack trace",
        paymentError: "Veuillez procéder au paiement afin d'accéder à ce lien",
        queryFailed: "La requête a échoué",
        required: {
          generic: "Une valeur pour ce champ est requise",
          name: "Le nom est requis",
          type: "Le type est requis",
        },
        signinError: "Veuillez vous connecter afin accéder à ce lien",
      },
      filetype: {
        NTDNotification: "Notification NTD",
        acceptanceLetter: "Lettre d'acceptation",
        accountingSummary: "Informations fiscales",
        agreement: "Contrat",
        agreementInternship: "Convention de stage",
        auditorCRCCRegistration: "Attestation inscription CRCC",
        auditorRegistrationCertificate: "Lettre d'acceptation de mission",
        bankAccountDetails: "Détails bancaire",
        BSPCEMiniPact: "Mini-pacte BSPCE",
        BSPCEPlan: "BSPCE plan",
        bylaws: "Statuts",
        ceaseAndDesist: "Mise en demeure",
        cerfaEE0: "Cerfa EE0",
        cerfaM0: "Cerfa M0",
        cerfaM2: "Cerfa M2",
        certificateAccounting: "Certificat - Attestation de tenue de comptabilité",
        certificateBankDeposit: "Certificat - Dépôt des fonds",
        certificateBenificialOwnersList: "Certificat - Registre des bénéficiaires effectifs",
        certificateCFE: "Certificat - Attestation de dépôt au CFE",
        certificateDebits: "Certificat - Autorisation de prélevement",
        certificateDocumentFiling: "Certificat - Dépôt d'actes",
        certificateDPAE: "Certificat DPAE",
        certificateEmployer: "Attestation d'employeur",
        certificateEmployment: "Attestation de travail",
        certificateFilingForPersonOfSignificantControl: "Certificat - Attestation de dépôt des bénéficiaires effectifs",
        certificateINSEE: "Certificat - INSEE",
        certificateIncorporationFiling: "Certificat - Récépissé de dépôt immatriculation",
        certificateLegalAnnouncement: "Certificat - Attestation de publication légale",
        certificateMaritalStatus: "Certificat - Statut marital",
        certificateRegistration: "Certificat - Kbis",
        certificateTransferOfShares: "Certificat - Cession d'actions",
        certificateTribunalFiling: "Certificat - Inscription à l'ordre des experts comptables",
        certificateVAT: "Certificat - TVA",
        criminalStatement: "Déclaration de non condamnation",
        criminalRecordExtract: "Extrait de casier judiciaire",
        diploma: "Diplôme",
        directorSalary: "Rémunération du Président",
        domiciliationContract: "Contrat de domiciliation",
        driverLicense: "Permis de conduire",
        employmentContract: "Contrat de travail",
        escrowRequestLetter: "Demande d'ouverture de compte séquestre",
        finalPaymentReceipt: "Reçu pour paiement final",
        foundersList: "Liste des souscripteurs",
        generalAssemblyConvocation: "Convocation de l'assemblée générale",
        generalAssemblyDecision: "Procès verbal d’assemblée générale",
        guarantorStatement: "Déclaration du garant",
        headquarterList: "Liste du siège",
        idCard: "Carte d'identité",
        invoice: "Facture",
        legalAnnouncementInvoice: "Annonce légale - Facture",
        other: "Autre",
        passport: "Passeport",
        payslip: "Bulletin de salaire",
        proofOfActivity: "Justification de l'activité de la société",
        proofOfResidency: "Justificatif de domicile",
        proxyFormalist: "Pouvoir du mandataire",
        proxyMail: "Procuration postale",
        registeredMail: "Courrier recommandé",
        regularMail: "Courrier régulier",
        resignationLetter: "Lettre de démission",
        socialSecurityCard: "Carte de sécurité sociale",
        statutoryAuditorLetter: "Carte vitale",
        subpoena: "Assignation",
        supportingDocuments: "Documents justificatifs",
        tradeLicense: "Licence commerciale",
        workPermit: "Permis de travail",
      },
      gdpr: "RGPD",
      modify: "Modifier",
      modalConfirm: "Ne plus afficher cette fenêtre",
      searchInputPlaceholder: "Que cherchez-vous ?",
      select: {
        category: {
          others: "Autres",
          usualCompany: "Formes de sociétés usuelles",
        },
      },
      services: {
        authenticate: "Connexion",
        docPreview: "Valider des documents",
        form: "Composer un questionnaire",
        generateDocuments: "Générer des documents",
        inviteUsers: "Inviter un utilisateur",
        notifications: "Notifier l'utilisateur",
        openDocument: "Ouvrir des documents",
        payment: "Faire payer le service",
        publishLegalNotice: "Publier une annonce",
        sendDocuments: "Envoyer un mail",
        sendLetter: "Envoyer un courrier recommandé",
        signDocuments: "Signer des documents",
        shareId: "Acquisition pieces d'identités",
        syncEntity: "Synchroniser les dossiers",
        sendMailWithPreview: "Prévisualiser et envoyer un mail",
      },
      status: {
        draft: "Beta",
        publish: "Publié",
      },
      success: {
        generic: "Operation réussie",
      },
      state: {
        document: "État",
        status: {
          draft: "Brouillon",
          version: "Dernière version",
          toggleVersion: "Dernière version officielle",
        },
      },
      text: {
        action: "Action",
        add: "Ajouter",
        addressHome: "Adresse du domicile",
        addCondition: "Ajouter une condition",
        addDocument: "Ajouter un document",
        addtionalDoucment: "document",
        addQuestion: "Ajouter une question",
        address: "Adresse",
        admin: "Administrateur",
        anonymousUser: "Utilisateur Inconnu",
        answers: "Réponses",
        appearance: "Apparence",
        application: "Application",
        archive: "Archivage Sécurisé",
        areYouSure: "Êtes vous sûr ?",
        askIntegration: "Demander une intégration",
        subTitleIntegration: "Décrivez ici l'application que vous souhaitez intégrer :",
        authorize: "Autoriser",
        back: "Retour",
        bank: "Banque",
        beta: "BETA",
        bills: "Factures payées",
        birthday: "Date de naissance",
        cancel: "Annuler",
        category: "Catégorie",
        chat: "Messagerie instantanée",
        city: "Ville",
        companyName: "Nom de la société",
        collaborator: "Collaborateur",
        contactExpert: "Contactez un expert LegalTech",
        companyTitle: "Informations sur ma société",
        companySubTitle:
          "Renseignez les informations légales permettant d’identifier votre société et ses représentants",
        continue: "Continuer",
        country: "Pays",
        courrier: "Courrier recommandé",
        created: "Créé",
        creationDate: "Date de création",
        createdOn: "créé à",
        crop: "Recadrez l'aperçu pour l'ajouter en tant qu'icône pour votre service",
        customer: "Utilisateur",
        date: "Date",
        days: "Jours",
        debug: "Debug",
        delay: "Délai",
        delete: "Supprimer",
        details: "Détails",
        dependencies: "Dépendances",
        dependencyOf: "est une dependance de",
        description: "Description",
        documentType: "Type de document",
        document: "Document",
        documents: "Documents",
        domainName: "Nom de Domaine",
        download: "Télécharger",
        dropZone: {
          labelDrag: "Glisser déposer",
          labelOr: "ou",
          labelBrowse: "Télécharger",
          accept: "Format accepté :",
        },
        dropZoneDoc: {
          labelDragOr: "Glisser-déposer ou ",
        },
        edit: "Éditer",
        editQuestion: "Modifier une question",
        editContent: "Éditer le contenu",
        editDocument: "Modifier ou remplacer un document",
        editor: "Éditeur",
        editable: "Modifiable",
        email: "Email",
        emailCc: "Email de référence - ",
        end: "Fin",
        errorButton: "Consulter mon rôle et mes permissions",
        errorText:
          "Votre rôle ne vous permet pas d'accéder au contenu de cette page. Contactez votre Administrateur pour modifier votre rôle.",
        excluded: "Exclus",
        export: "Exporter",
        faq: "Vos questions",
        financial: "Fiscal & comptabilité",
        firstname: "Prénom",
        form: "Formulaire",
        forms: "Formulaires",
        freemium: "Freemium",
        freemiumState: "Type",
        government: "Gouvernement",
        greyLabel: "Marque Grise",
        grossAmount: "Montant HT",
        guest: "cet invité",
        groups: "Groupes",
        hours: "Heures",
        idCard: "Carte d'identité",
        import: "Importer",
        in: "dans",
        included: "Inclus",
        includedVAT: "Inclure la TVA",
        invited: "Invité le",
        invoices: "Factures émises",
        job: "Job",
        jobs: "Jobs",
        kyc: "Authentification",
        language: "Langue",
        launchSignatories: "Relancer tous les signataires",
        remindSignatories: "Renvoyer une demande de signature à tous les signataires qui n'ont pas encore signé",
        lastname: "Nom de Famille",
        legalAnnouncement: "Publication légale",
        legalForm: "Forme légale",
        legalRep: "Représentant légal",
        loading: "Chargement",
        maximumSize: "Le fichier est trop volumineux.\nLa taille maximale autorisée est",
        member: "membre",
        members: "Membres",
        metastep: "Étape principale",
        metasteps: "Étapes principales ",
        minutes: "Minutes",
        moreinfo: "En savoir plus",
        modify: "Modifier",
        modified: "Modifié",
        modifiedAt: "Modifié à",
        myTeam: "Mon équipe",
        name: "Nom",
        namePlural: "Nom au pluriel",
        nationality: "Nationalité",
        newLanguagePlus: "+ Ajouter une language",
        newLanguage: "Ajouter une language",
        new: "Créer",
        netAmount: "Montant TTC",
        no: "Non",
        noData: "Pas de Données",
        noDependencies: "Il n'y a pas de dépendances",
        noDocuments: "Pas de Documents",
        ok: "Ok",
        operator: "Opérateur",
        options: "Options",
        passport: "Passeport",
        pay: "Payer",
        payment: "Paiement",
        paymentCompleted: "Paiement réussi",
        paymentCompletedSubtitle: "Nous avons bien reçu votre paiement",
        paymentCompletedTitle: "Merci!",
        platform: "!",
        preview: "Prévisualiser",
        price: "Prix",
        priceWithOutVat: "HT",
        priceWithVat: "TTC",
        processingPayment: "Paiement en cours",
        product: "Service",
        profile: "Profile",
        publish: "Publier",
        private: "Privée",
        public: "Publique",
        questionnaire: "Formulaire",
        questionnaires: "Formulaires",
        questions: "Questions",
        rate: "Taux",
        recto: "Recto",
        redirectButton: "Continuer",
        replace: "Remplacer",
        reject: "Rejeter",
        rejectSignature: "Annuler la signature",
        revision: "révision",
        role: "Rôle",
        save: "Enregistrer",
        saveChanges: "Enregister les modifications",
        send: "Envoyer",
        sendRequest: "Envoyer ma requête",
        seconds: "Secondes",
        setup: "Paramétrer",
        share: "Partager",
        showLess: "Voir moins",
        showMore: "Voir plus",
        signature: "Signature",
        signatureCompleted: "Signature réussie",
        signatureCompletedSubtitle: "Tous les signataires ont bien signé tous les documents",
        signatureDate: "Date de signature",
        signee: "Représentant",
        signin: "Connexion",
        signout: "Déconnexion",
        sizeLimitExceeded: "Taille maximale dépassée",
        slug: "Slug",
        state: "État",
        storage: "Stockage",
        steps: "Étapes ",
        subdomain: "Sous-Domaine",
        summary: "Synthèse",
        switchNewUI: "Changer pour la nouvelle interface",
        switchOldUI: "Changer pour l'ancienne interface",
        template: "Document",
        test: "Test",
        testMode: "Mode Test",
        title: "Titre",
        to: "sur votre plateforme",
        total: "Total",
        totalCapitalized: "TOTAL (TVA Inclus)",
        type: "Type",
        unallowed: "Seule la personne en charge du dossier est autorisée à valider cette étape",
        unknown: "Inconnu",
        unknownUser: "Utilisateur Inconnu",
        update: "Modifier",
        updatedOn: "mis à jour à",
        upload: "Télécharger",
        url: "Url",
        user: "Utilisateur",
        users: "Utilisateurs",
        userInvite: "Invitation",
        userConnected: "Vous êtes connecté en tant que",
        variables: "Variables",
        vat: "TVA",
        vatRate: "Taux de TVA",
        vatSystem: "Régime TVA",
        verso: "Verso",
        viewAll: "Voir tout",
        validated: "Validé",
        whiteLabel: "Marque Blanche",
        welcome: "Bienvenue",
        welcomeMessage: "Bienvenue sur votre plateforme {{applicationName}} !",
        wipAutomate:
          "Ce service est actuellement en cours d'automatisation. Si vous êtes intéressé par ce service ou tout autre service qui ne se trouve pas dans notre catalogue, merci de nous contacter.",
        workflow: "Workflow",
        yes: "Oui",
        you: "C'est vous",
        yourCompany: "Votre société",
        zipCode: "Code postal",
        refuse: "Refuser",
        writeQuestion: "Écrivez ici la question",
        writeSubQuestion: "Décrivez la réponse",
      },
      download: {
        json: "JSON",
        csv: "CSV",
        pdf: "PDF",
      },
      username: {
        invalid: "Veuillez insérer votre nom!",
      },
      pagesOn: "Page {{current}} sur {{length}}",
    },
    companies: "Sociétés",
    companyName: "Dénomination sociale",
    companyNotFound: "Entreprise non trouvée",
    computedVatRecords: "Registres de TVA par pays",
    calendarPage: {
      dossier: "Statut du dossier",
      category: "Catégorie d'échéance",
    },
    composePage: {
      freemiumAuto: "Automatisez vos tâches récurrentes grâce à des fonctionnalités juridiques clés",
      freemiumUpdate: "Mettez à jour et modifiez vos workflows",
      freemiumConf: "Configurer la collaboration à vos workflows",
    },
    contactList: {
      create: "Créer un contact",
    },
    contactEmail: "Mail de contact",
    country: "Pays",
    create: "Créer",
    createAnnonce: "Créer l'annonce",
    createEntity: "Créer une société",
    createProcess: "Créer une procedure",
    createProvider: "Créer un expert",
    createTemplates: "Créer un modèle",
    createWorkflow: "Créer un processus",
    currentStep: "Etape en cours",
    dateLegalEntity: "Date d'échéance de la personne morale",
    dateClosingFiscal: "Dâte de clôture de l’exercice social",
    dashboard: {
      withEmail: "Choisir le dossier à associer à l'e-mail",
      freemiumSteps: "La modification des étapes de votre workflow",
      freemiumStatus: "La mise à jour en temps réel de votre dossier",
      freemiumDossier: "La gestion de vos dossiers archivés et terminés",
      freemiumUpdate: "La modification de l'état d'avancement de votre dossier",
      empty: "Vous n’avez pas de dossiers en cours !",
      action: {
        archive: "Archiver",
        delete: "Supprimer",
        detail: "Documents",
        duplicate: "Dupliquer",
        restore: "Restorer",
        retry: "Réessayer",
        share: "Partager",
        update: "Editer",
        upgradeWorkflow: "Mettre à jour",
      },
      column: {
        DONE: "Terminé",
        ERROR: "Erreur",
        actions: "Actions",
        author: "Auteur",
        collaborators: "Collaborateurs",
        date: "Date",
        label: "Titre",
        progress: "Progression",
        service: "Service",
        status: "Statut",
        step: "Étape",
        toDo: "À faire",
        owner: "Propriétaire",
        archivedAt: "Archivé",
      },
      delete: {
        areYouSure: "Êtes-vous sûr de vouloir supprimer ce dossier ?",
        yesDelete: "Oui, supprimer ce dossier",
      },
      label: {
        undefined: "Sans Titre",
      },
      modal: {
        share: {
          form: {
            email: {
              error: "L'email est requis",
              label: "Email",
              placeholder: "Email",
            },
            error: "Une erreur est survenue durant le partage de la formalité",
            success: "Le dossier à été partagé",
          },
          title: "Partager le dossier",
        },
      },
      searchBar: { placeholder: "Rechercher" },
      subTitle: "",
      title: "Dossiers en cours",
      upgradeWorkflow: {
        error: "Erreur lors de la mise à jour du workflow",
        success: "Le dossier à été mis à jour",
      },
      since: "Depuis",
    },
    dashboardarchive: {
      title: "Dossiers archivés",
      empty: "Vous n’avez pas de dossiers archivés !",
    },
    dashboarddeleted: {
      title: "Dossiers supprimés",
      empty: "Vous n’avez pas de dossiers supprimés !",
    },
    dashboarddone: {
      title: "Dossiers terminés",
      empty: "Vous n’avez pas de dossiers terminés !",
    },
    data: "Données",
    date: "Date",
    denomination: "Dénomination",
    display: "Afficher",
    divorced: "Divorcé(e)",
    documentList: {
      archive: "Archive",
      category: "Catégorie du document",
      confirm: {
        title: "Êtes-vous sûr de vouloir supprimer ce document?",
        accept: "Oui, supprimer le document",
        cancel: "Annuler",
      },
      create: "Créer un document",
      toValidate: "Vous avez {{count}} document(s) à valider",
      toValidateShort: "{{count}} document(s) à valider",
      noneToValidate: "Vous n'avez pas de document à valider",
    },
    docName: "Nom du document",
    documentTitle: "Titre du document",
    documentListPage: {
      delete: {
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer ce(s) document(s) ?",
        yesDelete: "Oui, supprimer le(s) document(s)",
      },
    },
    docusign: {
      actions: { sendAgain: "Renvoyer", remind: "Relancer", thank: "Remercier" },
      status: {
        authenticationfailed: {
          name: "Echec d'authentification",
          subtitle: "Le signataire a été toutes ses tentatives d'authentification.",
          title: "Echec d'authentification",
        },
        completed: {
          name: "Complétée",
          subtitle: "Tous les documents ont été signés par tous les signataires le {{date}} à {{time}}.",
          title: "Documents déjà signés",
        },
        declined: {
          name: "Refusée",
          subtitle:
            "Un ou plusieurs signataires ont refusé la signature. Consultez la table ci-dessous pour identifier et contacter les signataires qui bloquent le dossier.",
          title: "La signature a été refusée",
        },
        delivered: {
          name: "Reçue",
          subtitle:
            "Tous les signataires ont reçu et ouvert le lien de signature Docusign®. Consultez la table ci-dessous pour relancer les signataires qui bloquent le dossier.",
          title: "Les signataires ont tous reçu l'enveloppe",
        },
        autoresponded: {
          name: "Jamais Reçu",
          subtitle:
            "Le signataire n'a jamais reçu le lien de signature Docusign®. Consultez la table ci-dessous pour relancer les signataires qui bloquent le dossier.",
          title: "Les signataires ont tous reçu l'enveloppe",
        },
        deliveryfailed: {
          name: "Jamais Reçu",
          subtitle:
            "Le signataire n'a jamais reçu le lien de signature Docusign®. Consultez la table ci-dessous pour relancer les signataires qui bloquent le dossier.",
          title: "Les signataires ont tous reçu l'enveloppe",
        },
        draft: {
          name: "En cours de traitement",
          subtitle:
            "Nos équipes verifient les derniers détails de votre enveloppe et vous en recevrez le lien de signature finale sous peu. Vous pourrez ensuite consulter la table ci-dessous pour être à jour de l'état de chaque signataire.",
          title: "Nous verifions votre enveloppe de signature",
        },
        pending: {
          // copy of draft
          name: "En cours de traitement",
          subtitle:
            "Nos équipes verifient les derniers détails de votre enveloppe et vous en recevrez le lien de signature finale sous peu. Vous pourrez ensuite consulter la table ci-dessous pour être à jour de l'état de chaque signataire.",
          title: "Nous verifions votre enveloppe de signature",
        },
        sent: {
          name: "Envoyée",
          subtitle:
            "Un lien de signature a été adressé à tous les destinataires via la plateforme sécurisée Docusign®. Veuillez vérifier votre boite de réception ainsi que la liste des adresse email ci-dessous.",
          title: "L'enveloppe de signature a été envoyée",
        },
        signed: {
          name: "Signée",
          subtitle: "L'enveloppe de signature a été signée par le destinaire.",
          title: "L'enveloppe de signature a été signée",
        },
        voided: {
          name: "Annulée",
          subtitle:
            "L'enveloppe de signature Docusign a été annulée ou éfacée par votre administrateur. Contactez le support si cela vous semble anormal",
          title: "L'enveloppe de signature a été annullée",
        },
      },
      text: { docusignEnvelope: "enveloppe Docusign®" },
    },
    domainName: "Noms de domaine",
    done: "Terminé",
    editDate: "Date de modification",
    editDocument: "Editer le document",
    editMember: "Editer un membre",
    editProduct: "Editer le produit",
    editProfile: "Edition du profil",
    editWorkflow: "Editer un processus",
    email: "Mail",
    emailDetail: {
      start: "Démarrer le dossier",
      add: "Ajouter à un dossier",
      to: "À",
      cc: "CC",
      bcc: "CCI",
      open: "Ouvrir l'email",
    },
    emailList: {
      title: "Boîte de réception",
      noselected: "Aucun message sélectionné",
      details: {
        total: "{{ total }} messages",
        unviewed: "{{ total }} non lus",
      },
    },
    entity: {
      creator: "Contact interne",
      legalRep: "Représentant légal",
      legalRepType: "Type de représentant légal",
      primaryContact: "Contact principal",
      primaryContactEmpty: "Informations sur la personne à contacter",
      empty: "Retrouvez ici tous vos contacts",
      title: "Mes contacts",
      who: "Qui est votre contact ?",
      all: "Tous les contacts",
      delete: {
        title: "Êtes-vous sûr(e) de vouloir supprimer ce contact ?",
        confirm: "Oui, supprimer ce contact",
      },
      company: {
        actions: {
          changeCapital: "Changer le capital",
          changeDirectors: "Changer les dirigeants",
          changeHeadquarters: "Changer l'adresse",
          changeName: "Changer le nom / sigle",
        },
        description: "Personne morale",
        listTitle: "Vos Sociétés",
        name: "Société",
        newSubTitle: "Créez une société",
        newTitle: "Nouvelle Société",
        share: "Partagez une entreprise",
        shareAccountant: "Partager avec votre comptable",
        typeName: "Sociétés",
        vat: {
          calendarEmpty: "Vous êtes à jour dans votre agenda",
          claimableVat: "Crédit de TVA",
          collectedOnSales: "Perçus sur vos ventes",
          owedVat: "TVA à payer",
          paidOnPurchases: "Payés durant vos achats",
          uploadModalTitle: "Complétez les informations de ce document",
          invoiceDetails: "Détails de la facture",
          uploadNoData: "Aucune facture pour l'instant",
          addEvent: "Ajoutez un évenement au calendrier",
          addDeadline: "Ajouter une échéance",
          allDeadlines: "Toutes les échéances",
          editDeadline: "Modifier une échéance",
          addDeadlineForm: {
            name: "Nom de l’échéance",
            type: "Type d’échance",
            types: {
              accounting: "Comptable",
              contractual: "Contractuelle",
              fiscal: "Fiscal",
              social: "Sociale",
            },
            date: "Date",
            notification: "Me notifier avant échéance",
            description: "Description",
            characters: "caractères",
            workflowAssociated: "Workflow associé",
          },
          amount: "Montant (hors TVA)",
          amountHT: "Prix HT",
          calendar: "Calendrier",
          contact: "Contacter",
          deadlineType: "Echéance {{type}}",
          descriptionPlaceholder: "description",
          descriptionRequired: "veuillez entrer une description",
          nameRequired: "veuillez entrer un nom",
          recordType: { expense: "Achats", income: "Ventes" },
          vatRecordForm: {
            title: "Téléchargement de facture",
            category: "Catégorie",
            expenseLabel: "Description",
            invoiceNumber: "Numéro de facture",
            grossAmount: "Montant HT",
            taxRate: "Taux de TVA",
          },
          quarter: "Trimestre",
          deleteEvent: {
            delete: "Êtes-vous sûr(e) de vouloir supprimer cette échéance ?",
            confirm: "Oui, supprimer l’échéance",
            cancel: "Annuler",
          },
          send: "Envoyer",
          upload: "Ajouter",
          vat: "TVA",
        },
      },
      entities: {
        error: "Une erreur est survenue pendant la mise à jour du contact",
        fullAccess: "accès total",
        invite: "Invitez un collaborateur",
        isPublic: "Partager ce contact avec tous les membres de la plateforme",
        listSubTitle: "Liste de toutes vos contacts",
        listTitle: "Contacts",
        name: "Contact",
        newSubTitle: "Créez une nouvelle abstraite",
        newTitle: "Nouveau Contact",
        rightsDescription: "description des accès",
        success: "Le contact a été mis à jour ",
        typeName: "Contact",
      },
      houses: {
        listSubTitle: "",
        listTitle: "Vos Maisons",
        name: "Maison",
        newSubTitle: "Commandez une nouvelle maison",
        newTitle: "Nouvelle Maison",
        typeName: "Maisons",
      },
      new: "Créer un contact",
      persons: {
        listSubTitle: "",
        listTitle: "Vos Clients",
        name: "Personne",
        newSubTitle: "Enregistrez une nouvelle personne",
        newTitle: "Nouvelle Personne",
        typeName: "Personnes",
        description: "Personne physique",
      },
      share: {
        title: "Partager le contact",
        error: "Une erreur est survenue durant le partage du contact",
        success: "Votre contact a été partagé",
      },
    },
    entityAdmin: {
      userId: {
        label: "Crée par",
      },
      delete: {
        description: "Il sera supprimé de tous les dossiers auxquels il contribue",
        title: "Êtes-vous sûr(e) de vouloir supprimer ce collaborateur ?",
        yesDelete: "Oui, supprimer ce collaborateur",
      },
      invite: "Inviter un collaborateur",
      inviteNewMembers: "Inviter un ou plusieurs collaborateurs à rejoindre",
    },
    entityCategories: {
      empty: "Vous n’avez pas encore de contacts !",
      add: "Ajouter un contact",
    },
    entityDossiers: {
      all: "Tous mes dossiers",
      dossier: "Dossier associé",
      service: "Service associé",
      empty: "Vous n’avez pas encore de dossiers pour ce contact !",
    },
    entityForm: {
      entityTypeSelection: "Veuillez sélectionner le type de contact",
      categoryUnvailable: {
        text: "Cette fonctionnalité n'est pas encore configurée pour votre plateforme. Votre rôle ne vous permet pas de configurer cette fonctionnalité",
        redirect: "Vérifier mon rôle et mes permissions",
      },
      categoryCreate: {
        text: "Cette fonctionnalité n'est pas encore configurée pour votre plateforme",
        redirect: "Configurer mes contacts",
      },
    },
    entityPage: {
      actionsTab: "Actions",
      admin: "Collaborateurs",
      detailsTab: "Synthèse",
      dossiersTab: "Dossiers",
      documentsTab: "Documents",
      title: "Détails",
      accountancyTab: "Comptabilité",
      calendarTab: "Échéances",
      summary: {
        upcomingDeadlines: "Prochaines échéances",
        lastDossiers: "Derniers dossiers en cours",
      },
    },
    entityType: {
      new: "Ajouter un(e) nouveau(elle)",
      error: {
        name: "Veuillez donner un nom à ce contact",
      },
    },
    entityTypeCreate: {
      title: "Créer un contact",
    },
    entityCategoryForm: {
      placeholder: {
        name: "Nom du contact en camelCase à utiliser dans les formulaires",
        slug: "Nom du contact à utiliser dans l'url",
        category: "Catégorie du contact dans l'onglet Mes contacts",
      },
    },
    entityTypeForm: {
      placeholder: {
        name: "Nom du contact en camelCase à utiliser dans les formulaires",
        slug: "Nom du contact à utiliser dans l'url",
        type: "Nom du contact à afficher dans le tableau",
      },
      creationForm: {
        label: "Editez le formulaire de creation",
      },
      description: {
        label: "Description",
        placeholder: "Entrez la description du contact",
      },
      entityNamePath: {
        label: "Nom d'affichage",
        placeholder: "Entrez le nom d'affichage (JMES path)",
      },
      icon: {
        label: "Icone",
        placeholder: "Nom de l'icône d'Ant Design",
      },
      namePlural: {
        placeholder: "Entrez le nom du contact au pluriel",
      },
      position: {
        placeholder: "Entrez un nombre pour la position dans le menu de gauche",
      },
      type: {
        error: "Veuillez selectionner un type",
      },
    },
    entityTypesList: {
      empty: "Vous n’avez pas encore de type !",
      add: "Ajouter un type",
    },
    entityView: {
      import: {
        title: "Importer",
        button: "Sélectionner",
      },
      select: {
        button: "Choisir dans la liste",
      },
      create: {
        title: "Créer",
      },
    },
    enumerationList: {
      create: "Créer une variable",
      delete: {
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer cette variable ?",
        yesDelete: "Oui, supprimer cette variable",
        subTitle:
          "La suppression de cette variable aura un impact sur les formulaires dans lesquels elle est utilisée. Assurez-vous qu'il n'y a pas de dépendances",
      },
    },
    enumerationForm: {
      saved: "Variable enregistrée avec succès",
      alreadyExists: "Une variable avec ce nom existe déjà",
      placeholder: {
        name: "Nom",
        defaultValue: "Valeur par défaut",
      },
      option: {
        value: "Valeur",
        label: "Nom d'affichage anglais",
        labelFr: "Nom d'affichage français",
        delete: {
          areYouSure: "Êtes-vous sûr(e) de vouloir supprimer cette option ?",
          yesDelete: "Oui, supprimer cette option",
          subTitle:
            "La suppression de cette option aura un impact sur les formulaires dans lesquels elle est utilisée. Assurez-vous qu'il n'y a pas de dépendances",
        },
      },
    },
    factureName: "Nom/numero de la facture",
    faqPage: {
      displayAll: "Toutes les réponses",
      delete: {
        yesDelete: "Oui, supprimer la page",
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer cette question de la F.A.Q ?",
      },
      goFurther: "Aller plus loin",
      headerTitle: "Comment puis-je vous aider ?",
    },
    videoPage: {
      displayAll: "Toutes les vidéos",
      delete: {
        yesDelete: "Oui, supprimer la video",
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer cette video de la Vidéothèque ?",
      },
      headerTitle: "Vidéothèque",
      form: {
        title: "Ajouter une vidéo",
        question: "Titre de la vidéo",
      },
    },
    featureFreemium: {
      title: "Votre accès FREEMIUM ne vous permet pas d'accéder à cette fonctionnalité",
      content: "Activez le plan Starter et accéder à :",
      freemiumButton: "Activez cette fonctionnalité",
    },
    file: {
      purpose: {
        headquarters: "Siège Social",
        kyc: "KYC",
        proofOfResidence: "Justificatif de domicile",
      },
      type: {
        companyLeaseAgreement: "Contrat de bail au nom de la société",
        companyRegistrationCertificate: "Certificat Kbis",
        domiciliationAgreement: "Contrat de domiciliation",
        hostID: "Pièce d'identité de l'hébergeant",
        leaseAgreement: "Contrat de bail",
        proofOfResidence: "Justificatif de domicile",
        residenceAttestation: "Attestation de Résidence",
        visa: "Visa",
      },
    },
    fillForm: "Remplir le formulaire",
    firstName: "Prenom",
    firstQuote: "What's up today",
    fithQuote: "What's up today",
    fix: "Fixe",
    forgotPassword: {
      form: {
        button: "J'ai oublié mon mot de passe",
        email: {
          error: "L'email est requis",
          placeholder: "Entrez votre email",
        },
        error: "Une erreur est survenue",
        signin: "<1>Retour à la page de connexion</1>",
      },
      title: "Mot de passe oublié",
    },
    forgotPasswordResult: {
      content: "Un lien pour réinitialiser votre mot de passe a été envoyé à",
      description: "Un lien pour réinitialiser votre mot de passe a été envoyé à",
      signin: "Connectez-vous !",
      signinPrompt: "Vous avez déjà réinitialisé votre mot de passe ?",
      receiveEmail: "Pas reçu d’email ?",
      title: "Votre demande est prise en compte",
      resend: "Renvoyer",
    },
    form: "Formulaire",
    formRunner: {
      common: {
        add: "Ajouter",
        save: "Enregistrer et continuer",
        import: "Importer",
        remove: "Supprimer",
      },
      fieldView: {
        fake: "Simuler une saisie avec des informations aléatoires",
        fakeAll: "Simuler une saisie avec des informations aléatoires pour tout le formulaire",
        fileView: { maxSize: "Taille maximale par fichier" },
      },
      fields: {
        idCheck: {
          generic: "Impossible de récupérer les informations",
        },
        info: {
          title: "Informations",
        },
        insee: {
          companyNotFound: "Impossible de trouver une entreprise avec ce numero de SIRET",
        },
        bodaccModalTitle: "Annonces publiées au Bodacc pour {{search}}",
      },
      fileUploadInput: {
        error: "Le téléchargement a échoué",
        passport: "Double page (photo & signature)",
      },
    },
    bugRequest: {
      title: "Soumettre un bug",
      subTitle: "Décrivez votre problème et précisez tous les éléments qui pourraient aider à le résoudre :",
      placeholder: "Décrivez ici votre problème",
      sendButton: "Soumettre mon bug",
    },
    featureRequest: {
      title: "Demander une fonctionnalité",
      subTitle: "Décrivez ici la fonctionnalité que vous voulez et précisez tout ce qui pourrait être utile :",
      placeholder: "Décrivez ici votre demande",
      sendButton: "Envoyez ma demande",
      success: "Votre message a été envoyé avec succès !",
    },
    serviceRequest: {
      title: "Demander un service",
      subTitle: "Décrivez ici le service que vous souhaitez automatiser :",
    },
    forms: "Formulaires",
    fourthQuote: "What's up today",
    fullname: "Nom",
    goToDashboard: "Retour au Dashboard",
    goToPersonalSpace: "Accéder à mon espace",
    headquarter: "Siège social",
    headOffice: "Adresse du siège social",
    historyCapital: "Historique du capital social",
    immatriculation: "Immatriculation",
    immatriculationDate: "Date d'immatriculation",
    import: {
      error: "Une erreur indéfinie s'est produite",
      product: {
        error: "Une erreur s'est produite durant l'import du service",
        success: "Le service a été importé",
      },
      process: {
        error: "Une erreur s'est produite durant l'import du workflow",
        success: "Le workflow a été importé",
      },
    },
    importCompany: "Ajouter les informartions",
    information: "Informations",
    indexRepetition: "Complément",
    inputCompanyIdentification: "Voulez vous créer une entreprise via son numéro de SIRET",
    investor: "Investisseur",
    invit: "Inviter",
    invoices: {
      freemiumGestion: "La gestion de votre facturation",
      freemiumCustom: "La personnalisation de vos modèles de facture et la numérotation",
      freemiumHistory: "L'historique des paiements",
    },
    invitMembers: "Inviter des membres",
    invitMembersTitle: "Invitation d'un membre",
    inviteUser: "Inviter un utilisateur",
    job: {
      error: {
        name: "Veuillez donner un nom à ce job",
        category: "Le nom de la catégorie doit être unique",
      },
      title: { label: "Choisissez un nom descriptif" },
      type: { label: "Qu'allons-nous automatiser maintenant ?" },
    },
    jobCreate: {
      form: { success: "Le job a été créé" },
      subTitle: "Automatisez un job",
      title: "Job",
    },
    jobList: {
      column: {
        filter: {
          placeholder: "Chercher le nom",
          reset: "Effacer",
          submit: "Filtrer",
        },
        label: "Nom",
        type: "Type",
      },
      create: { jobs: "Créer une étape", forms: "Créer un formulaire" },
      subTitle: "Vos procédures sont composées de jobs",
      title: "Jobs",
      empty: {
        jobs: "Créer les étapes de vos workflows",
        forms: "Composer vos formulaires ici",
      },
    },
    jobUpdate: {
      form: { success: "Le formulaire a été mis à jour" },
      job: { success: "L'étape a été mis à jour" },
      subTitle: "Changez la configuration du job",
      title: "Job",
    },
    jobForm: {
      JobDocumentsFields: {
        addUploadedDocuments: {
          label: "Ajouter les documents téléchargés",
        },
        documents: {
          labelLong: "Selectionnez les documents à ",
        },
        templates: "Mes Documents",
        uploadedDocuments: "Documents téléchargés",
        uploadedDocumentsPath: { label: "Filtrer les documents selon un chemin", placeholder: "membres" },
        uploadedDocumentsTypes: {
          label: "Filtrer les documents selon leur type",
        },
        verb: {
          sign: "signer",
          attach: "joindre",
          send: "envoyer",
        },
      },
    },
    lang: "fr",
    lastName: "Nom",
    layout: {
      navigation: {
        administrativeFormalities: "Formalités administratives - SASU pour startups",
        analytics: "Analyser",
        application: "Ma plateforme",
        applications: "Portfolio Alf",

        categories: "Catégories",
        company: "Ma société",
        compose: "Composer",
        contacts: "Mes contacts",
        dossiers: {
          title: "Mes dossiers",
          inProgress: "En cours",
          archive: "Archivés",
          done: "Terminés",
          deleted: "Supprimés",
        },
        entities: "Mes entités",
        entitiesType: "Entités",
        forms: "Mes formulaires",
        jobs: "Mes étapes",
        clauses: "Mon clausier",
        platform: "Ma plateforme",
        procedures: "Workflows",
        processes: "Workflows",
        products: "Products",
        reports: "Mes rapports",
        settings: "Paramétrer",
        store: "Mes services",
        services: "Services",
        storeSettings: "Réglages Store",
        templates: "Mes documents",
        userInvites: "Invitations",
        users: "Utilisateurs",
        welcome: "Bienvenue",
        workflows: "Mes workflows",
        workflowJobs: "Workflows",
        formsJobs: "Formulaire",
      },
      header: {
        faq: "Consulter la F.A.Q",
        video: "Accéder à la vidéothèque",
        submitBug: "Soumettre un bug",
        requestFeature: "Demander une fonctionnalité",
      },
    },
    leaders: "Dirigeants",
    legalForm: "Forme juridique",
    linkDevis: "Lien vers le devis",
    linkSpecimen: "Lien vers le specimen",
    listCompany: "Sociétés",
    listForm: "Formulaires",
    listPartener: "Associés",
    listProcess: "Procédures en cours",
    listProvider: "Experts",
    listTemplates: "Modèles",
    listUser: "Utilisateurs",
    listVatRecords: "Factures",
    login: "Se connecter",
    logout: "Me déconnecter",
    mainActivity: "Activité principale",
    mainInfo: "Information principale",
    maritalStatus: "Statut matrimonial",
    married: "Marié(e)",
    memberMsg: "Le membre à été invité",
    members: "Membres",
    model: "Modèles",
    myCompanies: "Mes sociétés",
    name: "Nom",
    nbActions: "# Actions",
    new: "Nouveau",
    next: "Suivant",
    nextStep: "Prochaine étape ",
    no: "Non",
    noProcess: "Pas de procédure en cours",
    notFound: {
      content: "La page demandée n'a pas été trouvée",
      title: "Page manquante",
    },
    notStarted: "A commencer",
    number: "numéro",
    office: "Bureaux",
    offices: "Bureaux",
    owner: "Propriétaire",
    partnersAndLeaders: "Associés et Dirigeants",
    paymentCompleted: { title: "Votre paiement a déjà été effectué" },
    paymentForm: {
      PaymentSuccessRedirection: {
        subTitle: "Cliquez ci-dessous ou attendez 5 secondes pour continuer",
        title: "Merci !",
      },
    },
    pending: "En attente",
    percentCapital: "% Capital",
    personList: {
      subTitle: "Liste de toutes les personnes enregistrées",
      title: "Personnes",
    },
    phone: "Téléphone",
    platformInformations: {
      freemiumPerso: "La personnalisation de votre plateforme de A à Z",
      freemiumInteg: "L'intégration de votre logo",
      freemiumChoice: "Le choix de vox couleurs, polices de caractère et librairies d'icônes",
    },
    placeholderEmail: "Adresse email",
    placeholderPassword: "Mot de passe",
    previewDocument: "Prévisualiser le document",
    price: "Prix",
    process: "Workflows",
    processInstance: {
      details: "Détails",
      noDocuments: "Vous n’avez pas encore de documents !",
      succes: "Succèss",
      title: "Procédure",
      correspondence: {
        tabTitle: "Correspondances",
        empty: "Retrouvez ici toute votre correspondance relative au dossier",
      },
      journal: {
        tabTitle: "Journal",
        dateLabels: {
          today: "Aujourd'hui",
          yesterday: "Hier",
        },
        filterLabels: {
          all: "Toutes les intéractions",
          ACTIVITY: "Activité",
          DOCUMENT: "Document",
          EMAIL: "Email",
          SHARE: "Invitation",
          VALIDATION: "Validation",
          TIME: "Timer",
        },
        logText: {
          title: {
            generic: "Dernière activité",
            NEXT_STEP_PROCESS_INSTANCE: "Changement d'étape",
            START_PROCESS_INSTANCE: "Statut du dossier",
            FINISH_PROCESS_INSTANCE: "Statut du dossier",
            INCOMMING_MAIL: "Email",
            OUTGOING_MAIL: "Email",
            ADDED_MAIL: "Email",
            DOCUMENT_REJECTION: "Validation",
            DOCUMENT_VALIDATION: "Validation",
            DOCUMENT_UPLOAD: "Document",
            DOCUMENT_GENERATE: "Document",
            ACTIVITY_TRACKED: "Suivi du temps",
            SHARE_PROCESS_INSTANCE: "Invitation",
          },
          message: {
            NEXT_STEP_PROCESS_INSTANCE: "Le dossier est déplacé à l’étape <2>{{nextStepIndex}}. {{nextStepName}}</2>",
            START_PROCESS_INSTANCE: "Dossier <1>{{processInstanceName}}</1> est commencé",
            FINISH_PROCESS_INSTANCE: "Dossier <1>{{processInstanceName}}</1> est cloturé",
            INCOMMING_MAIL: "Un email avec l’objet <1>{{subject}}</1> a été reçu",
            OUTGOING_MAIL: "Un email avec l’objet <1>{{subject}}</1> a été envoyé",
            ADDED_MAIL: "Un email avec l’objet <1>{{subject}}</1> a été ajouté",
            OUTGOING_MAIL_SUB: "Une réponse avec l'objet <1>{{subject}}</1> est réceptionnée",
            DOCUMENT_REJECTION: "Le document <1>{{documentName}}</1> est rejeté",
            DOCUMENT_VALIDATION: "Le document <1>{{documentName}}</1> est validé",
            DOCUMENT_UPLOAD: "Un nouveau document <1>{{documentName}}</1> est ajouté",
            DOCUMENT_GENERATE: "Un nouveau document <1>{{documentName}}</1> est généré",
            ACTIVITY_TRACKED: "Une activité de <1>{{timeAdded}}</1> a été suivie",
            ACTIVITY_TRACKED_NOTE: " avec la note suivante <1>{{note}}</1>",
            SHARE_PROCESS_INSTANCE: "Le dossier est partagé avec <1>{{content}}</1>",
            SHARE_PROCESS_INSTANCE_GROUP: "Le dossier est partagé avec le groupe <1>{{content}}</1>",
          },
        },
      },
      email: {
        quiz: "Voulez-vous enregistrer une copie de ce mail dans votre dossier ?",
        yes: "Oui",
        no: "Non",
      },
    },
    processInstanceUpdate: {
      handling: {
        toRevalidateModal: {
          title: "Vous pouvez choisir :",
          reRunDescription: "<strong>{{processInstanceStepIndex}}. {{processInstanceName}}</strong>",
          reRun: "Relancer",
          skip: "Passer",
        },
        inviteUsers: {
          success: "Une invitation à ce dossier a été envoyée à <strong>{{target}}</strong>",
        },
        reject:
          "Cliquer sur ce bouton annulera cette étape et vous amènera à la première étape du processus. Contactez un responsable pour obtenir de l'aide.",
      },
      done: {
        action: "Continuer mon dossier",
        title: "Vous avez terminé votre procédure",
      },
      payment: {
        action: "Effectuer le règlement",
        configurationError: "Erreur de configuration paiement. Avez-vous pensé à renseigner vos clés d'api Stripe ?",
        error: "Erreur lors du paiement",
      },
      processing: {
        default: {
          description: "Un instant s'il-vous-plaît",
          title: {
            0: "On plante des arbres",
            1: "On compte les abeilles",
            2: "On dessine des arcs-en-ciel",
            3: "On nettoie les océans",
            4: "Et on regarde les oiseaux voler",
          },
        },
        generateDocuments: {
          description: "Nous préparons vos documents.",
          title: {
            0: "On plante des arbres",
            1: "On compte les abeilles",
            2: "On dessine des arcs-en-ciel",
            3: "On nettoie les océans",
            4: "Et on regarde les oiseaux voler",
          },
        },
        publishing: {
          description: "Publication en cours...",
          title: "Envoi pour impression",
        },
        publishLegalNotice: {
          description: "Nous analysons le planning de 323 journaux...",
          title: {
            0: "Selection du meilleur journal",
            1: "Analyse des calendriers",
            2: "Compresssion des mots",
          },
        },
        sendDocuments: {
          description: "Envoi de l'email",
          title: {
            0: "Vérifiez votre boîte de réception",
          },
        },
        sendLetter: {
          description: "Votre lettre est en préparation",
          title: {
            0: "On plante des arbres",
            1: "On compte les abeilles",
            2: "On dessine des arcs-en-ciel",
            3: "On nettoie les océans",
            4: "Et on regarde les oiseaux voler",
          },
        },
        signDocuments: {
          description: "Nous préparons l'enveloppe de signature",
          title: {
            0: "On plante des arbres",
            1: "On compte les abeilles",
            2: "On dessine des arcs-en-ciel",
            3: "On nettoie les océans",
            4: "Et on regarde les oiseaux voler",
          },
        },
      },
      timer: {
        title: "Suivi du temps",
        stop: "Êtes-vous sûr de vouloir enregistrer votre activité de <strong>{{time}}</strong> pour ce dossier ?",
        note: "Note",
        reset: "Supprimer cette session",
        tabs: {
          timer: "Minuteur",
          manual: "Manuel",
          range: "Plage",
        },
      },
      publishLegalNotice: {
        alreadyPublished:
          "Votre annonce légale paraitra dans {{distance}} jours et a déjà été plannifiée pour la parution du {{date}}.",
        description:
          "Nous avons trouvé le meilleur moment pour votre annonce légale dans {{distance}} jours et elle sera publiée dans {{newspaper}} le {{date}}. Veuillez vérifier l'aperçu ci-dessous et contactez-nous si vous remarquez la moindre anomalie. Si tout vous semble en ordre, cliquez sur Publier.",
        error: "Une erreur est survenue durant la soumission",
        newspaper: {
          coveredDepartments: { title: "Departments couverts" },
          digitalCertificate: { title: "Certificat digital" },
          economicFormat: { economic: "Economique", standard: "Standard", title: "Format" },
          publicationDays: { title: "Jours de publication" },
          publicationType: {
            title: "Type de publication",
            online: "Internet",
            print: "Papier",
          },
        },
        orderInfo: {
          companyName: { title: "Nom de la société" },
          companyZipCode: { title: "Code postal de la société" },
          orderNumber: { title: "Numéro de commande" },
          publicationDate: { title: "Date de publication" },
          title: "Détail de votre commande",
        },
        publishing: "Publication en cours...",
      },
      reviewDocuments: {
        draft: "Brouillon",
        description: "Pour les documents en état ",
        endDescription: "veillez bien à vérifier que les informations du document sont correctes avant de valider.",
        GDPR: "RGPD",
        descriptionConfidential: "Tous les documents qui contiennent la mention ",
        endDescriptionConfidential: "sont confidentiels et soumis à des règles strictes de partage et de sécurité.",
        rectify: "Corriger",
        title: "Examinez puis validez vos documents",
        validateAll: "Valider tous les documents",
        information: "Mes informations",
        verify: "Informations",
        compare: "Comparer",
        archive: "Vos documents archivés",
        all: "Tous mes documents",
        validateDocument: "Valider ce document",
        search: "Rechercher un document",
        notAllowed:
          "Vos droits d'accès ne vous permettent pas de valider les documents. Cependant, vous pouvez les examiner. Une personne disposant de droits d'accès supérieurs examinera et validera bientôt cette étape.",
      },
      signDocuments: {
        signersList: { title: "Signataires" },
      },
      sendLetter: {
        reviewDocuments: {
          title: "Vérifiez les documents à envoyer dans ce courrier",
          description:
            "Vous trouverez ci-dessous tous les documents de ce courrier. Vous pouvez les modifier, les télécharger ou les remplacer en cliquant sur les icônes situées à côté de chaque document.",
        },
        sendLetter: {
          success: "La lettre a été envoyée avec succès",
          error: "Impossible d'envoyer la lettre",
        },
        cancelLetter: {
          success: "La lettre a été annulée avec succès",
          error: "Échec de l'annulation de la lettre",
        },
        timeLeft: "Temps restant pour annuler la lettre",
        update: { success: "La configuration de la lettre a été mise à jour" },
      },
    },
    processOverview: {
      step: {
        description: {
          done: "L'étape s'est déroulée avec succès",
          error: "Une erreur est survenue durant l'étape",
          waiting: "L'étape est en cours de traitement",
        },
      },
    },
    documentCard: {
      all: "Tous les documents",
    },
    product: "Produits",
    productCard: {
      days: "Jours",
      documentsGenerated: "Documents",
      steps: "Étapes",
      vatIncluded: "TTC",
      vatExcluded: "HT",
      allServices: "Tous mes services",
    },
    productCreate: {
      form: {
        error: "Une erreur est survenue durant la création",
        success: "Le produit a été créé",
      },
      subTitle: "Création d'un service dans le store",
      title: "Créer un service",
    },
    productDetails: {
      title: "Détails du service",
      free: "Gratuit",
      start: "Démarrer",
      steps: "Vos étapes",
      variable: "Variable",
      serviceType: "Type de service",
      value: {
        internal: "Interne",
        external: "Externe",
      },
    },
    productForm: {
      category: {
        label: "Catégorie du store",
        placeholder: "Entrez la catégorie du produit",
      },
      delete: {
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer ce service ?",
        yesDelete: "Oui, supprimer ce service",
        subTitle: "La suppression du service entraine la suppression des dossiers et documents associés à ce dernier",
      },
      description: {
        error: "Le sommaire est requis",
        lengthError: "Le sommaire peut comporter jusqu'à 150 caractères",
        label: "Sommaire",
        placeholder: "Entrez le sommaire du produit",
      },
      detailedDescription: {
        error: "La description détaillée est requise",
        label: "Description détaillée",
        placeholder: "Entrez la description détaillée du produit (syntaxe markdown acceptée)",
      },
      steps: {
        label: "Étapes",
      },
      documents: {
        label: "Documents",
      },
      entity: {
        action: {
          label: "Action contact",
        },
        category: {
          label: "Catégorie de contact",
        },
      },
      estimatedTime: {
        label: "Délai",
        estimated: "Estimé en",
        errorEstimatedTime: "Le délai est requis",
        errorEstimatedTimeType: "Le type du délai est requis",
      },
      generatedDocsDescription: {
        disclaimer: "Des documents additionels seront générés selon votre situation",
        label: "Nous préparons ces documents pour vous",
        labelForm: "Sélectionnez les principaux documents obtenus ou générés",
        placeholder: "Sélectionnez les documents",
      },
      icon: {
        label: "Icône du store",
        placeholder: "Téléchargez l'icône du produit",
      },
      options: {
        placeholder: "Options de la procédure",
      },
      display: {
        label: "Affichage",
        information: "Informations à afficher dans la carte du service (3 maximum)",
      },
      position: {
        error: "La position est requise",
        label: "Position dans le store",
        placeholder: "Choisissez la position du produit dans le store",
      },
      price: {
        error: "Le prix est requis",
        label: "Prix du Service - en centimes d'Euros",
        placeholder: "Définissez le prix du produit",
      },
      priceDescription: {
        error: "La description du prix est requise",
        label: "Détails du prix",
        placeholder: "Décrivez, sous forme de texte, le détail du prix (syntaxe markdown acceptée)",
      },
      process: {
        error: "Le workflow est requis",
        label: "Workflow",
        placeholder: "Choisissez une procédure dans cette liste",
      },
      status: {
        error: "Le statut est requis",
        label: "Statut",
        placeholder: "Choisissez le statut du produit",
      },
      title: {
        error: "Le titre est requis",
        label: "Titre",
        placeholder: "Entrez le titre du produit",
      },
      uploadedDocsDescription: {
        disclaimer: "Des documents additionels peuvent être requis selon votre situation",
        label: "Vous recevrez ces documents",
        labelForm: "Sélectionnez les principaux documents reçus",
        placeholder: "Sélectionnez les documents",
      },
      priceIncludesVat: {
        label: "TVA",
        error: "TVA est requis",
      },
    },
    priceIncludesVat: {
      label: "TVA",
      error: "TVA est requis",
    },
    clauseList: {
      column: {
        title: "Nom de la clause",
      },
      create: "Créer un clausier",
    },
    productCategory: {
      delete: {
        form: {
          error: "Une erreur s'est produite lors de la suppression de la catégorie",
          success: "La catégorie a été supprimée",
        },
        confirm: {
          yesDelete: "Oui, supprimer cette catégorie",
          areYouSure: "Voulez-vous vraiment supprimer cette catégorie ?",
        },
      },
    },
    productList: {
      info: 'Pour sélectionner un service dans le catalogue, cliquez sur l\'icône "Étoile" dans le coin supérieur droit de la carte de service',
      column: {
        description: "Description",
        status: "Statut",
        title: "Titre",
      },
      confirm: {
        title: "Êtes-vous sûr de vouloir supprimer ce produit ?",
      },
      empty: "Proposez ici votre catalogue de services",
      subTitle: "Offrez vos services dans le store",
      title: "Catalogue de services",
      tabTitle: {
        first: "Services internes",
        second: "Services externes",
      },
      freemiumLimit: "Choisissez jusqu'à 10 services de votre choix",
      freemiumAutomize: "Automatisez vos propres services et catégorisez-les selon vos besoins",
      freemiumCustom: "Personnalisez vos services avec vos couleurs et vos icônes",
      withEmail: "Choisir le service à associer à l'email",
    },
    productUpdate: {
      form: {
        error: "Une erreur est survenue durant l'édition du produit",
        success: "Le service a été mis à jour",
      },
      subTitle: "Édition du produit à exposer",
      title: "Édition du produit",
    },
    projectType: "Type de projet",
    provider: "Experts",
    providerProcedure: "Liste des procédures",
    quarter: "Trimestre",
    rcs: "RCS",
    rcsNumber: "N°RCS",
    rcsCity: "Ville RCS",
    recoverPassword: {
      form: {
        error: "Une erreur est survenue",
        password: {
          error: "Le mot de passe est requis",
          placeholder: "Entrez votre nouveau mot de passe",
        },
        passwordConfirm: {
          error: "Le mot de passe ne correspond pas",
          placeholder: "Confirmez votre nouveau mot de passe",
          newUIPlaceholder: "Entrez votre nouveau mot de passe",
        },
        button: "Me connecter",
      },
      title: "Créer un nouveau mot de passe",
      newUITitle: "Créer un nouveau mot de passe",
      validateStatus: {
        upperCase: "Une majuscule",
        specialSymbol: "Un caractère spécial (?!/$@)",
        number: "Un chiffre",
        eightSymbols: "8 caractères minimum",
      },
    },
    registration: "Immatriculation",
    registrationDate: "Date d'immatriculation",
    regulatedActivity: "Activité règlementée",
    role: "Rôle",
    runProcess: "Démarrer la procédure",
    // save: 'Sauvegarder',
    secondQuote: "What's up today",
    selectCountry: "Selectionner un pays",
    shareWithUser: {
      placeholder: "Partager avec un groupe, un utilisateur ou un email",
      add: "Partager avec plusieurs interlocuteurs",
    },
    sendLetter: {
      actions: { sendAgain: "Envoyer à nouveau", remind: "Renvoyer" },
      status: {
        draft: {
          name: "Brouillon",
          title: "Vérifiez les informations de votre courrier",
          subTitle: "Vous pouvez modifier directement les informations, sauvegarder et prévisualiser votre courrier.",
        },
        created: {
          name: "Établi",
          title: "La lettre est créée",
        },
        accepted: {
          name: "Accepté",
          title: "La lettre est acceptée par le système d'impression et de courrier",
        },
        filingProof: {
          name: "Preuve de dépôt",
          title: "La lettre a reçu une preuve de dépôt",
        },
        sent: {
          name: "Expédié",
          title: "La lettre est envoyée",
        },
        inTransit: {
          name: "En transit",
          title: "La lettre est en transit",
        },
        waitingToBeWithdrawn: {
          name: "En attente d'être retiré",
          title: "La lettre est disponible pour être récupérée par le destinataire",
        },
        deliveryProof: {
          name: "Preuve de livraison",
          title: "La lettre a reçu un accusé de réception",
        },
        distributed: {
          name: "Distribué",
          title: "La lettre est reçue par le destinataire",
        },
        wrongAddress: {
          name: "Mauvaise adresse",
          title: "La lettre n'a pas pu être remise au destinataire",
        },
        canceled: {
          name: "Annulé",
          title: "La lettre est annulée",
        },
        error: {
          name: "Erreur",
          title: "Une erreur s'est produite",
        },
        returnedToSender: {
          name: "Renvoyé à l'expéditeur",
          title: "La lettre est renvoyée à l'expéditeur",
        },
      },
    },
    sendMailWithPreview: {
      title: "Email",
      subTitle: "Vérifiez l'email avant de l'envoyer",
      send: "Envoyer",
      header: {
        title: "Examiner le projet de mail",
        description: "Vérifier les destinataires → Vérifier le contenu → Vérifier les pièces jointes → ",
        final: "Envoyer le mail",
      },
      emailDetail: {
        title: "Détail de l'email",
        to: "À",
        cc: "CC",
        bcc: "BCC",
        subject: "Sujet",
        emailTitle: "Titre de l'email",
        message: "Message",
        modify: "Modifier l'email",
        preview: "Prévisualiser l'email",
      },
      error: {
        to: "Veuillez saisir au moins un destinataire principal",
        subject: "Veuillez saisir l'objet de l'email",
        title: "Veuillez saisir le titre de l'email",
        message: "Veuillez saisir le message de l'email",
      },
      reviewDocuments: {
        title: "Vos pièces jointes",
        description:
          "Vous trouverez ci-dessous tous les documents de cet email. Vous pouvez les modifier, les télécharger ou les remplacer en cliquant sur les icônes situées à côté de chaque document.",
      },
    },
    service: "Service",
    serviceDescription: {
      info: "Vous pouvez personnaliser le contenu de cette page en fonction de vos besoins. Essayez-le en cliquant sur l'icône d'édition dans le coin supérieur droit, à côté du bouton \"Démarrer\"",
      mainTitle: "SASU pour startups",
      mySteps: "Description du service",
      myDocuments: "Documents du dossier",
      priceDetails: "Détails du prix",
      addDocument: "+ Ajouter un document",
      infoDocuments:
        "Personnalisez les \"Documents générés\" avec vos propres modèles en cliquant sur l'icône d'édition à côté du document",
      documentsBlock: {
        titles: {
          provided: "Documents à fournir",
          generated: "Documents générés",
          received: "Documents reçus",
          displayed: "Documents à afficher dans la page du service :",
        },
        managersID: "Pièce d’identité des dirigeants",
        proofRCS: "Preuve d’enregistrement RCS",
        companyLaws: "Statuts de la, société",
        proofOccupancy: "Justificatif d’occupation des locaux",
        subscribersList: "Liste des souscripteurs d’actions",
        newByLaws: "Nouveaux statuts de la société",
        depositOfFunds: "Certificat - Dépôts des fonds",
        documentsFiling: "Certificat - Dépôt des actes",
        beneficialFiling: "Certificat - Attestation de dépôt des bénéficiaires effectifs",
        bankDetails: "Détails bancaires",
      },
      detailsBlock: {
        administrativeCosts: "Frais et débours administratifs",
        legalAnnouncement: "Publicité légale",
        serviceFees: "Frais de service",
        totalPackage: "Total forfait HT",
        additional: "Des frais supplémentaires s’appliqueront si vous choisissez une profession règlementée",
      },
      stepsBlock: {
        readySASU: "Votre SASU est prête !",
        byLaws: "Statuts",
        fillQuestionnaire: "Remplissez le questionnaire avec les informations de votre société",
        checkAndValidate: "Vérifiez et validez les statuts générés automatiquement",
        downloadDocuments: "Téléchargez les documents nécessaires pour finaliser votre formalité",
        step: "ÉTAPE",
        steps: "ÉTAPES",
      },
    },
    signedDocs: "Documents signés",
    signin: {
      form: {
        email: {
          error: "L'email est requis",
          placeholder: "Email",
        },
        error: {
          invalidCredentials:
            "Ooops... Il semblerait que vous identifiants sont invalides.\nSi vous avez oublié votre mot de passe, vous pouvez cliquer sur 'J'ai oublié mon mot de passe'",
        },
        forgotPassword: "Mot de passe oublié ?",
        login: "Connexion",
        password: {
          password: "Le mot de pass est requis",
          placeholder: "Mot de passe",
          newUIPlaceholder: "Créer un mot de passe",
        },
        signup: "Enregistrez-vous !",
        signupPrompt: "Vous n'avez pas de compte utilisateur ?",
      },
      title: "Vous nous avez manqué {{name}}!",
      newUITitleRemembered: "Vous nous avez manqué !",
      newUITitle: "Ravis de vous revoir !",
      loginViaEmail: "Me connecter avec mon email",
      noaccount: "Vous n'avez pas encore de compte ?",
      signInConnect: "Ou me connecter via",
      popoverText: {
        emailUnknown: "Aucun compte n’est associé à cet email. Souhaitez-vous",
        signup: "créer un compte ?",
        loginError:
          "Je ne reconnais pas cet email et/ou ce mot de passe. <strong>Essayez une autre combinaison !</strong>",
        validateError:
          "<strong>Cet adresse email ne semble pas valide.</strong> Recommencez ou renseignez une autre adresse email !",
      },
    },
    signup: {
      popoverText: {
        usedEmail: "Cette adresse email est déjà associée à un compte.",
        connect: "Connectez-vous",
      },
      firstLabel: "Bienvenue chez {{name}} !",
      secondLabel: "Accéder à la plateforme via",
      form: {
        button: {
          text: "Inscription",
          newUIText: "Créer un compte",
        },
        email: {
          error: "Vous devez saisir votre email",
          placeholder: "Email",
          // label: 'Ou créer un compte avec votre adresse email ',
          label: "Créer un compte avec votre adresse email ",
        },
        error: {
          generic: "Impossible de vous connecter avec cet email / mot de passe",
        },
        password: {
          error: "Vous devez saisir votre mot de passe",
          placeholder: "Créez un mot de passe (8 caractères min.)",
          newUIPlaceholder: "Créer un mot de passe",
        },
        termsOfService: {
          first: "J’accepte les",
          second: " Conditions Générales d’Utilisation",
        },
        passwordConfirm: {
          passwordsDoNotMatch: "Les mots de passe ne correspondent pas... encore",
          placeholder: "Confirmez votre mot de passe",
        },
        signin: "Déjà inscrit ? <1>Connectez-vous!</1>",
        terms: {
          error: "Vous devez accepter les conditions générales d'utilisation pour accéder au service",
          text: "J'accepte <1>les Conditions générales d'utilisation</1>",
          url: "https://thisisalf.com/termes",
        },
      },
      invite: {
        expired: "L'invitation a expiré",
        title: "Acceptez l'invitation sur {{name}}",
      },
      title: "Inscrivez-vous sur {{name}}",
      hadAccount: {
        label: "Vous avez déjà un compte ?",
        link: "Me connecter",
      },
      mainTitle: "La plateforme",
      subTitle: "des juristes innovants",
    },
    since: "Depuis le",
    single: "Célibataire",
    siren: "SIREN",
    siret: "SIRET",
    siretRegistration: "N°SIRET ou d’enregistrement étranger",
    siretNumber: "Numéro de siret",
    sixthQuote: "What's up today",
    slug: "Référence",
    smallScreenDisclaimer: {
      action: "Plus d'informations depuis votre mobile sur <1>https://thisisalf.com</1>",
      content:
        "Vous pourrez très prochainement accéder à la version mobile du site. En attendant, retrouvez tous les formulaires gratuits depuis votre ordinateur portable ou de bureau.",
      title: "Avis aux petits écrans",
    },
    socialCapital: "Capital social",
    socialDenomination: "Dénomination sociale",
    socialLogin: {
      Linkedin: {
        loginError: "Impossible de se connecter avec Linkedin, vous allez être redirigé vers la page d'accueil",
      },
      description: "et simplifiez-vous la vie",
      or: "ou",
      signin: "Me connecter avec mon email",
      signup: "Créer un compte",
      newUITitle: "Me connecter avec {{name}}",
      warning: "Cette application est accessible uniquement sur invitation.",
    },
    socialObject: "Objet social",
    startActivityDate: "Date de début d'activité",
    startedAt: "Date de début",
    step: "Etape",
    store: {
      subTitle: "",
      title: "Vos Services",
      start: "Commencer un dossier",
      dossierEmpty: "Aucun dossier en cours à ce jour",
    },
    street: "Rue",
    streetName: "Nom de la voie",
    streetNumber: "Numéro de rue",
    streetType: "Type de voie",
    success: "Validé",
    summary: "Synthèse",
    table: {
      noData: {
        button: "Aidez-moi!",
        subtitle:
          "Veuillez vérifier si vous avez commencé une procédure ou contactez-nous pour obtenir de l'aide, nous serons heureux de vous aider!",
        title: "Aucun résultat trouvé",
      },
    },
    teamForm: {
      name: {
        required: "Le nom est requis",
        exists: "Le nom doit être unique",
        label: "Nom de l'équipe",
      },
    },
    faqCreate: {
      form: {
        error: "Une erreur est survenue durant la création du faq",
        success: "Le faq a été créé",
      },
    },
    templateCreate: {
      form: {
        error: "Une erreur est survenue durant la création du modèle",
        success: "Le modèle a été créé",
      },
      subTitle: "Création d'un nouveau modèle",
      title: "Nouveau Document",
    },
    templateForm: {
      editor: {
        content: {
          error: "Le contenu est requis",
          label: "Contenu",
          placeholder: "Entrez le contenu du modèle",
        },
        preview: {
          title: "Prévisualisation du contenu",
        },
        tab: {
          data: "Données",
          preview: "Previsualisation",
        },
        title: "Éditeur de contenu",
        word: {
          title: "Personnalisez avec votre propre modèle",
          drop: "Glissez-déposez les variables dans votre document",
          info1:
            "Pour personnaliser la version du document générée (aperçu à gauche), préparez votre modèle au format Word et téléchargé-le ci-dessous.",
          info2:
            "Dans l'étape suivante, vous pourrez personnaliser votre modèle en Word avec les variables disponibles en les plaçant où vous le souhaitez.",
        },
      },
      filename: {
        label: "Nom du fichier généré",
        placeholder: "SAS ByLaws - ${companyName}",
      },
      filetype: {
        label: "Le type du fichier généré",
        placeholder: "Autre",
      },
      mimetype: {
        error: "Le mimetype est requis",
        label: "Mimetype",
        placeholder: "Choisissez le mimetype du modèle",
      },
      status: {
        error: "Le statut est requis",
        label: "Statut",
        placeholder: "Choisissez le statut du modèle",
      },
      tags: {
        label: "Tags",
      },
      title: {
        error: "Le titre est requis",
        label: "Titre",
        placeholder: "Entrez le titre du modèle",
      },
      type: {
        document: "Document",
        error: "Le type est requis",
        label: "Type",
        partial: "Fragment",
        placeholder: "Choisissez le type du modèle",
      },
    },
    templateList: {
      column: {
        id: "ID",
        slug: "Slug",
        status: "statut",
        title: "Titre",
        type: "Type",
        jobs: "Étapes",
      },
      confirm: {
        title: "Êtes-vous sûr de vouloir supprimer ce modèle ?",
      },
      delete: {
        error: "Une erreur est survenue durant la suppression du modéle",
      },
      subTitle: "Ajoutez vos modèles de documents",
      title: "Documents",
      empty: "Créer votre bibliothèque de modèles de documents",
    },
    templateUpdate: {
      form: {
        error: "Une erreur est survenue durant la mise à jour du modèle",
        success: "Le document a été mis à jour",
      },
      subTitle: "Mise à jour du modèle",
      title: "Document",
    },
    templates: "Modèles",
    thirdQuote: "What's up today",
    totalPrice: "Prix total",
    tradeName: "Nom commercial",
    troubleLogging: "Problème de connexion ?",
    type: "Type",
    user: "Utilisateurs",
    userForm: {
      email: {
        error: "L'email est requis",
        label: "Email",
        placeholder: "Entrez l'email de l'utilisateur",
      },
      firstname: {
        label: "Prénom",
        placeholder: "Entrez le prénom de l'utilisateur",
      },
      lastname: {
        label: "Nom",
        placeholder: "Entrez le nom de famille de l'utilisateur",
      },
      phone: {
        label: "Numéro de téléphone",
        placeholder: "Entrez le numéro de téléphone de l'utilisateur",
      },
      type: {
        admin: "Super Admin",
        broker: "Administrateur",
        customer: "Utilisateur",
        editor: "Editeur",
        label: "Type",
        operator: "Opérateur",
        placeholder: "Choisissez le type de l'utilisateur",
      },
    },
    reportsPage: {
      share: {
        title: "Partager le rapport",
        success: "Votre rapport a été partagé",
        error: "Une erreur est survenue durant le partage du rapport",
      },
      document: {
        update: "Modification de la version d'un rapport",
        textPopUp: "Enregistrer et partager une version de votre rapport",
        buttonPopUp: "Ajouter une version",
      },
      tab: {
        data: "Données",
        versions: "Versions",
      },
      create: "Créer un rapport",
      update: "Modification du rapport",
      delete: {
        areYouSure: "Êtes-vous sûr de vouloir supprimer ce rapport ?",
        yesDelete: "Oui, supprimer ce rapport",
      },
      column: {
        add: "Ajouter une colonne",
        workflow: "Service",
        variable: "Variable",
      },
      textPopUp: "Accéder et partager vos rapports personnalisés",
      buttonPopUp: "Créez votre premier rapport",
      freemiumActivity: "Tous vos rapports d'activité",
      freemiumPers: "La personnalisation de vos indicateurs de performance",
      freemiumConfig: "Le pilotage de vos KPIs",
      extraVariables: {
        documents: "Documents",
        timeSpent: "Temps passé",
      },
      ranges: {
        today: "Aujourd'hui",
        thisweek: "Cette semaine",
        thismonth: "Ce mois-ci",
        lastweek: "La semaine dernière",
        last2week: "2 dernières semaines",
        lastmonth: "Le mois dernier",
        lastquarter: "4 derniers mois",
        lastyear: "l'année dernière",
      },
      version: {
        new: "Enregistrer cette version",
        name: "{{name}} - Rapport de {{dateAndTime}}",
      },
    },
    routeLeavingGuard: {
      cancel: "Quitter la page",
      confirm: "Sauvegarder mes modifications",
      title: "Êtes-vous sûr de vouloir quitter cette page sans la sauvegarder ?",
    },
    userInviteTableList: {
      roles: "Rôles et permissions",
      admin: "Administrateur",
      editor: "Editeur",
      operator: "Opérateur",
      user: "Utilisateur",
      features: {
        createProcedure: "Créer des workflows",
        setUpPlatform: "Paramétrer la plateforme",
        editFiles: "Editer des dossiers",
        interveneFile: "Intervenir dans un dossier",
      },
    },
    userInviteCreate: {
      form: {
        error: "Une erreur est survenue durant la création de l'invitation",
        success: "L'invitation a été créée et envoyée",
      },
      subTitle: "Création et envoi d'une invitation",
      title: "Invitation",
    },
    userInviteResend: {
      form: {
        error: "Une erreur est survenue durant l'envoi de l'invitation",
        success: "L'invitation a été envoyée",
      },
    },
    userInviteForm: {
      email: {
        error: "L'email est requis",
        label: "Email",
        placeholder: "Entrez l'email de l'utilisateur",
      },
      userType: {
        label: "Droit d'accès de l'utilisateur",
      },
    },
    userInviteList: {
      column: {
        email: "Email",
        expired: "Statut",
        id: "ID",
      },
      confirm: {
        title: "Êtes-vous sûr de vouloir supprimer cet invitation ?",
      },
      firstMember: "Invitez votre premier membre !",
      subTitle: "Gérez les invitations des utilisateurs",
      title: "Invitations",
      handling: {
        resend: "Relancer",
      },
      status: {
        expired: "Expirée",
        pending: "En attente",
        done: "Terminé",
      },
      waiting: "En attente",
    },
    userList: {
      column: {
        email: "Email",
        firstName: "Prénom",
        id: "ID",
        lastName: "Nom",
        type: "Type",
      },
      confirm: {
        areYouSure: "Êtes-vous sûr(e) de vouloir supprimer {{modalText}} ?",
        yesDelete: "Oui, supprimer {{modalText}}",
        title: "Êtes-vous sûr(e) de vouloir supprimer ce membre ?",
        deleteSubtitle: "Il sera supprimé de tous les dossiers auxquels il contribue",
      },
      collaborator: {
        title: "Inviter des collaborateurs",
        placeholder: "Inviter un groupe, un utilisateur ou un email",
        add: "Inviter un autre collaborateur",
      },
      inviteAnotherMember: "Inviter un autre membre",
      inviteNewMembers: "Inviter de nouveaux membres",
      select: "Sélectionner les invités",
      noMembers: "Vous n'avez pas encore de membres !",
      firstTeam: "Créez votre première équipe !",
      createTeam: "Créer une équipe",
      removeTeam: "Êtes-vous sûr(e) de vouloir supprimer cette équipe ?",
      confirmRemoveTeam: "Oui, supprimer cette équipe",
      inviteNewMembersTeam: "Inviter un ou plusieurs collaborateurs à rejoindre l’équipe",
      removeNewMembersTeam: "Êtes-vous sûr(e) de vouloir supprimer ce collaborateur de cette équipe ?",
      confirmRemoveNewMembersTeam: "Oui, supprimer ce collaborateur",
      subTitle: "Liste des utilisateurs de l'application",
      tabs: {
        members: "Membres",
        invitations: "Invitations",
        teams: "Équipes",
        manageRoles: "Gérer les rôles",
      },
      title: "Utilisateurs",
    },
    userProfile: {
      name: "Mon profil",
      GDPR: {
        anonymize: "Anonimiser mes données",
        deleteAccount: "Effacer mon compte",
        deleteData: "Effacer mes données personnelles",
      },
      entity: {
        label: "Reliez un contact à votre utilisateur",
        placeholder: "Choisissez un contact",
      },
      form: {
        email: {
          error: "L'email est requis",
          label: "Email",
          placeholder: "Saisissez votre adresse email",
        },
        error: "Une erreur est survenue, l'édition a échoué",
        firstname: {
          label: "Prénom",
          placeholder: "Saisissez votre prénom ",
        },
        lastname: {
          label: "Nom",
          placeholder: "Saisissez votre nom de famille",
        },
        phone: {
          label: "Téléphone",
          placeholder: "Saisissez votre numéro de téléphone",
        },
        job: {
          label: "Titre",
          placeholder: "Saisissez votre titre",
        },
        role: {
          label: "Rôle",
          placeholder: "Saisissez votre rôle",
        },
        status: {
          title: "État",
          acivated: "Actif",
          disactivated: "Inactif",
        },
        team: {
          label: "Équipes",
          placeholder: "Saisissez vos équipes",
        },
        save: "Enregistrer les modifications",
        success: "Votre profil a été mis à jour",
      },
      preferedLanguage: {
        label: "Votre language de préférence pour les communications",
        placeholder: "Séléctionnez un language",
      },
      subTitle: "La protection de votre vie privée est une priorité",
      title: "Mon compte",
      roles: {
        title: "Mes permissions",
        update: "Modifier les rôles",
      },
      teams: {
        title: "Mes équipes",
        update: "Gérer mes équipes",
        empty: {
          title: "Vous n’êtes pas encore associé à une équipe",
          description: "Un peu de patience, votre administrateur s’en occupe pour vous",
        },
      },
    },
    userUpdate: {
      form: {
        error: "Une erreur est survenue durant la mise à jour de l'utilisateur",
        success: "L'utilisateur a été mis à jour",
      },
      subTitle: "Mettre à jour un utilisateur",
      title: "Utilisateur",
    },
    modal: {
      wip: {
        title: "Bienvenue dans le futur...",
        subtitle: "Cette fonctionnalité n'est pas encore disponible",
        description: "Notre équipe travaille à sa création. Vous serez alerté dès sa mise en ligne",
      },
      noRights: {
        subtitle: "Votre rôle ne vous permet pas d'effectuer cette action.",
        linkRolesPermissions: "Voir mon rôle et mes permissions",
      },
    },
    roles: {
      freemiumRoles: "La fonctionnalité de gestion de rôles",
      freemiumAccess: "L'attribution des droits d'accès de vos équipes",
    },
    shareCapital: "Capital social",
    storeFreemiumModal: {
      title: "Choisissez parmi notre bibliothèque de modèles de services juridiques personnalisables",
      CTA: "Accéder au catalogue",
    },
    teams: {
      freemiumCreation: "La création de vos équipes de travail",
      freemiumGestion: "La gestion de vos équipes",
    },
    users: "Utilisateurs",
    validAnnonce: "Valide l'annonce",
    validDocument: "Valider les documents",
    valueByActions: "Valeur par action",
    welcomeLetter: { continue: "Continuer", preview: "Prévisualiser", welcome: "Message de bienvenue" },
    widowed: "Veuf(ve)",
    wipFreemiumModal: {
      title:
        "Cette fonctionnalité n'est pas disponible dans la version Freemium. Contactez notre équipe de vente pour passer à un plan adapté à vos besoins.",
      upgrade: "Upgrader ma plateforme",
      redirect: "Hubspot",
    },
    wipFeatureModal: {
      header: {
        processInstanceSettingStep: "Changement d'étape",
      },
      title: "Cette fonctionnalité sera prochainement disponible sur votre plateforme",
      subtitle: "Cette fonctionnalité n'est pas encore disponible",
      description: "Notre équipe travaille à sa création. Vous serez alerté dès sa mise en ligne",
    },
    workflow: "Workflow",
    workflowCreate: {
      form: {
        error: "Une erreur s'est produite durant la création du workflow",
        success: "Le workflow a été créé",
      },
      subTitle: "Créez un workflow",
      title: "Workflow",
    },
    workflowExport: {
      error: "Une erreur s'est produite durant l'export du workflow",
    },
    workflowPrevious: {
      consult: "Vous pouvez retrouver :",
      yes: "Revenir en arrière",
      title: "Êtes-vous sûr(e) de vouloir revenir en arrière ?",
      description: "Si vous regénérez l'un des documents, la version actuelle sera supprimée.",
      summary: {
        text: "Toutes les réponses aux questionnaires",
        link: "ici",
      },
      documents: {
        text: "Tous les documents de votre dossier",
        link: "ici",
      },
    },
    workflowv2Form: {
      create: "No-Code workflow",
      title: "Créer un <strong>workflow</strong>",
      step1: { continue: "Continuer sans documents", accepted: "Format accepté : .docx" },
      step2: {
        title: "Définissez les variables et les conditions du document",
        continue: "Choix de la première étape",
      },
      step3: {
        empty: "Choisissez votre première action",
        variables: "Questions existantes",
      },
      variables: {
        description: {
          title: "Définissez les variables de votre document :",
          step1: "Sélectionnez le texte de votre document que vous souhaitez ajouter comme variable",
          step2: "Ajoutez une nouvelle variable ou choisissez dans une liste prédéfinie",
          step3: "Définir les propriétés de la variable",
        },
        delete: "Supprimer la variable",
        empty: "Nom de la variable",
        name: "Entrez le nom de la variable",
        type: "Type de question",
        popover: {
          replace: "Souhaitez-vous remplacer le contenu similaire ?",
          replaceOne: "Non",
          replaceAll: "Oui",
          title: "Ajouter une variable",
          search: "Rechercher une variable",
          add: "Ajouter une nouvelle variable",
        },
      },
      steps: {
        title: "Étapes",
        step: "Étape",
        add: "Ajouter une étape",
        edit: "Modifier une étape",
      },
      actions: {
        title: "Actions",
        action: "Action",
        add: "Ajouter une action",
      },
      form: {
        list: "Liste des questions",
        preview: "Prévisualisation",
        question: "Question",
        add: "Ajouter une question",
        fields: "Configuration",
        conditions: "Conditions",
        faq: "FAQ",
        name: "Nom de la question",
        label: "Titre de la question",
        type: "Type de question",
        required: "Requis",
      },
      conditions: {
        clausier: "Ajouter un article de mon clausier",
        description: {
          title: "Ajoutez des conditions à votre document :",
          step1: "Sélectionnez le texte de votre document que vous souhaitez conditionner",
          step2: "Ajoutez une nouvelle condition ou choisissez parmi une liste prédéfinie",
          step3: "Définir les propriétés de la condition",
        },
        delete: "Supprimer la condition",
        empty: "Nom de la condition",
        name: "Entrez le nom de la condition",
        popover: {
          title: "Ajouter une condition",
          search: "Rechercher une condition",
          add: "Ajouter une nouvelle condition",
        },
        text: "Ajouter votre texte",
      },
      tabs: {
        variables: "Variables",
        conditions: "Conditions",
      },
      types: {
        text: "Texte",
        textarea: "Long texte",
        money: "Devise",
        number: "Nombre",
        email: "Email",
        phoneNumber: "Téléphone",
        country: "Pays",
        file: "Document",
        enumeration: "Select",
        radio: "Radio",
        "multiple-selector": "Select multiple",
        checkbox: "Checkbox",
        siret: "Siret",
        idCheck: "idCheck",
        entity: "entity",
      },
    },
    workflowForm: {
      add: {
        form: "Formulaire",
        generateDocuments: "Générer les documents",
        notifications: "Notifier l'utilisateur",
        openDocument: "Ouvrir les documents",
        payment: "Paiement",
        publishLegalNotice: "Publier une annonce",
        reviewDocuments: "Valider les documents",
        sendDocuments: "Envoyer un mail",
        signDocuments: "Signer les documents",
      },
      description: {
        error: "La description est requise",
        label: "Description",
        placeholder: "Ajoutez une description optionelle si nécessaire",
      },
      label: {
        label: "Titre",
        placeholder: "variable qui sera utilisée comme titre sur le dashboard",
      },
      title: {
        error: "Le titre est requis",
        label: "Titre",
        placeholder: "Donnez un nom descriptif",
      },
      workflow: {
        form: {
          form: {
            error: "Le choix du formulaire est requis",
            label: "Formulaire",
            placeholder: "Choisissez le formulaire",
          },
          name: {
            error: "Le nom de l'étape est requis",
            label: "Nom",
            placeholder: "Entrez le nom de l'étape",
          },
          title: {
            label: "Editez ce formulaire",
          },
        },
        generateDocuments: {
          config: {
            alias: {
              label: "Alias des documents multiple",
              placeholder: "Entrez l'alias des documents multiples (member par défaut)",
            },
            bindings: {
              label: "Bindings des documents PDF Form",
              placeholder:
                "Configuration json du binding des variables vers le document pdf form sélectionné ci-dessus",
            },
            input: {
              label: "Entrée des documents multiples",
              placeholder: "Entrez le JMESPath sur lequel itérer",
            },
            renderMode: {
              highlightChanges: "Surlignage des réponses",
              label: "Mode de rendu des documents",
              normal: "Normal",
            },
            templatesSelection: {
              label: "Choisissez les documents à générer",
              labelSend: "Choisissez les documents à envoyer",
              conditions: "Conditions",
              binding: "Consolidations",
              batch: "Documents par lot",
            },
          },
        },
        inviteUsers: {
          config: {
            input: {
              label: "Le JMespath du/des utilisateurs à inviter",
              placeholder: "JMespath des utilisateur",
            },
          },
        },
        condition: {
          title: "Condition",
          description: "Description de la condition",
          variable: "Choisissez la variable pour la condition",
          nextStep: "Prochaine étape associée à la condition",
          defaultStep: "Etape par défaut",
        },
        job: {
          dependsOnPreviousSteps: { label: "Dépend des étapes précédentes" },
          description: { label: "Description de l'étape", placeholder: "" },
          toDo: { label: "À faire" },
          id: {
            error: "Vous devez choisir un job",
            label: "Job",
            placeholder: "Choisissez un job",
          },
          name: {
            error: "Vous devez donner un nom à l'étape",
            label: "Nom de l'étape",
            placeholder: "Le nom de l'étape apparait sur le tableau de bord",
          },
          templates: {
            error: "Le choix des documents est requis",
            label: "Documents",
            placeholder: "Choisissez les documents",
          },
          title: "Cliquez sur '+' pour ajouter un Job",
        },
        notifications: {
          config: {
            input: {
              error: "Vous devez définir un message ou supprimer l'étape",
              label: "Message",
              placeholder: "Vos documents sont prêts",
            },
          },
        },
        openDocuments: {
          config: {
            documentsPage: {
              label: "Ouvrir la page des documents",
            },
            input: {
              error: "Vous devez définir un message ou supprimer l'étape",
              label: "Message",
              placeholder: "Overture des Documents",
            },
            newWindow: {
              label: "Ouvrir dans une nouvelle fenêtre",
            },
          },
        },
        payment: {
          config: {
            configureYourStripeKeys: {
              label: "Configurez vos clés Stripe",
            },
            creditCard: {
              label: "Carte de crédit",
            },
            email: {
              label: "Email pour votre reçu",
            },
            fixedPrice: {
              label: "Prix fixe en Euros",
            },
            floatingPrice: {
              label: "Chemin vers une variable définissant le prix en euros",
            },
            fullName: {
              label: "Nom sur la carte",
            },
            invoice: { title: "Factures" },
            invoiceTemplates: { label: "Sélectionner les facture(s) à générer" },
            liveMode: { label: "Mode live" },
            paymentKey: {
              hint: "Les clés de paiement sont configurables dans les <0>réglages de votre application</0>. Une fois configurées, vous pourrez en choisir une ci-dessous. Si le Live Mode est desactivé, le paiement sera configuré en mode test et vous pourrez tester son fonctionnement en utilisant le numéro de carte factice suivant: 4242 4242 4242 4242, date d'expiration: 01/42, cryptogramme: 424",
              label: "Choisissez une clé de paiement",
              title: "Clés de paiement",
            },
            pricingType: {
              fixedPrice: "Prix Fixe",
              floatingPrice: "Prix Variable",
              label: "Définition du prix",
              servicePrice: "Prix du Service",
              stripePrice: "Produit Stripe",
              stripeSubscription: "Abonnement Stripe",
            },
            privateKey: {
              label: "Clé Secrète",
            },
            productionKeys: {
              label: "Clés de Production",
            },
            publicKey: {
              label: "Clé Publique",
            },
            stripe: {
              hint: "La composition du prix de votre service se fait directement sur <0>stripe.com</0> pour être selectionnée ci-dessous. Pour chaque service que vous offrez ici, vous devez créer un produit stripe à cette adresse: <1>https://dashboard.stripe.com/products</1>. Pour chaque produit stripe, vous pouvez ajouter autant d'elements de tarif que vous le souhaitez afin de distinguer les différents éléments de votre tarification.",
              priceItems: {
                title: "Détail du prix",
                hint: "Une fois tous vos tarifs créés sur stripe, selectionnez-les ci-dessous. Ces éléments apparaitront dans cet ordre sur la facture envoyée à votre client. Cliquez sur + pour ajouter un nouveau tarif",
              },
              taxes: { title: "Taxes" },
              title: "Composition du prix",
            },
            stripeGlobalTaxId: {
              label: "Taxe appliquée à toutes les entrées. Cliquez sur + pour ajouter un nouveau taux",
            },
            stripeKeysConfiguration: {
              error: "L'extraction de la liste de prix de Stripe a échoué",
              hint: "Êtes-vous sûr d'avoir configuré vos clés de stripe correctement ?",
              label: "Veuillez configurer vos clés API stripe",
            },
            stripePriceIds: {
              add: "Détail du prix sur la page paiement",
              label: "Prix",
            },
            stripeProductIds: {
              label: "Produit Stripe",
              placeholder: "Selectionnez un produit Stripe ou cliquez sur + pour en créer un nouveau",
            },
            stripeProductPrice: {
              label: "Prix en euros du produit Stripe",
            },
            stripeTaxIds: {
              label: "Taxe",
            },
            subscription: {
              label: "Identifiant d'abonnement Stripe",
            },
            testKeys: {
              label: "Clés de Test",
            },
          },
        },
        publishLegalNotice: {
          config: {
            newZipCode: "Entrez le chemin du nouveau code postal",
            certificateFilename: "Nom de fichier du certificat",
            data: {
              companyName: {
                label: "Nom de la société",
                placeholder: "Entrez le chemin du nom de la société",
              },
            },
            email: {
              label: "Email",
              placeholder: "Entrez le chemin de l'email de livraison",
            },
            invoiceFilename: "Nom de fichier de la facture",
            type: {
              addendum: "Additif",
              chairman: "Changement de dirigeant",
              changeName: "Changement de nom",
              constitution: "Constitution de société",
              corrigendum: "Rectificatif",
              headquarters: "Changement de siège social",
              headquartersArrival: "Changement de siège social (département d'arrivée)",
              label: "Type d'annonce",
              placeholder: "Choisissez le type",
            },
            zipCode: {
              label: "Code postal actuel",
              placeholder: "Entrez le chemin du code postal",
            },
          },
          section: {
            main: "Général",
            notice: "Annonce",
          },
        },
        sendDocuments: {
          addDocuments: "Ajoutez les documents",
          buttonText: "Texte du bouton",
          config: {
            input: {
              error: "Le JMESPath d'un email est requis",
              label: "Indiquez les destinataires",
              placeholder: "Entrez le JMESPath d'un ou plusieurs emails",
            },
            sendAsAttachment: "en pièce jointe à l'email",
            sendAsButtonLink: "bouton avec un lien vers votre plateforme dans l'email",
          },
          emailConfiguration: "Configuration de l'email",
          emailTitle: "Titre de l'email",
          greetings: {
            label: "Salutations",
            placeholder: "Bonjour Louis !",
          },
          linkAddress: "Adresse du lien",
          linkTitle: "Titre du lien",
          message: "Message",
          redirectButton: "Voulez-vous ajouter un bouton de redirection ?",
          redirectUrl: "Lien de redirection",
          sendDocuments: "Voulez-vous ajouter des documents ?",
          subject: "Sujet",
        },
        sendLetter: {
          addDocuments: "Ajoutez les documents",
          buttonText: "Texte du bouton",
          letterConfiguration: "Détails de l'envoi",
          companyName: {
            label: "Nom de la société",
            placeholder: "Entrez le chemin du nom de l'entreprise",
          },
          documents: {
            label: "Documents à envoyer",
            labelLong: "Sélectionnez les documents à envoyer",
          },
          zipCode: "Code postal",
          city: "Ville",
          sendDocuments: "Voulez-vous ajouter des documents?",
          subject: "Matière",
          cancelWindow: "Fenêtre d'annulation, min",
          sender: "Expéditeur",
          recipient: "Destinataire",
          isDraft: {
            label: "Envoyer comme brouillon",
          },
          name: {
            label: "Nom complet",
            placeholder: "Enter name path",
          },
        },
        signDocuments: {
          config: {
            addUploadedDocuments: {
              label: "Ajouter les documents téléchargés",
            },
            documents: {
              label: "Documents à signer",
              labelLong: "Selectionnez les documents à signer",
            },
            emailCcOptional: { label: "Envoie de l'email en CC optionnel" },
            emailCcPath: {
              label: "Selectionnez l'email en copie des documents signés",
              placeholder: "escrowAccountEmail",
            },
            emailSubject: {
              label: "Sujet de l'email envoyé pour la signature",
              placeholder: "Signature documents pour ${companyName} - ${__name}",
            },
            input: {
              error: "Veuillez sélectionner les signataires",
              label: "Sélectionnez les signataires",
              placeholder: "membres[?role === 'CEO']",
            },
            sendAsDraft: {
              label: "Envoyer comme brouillon",
            },
            smsAuthentication: { label: "Activer l'authentification par SMS" },
            templates: "Mes Documents",
            title: {
              label: "Selectionnez les signataires",
            },
            uploadedDocuments: "Documents téléchargés",
            uploadedDocumentsPath: { label: "Filtrer les documents selon un chemin", placeholder: "membres" },
            uploadedDocumentsTypes: {
              label: "Filtrer les documents selon leur type",
              placeholder: "Justificatif de domicile",
            },
            variableName: {
              label: "Nom de variable",
              placeholder: "Permet d'identifier chaque signature lors de l'édition de vos documents",
            },
          },
        },
        requiresValidation: {
          label: "Mode d'envoi d'email",
          true: "Manuel",
          false: "Automatique",
        },
        restrictedAccess: {
          user: "L'utilisateur peut modifier / valider l'étape",
          invitee: "L'invité peut modifier / valider l'étape",
        },
      },
    },
    vatNumber: "N° TVA intracommunautaire",
    workflowList: {
      column: {
        id: "ID",
        title: "Titre",
      },
      confirm: {
        title: "Êtes-vous sûr de vouloir supprimer ce workflow ?",
      },
      create: "Créer un workflow",
      delete: {
        error: "Une erreur s'est produite durant la suppression du workflow",
      },
      subTitle: "Assemblez vos jobs en un workflow",
      title: "Workflows",
      empty: "Automatisez vos workflows ici",
    },
    workflowUpdate: {
      form: {
        error: "Une erreur s'est produite durant la mise à jour du workflow",
        success: "Le workflow a été mis à jour",
      },
      subTitle: "Composez votre workflow",
      title: "Workflow",
    },
    workflows: "Workflows",
    yes: "Oui",
    zipCode: "Code postal",
    createDossier: "Créer un dossier",
    createDossierDescription: "Créer un nouveau dossier à partir du contenu du mail",
    attachDossier: "Attacher à un dossier",
    attachDossierDescription: "Ajouter votre mail à un dossier existant",
    createAnEntity: "Créer une entité",
    createAnEntityDescription: "Enregistrer des informations sur votre plateforme",
    updateEntity: "Mettre à jour une entité",
    updateEntityDescription: "Mettre à jour des informations sur votre plateforme",
    AIAnalysis: `Analyse de l'IA`,
    AIAnalysisDescription: `Utiliser l'IA pour analyser le contenu du mail`,
    AddStickyNote: "Ajouter une Sticky Note",
    AddStickyNoteDescription: "Créer et assigner une tâche",
    WhatDoYouWantToDo: "Que voulez-vous faire ?",
    RedirectToPlatform: "Accéder à votre plateforme →",
    menu: "Menu",
    selectYourService: "Choisir votre service",
    selectYourDossier: "Sélectionnez votre dossier",
    informationSaved: "Informations enregistrées",
    save: "Enregistrer",
    fileUploadSuccess: "Document sélectionné",
    upload: "Sélectionner",
    noAttachment: "Aucune pièce jointe trouvée",
    emailAttachment: "Sélectionner le document à ajouter au dossier",
    noData: "Aucun document",
    uploadFromPC: "Sélectionner dans votre ordinateur",
    welcomeBack: "Nous sommes heureux de vous revoir",
    loginEmail: "Email",
    password: "Mot de passe",
    niceToSeeYouAgain: `C'est un plaisir de vous revoir`,
    failedToGetAttachments: `Échec de l'obtention des pièces jointes`,
    noAttachments: "Aucune pièce jointe trouvée.",
    selectDocuments: "Sélectionnez les documents à ajouter au dossier",
    selectWorkSpace: `Sélectionnez l'espace de travail que vous souhaitez connecter`,
    select: "Sélectionner",
    uploadFromLocal: "Sélectionnez depuis votre ordinateur",
    errorOccurred: `'Une erreur s'est produite`,
    errorNoSlugs: `Oups..., il n'y a pas de slug pour se connecter.`,
    errorLoadingSlugs: `Il s'agit d'une erreur lors du chargement des slugs :`,
    errorUserPasswordNotValid: `Oups... Votre nom d'utilisateur ou votre mot de passe n'est pas valide.\nSi vous avez oublié votre mot de passe, vous pouvez toujours cliquer sur "J'ai oublié mon mot de passe".`,
    errorEmailSubmit:
      "Je ne reconnais pas cet email et/ou ce mot de passe. <strong>Essayez une autre combinaison !</strong>",
    errorNotValidEmail:
      "<strong>Cette adresse e-mail ne semble pas valide.</strong> Veuillez réessayer ou saisir une autre adresse e-mail !",
    errorValidEmail: `Aucun compte n'est associé à cet e-mail.`,
    errorNotValidPassword:
      "Votre mot de passe doit contenir au moins <strong>une lettre majuscule, un caractère spécial, un chiffre et 8 caractères.</strong>",
    dossier: "Dossier",
  },
};

export { fr };
