import React from 'react'; // Import React
import { ChevronLeft } from 'react-feather';

const ChevronLeftIcon: React.FC = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <ChevronLeft />
    </div>
  );
};

export default ChevronLeftIcon;
