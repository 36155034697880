import React from 'react'

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="6 6 24 24" {...props}>
    <g>
      <path
        d="M28.07,7.54H7.376v4.878h1.075v14.99h18.295v-14.99h1.324V7.54z M25.746,26.408H9.451v-13.99h16.295V26.408z M27.07,11.418
		h-0.324H8.451H8.376V8.54H27.07V11.418z"
      />
      <rect x="13.286" y="16.089" width="7.837" height="1" />
    </g>
  </svg>
)

export default SVG
