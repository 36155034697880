import React from 'react'

const SVG = props => (
  <svg id="Capa_1" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m455.52 219.999h-23.02v-204.999c0-8.284-6.716-15-15-15h-397c-8.284 0-15 6.716-15 15v384.23c0 27.265 22.177 49.689 49.436 49.988.055.001.11.001.165.001h.13 87.48v45.781c0 8.284 6.716 15 15 15h331.789c8.284 0 15-6.716 15-15v-226.011c0-27.013-21.972-48.99-48.98-48.99zm-420.02-189.999h367v189.999h-278.77c-.007 0-.013.001-.019.001h-.07c-26.336 0-47.832 20.601-48.937 46.899-.009.21-.013.42-.013.63v131.701c0 10.844-8.81 19.794-19.637 19.982-10.8-.233-19.553-9.15-19.553-19.982v-369.23zm64.987 389.22c2.661-6.044 4.203-12.947 4.203-19.989v-131.341c.561-10.028 8.813-17.89 19.04-17.89 10.466 0 18.98 8.519 18.98 18.99v150.23zm374.013 60.78h-301.79c0-5.59 0-205.555 0-211.01v-.001c0-6.602-1.364-13.148-3.829-18.989 9.423 0 277.529-.001 286.639-.001 10.466 0 18.98 8.519 18.98 18.99z" />
    <path d="m83.095 102.084h15.282v58.598c0 8.284 6.716 15 15 15s15-6.716 15-15v-58.598h15.282c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60.564c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
    <path d="m184.377 175.682c8.284 0 15-6.716 15-15v-8.682h30.564v8.682c0 8.284 6.716 15 15 15s15-6.716 15-15v-43.315c0-24.969-20.313-45.282-45.282-45.282s-45.282 20.313-45.282 45.282v43.315c0 8.284 6.716 15 15 15zm30.282-73.598c8.426 0 15.282 6.855 15.282 15.282v4.634h-30.564v-4.634c0-8.427 6.855-15.282 15.282-15.282z" />
    <path d="m284.81 172.264c6.393 5.263 15.849 4.347 21.114-2.051l18.699-22.724 18.699 22.724c5.266 6.399 14.719 7.313 21.114 2.051 6.397-5.264 7.315-14.717 2.051-21.113l-22.439-27.268 22.439-27.268c5.264-6.396 4.346-15.85-2.051-21.113-6.396-5.266-15.85-4.348-21.114 2.051l-18.699 22.724-18.699-22.724c-5.265-6.396-14.717-7.314-21.114-2.051s-7.315 14.717-2.051 21.113l22.439 27.268-22.439 27.268c-5.265 6.396-4.346 15.849 2.051 21.113z" />
    <path d="m324.167 315h-103.326c-8.284 0-15 6.716-15 15s6.716 15 15 15h103.326c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    <path d="m324.167 387h-103.326c-8.284 0-15 6.716-15 15s6.716 15 15 15h103.326c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    <path d="m436.5 315h-50.667c-8.284 0-15 6.716-15 15s6.716 15 15 15h50.667c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    <path d="m436.5 387h-50.667c-8.284 0-15 6.716-15 15s6.716 15 15 15h50.667c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
  </svg>
)

export default SVG
