import React from 'react'

// sourced from https://ant.design/components/icon/

const SVG = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" {...props}>
    <polygon points="42.15 85.63 33.16 73.64 29.96 76.04 41.36 91.25 65.05 73.28 62.63 70.09 42.15 85.63" />
    <path d="M57.3,48.61a25.19,25.19,0,1,0-21.2,0A34.93,34.93,0,0,0,11.81,81.84V100h4V81.84a30.89,30.89,0,0,1,61.78,0V100h4V81.84A34.93,34.93,0,0,0,57.3,48.61ZM25.51,25.76A21.19,21.19,0,1,1,46.7,47,21.21,21.21,0,0,1,25.51,25.76Z" />
  </svg>
)

export default SVG
