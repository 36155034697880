import React from 'react'

const Sad = () => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="3.5" width="17" height="17" rx="2.5" stroke="currentColor" />
      <path d="M15 10H15.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61406 10.3587L10.4505 10.1477C10.7656 10.1238 11.0021 9.84891 10.9788 9.53219C10.9554 9.21593 10.6801 8.98058 10.3668 9.00127L7.5299 9.21226C7.2152 9.23616 6.97826 9.51105 7.00158 9.82776C7.024 10.1298 7.27512 10.3601 7.57152 10.3601C7.5857 10.3601 7.59988 10.3596 7.61406 10.3587Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93599 15.8768C8.94141 15.8729 9.24747 15.6574 9.84515 15.4391C10.3525 15.2537 11.0667 15.0664 12.0165 15.0045C12.8281 14.9533 13.5635 15.0771 14.1192 15.2236C14.7426 15.3879 15.1399 15.58 15.1534 15.5871C15.4229 15.7228 15.7678 15.6431 15.9235 15.4057C16.0802 15.1692 15.9885 14.8656 15.7186 14.7279C15.6563 14.6957 14.2682 14 12.4088 14C12.2535 14 12.0946 14.0044 11.933 14.0151C9.45828 14.1762 8.24351 15.0914 8.19295 15.1303C7.95821 15.3105 7.93429 15.624 8.13968 15.8304C8.34463 16.0364 8.70125 16.0582 8.93599 15.8768Z"
        fill="currentColor"
      />
    </svg>
  </div>
)

export default Sad
