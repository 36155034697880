import React, { useMemo, useEffect, FC } from "react";
import pick from "lodash/pick";
import { Button, Row, Popover } from "antd";
import { useTranslation } from "react-i18next";
import Sad from "../Icon/Sad";
import RightArrowIcon from "../Icon/RightArrowIcon";
import Tag from "../Tag/Tag";
import classNames from "classnames";

import { Label } from "../../data-view";

interface FieldViewProps {
  meta: any;
  input: any;
  user: any;
  type?: any;
  definition: { View: any; fake: any; dataOptions: any };
  options?: any;
  initialOptions?: any;
  preview?: any;
  label?: string;
  description?: string;
  required?: boolean;
  display?: any;
  viewOnly?: boolean;
  defaultValue?: any;
  handleImportClick?: any;
  errorMsg?: any;
  getBodaccEntries?: any;
}

export const FieldView: FC<FieldViewProps> = ({
  meta,
  input,
  user,
  definition: { View, dataOptions },
  options,
  initialOptions,
  preview,
  label,
  description,
  required,
  display,
  viewOnly,
  ...props
}) => {
  const { t } = useTranslation();

  const cleanDataOptions = useMemo(() => pick(dataOptions, ["min"]), [dataOptions]);

  const hasError = useMemo(() => meta.touched && meta.error, [meta]);

  const errorText = useMemo(() => {
    switch (meta.error) {
      case "requiredField":
        return t("common.error.required.generic");
      case "incorrectEmail":
        return t("common.error.input.email.invalid");
      default:
        return null;
    }
  }, [t, meta]);

  useEffect(() => {
    if (!input.value) {
      setTimeout(() => {
        input.onChange(props.defaultValue);
      }, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (viewOnly) {
    return (
      <View
        className="form-runner-field-view"
        {...cleanDataOptions}
        {...props}
        {...input}
        meta={meta}
        options={options}
      />
    );
  }

  return (
    <div className={classNames("form-runner-field", props.type === "checkbox" && "form-runner-field--oneline")}>
      <div key="form-field-1">
        <Label
          label={label}
          required={required}
          validateStatus={hasError ? "error" : "success"}
          className={undefined}
          extra={undefined}
        ></Label>
        <>
          {Boolean(description) && (
            <>
              <Popover content={description} overlayStyle={{ maxWidth: "70%" }} placement="topLeft">
                <Button type="link" className="form-runner-field-moreinfo">
                  <span>
                    {t("common.text.moreinfo")}
                    <RightArrowIcon />
                  </span>
                </Button>
              </Popover>
            </>
          )}
          {hasError && errorText && (
            <Tag type="error" className="form-field-errortag">
              &nbsp;
              <Sad />
              &nbsp;{errorText}&nbsp;
            </Tag>
          )}
        </>
      </div>
      <Row key="form-field" justify="space-between">
        <View
          className="form-runner-field-view"
          {...cleanDataOptions}
          {...props}
          {...input}
          meta={meta}
          options={options}
          status={hasError && "error"}
        />
      </Row>
    </div>
  );
};
