import { extendedLocaleToLang } from './data-model/shared/formatters';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { fr, en } from './translations';
import { LS_LANGUAGE_PROPERTY_NAME } from './constants/locales';

interface Services {
  languageDetector?: {
    detect: () => string;
  };
}

const languageToLocale = (() => {
  let languageDetected: string = 'fr';

  return {
    cacheUserLanguage: (): void => { /* TODO: Check if cacheUserLanguage from `detector` is used */ },

    detect: () => {
      // if (Office.context && Office.context.displayLanguage) {
      //   return extendedLocaleToLang(Office.context.displayLanguage);
      // }
    
      // Fallback to existing logic
      const localLanguage = localStorage.getItem(LS_LANGUAGE_PROPERTY_NAME);
      return localLanguage || 'fr';
    },
    
    init: (services: Services) => {
      languageDetected = extendedLocaleToLang(services.languageDetector && services.languageDetector.detect()) || 'fr';
      // Additionally, consider getting the language from Office context here
      // const displayLanguage = Office.context && Office.context.displayLanguage;
      // if (displayLanguage) {
      //   languageDetected = extendedLocaleToLang(displayLanguage);
      // }
    },
    type: 'languageDetector',
  }
})();

i18n
  .use(detector)
  .use(languageToLocale as any)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en,
      fr,
    },

    debug: true
  });

export default i18n;