import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Result, Row, Collapse } from 'antd'
import { groupDataByFrame } from '../../../helpers/utils/groupDataByFrame'
import { isJSON } from '../../../helpers/text'
import { getEnumerationByNamesQuery } from '../graphql/queries'
import Loader from '../../../components/Loader'
import { cleanData } from '../../../data-model/helpers/cleanData'
import SVGIcon from '../../../components/SVGIcon/SVGIcon'
import QuestionImg from '../../../assets/svg/questionIcon.svg';
import SummaryForm from '../../../components/SummaryForm'

type ProcessInstanceSummaryPagePropType = {
  processInstance: any,
}

const ProcessInstanceSummaryPage: React.FC<ProcessInstanceSummaryPagePropType> = ({ 
  processInstance, 
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [openPanelKey, setOpenPanelKey] = useState<string | null>(null);

  const enumerationFields = useMemo(
    () =>
      Object.keys(processInstance.dataResolved)
        .filter(
          field =>
            processInstance.dataResolved[field].type &&
            processInstance.dataResolved[field].primaryType === 'enumeration'
        )
        .map(field => processInstance.dataResolved[field].type),
    [processInstance]
  )
  const { data: { getEnumerationByNames: enumerations = [] } = {}, loading } =
    useQuery(getEnumerationByNamesQuery, {
      fetchPolicy: 'cache-and-network',
      skip: !enumerationFields.length,
      variables: {
        names: enumerationFields,
      },
    })


  const cleanedData = useMemo(
    () =>
      cleanData(processInstance.dataResolved, {
        enumerations,
        keepToStrMethod: true,
      }),
    [processInstance.dataResolved, enumerations]
  )

  const summaryDatas = useMemo(
    () => groupDataByFrame(cleanedData, processInstance.workflow, language),
    [cleanedData, language, processInstance.workflow]
  )

  const summaryItems = useMemo(
    () => [
      ...summaryDatas
        .map(categ => {
          const formItems = categ.children
            .filter(
              ({ value, children }) =>
                (typeof value === 'string' && !isJSON(value) && value.length) ||
                typeof value === 'boolean' ||
                children
            )
            .sort((a, b) => a.index - b.index)
          if (!formItems.length) {
            return null
          }
          return {
            content: <SummaryForm data={formItems} />,
            title: categ.label,
          }
        })
        .filter(Boolean),
    ],
    [summaryDatas]
  )

    const toggleCollapse = (frameIndex: string) => {
      setOpenPanelKey(openPanelKey === frameIndex ? null : frameIndex);
  };
  
  if (loading) {
    return <Loader isRelative={true} />
  }

  if (!summaryDatas || !Object.keys(summaryDatas).length) {
    return <Result title={t('common.text.noData')} />
  }

  



  return (
    <div className="process-instance-information-page">
      {summaryDatas?.map((summary, index) => (
        <div key={summary.key}>
        <Collapse 
          className={index % 2 === 0 ? `form-card color-red` : `form-card color-blue`}
          bordered={false} 
          accordion
          expandIconPosition="end"
          activeKey={openPanelKey === index.toString() ? [index]: []}
          onChange={() => toggleCollapse(index.toString())}
          items={[
              {
                  key: index,
                  label: 
                      <div className="form-card-label">
                          <SVGIcon src={QuestionImg} width={'20px'} />
                          <div>{summary.label}</div>
                      </div>,
                  children:
                    summaryItems[index].content
              }
          ]}
      />

      </div>
      ))}
    
    </div>
  )
}

export default ProcessInstanceSummaryPage
