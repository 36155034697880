import React from "react";
import classNames from "classnames";

interface DescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Description: React.FC<DescriptionProps> = ({ className, ...props }) => (
  <div className={classNames("alf-form-description", className)} {...props} />
);
