import React from "react";
import { DatePicker, TimePicker } from "antd";

type DateTimeViewProps = {
  processInstance: any;
  stepId: string;
  id: string;
  mode: string;
  format: string;
  showTime: boolean;
  name: string;
  value: any;
  onChange: (value: any) => void;
};

export const DateTimeView: React.FC<DateTimeViewProps> = ({ id, mode, ...props }) => {
  const Component = React.useMemo(() => (mode === "time" ? TimePicker : DatePicker), [mode]);
  const format = React.useMemo(
    () => (props.format ? props.format : mode === "time" ? "HH:mm" : "DD/MM/YYYY" + (props.showTime ? " HH:mm" : "")),
    [mode, props.format, props.showTime]
  );

  const onSelect = (val) => {
    mode === "time" && props.onChange(val);
  };
  return (
    <Component
      id={id}
      format={format}
      allowClear={true}
      // @ts-ignore
      onSelect={onSelect}
      showTime
      showNow={false}
      name={props.name}
      {...props}
    />
  );
};
