import React from 'react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import classNames from 'classnames';

type LinkPropsType = {
  to?: string;
  back?: boolean;
  noUnderline?: boolean;
  className?: string;
  rightIcon?: React.ReactNode;
  href?: string;
  onClick?: () => void;
};

export const Link: React.FC<LinkPropsType> = ({
  to,
  back = false,
  noUnderline = false,
  className,
  rightIcon,
  children,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleClick = back
    ? () => navigate(-1)
    : rest.onClick;

  const linkClassName = classNames(
    'link',
    {
      'link-underlined': !noUnderline
    },
    className
  );

  if (rest.href) {
    return (
      <a
        {...rest}
        className={linkClassName}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        {rightIcon && <div className="link-right-icon">{rightIcon}</div>}
      </a>
    );
  }

  if (to) {
    return (
      <ReactRouterLink
        to={to}
        className={linkClassName}
        onClick={handleClick}
        {...rest}
      >
        {children}
        {rightIcon && <div className="link-right-icon">{rightIcon}</div>}
      </ReactRouterLink>
    );
  }

  return (<></>)
}

// export const MailLink = ({ email, children}) => {
//   return (<ExternalLink to={`mailto:${email}`}>{children}</ExternalLink>)
// }

// type ExternalLinkProps = {
//   to?: string,
//   target: string,
//   rel: string,
//   children?: any,
//   className?: string,
//   alwaysUnderline?: boolean,
//   noUnderline?: boolean
// }

// export default const ExternalLink : React.FC<ExternalLinkProps> = () => {
//   parseTo(to) {
//     const a = document.createElement('a')
//     a.href = to
//     return a
//   }
//   isInternal(toLocation) {
//     return window.location.host === toLocation.host
//   }

//   isSubdomain(toLocation) {
//     return (
//       toLocation.host.indexOf(window.location.host.replace(/^[^.]+\./g, '')) >=
//       0
//     )
//   }

//   const {
//     to,
//     target = '_blank',
//     rel = 'noopener noreferrer',
//     children,
//     className,
//     alwaysUnderline = true,
//     noUnderline = false,
//     ...rest
//   } = this.props

//   const toLocation = this.parseTo(to)
//   const isInternal = this.isInternal(toLocation)
//   const isSubDomain = this.isSubdomain(toLocation)
//   let resolvedTarget
//   if (isSubDomain) {
//     resolvedTarget = '_self'
//   } else {
//     resolvedTarget = target
//   }

//   if (isInternal) {
//     return (
//       <Link to={toLocation.pathname} {...rest}>
//         {children}
//       </Link>
//     )
//   } else {
//     return (
//       <a
//         className={classNames(
//           alwaysUnderline
//             ? 'link-underlined'
//             : noUnderline
//             ? 'link'
//             : 'link-underlined',
//           className
//         )}
//         href={to}
//         target={resolvedTarget}
//         rel={rel}
//         {...rest}
//       >
//         {children}
//       </a>
//     )
//   }
//   return (
//   <div></div>
//   )
// }
