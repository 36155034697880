import React from 'react'

const SVG = props => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M213.333,119.467h85.333c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-85.333
				c-4.71,0-8.533,3.823-8.533,8.533S208.623,119.467,213.333,119.467z"
          />
          <path
            d="M179.2,153.6h153.6c4.719,0,8.533-3.823,8.533-8.533c0-4.71-3.814-8.533-8.533-8.533H179.2
				c-4.71,0-8.533,3.823-8.533,8.533C170.667,149.777,174.49,153.6,179.2,153.6z"
          />
          <path
            d="M256,204.8c-65.877,0-119.467,53.589-119.467,119.467S190.123,443.733,256,443.733s119.467-53.589,119.467-119.467
				S321.877,204.8,256,204.8z M286.669,288.486c-9.02,1.007-19.115,1.647-30.669,1.647c-11.563,0-21.658-0.64-30.669-1.647
				c6.921-34.116,22.434-54.494,30.669-63.309C264.243,234.01,279.748,254.379,286.669,288.486z M289.109,305.408
				c0.606,5.982,1.024,12.194,1.024,18.859c0,6.665-0.418,12.868-1.016,18.842c-9.771-1.084-20.617-1.775-32.981-1.775
				c-12.467,0-23.415,0.7-33.246,1.8c-0.606-5.982-1.024-12.194-1.024-18.867c0-6.664,0.418-12.877,1.024-18.859
				c9.796,1.092,20.693,1.792,33.109,1.792S279.305,306.5,289.109,305.408z M234.889,224.077
				c-9.481,12.919-20.702,33.289-26.377,61.858c-21.973-4.343-34.142-11.017-39.765-14.993
				C183.236,247.33,206.933,229.965,234.889,224.077z M153.6,324.267c0-13.38,2.654-26.138,7.347-37.862
				c8.141,5.385,22.272,12.203,45.056,16.538c-0.708,6.767-1.203,13.79-1.203,21.325c0,7.543,0.495,14.575,1.212,21.342
				c-22.784,4.344-36.915,11.17-45.065,16.546C156.262,350.421,153.6,337.655,153.6,324.267z M168.764,377.617
				c5.683-4.019,17.886-10.658,39.748-14.993c5.683,28.553,16.905,48.913,26.377,61.833
				C206.942,418.569,183.253,401.22,168.764,377.617z M225.34,360.064c9.045-1.015,19.183-1.664,30.797-1.664
				c11.494,0,21.555,0.64,30.532,1.63c-6.921,34.133-22.434,54.511-30.669,63.326C247.765,414.532,232.252,394.155,225.34,360.064z
				 M277.12,424.457c9.472-12.919,20.693-33.306,26.377-61.884c21.922,4.318,34.125,10.957,39.791,14.95
				C328.806,401.178,305.092,418.56,277.12,424.457z M358.4,324.267c0,13.355-2.654,26.086-7.313,37.794
				c-8.184-5.385-22.323-12.186-45.09-16.495c0.708-6.758,1.203-13.773,1.203-21.299c0-7.535-0.503-14.558-1.212-21.316
				c22.75-4.318,36.907-11.119,45.09-16.495C355.746,298.163,358.4,310.903,358.4,324.267z M303.488,285.935
				c-5.675-28.561-16.896-48.939-26.368-61.858c27.964,5.888,51.669,23.27,66.167,46.908
				C337.545,275.021,325.308,281.626,303.488,285.935z"
          />
          <path
            d="M401.067,34.133h-8.533V25.6c0-14.114-11.486-25.6-25.6-25.6H93.867c-14.114,0-25.6,11.486-25.6,25.6v477.867
				c0,4.71,3.823,8.533,8.533,8.533h324.267c23.927,0,42.667-18.739,42.667-42.667V76.8
				C443.733,53.274,424.593,34.133,401.067,34.133z M426.667,469.333c0,14.353-11.238,25.6-25.6,25.6H85.333V25.6
				c0-4.702,3.831-8.533,8.533-8.533h273.067c4.71,0,8.533,3.831,8.533,8.533v8.533H110.933c-4.71,0-8.533,3.823-8.533,8.533
				c0,4.71,3.823,8.533,8.533,8.533h290.133c13.875,0,25.6,11.725,25.6,25.6V469.333z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export default SVG
