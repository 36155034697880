import React, { FC, ChangeEvent } from 'react'
import { Input } from 'antd'

interface MoneyViewProps {
  id: string
  type?: string
  stepId?: any
  processInstance?: any
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  [key: string]: any
}

export const MoneyView: FC<MoneyViewProps> = ({ id, type, stepId, processInstance, ...props }) => {
  const onBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value
    const { onChange } = props
    if (typeof onChange === 'function' && val && val.length > 1) {
      if (!Number.isInteger(Number(val[val.length - 1]))) {
        onChange(evt)
      }
    }
  }
  return <Input prefix="€" id={id} {...props} onBlur={onBlur} />
}